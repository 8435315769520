import {Col} from 'react-bootstrap';
import {Button} from '../../common';
import {useAuthContext, useThemeContext} from '../../../context';
import {useInternet} from '../../../hooks';
import useTime from '../../../hooks/useTime';
import {Sync} from '../../../api';
import {useState} from 'react';
import Icon from '../../../assets/icons';

const NavbarRight = ({type}) => {
    const {theme} = useThemeContext();
    const {isOffline} = useInternet();
    const {currentTime} = useTime();
    const {user} = useAuthContext();
    const [syncing, setSyncing] = useState(false);

    const handleSync = () => {
        if (syncing) return;
        setSyncing(true);

        setTimeout(() => {
            Sync(user?.email);
            setSyncing(false);
        }, 3000);
    };

    return (
        <Col md={6}>
            <div className="headerRigthSide header LeftActionButton">
                <Button
                    className="marBot0"
                    type="iconButton"
                    icon_name="printerNewIcon"
                    icon_fill={theme.brightGreen}
                    icon_width={'20'}
                    icon_height={'20'}
                    icon_viewBox={'0 0 18 18'}
                />

                <p className="topBarseperator  height20"></p>

                <span className={`cursorPointer ${syncing ? 'rotating' : 'paused'}`} onClick={handleSync}>
                    <Icon name="syncIcon" viewBox={'0 0 18 19'} />
                </span>
                <p className="topBarseperator  height20"></p>

                <Button
                    className="marBot0"
                    type="iconButton"
                    icon_name="wifiIcon2"
                    icon_fill={isOffline ? theme.wifiOff : theme.white}
                    icon_width={'20'}
                    icon_height={'20'}
                    icon_viewBox={'0 0 18 18'}
                />

                <p className="topBarseperator  height20"></p>

                <Button
                    className="marBot0"
                    type="iconButton"
                    icon_name="bluetoothIcon"
                    icon_fill={theme.white}
                    icon_width={'10'}
                    icon_height={'16'}
                    icon_viewBox={'0 0 10 16'}
                />

                <p className="topBarseperator height20"></p>

                <p className="fontSize14 fontWeight400 marBot0" style={{color: theme.white}}>
                    {currentTime}
                </p>
            </div>
        </Col>
    );
};

export default NavbarRight;
