import React from 'react';
import {useLanguageContext, useThemeContext} from '../../../context';
import {formatNum} from '../../../constants';

const ReviewPaymentCard = ({length, payee, key}) => {
    const {theme} = useThemeContext();
    const {I18n} = useLanguageContext();

    return (
        <>
            <div className="reviewPaymentCardWrapper marTop20" key={key}>
                <div className="spaceBetweenCenter ">
                    <p className="fontSize16 marBot5 fontWeight500" style={{color: theme.white}}>
                        {payee.account_title}

                        <span className="marLeft10 fontSize12 fontWeight400 " style={{color: theme.darkGrayTwo}}>
                            <img
                                src={payee.logo_link}
                                title={`${payee.bank_name} logo`}
                                alt={`${payee.bank_name} logo`}
                                style={{
                                    width: '20px',
                                    height: '20px',
                                    objectFit: 'contain',
                                    marginRight: '5px',
                                    marginBottom: '5px',
                                }}
                            />
                            {payee.bank_name}
                        </span>
                    </p>

                    <p className="fontSize16 marBot5 fontWeight400" style={{color: theme.text}}>
                        {payee.account_number}
                    </p>
                </div>

                <div className="">
                    <p className="fontSize14 marBot0 " style={{color: theme.white}}>
                        {`${I18n.transfer_amount}: `}
                        <span className="fontSize16 marBot0 fontWeight500"> Rs. {formatNum(payee.amount)}</span>
                    </p>
                </div>
            </div>
            {key < length - 1 && <div className="thinDivider width100 marTop20" />}
        </>
    );
};

export default ReviewPaymentCard;
