import { StaticDateRangePicker } from "@mui/x-date-pickers-pro/StaticDateRangePicker";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { formatDateAndDays, shortcutsItems } from "../../../constants";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { useThemeContext } from "../../../context";
import Icon from "../../../assets/icons";
import { useState } from "react";
import * as React from "react";
import {
  PickersLayoutRoot,
  usePickerLayout,
} from "@mui/x-date-pickers/PickersLayout";
import ClickAwayListener from '@mui/material/ClickAwayListener'; // Import ClickAwayListener

const CustomLayout = (props) => {
  const { toolbar, content, shortcuts } = usePickerLayout(props);
  return (
    <PickersLayoutRoot ownerState={props} className="PickersLayoutRoot">
      <div className="shortcuts">{shortcuts}</div>
      <div className="contentWrapper">
        <div className="content">{content}</div>
        <div className="toolbar">{toolbar}</div>
      </div>
    </PickersLayoutRoot>
  );
};

const RangePicker = ({onFilterDate, className, vs = true}) => {
    const {theme} = useThemeContext();

  const [toggle, setToggle] = useState(false);
  const [dateRange, setDateRange] = useState({
    PreviousLabel: "Yesterday",
    currentLabel: "Today",
    range: [null, null],
  });

  const handleToggle = () => setToggle((prev) => !prev);

    const handleChange = (newValue, ctx) => {
        if (newValue[0] != dateRange.range[0] && !ctx?.shortcut?.label) {
            setDateRange({...dateRange, range: [newValue[0], null]});
        } else {
            let currentLabel,
                PreviousLabel,
                diffInDays = 0;

            const [startDate, endDate] = [new Date(newValue[0]), new Date(new Date(newValue[1]).setHours(23, 59, 59))];

            currentLabel = ctx?.shortcut?.label || `${formatDateAndDays(startDate)} - ${formatDateAndDays(endDate)}`;

            if (currentLabel.includes('Today') || currentLabel.includes('This')) {
                if (currentLabel == 'Today') PreviousLabel = 'Yesterday';
                else PreviousLabel = currentLabel.replace(/\b(This)\b/g, 'previous');
            } else {
                const oneDay = 1000 * 60 * 60 * 24;
                diffInDays = Math.ceil((endDate.getTime() - startDate.getTime()) / oneDay);
                PreviousLabel = `${diffInDays} days prior`;
            }

            onFilterDate(ctx?.shortcut?.label || 'Custom', startDate, endDate, diffInDays);
            setDateRange({PreviousLabel, currentLabel, range: newValue});
        }
    };

   


  const handleClickAway = () => {
    setToggle(false);
  };

  return (
    <>
      <div className="summaryDateBox">
        <div onClick={handleToggle} className="DatePickerContainer">
          <p
            className=" fontSize14 marBot0 fontWeight400 marRight10"
            style={{ color: theme.white }}
          >
            {dateRange.currentLabel}
          </p>

          <Icon
            viewBox={"0 0 8 5"}
            fill={theme.white}
            name={"downIcon"}
            height={"11"}
            width={"14"}
          />
        </div>
        
        {vs ?
          <div className="DatePickerCompareContainer" style={{ borderColor: dateRange.PreviousLabel ? theme.newBorderColorTwo : theme.darkGrayTwo }}>
            <p
              style={{ color: dateRange.PreviousLabel ? theme.newBorderColorTwo : theme.darkGrayTwo }}
              className="fontSize14 marBot0 fontWeight400 "
            >
              vs {dateRange.PreviousLabel}
            </p>
          </div>
          : null}

        {toggle ? (
          <ClickAwayListener onClickAway={handleClickAway}>
            <div className={`${className} rangePickerWrapper`}>
              <LocalizationProvider dateAdapter={AdapterDayjs}>
                <StaticDateRangePicker
                  slots={{ layout: CustomLayout }}
                  value={dateRange.range}
                  onChange={handleChange}
                  slotProps={{
                    shortcuts: {
                      items: shortcutsItems,
                    },
                    actionBar: { actions: [] },
                  }}
                  leftArrow
                  localeText={{
                    start: "Start",
                    end: "End",
                  }}
                />
              </LocalizationProvider>
            </div>
          </ClickAwayListener>
        ) : null}
      </div>
    </>
  );
};
export default RangePicker;
