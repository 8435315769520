import {Model} from '@nozbe/watermelondb';
import {field, relation} from '@nozbe/watermelondb/decorators';
import {DEAL_PRODUCT_SCHEMA} from '../schema';

export default class DealProduct extends Model {
    static table = DEAL_PRODUCT_SCHEMA;

    static associations = {
        deal: {type: 'belongs_to', key: 'deal_id'},
        product: {type: 'belongs_to', key: 'product_id'},
    };

    @field('quantity') quantity;

    @relation('product', 'product_id') product;
    @relation('deal', 'deal_id') deal;
}
