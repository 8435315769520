import React from 'react';
import {useLanguageContext, useThemeContext} from '../../../context';
import Button from '../Button';

const DashboardCardNonIdeal = ({data}) => {
    const {I18n} = useLanguageContext();
    const {theme} = useThemeContext();
    const {title, subTitle, actionTitle, handleAction, showCta} = data;
    return (
        <div className="cardBoxNonIdeal">
            <h3 className="fontWeight600 fontSize24 white marBot10 textCenter">{I18n[title]}</h3>
            <p className="fontWeight400 fontSize16 darkGrayTwo marBot40 width45 textCenter">{I18n[subTitle]}</p>
            {showCta && (
                <Button
                    type="secondary"
                    className={`fontWeight600 fontSize16 cursorPointer`}
                    backgroundColor={theme.brightGreen}
                    txtColor={theme.white}
                    title={I18n[actionTitle]}
                    handleClick={handleAction}
                />
            )}
        </div>
    );
};

export default DashboardCardNonIdeal;
