import {useLanguageContext, useThemeContext} from '../../../../../context';
import {CheckBox, Dropdown, MessageModal} from '../../../../common';
import TableBody from '../../../../common/TableContainer/TableBody';
import withObservables from '@nozbe/with-observables';
import {firstLetterCaptilize} from '../../../../../constants';
import {useNavigate} from 'react-router-dom';
import {useState} from 'react';

const List = ({rider, orders, handleChecked, selected, setSelected, setShowMessage, filter, userPermission}) => {
    let navigate = useNavigate();

    const {I18n} = useLanguageContext();
    const {theme} = useThemeContext();
    const [archiveModal, setArchiveModal] = useState(false);
    const toggleArchiveModal = () => setArchiveModal(x => !x);

    const handleArchive = async rider => {
        setArchiveModal(false);
        let data = rider?.id;
        if (selected[data]) {
            setSelected(items => {
                let obj = {...items};
                let item = obj[data];
                if (item) delete obj[data];
                return obj;
            });
        }

        await rider.archiveRider(rider?.archive ? false : true);
        setShowMessage({
            visible: true,
            message: rider?.archive ? I18n.rider_archived_successfully : I18n.rider_unarchived_successfully,
        });
    };

    const tableAction = [
        {
            title: I18n.edit,
            action: () => navigate(`/riders/${rider.id}/edit`),
        },

        {
            title: I18n[rider.archive ? 'unarchive' : 'archive'],
            action: toggleArchiveModal,
        },
    ];

    return (
        <>
            <TableBody className={`fiveColumn cursorPointer`} style={{borderColor: theme.inputBorder}}>
                <div className="checkboxCol">
                    <CheckBox
                        checkboxLabel={
                            <p className="marBot0 fontSize14 OneLineTruncate" style={{color: theme.white}}>
                                {firstLetterCaptilize(rider.name)}
                            </p>
                        }
                        onChange={() => handleChecked(rider)}
                        checked={selected[rider?.id]}
                    />
                </div>
                <div className="BoxWidth justifyCenter">
                    <p className="fontSize14 OneLineTruncate" style={{color: theme.white}}>
                        {rider.phone_number}
                    </p>
                </div>
                <div className="BoxWidth justifyCenter">
                    <p className="fontSize14 OneLineTruncate" style={{color: theme.white}}>
                        {rider.email || '-'}
                    </p>
                </div>
                <div className="BoxWidth justifyCenter">
                    <p className="fontSize14 OneLineTruncate" style={{color: theme.white}}>
                        {rider.city || '-'}
                    </p>
                </div>

                {userPermission?.canCrud && (
                    <div className="BoxWidth justifyCenter">
                        <div className="listActionBox">
                            <Dropdown
                                dropDown={{
                                    name: 'threeDotIcon',
                                    fill: theme.barclaysBlue,
                                    width: 18,
                                    height: 4,
                                    viewBox: '0 0 18 4',
                                    option: tableAction,
                                }}
                            />
                        </div>
                    </div>
                )}
            </TableBody>

            <MessageModal
                setToggle={setArchiveModal}
                toggle={archiveModal}
                className="messageModal"
                description={I18n[rider.archive ? 'do_you_really_want_to_unarchive_this_rider' : 'do_you_really_want_to_archive_this_rider']}
                secondaryCta={{
                    title: I18n.cancel,
                    action: toggleArchiveModal,
                }}
                primaryCta={{
                    title: I18n[rider.archive ? 'unarchive' : 'archive'],
                    action: () => {
                        handleArchive(rider);
                    },
                }}
            />
        </>
    );
};

const enhance = withObservables(['rider'], ({rider}) => ({
    rider: rider.observe(),
    orders: rider.order.observe(),
}));

export default enhance(List);
