import React from 'react';
import {Dropdown} from '..';
import {useLanguageContext, useThemeContext} from '../../../context';
import Icon from '../../../assets/icons';

const NewDropDown = ({setSelectedLocation, selectedLocation, filterOption = [], title = '', iconName}) => {
    const {theme} = useThemeContext();
    const {I18n} = useLanguageContext();


    const titles = Object.keys(selectedLocation || {}).length == 0 
    ? I18n.select_location 
    : Object.keys(selectedLocation || {}).length > 1 
    ? I18n.multi_location 
    : selectedLocation[Object.keys(selectedLocation)[0]]?.business_name;
    


    return (
        <div className="locationDropDown">
            <span className="locationDropIcon">
                <Icon name={iconName} fill={theme.white} width="19" height="22" viewBox="0 0 19 22" />
                <b>{title}:</b>
            </span>
            <Dropdown
                type="checkBox"
                dropDown={{
                    title: titles,
                    option: filterOption,
                    setSelectedItem: setSelectedLocation,
                    selectedItem: selectedLocation,
                }}
            />
        </div>
    );
};

export default NewDropDown;
