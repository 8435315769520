import React, { useState } from 'react';
import {Col, Row} from 'react-bootstrap';
import { useLanguageContext, useThemeContext } from '../../../../../../context';
import { NonIdealScreen } from '../../../../../common';
import usePermission from '../../../../../../hooks/usePermission';
import { online_nonideal } from '../../../../../../assets/images/image';

const SaleOnline = () => {

    const {I18n} = useLanguageContext();
    const {theme} = useThemeContext();
    const userPermission = usePermission('category');

    const handleOnboarding = () => {
        // handleOnboarding
    }
    
    return false?(
        <div>
            <p style={{color: theme.white}}>
                Sale Online
            </p>
        </div>
    )
    :
    (
    <div className='onlineNonIdeal'>

        <NonIdealScreen
                heading={I18n.achieve_unmatched_sales_growth_online}
                subHeading={I18n.achieve_unmatched_para}
                nonIdealImage={online_nonideal}
                name="categoryIcon"
                fill={theme.white}
                secondaryCta={
                    userPermission?.canCrud
                        ? {
                                title: I18n.start_onboarding,
                                action: handleOnboarding,
                            }
                        : null
                }
                isMultiStep={false}
            />
    </div>
    );
};

export default SaleOnline;
