import Account from './account';
import Business from './business';
import Category from './category';
import Counter from './counter';
import Customer from './customer';
import Discount from './discount';
import Item from './item';
import Option from './option';
import Order from './order';
import OrderLine from './order_line';
import Product from './product';
import ProductTemplate from './product_template';
import PurchaseTax from './purchase_tax';
import SalesTax from './sales_tax';
import Session from './session';
import User from './user';
import UserBusiness from './user_business';
import Company from './company';
import PurchaseOrder from './purchase_order';
import PurchaseOrderLine from './po_line';
import Role from './role';
import Module from './module';
import Modifier from './modifier';
import ModifierOption from './modifier_option';
import ProductModifier from './product_modifier';
import FloorPlan from './floor_plan';
import DineInTable from './dine_in_table';
import Waiter from './waiter';
import Rider from './rider';
import payment from './payment';
import SettingConfig from './setting_config';
import ProductBusiness from './product_business';
import DiscountBusiness from './discount_business';
import CreditTransaction from './credit_transaction';
import PaymentMethod from './payment_method';
import Deal from './deal';
import DealProduct from './deal_product';

export const Models = [
    Account,
    Business,
    Role,
    Module,
    User,
    UserBusiness,
    Category,
    Item,
    Customer,
    ProductTemplate,
    Product,
    ProductBusiness,
    Option,
    Session,
    Order,
    OrderLine,
    Discount,
    SalesTax,
    PurchaseTax,
    Counter,
    Company,
    PurchaseOrder,
    PurchaseOrderLine,
    Modifier,
    ModifierOption,
    ProductModifier,
    FloorPlan,
    DineInTable,
    Waiter,
    Rider,
    payment,
    SettingConfig,
    DiscountBusiness,
    CreditTransaction,
    PaymentMethod,
    Deal,
    DealProduct,
];
