import React from 'react';
import {useThemeContext} from '../../../../../../context';

const List = ({mod, selectedModifier, handleSelectModifier}) => {
    const {theme} = useThemeContext();

    return (
        <div>
            <p className="fontSize14 marBot10 marTop20" style={{color: theme.darkGrayTwo}}>
                {mod?.modifiers?.name}
            </p>
            <div className="flex gap20">
                {mod.modifiersOption
                    ?.filter(modOpt => modOpt.in_stock)
                    ?.map(opt => {
                        return (
                            <div
                                className={`cursorPointer ${selectedModifier?.[mod?.modifiers?.id]?.[opt.id] ? 'selectedModifierBox' : 'modifierBox'}`}
                                onClick={() => handleSelectModifier(mod?.modifiers?.id, opt)}>
                                <span className="fontSize14 marBot " style={{color: theme.white}}>
                                    {opt.name}
                                </span>
                                <p className="fontSize14 marBot0" style={{color: theme.darkGrayTwo}}>
                                    {`Rs. ${opt.price || 0}`}
                                </p>
                            </div>
                        );
                    })}
            </div>
        </div>
    );
};

export default List;
