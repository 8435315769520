import {useEffect, useState} from 'react';
import {useAuthContext, useLanguageContext} from '../../../../../context';
import {useOrder} from '../../../../../hooks';
import {Loading, OrderDetailCompnent} from '../../../../common';
import {useSearchParams} from 'react-router-dom';
import {findBusinessByID} from '../../../../../pos-core/database/helpers';

const OrderDetails = () => {
    const {business} = useAuthContext();
    const [searchParams] = useSearchParams();
    const [resolvedBusiness, setResolvedBusiness] = useState(business);
    const business_id = searchParams?.get('business_id');

    const handleBusiness = async () => {
        if (business_id) {
            const businessData = await findBusinessByID(business_id);
            setResolvedBusiness(businessData);
        }
    };

    useEffect(() => {
        handleBusiness();
    }, [business_id]);

    const {isLoading, order} = useOrder(resolvedBusiness);

    return isLoading ? <Loading /> : <OrderDetailCompnent order={order} />;
};

export default OrderDetails;
