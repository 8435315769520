import React from 'react';
import {useLanguageContext, useThemeContext} from '../../../context';
import {formatNum} from '../../../constants';

const MetricsCard = ({data, index}) => {
    const {I18n} = useLanguageContext();
    const {theme} = useThemeContext();
    const {title, value} = data || {};

    return (
        <div className="metricsBoxInner" key={index}>
            <p className="fontSiz16 fontweight400 darkGrayTwo marBot10">{I18n[title]}</p>
            <p className="fontSiz16 fontWeight600 white marBot10">{formatNum(value, 'Rs. ')}</p>
        </div>
    );
};

export default MetricsCard;
