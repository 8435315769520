import {Model} from '@nozbe/watermelondb';
import {field, text, relation, children, writer, date} from '@nozbe/watermelondb/decorators';
import {DEAL_SCHEMA} from '../schema';

export default class Deal extends Model {
    static table = DEAL_SCHEMA;

    static associations = {
        account: {type: 'belongs_to', key: 'account_id'},
        product: {type: 'has_many', foreignKey: 'deal_id'},
    };

    @text('name') name;
    @text('discount_rule') discount_rule;
    @field('amount') amount;
    @field('archive') archive;
    @date('created_at') created_at;

    @relation('account', 'account_id') account;
    @children('product') product;

    @writer async archiveDeal(status = true) {
        return await this.update(deal => {
            deal.archive = status;
        });
    }

    @writer async updateDeal(payload) {
        return await this.update(deal => {
            deal.name = payload.name;
            deal.amount = payload.amount;
        });
    }
}
