import {useNavigate} from 'react-router-dom';
import {useLanguageContext, useThemeContext} from '../../../context';
import {Col, Container, Row} from 'react-bootstrap';
import {Button, Dropdown, SearchBox, TextField} from '../../common';

const PosCockpitHeader = ({leftCta, centerCta, rightCta, search}) => {
    const navigate = useNavigate();

    const {I18n} = useLanguageContext();
    const {theme} = useThemeContext();

    return (
        <section className="posCockpitHeader">
            <Container fluid>
                <Row>
                    <Col md={6}>
                        <div className="topBarLeftSide">
                            <SearchBox prefixFill="white" {...search} />
                        </div>
                    </Col>
                    <Col md={2}>
                        <div>
                            <Dropdown
                                type="radioList"
                                dropDown={{
                                    title: leftCta.title,
                                    name: leftCta.name,
                                    fill: leftCta.fill,
                                    width: leftCta.width,
                                    height: leftCta.height,
                                    viewBox: leftCta.viewBox,
                                    option: leftCta.option,
                                }}
                            />
                        </div>
                    </Col>
                    <Col md={2}>
                        <Button
                            className={`posCockpitHeaderRightCta width100 fontWeight500 fontSize14 cursorPointer`}
                            backgroundColor={centerCta.backgroundColor}
                            borderColor={centerCta.borderColor}
                            txtColor={centerCta.textColor}
                            title={centerCta.title}
                            handleClick={centerCta.action}
                        />
                    </Col>
                    <Col md={2}>
                        <Button
                            className={`posCockpitHeaderRightCta width100 fontWeight500 fontSize14 cursorPointer`}
                            background={theme.brightGreen}
                            txtColor={theme.white}
                            title={rightCta.title}
                            handleClick={rightCta.action}
                        />
                    </Col>
                </Row>
            </Container>
        </section>
    );
};

export default PosCockpitHeader;
