import {Outlet, useLocation, useNavigate} from 'react-router-dom';
import {RequireAuth} from '../../../services';
import Navbar from '../Navbar';
import HamburgerMenu from '../HamburgerMenu';
import {Sync} from '../../../api';
import {useEffect} from 'react';
import {useAuthContext} from '../../../context';

const Menu = () => {
    const {pathname} = useLocation();
    const {user, handleLogoutInDB, setRenderToast} = useAuthContext();
    let navigate = useNavigate();

    const isMenuHidden = pathname == '/business-details' ? true : false;

    const checkUnsyncedChanges = async () => {
        if (user?.email) {
            let timer = setTimeout(async () => {
                try {
                    const resp = await Sync(user.email);
                    if (resp?.response?.status === 401) {
                        const force_logout = true;
                        handleLogoutInDB(force_logout);
                        navigate('/login', {replace: true});
                        let toastMessage = 'forced_logout';
                        setRenderToast(toastMessage);
                    }
                } catch (error) {
                    console.log('ERROR ------>', error);
                }
            }, 500);
            return () => clearTimeout(timer);
        }
    };

    useEffect(() => {
        checkUnsyncedChanges();
    }, [pathname]);

    return (
        <RequireAuth>
            {isMenuHidden ? null : (
                <>
                    <Navbar from="primary" />
                    <HamburgerMenu />
                </>
            )}

            <Outlet />
        </RequireAuth>
    );
};

export default Menu;
