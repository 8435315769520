import React from 'react';
import RangePicker from '../RangePicker';
import Icon from '../../../assets/icons';
import {useThemeContext} from '../../../context';

const RangePickerCalendar = ({onFilterDate}) => {
    const {theme} = useThemeContext();
    return (
        <div className="OrderCalenderConatiner">
            <span className="dateDropIcon">
                <Icon name="calendarIcon" fill={theme.white} width="22" height="22" viewBox="0 0 24 24" />
            </span>
            <RangePicker onFilterDate={onFilterDate} className="orderCalender" />
        </div>
    );
};

export default RangePickerCalendar;
