import React from 'react'
import { useLanguageContext } from '../../../context'

const DashboardGraphNonIdeal = ({ data }) => {
    const { title, image } = data;
    const { I18n } = useLanguageContext();
  return (
    <div className='graphBoxNonIdeal'>
      <img src={image} title="" alt="" />
      <p className='fontWeight400 fontSize14 nonIdealText marBot40 width45 textCenter marBot0 marTop10'>
        {I18n[title]}
      </p>
    </div>
  )
}
export default DashboardGraphNonIdeal
