import {useEffect, useState} from 'react';
import {useParams} from 'react-router-dom';

const useModifier = account => {
    const {id} = useParams();
    const [isLoading, setIsLoading] = useState(id ? true : false);
    const [modifier, setModifier] = useState();

    useEffect(() => {
        handleModifier(id);
    }, [id]);

    const handleModifier = async id => {
        let modifierList = await account.getModifier(id).fetch();
        if (modifierList.length) setModifier(modifierList[0]);
        setIsLoading(false);
    };

    return {isLoading, modifier};
};

export default useModifier;
