import React from 'react';
import {DEVICE_HEIGHT} from '../../../constants';
import {useLanguageContext, useThemeContext} from '../../../context';
import ReportsBoxHeader from '../Header/ReportsBoxHeader';
import NonIdealScreen from '../NonIdeal';
import TableHeader from '../TableContainer/TableHeader';
import TableFooter from '../TableFooter';
import List from './List';
import Loading from '../Loading';

const CategorySaleReport = ({data, handleExportCSV, loading}) => {
    const {I18n} = useLanguageContext();
    const {theme} = useThemeContext();

    const handleExport = async () => {
        await handleExportCSV();
    };

    return loading ? (
        <div className="reportsWrapper">
            <div className="reportsWrapperInner">
                <Loading />
            </div>
        </div>
    ) : data?.sales_category?.length > 0 ? (
        <div className="reportsWrapper">
            <div className="reportsWrapperInner">
                <ReportsBoxHeader
                    title="category_sales"
                    primaryCta={{
                        title: 'export_csv',
                        action: handleExport,
                    }}
                />

                <TableHeader className={`threeColumn`} style={{borderColor: theme.inputBorder}}>
                    <div className="BoxWidth justifyStart">
                        <p className="fontSize16 OneLineTruncate" style={{color: theme.white}}>
                            {I18n.category}
                        </p>
                    </div>

                    <div className="BoxWidth justifyCenter">
                        <p className="fontSize16 OneLineTruncate" style={{color: theme.white}}>
                            {I18n.items_sold}
                        </p>
                    </div>

                    <div className="BoxWidth justifyEnd">
                        <p className="fontSize16 OneLineTruncate" style={{color: theme.white}}>
                            {I18n.sales}
                        </p>
                    </div>
                </TableHeader>

                <div id="scrollableDiv" className='tableListMainBox' style={{height: DEVICE_HEIGHT - 490, overflow: 'auto'}}>
                    {data?.sales_category?.map((item, index) => {
                        return <List data={item} index={index} />;
                    })}
                </div>
                <TableFooter title="total" value={data?.total_sales || 0} />
            </div>
        </div>
    ) : (
        <div className="onlineNonIdeal reportDashboardBox">
            <NonIdealScreen heading={I18n.category_sales} subHeading={I18n.no_activity_zero_transactions_recorded} name="categorySalesIcon" fill={theme.white} isMultiStep={false}  viewBox='0 0 100 100'/>
        </div>
    );
};

export default CategorySaleReport;
