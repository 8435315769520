import React, {useState} from 'react';
import {useAuthContext, useLanguageContext, useThemeContext} from '../../../../context';
import Icon from '../../../../assets/icons';
import RadioButton from '../../../common/RadioButton';
import {updateDeviceConfiguration} from '../../../../api';
import {formatDate} from '../../../../constants';

const radioOptions = [
    {
        label: (
            <div className="">
                <div className="displaySizeIconBox cursorPointer">
                    <Icon name="flexibleGridColoredIcon" width={'105'} height={'58'} viewBox={'0 0 105 58'} />
                </div>
                <p className="marBot0 marTop10 textCenter fontSize14 fontWeight400 white">Standard</p>
            </div>
        ),
        value: 'standard',
    },
    {
        label: (
            <div>
                <div className="displaySizeIconBox cursorPointer">
                    <Icon name="fullLengthGridColoredIcon" width={'103'} height={'61'} viewBox={'0 0 103 61'} />
                </div>
                <p className="marBot0 marTop10 textCenter fontSize14 fontWeight400 white">Full length</p>
            </div>
        ),
        value: 'fullLength',
    },
];

const DisplaySetting = () => {
    const {theme} = useThemeContext();
    const {I18n} = useLanguageContext();
    const {deviceConfiguration, handleDeviceCofiguration} = useAuthContext();
    const [selectedSize, setSelectedSize] = useState(deviceConfiguration?.configuration?.card_size);

    const handleChange = async e => {
        const value = e?.target.value;
        const deviceId = deviceConfiguration?.id;
        try {
            setSelectedSize(value);
            const devicePayload = {
                card_size: value,
            };
            const response = await updateDeviceConfiguration(devicePayload, deviceId);
            handleDeviceCofiguration(response?.data);
        } catch (error) {
            console.log('error', error);
        }
    };

    return (
        <div className="settingsConfigurationWrapper">
            <p className="marBot0 fontSize20 fontWeight600" style={{color: theme.white}}>
                {I18n.configuration}
            </p>

            <div className="displaySizeIconBoxWrapper marTop50">
                <RadioButton options={radioOptions} className="displaySizeIconBoxWrapper" selectedSize={selectedSize} handleChange={handleChange} />
            </div>
            <p className="notificationHeadSeperator"></p>
            <div>
                <p className="marBot30 marTop30 fontSize20 fontWeight600" style={{color: theme.white}}>
                    {I18n.display_information}
                </p>

                <div className="flex" style={{gap: '100px'}}>
                    <p className="fontSize14 fontWeight400 white">{I18n.display_name}</p>

                    <p className="textCenter fontSize14 fontWeight400 white">{deviceConfiguration?.name}</p>
                </div>

                <div className="flex" style={{gap: '110px'}}>
                    <p className="fontSize14 fontWeight400 white">{I18n.sign_in_date}</p>

                    <p className="textCenter fontSize14 fontWeight400 white">{formatDate(deviceConfiguration?.created_at)}</p>
                </div>

                <div className="flex" style={{gap: '55px'}}>
                    <p className="fontSize14 fontWeight400 white">{I18n.point_of_sale_system}</p>

                    <p className="textCenter fontSize14 fontWeight400 white">{deviceConfiguration?.configuration?.pos_user}</p>
                </div>
            </div>
            <p className="notificationHeadSeperator"></p>
        </div>
    );
};

export default DisplaySetting;
