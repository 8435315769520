import {Col, Container, Row} from 'react-bootstrap';
import {useLanguageContext, useThemeContext} from '../../../context';
import {CustomerDetailComponent} from '../../common';
import {DEVICE_HEIGHT, formatDateAndTime, formatNum} from '../../../constants';
import TableHeader from '../TableContainer/TableHeader';
import OrderDetailList from './OrderDetailList';
import withObservables from '@nozbe/with-observables';
import {NewPosHeader} from '../V2';

const OrderDetailCompnent = ({order, order_lines, sales_tax, discount, table, isCustomerDetails = false, customer, payments}) => {
    const {I18n} = useLanguageContext();
    const {theme} = useThemeContext();
    let headerTitle = `${I18n.order} ${order.number}`;

    console.log('ORDER', order);
    console.log('ORDER Line', order_lines);

    return (
        <>
            <div className="itemListContainer orderDetailList" fluid style={{background: theme.topBarBG}}>
                <Container className="noPadding" fluid>
                    <Row className="justify-content-md-center noMargin">
                        <Col md={7} className="noPadding">
                            <NewPosHeader
                                backBtnCta={true}
                                title={{
                                    name: headerTitle,
                                    decs: `(${formatDateAndTime(order.completed_at)})`,
                                }}
                                rightAction={{
                                    title: `Payment received by ${order?.payment_method?.toLowerCase()} of ${I18n.rs} ${formatNum(order.total)}`,
                                    txtColor: theme.white,
                                    icon_name: 'tickCircleIcon',
                                    icon_fill: theme.brightGreen,
                                }}
                            />
                            <div className="orderDetailMainBox" style={{height: DEVICE_HEIGHT - 110}}>
                                <TableHeader className={`detailTbleColumn`} style={{borderColor: theme.inputBorder}}>
                                    <div className="BoxWidth marLeft10 justifyStart">
                                        <p className="fontSize16 OneLineTruncate " style={{color: theme.white}}>
                                            {I18n.item}
                                        </p>
                                    </div>
                                    <div className="BoxWidth justifyCenter">
                                        <p className="fontSize16 OneLineTruncate" style={{color: theme.white}}>
                                            {I18n.qty}
                                        </p>
                                    </div>
                                    <div className="BoxWidth justifyCenter">
                                        <p className="fontSize16 OneLineTruncate" style={{color: theme.white}}>
                                            {I18n.discount}
                                        </p>
                                    </div>
                                    <div className="BoxWidth justifyCenter">
                                        <p className="fontSize16 OneLineTruncate" style={{color: theme.white}}>
                                            {I18n.amount}
                                        </p>
                                    </div>
                                    <div className="BoxWidth justifyCenter">
                                        <p className="fontSize16 OneLineTruncate" style={{color: theme.white}}>
                                            {I18n.status}
                                        </p>
                                    </div>
                                </TableHeader>
                                <div className="tableListMainBox" style={{height: DEVICE_HEIGHT - 170}}>
                                    {order_lines.map(val => (
                                        <OrderDetailList line={val} />
                                    ))}
                                </div>
                            </div>
                        </Col>

                        <Col md={5} className="noPadding">
                            <div className="orderDetailRightSide" style={{background: theme.topBarBG}}>
                                <NewPosHeader
                                    title={{
                                        name: I18n.summary_and_details,
                                    }}
                                />

                                <CustomerDetailComponent
                                    customer={customer}
                                    amountBoxShow={true}
                                    order={order}
                                    sales_tax={sales_tax}
                                    discount={discount}
                                    order_lines={order_lines}
                                    table={table}
                                />
                            </div>
                        </Col>
                    </Row>
                </Container>
            </div>
        </>
    );
};

const enhance = withObservables(['order'], ({order}) => ({
    customer: order.customer.observe(),
    order_lines: order.order_line.observe(),
    discount: order.discount.observe(),
    sales_tax: order.sales_tax.observe(),
    payments: order.payment.observe(),
    table: order.dine_in_table.observe(),
}));

export default enhance(OrderDetailCompnent);
