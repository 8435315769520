import {useLanguageContext, useThemeContext} from '../../../context';
import {Dropdown} from 'react-bootstrap';
import {CheckBox} from '../../common';

const DropDownCheckbox = ({dropDown}) => {
    const {I18n} = useLanguageContext();
    const {theme} = useThemeContext();

    const {option, selectedItem, setSelectedItem, title} = dropDown || {};

    const handleCheckboxChange = item => {
        setSelectedItem(prev => {
            if (item.id === 'all') {
                if (prev?.[item.id]?.selected) {
                    return {};
                } else {
                    const updatedLocations = option.reduce((acc, loc) => {
                        acc[loc.id] = {
                            business_name: loc.business_name,
                            selected: true,
                        };
                        return acc;
                    }, {});
                    return updatedLocations;
                }
            } else {
                const updatedLocations = {...prev};
                if (prev?.[item.id]?.selected) {
                    delete updatedLocations[item.id];
                } else {
                    updatedLocations[item.id] = {
                        business_name: item.business_name,
                        selected: true,
                    };
                }

                const allSelected = option.every(loc => loc.id === 'all' || updatedLocations[loc.id]?.selected);
                if (allSelected) {
                    updatedLocations['all'] = {
                        business_name: 'All Locations',
                        selected: true,
                    };
                } else {
                    delete updatedLocations['all'];
                }

                return updatedLocations;
            }
        });
    };
    return option?.map((item, index) => {
        const isChecked = !!selectedItem[item?.id];

        return (
            <Dropdown.Item key={index} onClick={() => handleCheckboxChange(item)}>
                <CheckBox
                    checkboxLabel={
                        <p className="marBot0 fontSize14 OneLineTruncate" style={{color: theme.white}}>
                            {item?.business_name || ''}
                        </p>
                    }
                    checked={isChecked}
                />
            </Dropdown.Item>
        );
    });
};

export default DropDownCheckbox;
