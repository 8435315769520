import {Col, Row} from 'react-bootstrap';
import {TextField, DateSelector, POAmountBox, MessageModal} from '../../common';
import {useLanguageContext, useThemeContext} from '../../../context';
import {useEffect, useState} from 'react';
import {isDateDisabled, toSnakeCase} from '../../../constants';
import {useSearchParams} from 'react-router-dom';
import {observeActiveProducts, observePurchaseTax, observeActiveCompany} from '../../../pos-core/database/helpers';
import withObservables from '@nozbe/with-observables';
import List from './List';
import CsvUploadModal from '../CsvUploadModal';
import CompanyModal from '../CompanyModal';

const CreatePurchaseOrderComponent = ({
    allCompanies,
    company,
    setCompany,
    ship,
    setShip,
    reference,
    setReference,
    poDate,
    setPoDate,
    expected,
    setExpected,
    shippingMethod,
    setShippingMethod,
    notememo,
    setNoteMemo,
    notes,
    setNotes,
    itemData,
    setItemData,
    products,
    purchaseTax,
    handleSelect,
    errors,
    setErrors,
    disablFields,
    purchaseOrder,
    setOrderDiscountModal,
    orderLevelDiscount,
    totalItemsCount,
    discardModalToggle,
    setDiscardModalToggle,
    toggleDiscardModal,
    handleDiscardAction,
    toggleCompanyModal,
    setToggleCompanyModal,
    companyList,
    po_data,
    setPersistData,
    isCreatedPO,
}) => {
    const {I18n} = useLanguageContext();
    const {theme} = useThemeContext();
    const [filterData, setFilterData] = useState(products);
    const [cvsModal, setCvsModal] = useState(false);
    const [searchParams] = useSearchParams();

    const company_id = searchParams?.get('company_id');

    useEffect(() => {
        if (company_id && companyList) {
            findAndSetData(companyList, company_id, setCompany);
        }
    }, [company_id, companyList]);

    function findAndSetData(source, id, setter) {
        const data = source?.filter(x => x?._raw?.id?.includes(id));
        if (data.length > 0) {
            setter(data[0]);
        }
    }
    const onChange = async (label, val) => {
        if (label == I18n.reference) {
            setReference(val);
        } else if (label == I18n.company) {
            setToggleCompanyModal(true);
        } else if (label == I18n.ship_to) setShip(val);
        else if (label == I18n.purchase_order_date) setPoDate(val);
        else if (label == I18n.expected_on) setExpected(val);
        else if (label == I18n.shipping_method) setShippingMethod(val);
        else if (label == I18n.notes_Memo) setNoteMemo(val);
        else if (label == I18n.notes) setNotes(val);
        else if (label == I18n.search_by_name) {
            label = 'po_line';
            if (val?.id) {
                const product_template = await val.product_template.fetch();

                setItemData(prev => [
                    ...prev,
                    {
                        name: val?.name,
                        id: val?.id,
                        product: val,
                        unit: product_template?.unit,
                    },
                ]);
            }
        }

        setErrors(prevErrors => {
            const updatedErrors = {...prevErrors};
            if (updatedErrors[toSnakeCase(label)]) {
                delete updatedErrors[toSnakeCase(label)];
            }

            return updatedErrors;
        });
    };

    const updateListItem = (label, val, id) => {
        if (label === 'taxes') {
            val = val;
        } else if (label === 'unit') {
            val = val.name;
        } else {
            val = Number(val);
        }

        const updatedItem = itemData?.map(allItem => {
            if (allItem.id === id) {
                return {
                    ...allItem,
                    [label]: val,
                };
            }
            return allItem;
        });
        setItemData(updatedItem);

        setErrors(prevErrors => {
            const updatedErrors = {...prevErrors};
            if (updatedErrors?.poLines?.[id]?.[toSnakeCase(label)] === 'required') {
                delete updatedErrors?.poLines?.[id]?.[toSnakeCase(label)];
            }

            return updatedErrors;
        });
    };

    const handleDeleteItem = listId => {
        const filterItemData = itemData?.filter(item => item.id !== listId);
        setItemData(filterItemData);
    };

    useEffect(() => {
        handleFilter();
    }, [itemData]);

    useEffect(() => {
        setFilterData(products);
    }, [products]);

    const handleFilter = () => {
        const filterProducts = products?.filter(prod => !itemData.some(item => item.name === prod.name));
        setFilterData(filterProducts);
    };

    function handleImport() {
        if (disablFields) return;
        setCvsModal(x => !x);
    }

    const handleCompany = async val => {
        if (val == 'remove_company') {
            setCompany('');
        } else {
            setCompany(val);
        }
        setToggleCompanyModal(false);
    };

    const handleToggleCompanyModal = () => {
        if (!disablFields) {
            setPersistData({purchaseOrder: po_data});
            setToggleCompanyModal(x => !x);
        }
    };
    return (
        <>
            <Row className="justify-content-md-center createPO">
                <Col md={10}>
                    <div className="loginFormBox">
                        <div className="signupTextBox">
                            <h3 className="fontSize24 fontWeight600 marBot35 marTop0 textCenter" style={{color: theme.text}}>
                                {I18n.create_purchase_order}
                            </h3>
                            <h2 className="optionPara fontSize20 fontWeight400 marBot15" style={{color: theme.text}}>
                                {I18n.purchase_order_details}
                            </h2>
                        </div>
                        <div className="itemFields">
                            <div className="intventoryFormGridTwo">
                                <div className="priceSectionInputLeft" onClick={handleToggleCompanyModal}>
                                    <TextField
                                        label={I18n.company}
                                        placeholder={I18n.company}
                                        value={company?.name || ''}
                                        error={errors['company']}
                                        onChange={onChange}
                                        inputType="text"
                                        type="text"
                                        required={true}
                                        suffix="downIcon2"
                                        suffix_fill={theme.white}
                                        suffix_width={'24'}
                                        suffix_height={'24'}
                                        suffix_viewBox={'0 0 18 18'}
                                        disabled={disablFields}
                                    />
                                </div>
                                <div className="priceSectionInputRight">
                                    <DateSelector
                                        label={I18n.purchase_order_date}
                                        icon={'addCustomer'}
                                        value={poDate}
                                        required={true}
                                        setValue={onChange}
                                        error={errors['purchase_order_date']}
                                        disabled={disablFields}
                                    />
                                </div>
                            </div>
                            <div className="intventoryFormGridTwo">
                                <div className="priceSectionInputLeft">
                                    <DateSelector
                                        label={I18n.expected_on}
                                        icon={'addCustomer'}
                                        value={expected}
                                        setValue={onChange}
                                        error={errors['expected_on']}
                                        disabled={disablFields}
                                        disableDate={date => isDateDisabled(date, poDate)}
                                    />
                                </div>
                                <div className="priceSectionInputRight">
                                    <TextField
                                        label={I18n.shipping_method}
                                        placeholder={I18n.shipping_method}
                                        onChange={onChange}
                                        required={true}
                                        data={[
                                            {
                                                name: I18n.cash_on_delivery,
                                            },
                                            {
                                                name: I18n.via_Bank_on_delivery,
                                            },
                                        ]}
                                        highlighter={true}
                                        value={shippingMethod}
                                        error={errors['shipping_method']}
                                        inputType="select"
                                        type="text"
                                        suffix="downIcon2"
                                        suffix_fill={theme.white}
                                        suffix_width={'24'}
                                        suffix_height={'24'}
                                        suffix_viewBox={'0 0 18 18'}
                                        disabled={disablFields}
                                    />
                                </div>
                            </div>

                            <div className="intventoryFormGridTwo">
                                <div className="priceSectionInputLeft">
                                    <TextField
                                        onChange={onChange}
                                        error={errors['reference']}
                                        label={I18n.reference}
                                        placeholder={I18n.reference}
                                        value={reference}
                                        disabled={disablFields}
                                        required={true}
                                    />
                                </div>
                                <div className="priceSectionInputRight">
                                    <TextField
                                        label={I18n.ship_to}
                                        placeholder={I18n.ship_to}
                                        onChange={onChange}
                                        highlighter={true}
                                        value={ship}
                                        type="text"
                                        disabled={disablFields}
                                    />
                                </div>
                            </div>

                            <TextField label={I18n.notes} placeholder={I18n.notes} onChange={onChange} inputType="textarea" rows="1" value={notes} disabled={disablFields} />

                            <p style={{background: theme.topBarBG}} className="itemSeperator"></p>

                            <div className="itemDetailHeading justifySpa">
                                <p className="fontSize20 fontWeight400 marBot0" style={{color: theme.text}}>
                                    {I18n.item_information}
                                </p>
                                <p
                                    className="fontSize14 fontWeight500 marBot0 cursorPointer"
                                    onClick={handleImport}
                                    style={{color: disablFields ? theme.darkGrayTwo : theme.barclaysBlue}}>
                                    {I18n.import_items}
                                </p>
                            </div>
                            <p style={{background: theme.topBarBG}} className="itemSeperator itemDetailListSep"></p>

                            {itemData?.length
                                ? itemData?.map((item, index) => {
                                      return item.name ? (
                                          <div key={item.id}>
                                              <List
                                                  I18n={I18n}
                                                  theme={theme}
                                                  item={item}
                                                  purchaseTax={purchaseTax}
                                                  updateListItem={updateListItem}
                                                  handleDeleteItem={handleDeleteItem}
                                                  setErrors={setErrors}
                                                  errors={errors}
                                                  disablFields={disablFields}
                                              />
                                          </div>
                                      ) : null;
                                  })
                                : null}

                            <div className="marTop10">
                                <TextField
                                    onChange={onChange}
                                    setOptionAsLabel={true}
                                    label={I18n.search_by_name}
                                    placeholder={I18n.search_by_name}
                                    value={''}
                                    required={true}
                                    data={[...filterData]}
                                    highlighter={true}
                                    inputType="select"
                                    type="text"
                                    suffix="downIcon2"
                                    suffix_fill={theme.white}
                                    suffix_width={'24'}
                                    suffix_height={'24'}
                                    suffix_viewBox={'0 0 18 18'}
                                    disabled={disablFields}
                                    error={errors['po_line']}
                                />
                            </div>

                            <TextField label={I18n.notes_Memo} placeholder={I18n.notes_Memo} onChange={onChange} value={notememo} disabled={disablFields} />

                            <POAmountBox itemData={itemData} setOrderDiscountModal={setOrderDiscountModal} orderLevelDiscount={orderLevelDiscount} disable={disablFields} />
                            <CompanyModal
                                id={purchaseOrder?.id || ''}
                                screen_name={isCreatedPO ? 'po_creation' : 'po_edit'}
                                setToggle={setToggleCompanyModal}
                                toggle={toggleCompanyModal}
                                handleSelect={handleCompany}
                                data={company}
                            />

                            <CsvUploadModal setToggle={setCvsModal} toggle={cvsModal} type={'item'} />
                        </div>
                    </div>
                </Col>
            </Row>
            <MessageModal
                className="messageModal"
                setToggle={setDiscardModalToggle}
                toggle={discardModalToggle}
                description={I18n.do_you_really_want_to_discard}
                subDescription={I18n.this_action_is_irreversible_once_you_discard_it_its_gone}
                secondaryCta={{
                    title: I18n.cancel,
                    action: toggleDiscardModal,
                }}
                primaryCta={{
                    title: I18n.discard,
                    backgroundColor: theme.white,
                    borderColor: theme.red,
                    txtColor: theme.red,
                    action: handleDiscardAction,
                    isDelete: true,
                }}
            />
        </>
    );
};

const enhance = withObservables(['totalItemsCount'], ({totalItemsCount}) => ({
    products: observeActiveProducts(0, totalItemsCount),
    purchaseTax: observePurchaseTax(),
    companyList: observeActiveCompany(),
}));

export default enhance(CreatePurchaseOrderComponent);
