import React from 'react';
import {useLanguageContext, useThemeContext} from '../../../context';
import {formatNum} from '../../../constants';

const FundsJourney = ({title, list}) => {
    const {theme} = useThemeContext();
    const {I18n} = useLanguageContext();
    const length = list?.length;

    return (
        <div>
            <p className="marTop20 marBot20 fontSize20 fontWeight500" style={{color: theme.white}}>
                {title}
            </p>

            <div className="pad10">
                <div className="flex width100">
                    <div className="width30">
                        <p className=" marBot0 fontSize16 fontWeight500" style={{color: theme.white}}>
                            {I18n.type}
                        </p>
                    </div>
                    <div className="width50">
                        <p className=" marBot0 fontSize16 fontWeight500" style={{color: theme.white}}>
                            {I18n.description}
                        </p>
                    </div>
                    <div className="width20 textRight">
                        <p className=" marBot0 fontSize16 fontWeight500" style={{color: theme.white}}>
                            {I18n.amount}
                        </p>
                    </div>
                </div>
                <div className="thinDivider width100 marTop10 marBot10" />
                {list.map((fund, ind) => {
                    return (
                        <div key={ind}>
                            <div className="flex">
                                <div className="width30">
                                    <p className={`marBot0 fontSize14 ${ind < length - 1 ? 'fontWeight400' : 'fontWeight600'}`} style={{color: theme.white}}>
                                        {fund.type}
                                    </p>
                                </div>
                                <div className="width50">
                                    <p className="marBot0 fontSize14 fontWeight400" style={{color: theme.lightGrayTwo}}>
                                        {fund.description}
                                    </p>
                                </div>
                                <div className="width20 textRight">
                                    <p className={`marBot0 fontSize14 ${ind < length - 1 ? 'fontWeight400' : 'fontWeight600'} `} style={{color: theme.white}}>
                                        <span className="marRight5">Rs.</span>
                                        {formatNum(fund.amount)}
                                    </p>
                                </div>
                            </div>

                            {ind < length - 1 && <div className="thinDivider width100 marTop10 marBot10" />}
                        </div>
                    );
                })}
            </div>
        </div>
    );
};

export default FundsJourney;
