import {database} from '..';
import {uuid} from '../../constants';
import {Q} from '@nozbe/watermelondb';
import {DINE_IN_TABLE_SCHEMA} from '../schema';

const dine_in_table = database.collections.get(DINE_IN_TABLE_SCHEMA);

const prepareInsertion = (dineInTables, floorPlan) => {
    return dineInTables?.map(item => {
        try {
            return dine_in_table.prepareCreate(table => {
                table.floor_plan.set(floorPlan);
                table._raw.id = item.id;
                table.name = item.name;
                table.height = item.height;
                table.width = item.width;
                table.seats = item.seats;
                table.shape = item.shape;
                table.xAxis = item.xAxis;
                table.yAxis = item.yAxis;
                table.xCoord = item.xCoord;
                table.yCoord = item.yCoord;
                table.created_at = new Date().getTime();
                table.archive = false;
                table.is_active = false;
            });
        } catch (err) {
            console.log(err);
        }
    });
};

export const bulkCreateDineInTables = async (dineInTables, floorPlan) => {
    return await database.write(async () => {
        const allRecords = prepareInsertion(dineInTables, floorPlan);
        await database.batch(allRecords);
        return allRecords;
    });
};

const prepareUpdation = dineInTables => {
    return dineInTables.map(table => {
        return table.prepareUpdate(obj => (obj.archive = true));
    });
};

export const bulkDeleteDineInTables = async dineInTables => {
    await database.write(async () => {
        const allRecords = await prepareUpdation(dineInTables);
        await database.batch(...allRecords);
        return allRecords;
    });
};
