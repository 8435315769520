import React from 'react';
import {Col, Row} from 'react-bootstrap';
import {Button, CustomContainer} from '../../../../common';
import {useAuthContext, useLanguageContext, useThemeContext} from '../../../../../context';
import {useNavigate} from 'react-router-dom';
import {formatDateAndTimeWithYear, formatNum, PAYMENT_STATUS} from '../../../../../constants';

const PayoutReceipt = () => {
    const {theme} = useThemeContext();
    const {I18n} = useLanguageContext();
    const {payoutBeneficiaries} = useAuthContext();
    let navigate = useNavigate();

    const totalAmount = Object.values(payoutBeneficiaries || {})?.reduce((acc, curr) => acc + (curr.transferred_amount || 0), 0);
    const payeeList = Object.values(payoutBeneficiaries || {});

    const handleActivity = () => {
        navigate('/dashboard', {state: {screenName: 'wallet', tab: 'activity'}});
    };

    return (
        <>
            <CustomContainer className="addScreenMainBox createCustomerContainer" reduceHeight={45}>
                <Row className="justify-content-md-center marTop40">
                    <Col md={5}>
                        <div className="payoutReceiptWrapper">
                            <div>
                                <p className="fontSize48  textCenter fontWeight700 marBot10 " style={{color: theme.blackPearl}}>
                                    {`Rs. ${formatNum(totalAmount)}`}
                                </p>

                                <p className="fontSize18  textCenter fontWeight400 marBot10 " style={{color: theme.darkGrayTwo}}>
                                    {`We are processing your ${payeeList.length} payment(s) of Rs.${formatNum(totalAmount)}`}
                                </p>

                                <p className="fontSize14  textCenter fontWeight400 marBot20 " style={{color: theme.seperatorBg}}>
                                    {I18n.date_and_time} {formatDateAndTimeWithYear(payeeList[0]?.date)}
                                </p>

                                <p className="fontSize16  fontWeight500 " style={{color: theme.seperatorBg}}>
                                    {I18n.processing_payments}
                                </p>
                            </div>
                            {payeeList.map((payee, ind) => {
                                return (
                                    <div className="payeeDeatilsCard marTop20" key={ind}>
                                        <div className="spaceBetweenCenter">
                                            <p className="fontSize16  fontWeight500 marBot0" style={{color: theme.seperatorBg}}>
                                                {payee.name}
                                                <span className="marLeft10 fontSize12 fontWeight400 " style={{color: theme.darkGrayTwo}}>
                                                    <img
                                                        src={payee.bank_logo}
                                                        title={`${payee.bank} logo`}
                                                        alt={`${payee.bank} logo`}
                                                        style={{
                                                            width: '20px',
                                                            height: '20px',
                                                            objectFit: 'contain',
                                                            marginRight: '3px',
                                                            marginBottom: '3px',
                                                        }}
                                                    />
                                                    {payee.bank}
                                                </span>
                                            </p>

                                            <p className="fontSize16  fontWeight400 marBot0" style={{color: theme.seperatorBg}}>
                                                {payee.account_number}
                                            </p>
                                        </div>

                                        <div className="spaceBetweenCenter marTop5">
                                            <p className="fontSize12  fontWeight400 marBot0" style={{color: theme.darkGrayTwo}}>
                                                {`${I18n.transaction_id}:`}
                                            </p>

                                            <p className="fontSize12  fontWeight400 marBot0" style={{color: theme.barclaysBlue}}>
                                                {payee.transaction_id}
                                            </p>
                                        </div>

                                        <div className="spaceBetweenCenter marTop5">
                                            <p className="fontSize12  fontWeight400 marBot0" style={{color: theme.darkGrayTwo}}>
                                                {I18n.status}
                                            </p>

                                            <p className="fontSize12  fontWeight400 marBot0" style={{color: theme.brightOrange}}>
                                                {PAYMENT_STATUS[payee.transaction_status]}
                                            </p>
                                        </div>

                                        <div className="marTop5">
                                            <p className="fontSize14  fontWeight400 marBot0" style={{color: theme.seperatorBg}}>
                                                {`${I18n.transferred_amount}:`}
                                                <span className="fontSize16  fontWeight500 separatorGray"> Rs. {formatNum(payee.transferred_amount)}</span>
                                            </p>
                                        </div>
                                    </div>
                                );
                            })}
                        </div>

                        <div className="width100 flex horizontalCenter">
                            <Button
                                className={`marTop20 width70 fontWeight500 fontSize20 cursorPointer`}
                                background={theme.brightGreen}
                                txtColor={theme.white}
                                title={I18n.go_to_activity}
                                handleClick={handleActivity}
                            />
                        </div>
                    </Col>
                </Row>
            </CustomContainer>
        </>
    );
};

export default PayoutReceipt;
