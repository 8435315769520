import React from 'react';
import {TextField} from '../../../../../common';
import {useLanguageContext, useThemeContext} from '../../../../../../context';
import {toSnakeCase} from '../../../../../../constants';
import FormCheck from 'react-bootstrap/FormCheck';
import Icon from '../../../../../../assets/icons';

const AddModifierList = ({item, updateListItem, handleDeleteModifier, errors, setErrors}) => {
    const {I18n} = useLanguageContext();
    const {theme} = useThemeContext();

    const onChangeModifiers = (label, val) => {
        if (label.includes('Rs')) {
            label = 'price';
        }
        updateListItem(toSnakeCase(label), val, item.id);

        setErrors(prevErrors => {
            const updatedErrors = {...prevErrors};
            if (updatedErrors[item.id]) {
                delete updatedErrors[item.id];
            }
            delete updatedErrors['modifier'];
            return updatedErrors;
        });
    };

    const HandleToggle = e => {
        const value = e.target.checked;
        updateListItem('isChecked', value, item.id);
    };

    return (
        <div className="addModifier">
            <div className="width40">
                <TextField
                    onChange={onChangeModifiers}
                    error={errors?.[item.id]}
                    label={I18n.modifier_name}
                    placeholder={I18n.modifier_name}
                    value={item.modifier_name}
                    variant="standard"
                    maxLength={100}
                />
            </div>
            <div className="width30">
                <TextField onChange={onChangeModifiers} label={I18n.rs_zero} placeholder={I18n.rs_zero} value={item.price} variant="standard" />
            </div>
            <div
                className="width30"
                style={{
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'flex-end',
                    gap: '20px',
                }}>
                <FormCheck type="switch" id="stock_available" onChange={e => HandleToggle(e)} checked={item.isChecked} />

                <p className="fontSize14 marBot0" style={{color: theme.white}}>
                    Available
                </p>
                <span onClick={() => handleDeleteModifier(item.id)} className="cursorPointer">
                    <Icon name="closeIcon" fill={theme.darkGrayTwo} width={'12'} height={'12'} viewBox={'0 0 15 15'} />
                </span>
            </div>
        </div>
    );
};

export default AddModifierList;
