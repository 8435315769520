import {useLanguageContext, useThemeContext} from '../../../../../../context';
import {observepaymentByID} from '../../../../../../pos-core/database/helpers';
import {DEVICE_HEIGHT, firstLetterCaptilize, formatDateAndTimewithoutYear, formatDayTime, formatNum} from '../../../../../../constants';
import {NewPosHeader} from '../../../../../common/V2';
import withObservables from '@nozbe/with-observables';
import {switchMap} from 'rxjs';
import React, {useEffect, useState} from 'react';
import {Col, Container, Row, Spinner} from 'react-bootstrap';
import {CustomContainer, Loading} from '../../../../../common';
import StatusBox from '../../../../../common/StatusBox';
import LocationBox from '../../../../../common/LocationBox';
import CardPaymentDetail from './CardPaymentDetail';
import CashDetail from './CashDetail';
import PaymentTimeline from './PaymentTimeline';
import {getSalesTransationsList} from '../../../../../../api';
import {useLocation, useNavigate, useParams} from 'react-router-dom';

const TransactionDetail = () => {
    const {I18n} = useLanguageContext();
    const {theme} = useThemeContext();
    const [data, setData] = useState({});
    const [loading, setLoading] = useState(true);
    const location = useLocation();
    const {businessId} = location.state || {};
    const {id} = useParams();
    let navigate = useNavigate();

    useEffect(() => {
        if (id && businessId) {
            getPaymentDetail(id);
        }
    }, [id, businessId]);

    async function getPaymentDetail(id) {
        try {
            let params = {
                transaction_id: id,
                business_ids: businessId,
            };
            const response = await getSalesTransationsList(params);
            if (response?.success) {
                setData(response?.payment_details);
            }
        } catch (error) {
            console.log('Error in transaction API --->', error);
        } finally {
            setLoading(false);
        }
    }

    const {amount, created_at, customer_id, gross_amount, net_amount, order_number, payment_method, status, tax, location_name, settled_at, business_id} = data || {};

    let stepdata = [
        settled_at
            ? {
                  iconName: 'tickCircleIcon',
                  viewBox: '0 0 18 18',
                  width: 19,
                  height: 19,
                  stepLebal: I18n.payment_settled,
                  details: formatDateAndTimewithoutYear(settled_at),
                  fill: theme.brightGreen,
              }
            : null,
        created_at
            ? {
                  iconName: 'pendingPaymentIcon',
                  viewBox: '0 0 18 18',
                  width: 16,
                  height: 16,
                  stepLebal: I18n.payment_created,
                  details: formatDateAndTimewithoutYear(created_at),
                  fill: theme.white,
              }
            : null,
    ].filter(Boolean);

    const handleNavigate = () => {
        navigate(`/orders/${order_number}?business_id=${business_id}`);
    };
    return (
        <CustomContainer reduceHeight={45} className="addScreenMainBox createCustomerContainer transactionDetail">
            <Row className="justify-content-md-center">
                <NewPosHeader
                    backBtnCta={true}
                    title={{
                        name: `Transaction ID | ${id}`,
                        decs: `(${formatDayTime(created_at)})`,
                    }}
                />
                <Col md={12}>
                    {loading ? (
                        <Loading />
                    ) : (
                        <div className="transactionDetailWrapper">
                            <div className="transactionDetailAmountSection">
                                <div className="transactionDetailAmountTop">
                                    <p className="marBot5 fontSize16" style={{color: theme.darkGrayTwo}}>
                                        {I18n.amount}
                                    </p>
                                    <p className="fontSize24 fontWeight600 marRight5" style={{color: theme.white}}>
                                        Rs. {formatNum(amount)}
                                    </p>

                                    <div className="transactionDetailAmountStatus">
                                        <StatusBox status={status} />
                                        {/* {location_name && <LocationBox location={location_name} />} */}
                                    </div>
                                </div>

                                <div className="transactionDetailAmountBottom">
                                    <p className="transactionDetailAmountBotInner">
                                        <span className="fontSize12 fontWeight400" style={{color: theme.lightGrayTwo}}>
                                            {I18n.customer_id}
                                        </span>
                                        <span className="fontSize16 fontWeight400" style={{color: theme.white}}>
                                            {customer_id || '--'}
                                        </span>
                                    </p>
                                    <p className="transactionDetailAmountBotInner">
                                        <span className="fontSize12 fontWeight400" style={{color: theme.lightGrayTwo}}>
                                            {I18n.payment_method}
                                        </span>
                                        <span className="fontSize16 fontWeight400" style={{color: theme.white}}>
                                            {firstLetterCaptilize(payment_method) || '--'}
                                        </span>
                                    </p>
                                    {order_number && (
                                        <p className="transactionDetailAmountBotInner">
                                            <span className="fontSize12 fontWeight400" style={{color: theme.lightGrayTwo}}>
                                                {I18n.order_id}
                                            </span>
                                            <span onClick={handleNavigate} className="fontSize16 fontWeight400" style={{color: theme.barclaysBlue}}>
                                                {order_number || '--'}
                                            </span>
                                        </p>
                                    )}
                                </div>
                            </div>

                            <hr className="detailSeperator" />
                            {payment_method == 'card' && (
                                <>
                                    <h3 className="fontSize16 fontWeight700" style={{color: theme.white}}>
                                        {I18n.timeline}
                                    </h3>
                                    <PaymentTimeline detais={stepdata} />
                                    <hr className="detailSeperator" />
                                </>
                            )}

                            <div className="transactionDetailItemPurchased">
                                <h3 className="fontSize16 fontWeight700" style={{color: theme.white}}>
                                    {I18n.payment_details}
                                </h3>

                                <div className="detailItemPurchasedInner">
                                    <p className="fontSize12 fontWeight400" style={{color: theme.lightGrayTwo}}>
                                        {I18n.gross_amount}
                                    </p>
                                    <p className="fontSize14 fontWeight400" style={{color: theme.white}}>
                                        Rs. {formatNum(gross_amount)}
                                    </p>
                                </div>

                                <div className="detailItemPurchasedInner">
                                    <p className="fontSize12 fontWeight400" style={{color: theme.lightGrayTwo}}>
                                        {I18n.total_tax}
                                    </p>
                                    <p className="fontSize14 fontWeight400" style={{color: theme.white}}>
                                        Rs. {formatNum(tax || 0)}
                                    </p>
                                </div>

                                <div className="detailItemPurchasedInner">
                                    <p className="fontSize12 fontWeight400" style={{color: theme.lightGrayTwo}}>
                                        {I18n.net_amount}
                                    </p>
                                    <p className="fontSize14 fontWeight400" style={{color: theme.white}}>
                                        Rs. {formatNum(net_amount || 0)}
                                    </p>
                                </div>
                            </div>

                            <hr className="detailSeperator" />

                            <div className="transactionDetailItemPurchased">
                                <h3 className="fontSize16 fontWeight700" style={{color: theme.white}}>
                                    {I18n.payment_method}
                                </h3>
                                {payment_method == 'cash' && <CashDetail />}
                                {payment_method == 'card' && <CardPaymentDetail payment={data} />}
                            </div>
                        </div>
                    )}
                </Col>
            </Row>
        </CustomContainer>
    );
};

export default TransactionDetail;
