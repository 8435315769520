import {useLanguageContext, useThemeContext} from '../../../../context';
import {Header, TabBox, ToastBox} from '../../../common';
import {DEVICE_HEIGHT} from '../../../../constants';
import {Col, Container, Row} from 'react-bootstrap';
import {useEffect, useState} from 'react';
import KitchenOrderList from './KitchenOrderList';
import KitchenOrderHistory from './KitchenOrderHistory';
import SidebarModal from '../../../common/SidebarModal';
import KitchenNotifications from './KitchenNotifications';

const KitchenDisplayOrder = () => {
    const {theme} = useThemeContext();
    const {I18n} = useLanguageContext();
    const [sidebar, setSidebar] = useState(false);
    const [tabValue, setTabVal] = useState(0);
    const [showMessage, setShowMessage] = useState({
        visible: false,
        message: '',
    });
    const [filter, setFilter] = useState(I18n.all);
    const [search, setSearch] = useState('');

    useEffect(() => {
        if (showMessage.visible) {
            const timeoutId = setTimeout(() => {
                setShowMessage({
                    visible: false,
                    message: '',
                });
            }, 3000);

            return () => clearTimeout(timeoutId);
        }
    }, [showMessage]);

    const filterOption = [
        {
            title: I18n.all,
            action: () => handleFilter(I18n.all),
            checked: filter == I18n.all || filter === '' ? true : false,
        },
        {
            title: I18n.dine_in,
            action: () => handleFilter(I18n.dine_in),
            checked: filter == I18n.dine_in ? true : false,
        },
        {
            title: I18n.delivery,
            action: () => handleFilter(I18n.delivery),
            checked: filter == I18n.delivery ? true : false,
        },
        {
            title: I18n.take_away,
            action: () => handleFilter(I18n.take_away),
            checked: filter == I18n.take_away ? true : false,
        },
    ];

    const handleFilter = val => {
        setFilter(val);
    };

    const onChange = (event, key) => {
        let value = key || event?.target?.value || '';
        setSearch(value);
    };

    const handleChange = (event, newValue) => {
        setTabVal(newValue);
    };

    return (
        <>
            <Header
                type="kdsHeader"
                search={{
                    label: I18n.search_by_name_or_order_number,
                    onChange: onChange,
                }}
                setSidebar={setSidebar}
                leftCta={{
                    title: filter,
                    name: 'downIcon',
                    fill: theme.white,
                    width: '12',
                    height: '9',
                    viewBox: '0 0 8 5',
                    option: filterOption,
                }}
            />
            <div
                className="kdsPageWrapper"
                style={{
                    background: theme.topBarBG,
                    height: DEVICE_HEIGHT - 110,
                }}>
                <Container fluid>
                    <Row>
                        <Col md={12}>
                            <div className="kitchendDisplayTabs">
                                <TabBox tabValue={tabValue} tabs={[I18n.order, I18n.history]} handleChange={handleChange} />
                                <div className="kitchendDisplayTabBody">
                                    {tabValue === 0 ? (
                                        <KitchenOrderList setShowMessage={setShowMessage} filter={filter} search={search} />
                                    ) : (
                                        <KitchenOrderHistory setShowMessage={setShowMessage} showMessage={showMessage} filter={filter} search={search} />
                                    )}
                                </div>
                            </div>
                        </Col>
                    </Row>
                </Container>
                <SidebarModal
                    sidebar={sidebar}
                    setSidebar={setSidebar}
                    placement="end"
                    className="notificationSidebar"
                    heading={I18n.notifications}
                    children={<KitchenNotifications />}
                />
                <ToastBox
                    className="orderStatusToastBox"
                    ToastVisiable={showMessage.visible}
                    bodyPara={showMessage.message}
                    showHeader={false}
                    setShowMessage={setShowMessage}
                    showIcon={true}
                />
            </div>
        </>
    );
};

export default KitchenDisplayOrder;
