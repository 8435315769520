import {database} from '..';
import {BUSINESS_SCHEMA, DISCOUNT_BUSINESS_SCHEMA, DISCOUNT_SCHEMA} from '../schema';
import {Q} from '@nozbe/watermelondb';
import {uuid} from '../../constants';

const discount = database.collections.get(DISCOUNT_SCHEMA);
const discountBusiness = database.collections.get(DISCOUNT_BUSINESS_SCHEMA);
const today = new Date().getTime();

export const observeUnArchivedDiscounts = () =>
    discount
        .query(
            Q.on(DISCOUNT_BUSINESS_SCHEMA, 'business_id', localStorage.getItem('business_id')),
            Q.or(Q.and(Q.where('start_date', null), Q.where('end_date', null)), Q.and(Q.where('start_date', Q.lte(today)), Q.or(Q.where('end_date', Q.gte(today))))),
            Q.where('archive', Q.notEq(true)),
        )
        .observe();

export const observeUnArchivedDiscountsV2 = business_id => discount.query(Q.on(DISCOUNT_BUSINESS_SCHEMA, 'business_id', business_id), Q.where('archive', Q.notEq(true))).observe();

export const observeActivedDiscount = () =>
    discount
        .query(Q.on(DISCOUNT_BUSINESS_SCHEMA, 'business_id', localStorage.getItem('business_id')), Q.where('archive', Q.notEq(true)), Q.where('draft', Q.notEq(true)))
        .observe();
export const observeArchivedDiscounts = () =>
    discount.query(Q.on(DISCOUNT_BUSINESS_SCHEMA, 'business_id', localStorage.getItem('business_id')), Q.where('archive', Q.eq(true))).observe();
export const observeDraftDiscounts = () =>
    discount.query(Q.on(DISCOUNT_BUSINESS_SCHEMA, 'business_id', localStorage.getItem('business_id')), Q.where('draft', Q.eq(true))).observe();

const prepareInsertion = (data, account, business) => {
    return data?.map(details => {
        try {
            const disc = discount.prepareCreate(field => {
                field.account.set(account);
                field._raw.id = uuid();
                field.name = details.name;
                details.type && (field.type = details.type);
                field.location && (discount.location = details.location);
                field.is_inclusive = details.is_inclusive;
                field.limit = details.limit;
                field.max_discount_cap = details.max_discount_cap;
                field.archive = false;
                field.draft = false;
                field.total_given = 0;
            });

            const discBus = discountBusiness.prepareCreate(field => {
                field._raw.id = uuid();
                field.discount.set(disc);
                field.business.set(business);
            });

            return [disc, discBus];
        } catch (err) {
            console.log(err);
        }
    });
};

export const bulkCreateDiscount = async (data, account, business) => {
    await database.write(async () => {
        const allRecords = prepareInsertion(data, account, business);
        await database.batch(...allRecords.flat());
    });
};
