import {Container, Row, Col} from 'react-bootstrap';
import {useLanguageContext, useThemeContext} from '../../../context';
import List from './List';
import NonIdealScreen from '../NonIdeal';
import {useNavigate} from 'react-router-dom';
import {PosAppModal} from '../V2';
import SearchBox from '../SearchBox';
import {useSearch} from '../../../hooks';
import usePermission from '../../../hooks/usePermission';
import {DEVICE_HEIGHT} from '../../../constants';

const LocationModal = ({toggle, setToggle, screenName, locations, selectedLocations, setSelectedLocations, singleSelection}) => {
    const navigate = useNavigate();
    const {I18n} = useLanguageContext();
    const {theme} = useThemeContext();
    const {text, setText, searchList} = useSearch(handleSearch);
    const userPermission = usePermission('location');

    const handleClose = event => {
        setToggle(false);
        setText();
    };

    const handleCreateLocation = () => {
        navigate('/location/create', screenName && {state: screenName});
    };

    const onChange = (event, key) => {
        let value = key || event?.target?.value || '';
        setText(value);
    };

    function handleSearch(val) {
        return locations?.filter(x => x?.name?.toLowerCase().includes(val?.toLowerCase()));
    }

    const handleSave = () => {
        setToggle(false);
    };

    const handleback = () => {
        setToggle(false);
        setText();
    };

    const renderList = text ? searchList : locations;
    return (
        <PosAppModal
            className="selectCustomerModal"
            toggle={toggle}
            backCta={{
                name: 'backArrowIcon',
                fill: theme.barclaysBlue,
                width: '18',
                height: '18',
                viewBox: '0 0 18 18',
                action: handleback,
            }}
            handleClose={handleClose}
            bottomSaveCta={{
                title: I18n.save,
                action: () => handleSave(),
            }}
            bottomCancelCta={{
                title: I18n.cancel,
                action: () => handleClose(),
            }}
            rightCta={
                locations?.length && {
                    title: 'Location',
                    action: handleCreateLocation,
                    iconName: 'add2Icon',
                    fill: theme.barclaysBlue,
                    width: '18',
                    height: '18',
                    viewBox: '0 0 18 18',
                }
            }
            title={I18n.back}>
            <>
            
                {!locations?.length == 0 && (
                    <SearchBox
                        type="pos"
                        placeholder={'Search location by name'}
                        prefix={{
                            name: 'search2Icon',
                            fill: theme.white,
                        }}
                        onChange={onChange}
                    />
                )}

                <Container fluid>
                    <Row className="justify-content-md-center">
                        <Col md={12}>
                            {renderList?.length > 0 ? (
                                <section className="addScreenMainBox">
                                    <Row className="justify-content-md-center">
                                        <Col md={12}>
                                            <div
                                                className="selectCustomerListBox"
                                                style={{
                                                    height: DEVICE_HEIGHT - 300,
                                                }}>
                                                {renderList?.map((val, index) => (
                                                    <List
                                                        key={index}
                                                        location={val}
                                                        selectedLocations={selectedLocations}
                                                        setSelectedLocations={setSelectedLocations}
                                                        locations={locations}
                                                        singleSelect={singleSelection}
                                                    />
                                                ))}
                                            </div>
                                        </Col>
                                    </Row>
                                </section>
                            ) : (
                               
                                <NonIdealScreen
                                  heading={text ? I18n.search : I18n.location}
                                    subHeading={text ? I18n.we_couldnt_find_any_results_for_your_search : I18n.create_location}
                                    name={text ? "seacrhNotFoundIcon" : "discountIcon"}
                                    fill={theme.white}
                                    primaryCta={
                                        text
                                        ? null :userPermission?.canCrud
                                            ? {
                                                  title: I18n.create_location,
                                                  action: handleCreateLocation,
                                              }
                                            : null
                                    }
                                    isMultiStep={false}
                                />
                            )}
                        </Col>
                    </Row>
                </Container>
            </>
        </PosAppModal>
    );
};

export default LocationModal;
