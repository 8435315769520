import {useEffect, useState} from 'react';
import {Col, Row} from 'react-bootstrap';
import {useNavigate} from 'react-router-dom';
import Icon from '../../../../assets/icons';
import {useLanguageContext, useThemeContext} from '../../../../context';
import {Button, CustomContainer} from '../../../common';
import Otp from '../../../common/Otp';
import {sendOtp, verifyEmail} from '../../../../api';
import {firstLetterCaptilize, formatTimeInSec} from '../../../../constants';

const VerifyOTP = ({setStep}) => {
    const navigate = useNavigate();

    const {I18n} = useLanguageContext();
    const {theme} = useThemeContext();

    const [isLoading, setIsLoading] = useState(false);
    const [errors, setErrors] = useState({});
    const [otp, setOtp] = useState('');
    const [seconds, setSeconds] = useState(59);
    const email = localStorage.getItem('email');

    const handleVerify = async () => {
        try {
            setIsLoading(true);
            console.log('otp is', otp);

            if (otp.length !== 4) {
                setErrors({otp: 'Please enter correct verification code'});
                return;
            }

            const payload = {
                email,
                otp,
            };
            const response = await verifyEmail(payload);

            if (response.success !== true) {
                setErrors({otp: response.msg});
            } else {
                localStorage.setItem('otp', response.otp);
                setStep(3);
            }

            console.log('response', response);
            setIsLoading(false);
        } catch (error) {
            console.log('error', error);
        } finally {
            setIsLoading(false);
        }
    };

    const resendCode = async () => {
        if(seconds === 0){
            const response = await sendOtp({email});
            setOtp('');
            setSeconds(59);
        }
    };

    const handleEnterPress = event => {
        if (event.key === 'Enter') {
            handleVerify();
        }
    };

    useEffect(() => {
        if (errors?.otp) setErrors({});
    }, [otp]);

    useEffect(() => {
        const intervalId = setInterval(() => {
            setSeconds(seconds => {
                if (seconds <= 0) {
                    clearInterval(intervalId);
                    return 0;
                }
                return seconds - 1;
            });
        }, 1000);

        return () => clearInterval(intervalId);
    }, [seconds]);

    return (
        <CustomContainer handlePress={handleEnterPress} className="loginPageWrapper">
            <div className="logoBox">
                <Icon name={'oscarLogo'} fill={'white'} viewBox={'0 0 115 110'} width={'55'} height={'50'} />
            </div>

            <Row className="justify-content-md-center">
                <Col md={5}>
                    <div className="loginFormBox">
                        <h1 className="fontSize24 fontWeight700 marBot10 " style={{color: theme.text}}>
                            {I18n.verify_your_email}
                        </h1>
                        <p className="fontSize14 fontWeight400 marBot20 darkGrayTwo">
                            {I18n.verification_code_has_been_sent_to}

                            <span className="marLeft5 barclaysBlueColor">{email}</span>
                        </p>

                        <div className={`emailVerifyOtp  ${errors?.otp ? 'emailVerifyOtpErrors' : 'emailVerifyOtp'} `}>
                            <Otp otp={otp} setOtp={setOtp} numInputs={4} error={errors?.otp} />
                            <p className="inputError marBot0">{firstLetterCaptilize(errors?.otp || '')}</p>
                        </div>

                        <div className="resendCodeWrapper">
                            <p className="fontSize14 fontWeight400 cursorPointer" onClick={() => resendCode()} style={{color: theme.seperatorTwo}}>
                                <span>
                                    <Icon name="messageIcon" fill={seconds === 0 ?  theme.barclaysBlue : theme.darkGrayTwo} viewBox={'0 0 19 19'} />
                                </span>
                                <span className="marLeft5" style={{
                                    color: seconds === 0 ?  theme.barclaysBlue : theme.darkGrayTwo
                                }}>{I18n.resend_code}</span>
                            </p>

                            <p className="fontSize14 fontWeight400" style={{color: theme.white}}>
                                {formatTimeInSec(seconds)}
                            </p>
                        </div>

                        <div className="signinLoginButton marTop10">
                            <Button
                                className={`width100 fontWeight600 fontSize16 cursorPointer`}
                                title={I18n.verify}
                                saveLoad={isLoading ? true : false}
                                handleClick={handleVerify}
                            />
                        </div>
                    </div>
                </Col>
            </Row>
        </CustomContainer>
    );
};

export default VerifyOTP;
