import React, { useState } from 'react';
import { Col, Row } from 'react-bootstrap';
import usePermission from '../../../../../../hooks/usePermission';
import { DashboardHeader } from '../../../../../common';
import WalletActivity from './WalletActivity';
import WalletBalance from './WalletBalance';
import WalletSummary from './WalletSummary';

const WalletOverview = ({ dashboardData, selectedDateRange, setSelectedDateRange, selectedLocation, setSelectedLocation, initialTab = 0, visible, setVisible }) => {
    const [primaryTabValue, setPrimaryTabValue] = useState(initialTab);
    const isPosPermission = usePermission('pos');
    const userPermission = usePermission('wallet-overview');

    return (
        <>
            <Row>
                <Col md={12}>
                    <DashboardHeader
                        selectedLocation={selectedLocation}
                        setSelectedLocation={setSelectedLocation}
                        setSelectedDateRange={setSelectedDateRange}
                        selectedDateRange={selectedDateRange}
                        setPrimaryTabValue={setPrimaryTabValue}
                        primaryTabValue={primaryTabValue}
                        type="payment_dashboard"
                        visible={visible}
                        setVisible={setVisible}
                    />
                </Col>
            </Row>

            <>
                {primaryTabValue === 0 ? (
                    <WalletSummary isPosPermission={isPosPermission} dashboardData={dashboardData} />
                ) : primaryTabValue === 1 ? (
                    <WalletBalance setPrimaryTabValue={setPrimaryTabValue} userPermission={userPermission} setVisible={setVisible} />
                ) : (
                    <WalletActivity selectedDateRange={selectedDateRange} />
                )}
            </>
        </>
    );
};

export default WalletOverview;
