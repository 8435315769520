import React, {useState} from 'react';
import {UNITS} from '../../../constants';
import {useLanguageContext, useThemeContext} from '../../../context';
import TextField from '../TextField';
import TooltipComponent from '../V2/TooltipComponent';

const SingleItemCreationFeild = ({
    sellingPrice,
    costPrice,
    profit,
    margin,
    quantity,
    lowStock,
    barcode,
    errors,
    onChange,
    rate,
    unit,
    secondaryUnit,
    secondaryUnitRef,
    disableVarientOption,
}) => {
    const {I18n} = useLanguageContext();
    const {theme} = useThemeContext();
    const [profitTooltipToggle, setProfitTooltipToggle] = useState(false);
    const [marginTooltipToggle, setMarginTooltipToggle] = useState(false);

    function ToggleMarginToolTip(x) {
        setMarginTooltipToggle(x);
    }
    function ToggleProfitToolTip(x) {
        setProfitTooltipToggle(x);
    }

    return (
        <>
            <div>
                <p style={{background: theme.topBarBG}} className="itemSeperator"></p>

                {disableVarientOption && (
                    <>
                        <h3 className="fontSize20 fontWeight400 marBot15 marTop0 textLeft width100" style={{color: theme.text}}>
                            {I18n.pricing}
                        </h3>
                        <div className="intventoryFormGridTwo">
                            <div className="priceSectionInputLeft">
                                <TextField
                                    type="numeric"
                                    onChange={onChange}
                                    error={errors['price']}
                                    label={I18n.selling_price}
                                    placeholder={I18n.rs_zero}
                                    value={sellingPrice}
                                    required={true}
                                    maxLength={8}
                                />
                            </div>
                            <div className="priceSectionInputRight">
                                <TextField
                                    type="numeric"
                                    onChange={onChange}
                                    error={errors['cost_per_item']}
                                    label={I18n.cost_price}
                                    placeholder={I18n.rs_zero}
                                    value={costPrice}
                                    required={true}
                                    maxLength={8}
                                />
                            </div>
                        </div>

                        <div className="intventoryFormGridTwo">
                            <div className="priceSectionInputLeft tooltipFeild" onMouseEnter={() => ToggleProfitToolTip(true)} onMouseLeave={() => ToggleProfitToolTip(false)}>
                                <TooltipComponent
                                    titleName={`${I18n.profit}:`}
                                    desc={I18n.profit_will_be_automatically_calculated_and_displayed_when_adding_selling_price_and_item_cost}
                                    yesCta={{
                                        title: I18n.ok,
                                        action: () => ToggleProfitToolTip(false),
                                    }}
                                    toggle={profitTooltipToggle}
                                    children={
                                        <TextField
                                            onChange={onChange}
                                            label={I18n.profit}
                                            placeholder={I18n.profit}
                                            value={profit ? `Rs. ${profit?.toFixed(2)}` : ''}
                                            disabled={true}
                                            hideInputError={true}
                                            suffix="infocircleIcon"
                                            suffix_fill={theme.inputText}
                                            suffix_width={'24'}
                                            suffix_height={'24'}
                                            suffix_viewBox={'0 0 18 18'}
                                        />
                                    }
                                />
                            </div>
                            <div className="priceSectionInputRight tooltipFeild" onMouseEnter={() => ToggleMarginToolTip(true)} onMouseLeave={() => ToggleMarginToolTip(false)}>
                                <TooltipComponent
                                    titleName={`${I18n.margin}:`}
                                    desc={I18n.margin_will_be_automatically_calculated_and_displayed_when_adding_selling_price_and_item_cost}
                                    yesCta={{
                                        title: I18n.ok,
                                        action: () => ToggleMarginToolTip(false),
                                    }}
                                    toggle={marginTooltipToggle}
                                    children={
                                        <TextField
                                            onChange={onChange}
                                            label={I18n.margin}
                                            hideInputError={true}
                                            placeholder={I18n.margin}
                                            value={margin ? `${margin?.toFixed(2)}%` : ''}
                                            disabled={true}
                                            suffix="infocircleIcon"
                                            suffix_fill={theme.inputText}
                                            suffix_width={'24'}
                                            suffix_height={'24'}
                                            suffix_viewBox={'0 0 18 18'}
                                        />
                                    }
                                />
                            </div>
                        </div>
                    </>
                )}
                <h3 className="fontSize20 fontWeight400 marBot15 marTop0 textLeft width100" style={{color: theme.text}}>
                    {disableVarientOption ? I18n.inventory : I18n.primary_and_Secondary_Units}
                </h3>
                {disableVarientOption && (
                    <>
                        <div className="intventoryFormGridTwo">
                            <div className="priceSectionInputLeft">
                                <TextField onChange={onChange} label={I18n.add_quantity} placeholder={I18n.add_quantity} value={quantity} type={'numeric'} maxLength={8} />
                            </div>
                            <div className="priceSectionInputRight">
                                <TextField onChange={onChange} label={I18n.stock_alert} placeholder={I18n.stock_alert} value={lowStock} type={'numeric'} maxLength={8} />
                            </div>
                        </div>
                    </>
                )}
                {/* --- */}

                {disableVarientOption ? (
                    <>
                        {' '}
                        <div className="intventoryFormGridTwo">
                            <div className="priceSectionInputLeft">
                                <TextField onChange={onChange} label={I18n.barcode} placeholder={I18n.barcode} value={barcode} error={errors['barcode']} required={true} />
                            </div>
                            <div className="priceSectionInputRight">
                                <TextField
                                    error={errors['unit']}
                                    label={I18n.primary_unit}
                                    placeholder={I18n.primary_unit}
                                    onChange={onChange}
                                    data={UNITS}
                                    highlighter={true}
                                    value={unit}
                                    inputType="select"
                                    suffix="downIcon2"
                                    suffix_fill={theme.white}
                                    suffix_width={'24'}
                                    suffix_height={'24'}
                                    suffix_viewBox={'0 0 18 18'}
                                />
                            </div>
                        </div>
                        <div className="intventoryFormGridTwo">
                            <div className="priceSectionInputLeft">
                                <TextField
                                    inputRef={secondaryUnitRef}
                                    label={I18n.secondary_unit}
                                    placeholder={I18n.secondary_unit}
                                    onChange={onChange}
                                    data={UNITS}
                                    highlighter={true}
                                    value={secondaryUnit || ''}
                                    inputType="select"
                                    type="text"
                                    suffix="downIcon2"
                                    suffix_fill={theme.white}
                                    suffix_width={'24'}
                                    suffix_height={'24'}
                                    suffix_viewBox={'0 0 18 18'}
                                    disabled={!unit}
                                />
                            </div>
                            {unit && secondaryUnit && (
                                <div className="conversionRateSectionRight">
                                    <div className="">
                                        <p className="marBot0 fontSize14" style={{color: theme.text}}>
                                            {`1 ${unit} =`}
                                        </p>
                                    </div>
                                    <div className="primaryRateSection">
                                        <TextField type="numeric" onChange={onChange} error={errors['rate']} label={I18n.rate} placeholder={0} value={rate} maxLength={8} />
                                    </div>
                                    <div className="">
                                        <p className="marBot0 fontSize14" style={{color: theme.text}}>
                                            {secondaryUnit}
                                        </p>
                                    </div>
                                </div>
                            )}
                        </div>
                    </>
                ) : (
                    <>
                        <TextField
                            error={errors['unit']}
                            label={I18n.primary_unit}
                            placeholder={I18n.primary_unit}
                            onChange={onChange}
                            data={UNITS}
                            highlighter={true}
                            value={unit}
                            inputType="select"
                            suffix="downIcon2"
                            suffix_fill={theme.white}
                            suffix_width={'24'}
                            suffix_height={'24'}
                            suffix_viewBox={'0 0 18 18'}
                        />
                        <div className="intventoryFormGridTwo">
                            <div className="priceSectionInputLeft">
                                <TextField
                                    inputRef={secondaryUnitRef}
                                    label={I18n.secondary_unit}
                                    placeholder={I18n.secondary_unit}
                                    onChange={onChange}
                                    data={UNITS}
                                    highlighter={true}
                                    value={secondaryUnit}
                                    inputType="select"
                                    type="text"
                                    suffix="downIcon2"
                                    suffix_fill={theme.white}
                                    suffix_width={'24'}
                                    suffix_height={'24'}
                                    suffix_viewBox={'0 0 18 18'}
                                    disabled={!unit}
                                />
                            </div>
                            {unit && secondaryUnit && (
                                <div className="conversionRateSectionRight">
                                    <div className="">
                                        <p className="marBot0 fontSize14" style={{color: theme.text}}>
                                            {`1 ${unit} =`}
                                        </p>
                                    </div>
                                    <div className="primaryRateSection">
                                        <TextField type="numeric" onChange={onChange} error={errors['rate']} label={I18n.rate} placeholder={0} value={rate} />
                                    </div>
                                    <div className="">
                                        <p className="marBot0 fontSize14" style={{color: theme.text}}>
                                            {secondaryUnit}
                                        </p>
                                    </div>
                                </div>
                            )}
                        </div>
                    </>
                )}

                {/* --- */}
            </div>
        </>
    );
};
export default SingleItemCreationFeild;
