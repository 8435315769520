import React from 'react'
import { useLanguageContext, useThemeContext } from '../../../../../../context';

const CashDetail = () => {
    const { I18n } = useLanguageContext();
    const { theme } = useThemeContext();
    return (
        <div className="detailItemPurchasedInner">
            <p className="fontSize12 fontWeight400" style={{ color: theme.lightGrayTwo }}>
                {I18n.payment_type}
            </p>

            <p className="fontSize14 fontWeight400" style={{ color: theme.white }}>
                {I18n.cash}
            </p>
        </div>
    )
}

export default CashDetail
