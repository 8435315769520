import {useAuthContext} from '../../../../../context';
import {CustomContainer, Loading} from '../../../../common';
import CreateRiderComponent from '../../../../common/CreateRiderComponent';
import useRider from '../../../../../hooks/useRider';

const EditRider = () => {
    const {business} = useAuthContext();
    const {isLoading, rider} = useRider(business);

    return isLoading ? (
        <Loading />
    ) : (
        <>
            <CustomContainer className="addScreenMainBox createCustomerContainer">
                <CreateRiderComponent rider={rider} />
            </CustomContainer>
        </>
    );
};

export default EditRider;
