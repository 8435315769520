import {useState} from 'react';
import withObservables from '@nozbe/with-observables';
import {useNavigate} from 'react-router-dom';
import {DEVICE_HEIGHT} from '../../../constants';
import {useLanguageContext, useThemeContext} from '../../../context';
import TableHeader from '../TableContainer/TableHeader';
import OrderListItem from './OrderListItem';

const OrderList = ({orders, customerDetails = false}) => {
    const {I18n} = useLanguageContext();
    const {theme} = useThemeContext();
    const [selectOrder, setselectOrder] = useState();
    let navigate = useNavigate();

    const OrderModalToggle = val => {
        setselectOrder(val);
        navigate(`/orders/${val.number}`, {replace: true});
    };

    return (
        <div>
            <TableHeader className={`fiveColumn`} style={{borderColor: theme.posRightBg}}>
                <div className="BoxWidth justifyStart marLeft10">
                    <p className="fontSize16 OneLineTruncate" style={{color: theme.white}}>
                        {I18n.date}
                    </p>
                </div>
                <div className="BoxWidth justifyCenter">
                    <p className="fontSize16 OneLineTruncate" style={{color: theme.white}}>
                        {I18n.order_no_without_dot}
                    </p>
                </div>
                <div className="BoxWidth justifyCenter">
                    <p className="fontSize16 OneLineTruncate" style={{color: theme.white}}>
                        {customerDetails ? I18n.points_earned : I18n.amount}
                    </p>
                </div>
                <div className="BoxWidth justifyCenter">
                    <p className="fontSize16 OneLineTruncate" style={{color: theme.white}}>
                        {customerDetails ? I18n.bill_amount : I18n.actions}
                    </p>
                </div>

                <div className="BoxWidth justifyCenter">
                    <p className="fontSize16 OneLineTruncate" style={{color: theme.white}}>
                        {I18n.payment}
                    </p>
                </div>
            </TableHeader>

            <div className="tableListMainBox orderListBodyBox cursorPointer" style={{height: DEVICE_HEIGHT - 170 }}>
                {orders?.map(val => (
                    <OrderListItem customerDetails={customerDetails} order={val} OrderModalToggle={OrderModalToggle} />
                ))}
            </div>
        </div>
    );
};

const enhance = withObservables(['customer'], ({customer}) => ({
    orders: customer.getCompletedOrders.observe(),
}));

export default enhance(OrderList);
