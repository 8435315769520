import React, {useEffect, useState} from 'react';
import {useLanguageContext, useThemeContext} from '../../../context';
import NewAppModal from '../../common/NewAppModal';
import RadioButton from '../RadioButton';
import {SPLIT_BILL, toSnakeCase} from '../../../constants';
import SplitBillByAmount from './SplitBillByAmount';
import SplitBillIntoEqualPayments from './SplitBillIntoEqualPayments';
import {bulkCreatePayments} from '../../../pos-core/database/helpers';

const SplitBillModal = ({toggle, setToggle, totalBill, order, business}) => {
    const {I18n} = useLanguageContext();
    const {theme} = useThemeContext();

    const [selectedBillType, setSelectedBillType] = useState();
    const [payments, setPayments] = useState([]);
    const [numberOfPayment, setNumberOfPayment] = useState(0);
    const [billTypes, setBillTypes] = useState(SPLIT_BILL);
    const [errors, setErrors] = useState({});

    const addlabelsToBillTypes = () => {
        const modifiedTypes = billTypes?.map(item => ({
            ...item,
            label: (
                <div className="splitBillWrapper">
                    <p className="marBot0 marTop0 fontSize14 fontWeight400 white">{item.title}</p>
                    <p className="marBot0 marTop0 fontSize12 fontWeight400 darkGray">{item.subTitle}</p>
                </div>
            ),
            value: item.title,
        }));

        setBillTypes(modifiedTypes);
    };

    useEffect(() => {
        addlabelsToBillTypes();
    }, []);

    function handleClose(event) {
        setToggle(false);
        setSelectedBillType('');
    }

    const handleChange = async e => {
        const value = toSnakeCase(e?.target.value);
        setSelectedBillType(value);
    };

    const hanldeContinue = async () => {
        if (selectedBillType === 'split_bill_by_amount') {
            const splitPaymentAmount = payments.reduce((acc, curr) => Number(curr.amount) + acc, 0);
            if (totalBill === splitPaymentAmount) {
                const paymentPayload = payments.map(payment => ({
                    amount: Number(payment.amount),
                    status: 'pending',
                    expiry_date: new Date().getTime(),
                    started_at: new Date().getTime(),
                    created_at: new Date().getTime(),
                }));

                await bulkCreatePayments(paymentPayload, business, order);

                setToggle(false);
            } else {
                setErrors({
                    amount: 'Splitted amount is not equal to the total bill amount',
                });
            }
        } else if (selectedBillType === 'split_into_equal_payments') {
            if (numberOfPayment > 0) {
                const equalAmount = (totalBill / numberOfPayment).toFixed(2);
                const paymentPayload = Array.from({length: numberOfPayment}, (_, index) => ({
                    amount: Number(equalAmount),
                    status: 'pending',
                    expiry_date: new Date().getTime(),
                    started_at: new Date().getTime(),
                    created_at: new Date().getTime(),
                }));
                await bulkCreatePayments(paymentPayload, business, order);

                setToggle(false);
            } else {
                setErrors({
                    number_of_split: 'Number of splits will be greater than 0',
                });
            }
        } else {
            return;
        }
    };

    return (
        <>
            <NewAppModal
                className="deliveryFloorModal modal-backdrop-custom"
                toggle={toggle}
                backCta={{
                    name: 'backArrowIcon',
                    fill: theme.barclaysBlue,
                    width: '18',
                    height: '18',
                    viewBox: '0 0 18 18',
                }}
                handleClose={() => handleClose()}
                primaryCta={
                    selectedBillType
                        ? {
                              title: 'Continue',
                              action: () => hanldeContinue(),
                          }
                        : null
                }
                cancelCta={
                    selectedBillType
                        ? {
                              title: I18n.cancel,
                              action: () => handleClose(),
                          }
                        : null
                }
                title="Split Bill">
                <div className="flex horizontalCenter width100">
                    <div className="flex width50" style={{flexDirection: 'column'}}>
                        {selectedBillType === 'split_bill_by_amount' ? (
                            <SplitBillByAmount totalBill={totalBill} payments={payments} setPayments={setPayments} errors={errors} setErrors={setErrors} />
                        ) : selectedBillType === 'split_into_equal_payments' ? (
                            <SplitBillIntoEqualPayments totalBill={totalBill} setNumberOfPayment={setNumberOfPayment} errors={errors} setErrors={setErrors} />
                        ) : (
                            <>
                                <p className="fontSize20 marBot20" style={{color: theme.white}}>
                                    Split bill types
                                </p>

                                <RadioButton options={billTypes} className="billTypesWrapper" selectedSize={selectedBillType} handleChange={handleChange} showCustomBtn />
                            </>
                        )}
                    </div>
                </div>
            </NewAppModal>
        </>
    );
};

export default SplitBillModal;
