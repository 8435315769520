import React, {useState} from 'react';
import {Col, Container, Row} from 'react-bootstrap';
import List from './List';
import {useLanguageContext, useThemeContext} from '../../../context';
import ReportsBoxHeader from '../Header/ReportsBoxHeader';
import ReportCardBox from '../ReportCardBox';
import {CardData, DEVICE_HEIGHT, TopSell} from '../../../constants';
import NonIdealScreen from '../NonIdeal';
import TableHeader from '../TableContainer/TableHeader';
import TableFooter from '../TableFooter';
import Loading from '../Loading';

const TaxesReport = ({data, handleExportCSV, loading}) => {
    const {I18n} = useLanguageContext();
    const {theme} = useThemeContext();

    const handleExport = async () => {
        await handleExportCSV();
    };

    return loading ? (
        <div className="reportsWrapper">
            <div className="reportsWrapperInner">
                <Loading />
            </div>
        </div>
    ) : data?.tax_details?.length > 0 ? (
        <div className="reportsWrapper">
            <div className="reportsWrapperInner">
                <ReportsBoxHeader
                    title="sales_tax"
                    primaryCta={{
                        title: 'export_csv',
                        action: handleExport,
                    }}
                />

                <div className="ReportCardBoxWrapper">
                    <Row>
                        {Object.entries(data?.total_sales || {}).map(([key, value], index) => {
                            return (
                                <Col md={3} lg={3} key={index}>
                                    <ReportCardBox data={value} label={key} />
                                </Col>
                            );
                        })}
                    </Row>
                </div>

                <TableHeader className={`fiveColumn`} style={{borderColor: theme.inputBorder}}>
                    <div className="BoxWidth justifyStart">
                        <p className="fontSize16 OneLineTruncate" style={{color: theme.white}}>
                            {I18n.name}
                        </p>
                    </div>

                    <div className="BoxWidth justifyCenter">
                        <p className="fontSize16 OneLineTruncate" style={{color: theme.white}}>
                            {I18n.tax_rate_one}
                        </p>
                    </div>

                    <div className="BoxWidth justifyCenter">
                        <p className="fontSize16 OneLineTruncate" style={{color: theme.white}}>
                            {I18n.applied_on_of_items}
                        </p>
                    </div>
                    <div className="BoxWidth justifyCenter">
                        <p className="fontSize16 OneLineTruncate" style={{color: theme.white}}>
                            {I18n.taxable_item_sales}
                        </p>
                    </div>
                    <div className="BoxWidth justifyEnd">
                        <p className="fontSize16 OneLineTruncate" style={{color: theme.white}}>
                            {I18n.tax_collected}
                        </p>
                    </div>
                </TableHeader>

                <div className='tableListMainBox' style={{height: DEVICE_HEIGHT - 625, overflow: 'auto'}}>
                    {data?.tax_details?.map((item, index) => {
                        return <List data={item} index={index} />;
                    })}
                </div>
                <TableFooter title="total" value={data?.total || 0} />
            </div>
        </div>
    ) : (
        <div className="onlineNonIdeal reportDashboardBox">
            <NonIdealScreen heading={I18n.taxes} subHeading={I18n.no_activity_zero_transactions_recorded} name="taxReportIcon" fill={theme.white} isMultiStep={false} viewBox='0 0 100 100' />
        </div>
    );
};

export default TaxesReport;
