import React, {useState} from 'react';
import {Col, Row} from 'react-bootstrap';
import {useAuthContext, useLanguageContext, useThemeContext} from '../../../../../context';
import {Header} from '../../../../common';
import {useNavigate} from 'react-router-dom';
import VerifyOtpModal from '../../../../common/VerifyOtpModal';
import {sendWalletOTP} from '../../../../../api';
import {OSCAR_BENEFICIARY_VERIFICATION} from '../../../../../constants';

const ConfirmBeneficiaryDetails = ({current, setCurrent, from}) => {
    const {I18n} = useLanguageContext();
    const {theme} = useThemeContext();
    const {business} = useAuthContext();
    const [otpModal, setOtpModal] = useState(false);
    const [loading, setLoading] = useState(false);
    let navigate = useNavigate();

    const handleBack = () => {
        setCurrent(prevCurrent => ({
            ...prevCurrent,
            step: 1,
        }));
    };

    const handleCancel = () => {
        if (from === 'beneficiaryPage') {
            navigate('/beneficiary');
        } else {
            navigate('/dashboard', {state: {screenName: 'wallet', tab: 'balance'}});
            localStorage.setItem('showBeneficiaryModal', true);
        }
    };

    const handleContinue = async () => {
        setLoading(true);

        try {
            const sendOtpPayload = {
                business_id: business.id,
                action_type: OSCAR_BENEFICIARY_VERIFICATION,
            };

            const response = await sendWalletOTP(sendOtpPayload);
            if (response.otp_sent) {
                setCurrent(prevCurrent => ({
                    ...prevCurrent,
                    document_id: response.document_id,
                }));
                setOtpModal(true);
            }
        } catch (error) {
            console.log('error', error);
        } finally {
            setLoading(false);
        }
    };

    const handleClose = () => {
        setOtpModal(false);
    };

    return (
        <div>
            <Header
                type="draftHeader"
                title={I18n.back}
                backAction={handleBack}
                isDisabled={loading}
                saveCta={{
                    title: I18n.continue,
                    action: () => handleContinue(),
                    saveLoad: loading,
                }}
                primaryCta={{
                    title: I18n.cancel,
                    action: () => handleCancel(),
                }}
            />

            <Row className="justify-content-md-center">
                <Col md={5}>
                    <div>
                        <p className="fontSize24 textCenter fontWeight600 marBot20 marTop0" style={{color: theme.text}}>
                            {I18n.confirm_beneficiary_details}
                        </p>
                        <p className="marTop20 fontSize20 fontWeight400 marBot15 marTop0" style={{color: theme.text}}>
                            {I18n.beneficiary_account_details}
                        </p>
                    </div>

                    <div className="marTop20">
                        <div className="flex spaceBetweenCenter">
                            <p className="fontSize16 marBot0" style={{color: theme.lightGrayTwo}}>
                                {I18n.account_title}
                            </p>
                            <p className="fontSize16 marBot0" style={{color: theme.white}}>
                                {current.data.accountTitle}
                            </p>
                        </div>
                        <div className="thinDivider width100 marTop15 marBot15" />

                        <div className="flex spaceBetweenCenter">
                            <p className="fontSize16 marBot0" style={{color: theme.lightGrayTwo}}>
                                {I18n.account_number}
                            </p>
                            <p className="fontSize16 marBot0" style={{color: theme.white}}>
                                {current.data.accountNumber}
                            </p>
                        </div>
                        <div className="thinDivider width100 marTop15 marBot15" />

                        <div className="flex spaceBetweenCenter">
                            <p className="fontSize16 marBot0" style={{color: theme.lightGrayTwo}}>
                                {I18n.bank}
                            </p>
                            <p className="fontSize16 marBot0" style={{color: theme.white}}>
                                {current.data.bank}
                            </p>
                        </div>
                        <div className="thinDivider width100 marTop15 marBot15" />
                    </div>
                </Col>
            </Row>

            <VerifyOtpModal
                heading={I18n.verify_otp}
                toggle={otpModal}
                handleClose={handleClose}
                btnTitle={I18n.verify_otp}
                business={business}
                current={current}
                setCurrent={setCurrent}
                from={from}
            />
        </div>
    );
};

export default ConfirmBeneficiaryDetails;
