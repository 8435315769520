import {CustomContainer, Loading} from '../../../../common';
import CreateDealComponent from '../../../../common/CreateDealComponent';
import useDeal from '../../../../../hooks/useDeal';

const EditDeal = () => {
    const {isLoading, deal} = useDeal();

    return isLoading ? (
        <Loading />
    ) : (
        <>
            <CustomContainer className="addScreenMainBox createCustomerContainer">
                <CreateDealComponent deal={deal} />
            </CustomContainer>
        </>
    );
};

export default EditDeal;
