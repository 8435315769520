import React from 'react';
import {useLanguageContext, useThemeContext} from '../../../context';

const ReportCardBox = ({data, label}) => {
    const {I18n} = useLanguageContext();
    const {theme} = useThemeContext();

    return (
        <div className="ReportCardBox">
            <p className="cardAmountBox" style={{color: theme.white}}>
                Rs. {data}
            </p>
            <p className="cardTitleBox" style={{color: theme.white}}>
                {I18n[label]}
            </p>
        </div>
    );
};

export default ReportCardBox;
