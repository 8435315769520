import Icon from '../../../../assets/icons';
import {useThemeContext} from '../../../../context';
import Button from '../../Button';
import NameSlice from '../../NameSlice';

const ListItem = ({rider, onPress, order}) => {
    const {theme} = useThemeContext();
    let isSelected = order.rider.id == rider._raw.id ? true : false;

    const handleSelect = rider => {
        if (isSelected) {
            onPress('removeRider');
        } else {
            onPress(rider);
        }
    };

    return (
        <div className="selectCustomerInnerRow cursorPointer" style={{borderColor: theme.darkSlateBlue2}}>
            <div className="customerInitials">
                <NameSlice value={rider?.name || ''} />
            </div>
            <div className="customerDetailsBox">
                <span>
                    <p className="marBot0 fontSize18" style={{color: theme.white}}>
                        {rider.name}
                    </p>
                    <p className="marBot0 fontSize14" style={{color: theme.lightGrayTwo}}>
                        {rider.phone_number}
                    </p>
                </span>

                <div>
                    <Button
                        title={isSelected ? 'Unassign' : 'Assign'}
                        handleClick={() => handleSelect(rider)}
                        className={`${isSelected ? 'unAssignBtn' : 'assignBtn'} fontWeight400 fontSize14 cursorPointer`}
                    />
                </div>
            </div>
        </div>
    );
};

export default ListItem;
