import React, {useState} from 'react';
import {Col, Container, Row} from 'react-bootstrap';
import List from './List';
import {useLanguageContext, useThemeContext} from '../../../context';
import ReportsBoxHeader from '../Header/ReportsBoxHeader';
import ReportCardBox from '../ReportCardBox';
import {CardData, DEVICE_HEIGHT, formatNum, TopSell} from '../../../constants';
import NonIdealScreen from '../NonIdeal';
import TableHeader from '../TableContainer/TableHeader';
import TableFooter from '../TableFooter';
import PercentageBar from '../PercentageBar';
import Icon from '../../../assets/icons';
import Loading from '../Loading';

const PaymentMethodReport = ({data, handleExportCSV, loading}) => {
    const {I18n} = useLanguageContext();
    const {theme} = useThemeContext();

    const handleExport = async () => {
        await handleExportCSV();
    };
    const isNonIdeal = Object?.values(data?.sales_by_payments || {})?.some(value => value.sales > 0);
    return loading ? (
        <div className="reportsWrapper">
            <div className="reportsWrapperInner">
                <Loading />
            </div>
        </div>
    ) : isNonIdeal ? (
        <div className="reportsWrapper">
            <div className="reportsWrapperInner">
                <ReportsBoxHeader
                    title="payment_methods"
                    primaryCta={{
                        title: 'export_csv',
                        action: handleExport,
                    }}
                />
                <p className="fontSize18 fontWeight500 marBot10" style={{color: theme.white}}>
                    {I18n.top_payment_methods_by_net_total}
                </p>

                <PercentageBar
                    data={{
                        total: data?.total || 0,
                        cash: data?.sales_by_payments?.cash?.sales || 0,
                        card: data?.sales_by_payments?.card?.sales || 0,
                        wallet: data?.sales_by_payments?.wallet?.sales || 0,
                        credit: data?.sales_by_payments?.credit?.sales || 0,
                    }}
                />

                <TableHeader className={`fourColumn marTop50`} style={{borderColor: theme.inputBorder}}>
                    <div className="BoxWidth justifyStart">
                        <p className="fontSize16 OneLineTruncate" style={{color: theme.white}}>
                            {I18n.payment_method}
                        </p>
                    </div>

                    <div className="BoxWidth justifyCenter">
                        <p className="fontSize16 OneLineTruncate" style={{color: theme.white}}>
                            {I18n.payment}
                        </p>
                    </div>

                    <div className="BoxWidth justifyCenter">
                        <p className="fontSize16 OneLineTruncate" style={{color: theme.white}}>
                            {I18n.percentage}
                        </p>
                    </div>

                    <div className="BoxWidth justifyEnd">
                        <p className="fontSize16 OneLineTruncate" style={{color: theme.white}}>
                            {I18n.sales}
                        </p>
                    </div>
                </TableHeader>

                <div className="tableListMainBox" style={{height: DEVICE_HEIGHT - 610}}>
                    {Object.entries(data?.sales_by_payments || {}).map(([key, value], index) => {
                        return <List key={index} data={value} index={index} label={key} />;
                    })}
                </div>
                <TableFooter title="total" value={data?.total || 0} />
            </div>
        </div>
    ) : (
        <div className="onlineNonIdeal reportDashboardBox">
            <NonIdealScreen heading={I18n.payment_method} subHeading={I18n.no_activity_zero_transactions_recorded} name="paymentReportIcon" fill={theme.white} isMultiStep={false} viewBox='0 0 100 100' />
        </div>
    );
};

export default PaymentMethodReport;
