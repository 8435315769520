import React, {Fragment} from 'react';
import DashboardDataBox from '../DashboardDataBox';
import MetricsCard from '../MetricsCard';
import DashboardCardNonIdeal from '../NonIdeal/DashboardCardNonIdeal';
import {useNavigate} from 'react-router-dom';
import {useAuthContext} from '../../../context';
import DashboardListNonIdeal from '../NonIdeal/DashboardListNonIdeal';
import {RETAIL} from '../../../constants';

const KeyMetrics = ({metricsData, isPosPermission, title, type}) => {
    let navigate = useNavigate();
    const {business} = useAuthContext();

    const handleAction = () => {
        if (business?.type === RETAIL) {
            navigate('/pos');
        } else {
            navigate('/restaurant-pos');
        }
    };

    const hasPositiveValue = data => {
        return Object.values(data).some(value => typeof value === 'number' && value > 0);
    };

    const isDataExists = hasPositiveValue(metricsData.return_matrix) || hasPositiveValue(metricsData.sales_matrix[0]) || hasPositiveValue(metricsData.transaction_matrix);

    return (
        <DashboardDataBox title={title}>
            {isDataExists ? (
                <div className="keyMetricsWrapperBox">
                    <div className="keyMetricsWrapper">
                        {Object.entries(metricsData.sales_matrix[0]).map(([title, value], index) => {
                            const data = {title, value};
                            return <MetricsCard data={data} index={index} key={index} />;
                        })}
                    </div>

                    <div className="keyMetricsWrapper">
                        {Object.entries(metricsData.return_matrix).map(([title, value], index) => {
                            const data = {title, value};
                            return <MetricsCard data={data} index={index} key={index} />;
                        })}
                    </div>

                    <div className="keyMetricsWrapper">
                        {Object.entries(metricsData.transaction_matrix).map(([title, value], index) => {
                            const data = {title, value};
                            return <MetricsCard data={data} index={index} key={index} />;
                        })}
                    </div>
                </div>
            ) : (
                <DashboardCardNonIdeal
                    data={{
                        title: 'oscar_can_provide_insights_to_help_grow_their_business',
                        subTitle: 'oscar_nonideal_title',
                        actionTitle: 'start_selling',
                        handleAction: handleAction,
                        showCta: isPosPermission?.canCrud,
                    }}
                />
            )}
        </DashboardDataBox>
    );
};

export default KeyMetrics;
