import {useAuthContext, useLanguageContext, useThemeContext} from '../../../../../context';
import {Dropdown, MessageModal} from '../../../../common';
import TableBody from '../../../../common/TableContainer/TableBody';
import {useState} from 'react';
import {deleteBeneficiary} from '../../../../../api';

const List = ({beneficiary, userPermission, business, fetchBeneficiaries}) => {
    const {setRenderToast} = useAuthContext();
    const {I18n} = useLanguageContext();
    const {theme} = useThemeContext();
    const [archiveModal, setArchiveModal] = useState(false);
    const [loading, setLoading] = useState(false);

    const toggleArchiveModal = () => setArchiveModal(x => !x);

    const handleArchive = async beneficiary => {
        setLoading(true);
        try {
            const payload = {
                business_id: business.id,
                beneficiary_id: beneficiary?.beneficiary_id,
            };
            const response = await deleteBeneficiary(payload);
            if (response.success) {
                setRenderToast('beneficiary_deleted');
                fetchBeneficiaries();
            }
        } catch (error) {
            console.log('error', error);
        } finally {
            setLoading(false);
            setArchiveModal(false);
        }
    };

    const tableAction = [
        {
            title: I18n.delete,
            action: toggleArchiveModal,
        },
    ];

    return (
        <>
            <TableBody className={`sevenColumn cursorPointer`} style={{borderColor: theme.inputBorder}}>
                <div
                    className="BoxWidth justifyStart"
                    onClick={e => {
                        e.stopPropagation();
                    }}>
                    <p className="fontSize14 fontWeight600 OneLineTruncate" style={{color: theme.white}}>
                        {beneficiary.account_title}
                    </p>
                </div>
                <div className="BoxWidth justifyCenter">
                    <p className="fontSize14 OneLineTruncate" style={{color: theme.white}}>
                        {beneficiary.bank_name}
                    </p>
                </div>
                <div className="BoxWidth justifyCenter">
                    <p className="fontSize14 OneLineTruncate" style={{color: theme.white}}>
                        {beneficiary.account_number}
                    </p>
                </div>
                <div className="BoxWidth justifyCenter">
                    <p className="fontSize14 OneLineTruncate" style={{color: theme.white}}>
                        {beneficiary.name}
                    </p>
                </div>
                <div className="BoxWidth justifyCenter">
                    <p className="fontSize14 OneLineTruncate" style={{color: theme.white}}>
                        {beneficiary.contact || '-'}
                    </p>
                </div>
                <div className="BoxWidth justifyCenter">
                    <p className="fontSize14 OneLineTruncate" style={{color: theme.barclaysBlue}}>
                        {beneficiary.email || '-'}
                    </p>
                </div>

                {userPermission?.canCrud && (
                    <div
                        className="BoxWidth justifyCenter"
                        onClick={e => {
                            e.stopPropagation();
                        }}>
                        <div className="listActionBox deleteOption">
                            <Dropdown
                                dropDown={{
                                    name: 'threeDotIcon',
                                    fill: theme.barclaysBlue,
                                    width: 18,
                                    height: 4,
                                    viewBox: '0 0 18 4',
                                    option: tableAction,
                                }}
                            />
                        </div>
                    </div>
                )}
            </TableBody>

            <MessageModal
                setToggle={setArchiveModal}
                toggle={archiveModal}
                className="messageModal"
                description={I18n.do_you_really_want_to_delete_this_beneficiary}
                secondaryCta={{
                    title: I18n.cancel,
                    action: toggleArchiveModal,
                }}
                primaryCta={{
                    title: I18n.delete,
                    action: () => {
                        handleArchive(beneficiary);
                    },
                    loading: loading,
                }}
            />
        </>
    );
};

export default List;
