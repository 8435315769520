import React, {useEffect, useState} from 'react';
import {CustomContainer, Header, ToastBox} from '../../../../common';
import {useAuthContext, useLanguageContext, useThemeContext} from '../../../../../context';
import {useNavigate} from 'react-router-dom';
import {Col, Row} from 'react-bootstrap';
import ReviewPaymentCard from '../../../../common/ReviewPaymentCard';
import {bulkPayoutBeneficiaries} from '../../../../../api';
import {formatDate, formatNum} from '../../../../../constants';
import usePermission from '../../../../../hooks/usePermission';

const ReviewPayments = () => {
    const {I18n} = useLanguageContext();
    const {theme} = useThemeContext();
    const {payoutBeneficiaries, handleBeneficiaries, business} = useAuthContext();
    const userPermission = usePermission('wallet-overview');
    let navigate = useNavigate();

    const payoutList = Object.values(payoutBeneficiaries || {});
    const totalAmount = payoutList?.reduce((acc, curr) => acc + (curr.amount || 0), 0);
    const [loading, setLoading] = useState(false);
    const [showMessage, setShowMessage] = useState({
        visible: false,
        message: '',
    });
    let currentTimestamp = new Date();

    const handleBack = () => {
        navigate(-1);
    };

    const handleNext = async () => {
        setLoading(true);
        try {
            const payload = {
                business_id: business.id,
                data: payoutList,
            };
            const response = await bulkPayoutBeneficiaries(payload);
            if (response.success) {
                handleBeneficiaries(response.payouts);
                navigate('/payout-receipt');
            }
        } catch (error) {
            console.log('error', error);
            setShowMessage({
                visible: true,
                message: error?.response?.data?.message,
            });
        } finally {
            setLoading(false);
        }
    };

    const handleCancel = () => {
        navigate('/dashboard', {state: {screenName: 'wallet', tab: 'balance'}});
    };

    useEffect(() => {
        if (showMessage.visible) {
            const timeoutId = setTimeout(() => {
                setShowMessage({
                    visible: false,
                    message: '',
                });
            }, 3000);
            return () => clearTimeout(timeoutId);
        }
    }, [showMessage]);

    return (
        <>
            <CustomContainer className="addScreenMainBox createCustomerContainer" reduceHeight={45}>
                <Header
                    type="draftHeader"
                    title={I18n.back}
                    backAction={handleBack}
                    isDisabled={loading}
                    saveCta={
                        userPermission?.canCrud
                            ? {
                                  title: I18n.transfer,
                                  action: () => handleNext(),
                                  saveLoad: loading,
                              }
                            : null
                    }
                    primaryCta={
                        userPermission?.canCrud
                            ? {
                                  title: I18n.cancel,
                                  action: () => handleCancel(),
                              }
                            : null
                    }
                />

                <Row className="justify-content-md-center">
                    <Col md={6}>
                        <div>
                            <p className="fontSize24  textCenter fontWeight600 marBot0 marTop20" style={{color: theme.text}}>
                                {I18n.review_payments}
                            </p>
                            <div className="marTop40">
                                <p className="fontSize20" style={{color: theme.text}}>
                                    {I18n.confirm_payment_details}
                                </p>
                            </div>
                            <div className="spaceBetweenCenter marTop20">
                                <p className="fontSize14" style={{color: theme.text}}>
                                    {`${I18n.date}:`}
                                </p>

                                <p className="fontSize14" style={{color: theme.text}}>
                                    {formatDate(currentTimestamp)}
                                </p>
                            </div>
                            <div className="spaceBetweenCenter ">
                                <p className="fontSize14" style={{color: theme.text}}>
                                    {I18n.total_amount}
                                </p>

                                <p className="fontSize14" style={{color: theme.text}}>
                                    {`Rs. ${formatNum(totalAmount)}`}
                                </p>
                            </div>
                            <div className="thinDivider width100 marTop0" />
                            <div className="marTop20">
                                {payoutList?.map((val, ind) => (
                                    <ReviewPaymentCard length={payoutList.length} payee={val} key={ind} />
                                ))}
                            </div>
                        </div>
                    </Col>
                </Row>
            </CustomContainer>
            <ToastBox
                className={`addSuccessfullyToast ${showMessage?.visible ? 'errorToastMessage' : ''} `}
                ToastVisiable={showMessage.visible}
                bodyPara={showMessage.message}
                setShowMessage={setShowMessage}
                showIcon={true}
                errorToast={true}
            />
        </>
    );
};

export default ReviewPayments;
