import {Model, Q} from '@nozbe/watermelondb';
import {field, text, relation, children, writer, date, lazy, json} from '@nozbe/watermelondb/decorators';
import {PRODUCT_BUSINESS_SCHEMA, PRODUCT_SCHEMA} from '../schema';

export default class Product extends Model {
    static table = PRODUCT_SCHEMA;

    static associations = {
        account: {type: 'belongs_to', key: 'account_id'},
        category: {type: 'belongs_to', key: 'category_id'},
        product_template: {type: 'belongs_to', key: 'product_template_id'},
        sales_tax: {type: 'belongs_to', key: 'sales_tax_id'},
        purchase_tax: {type: 'belongs_to', key: 'purchase_tax_id'},
        deal: {type: 'belongs_to', key: 'deal_id'},
        order_line: {type: 'has_many', foreignKey: 'product_id'},
        purchase_order_line: {type: 'has_many', foreignKey: 'product_id'},
        product_modifier: {type: 'has_many', foreignKey: 'product_id'},
        product_business: {type: 'has_many', foreignKey: 'product_id'},
    };

    @text('name') name;
    @field('base_price') base_price;
    @field('selling_price') selling_price;
    @field('cost_price') cost_price;
    @field('profit') profit;
    @field('margin') margin;
    @field('quantity') quantity;
    @text('barcode') barcode;
    @text('type') type;
    @field('low_stock') low_stock;
    @field('archive') archive;
    @field('draft') draft;
    @date('created_at') created_at;
    @json('product_level_discount', val => val) product_level_discount;

    @relation('account', 'account_id') account;
    @relation('category', 'category_id') category;
    @relation('product_template', 'product_template_id') product_template;
    @relation('sales_tax', 'sales_tax_id') sales_tax;
    @relation('purchase_tax', 'purchase_tax_id') purchase_tax;
    @relation('deal', 'deal_id') deal;

    @children('order_line') order_line;
    @children('purchase_order_line') purchase_order_line;
    @children('product_modifier') product_modifier;
    @children('product_business') product_business;

    @lazy getProdBusiness = id => this.product_business.extend(Q.where('business_id', id));

    @writer async createProdBuss(payload) {
        return await this.collections.get(PRODUCT_BUSINESS_SCHEMA).create(item => {
            item.product.set(this);
            item.business.set(payload.business);
            item.quantity = payload.quantity;
        });
    }

    @writer async archiveProduct(status = true) {
        return await this.update(prod => {
            prod.archive = status;
        });
    }

    @writer async setProductDeal(deal) {
        return await this.update(prod => {
            prod.deal.set(deal);
        });
    }

    @writer async updateDetails(details) {
        return await this.update(product => {
            product.sales_tax.set(details.tax);
            product.name = details.name;
            product.barcode = details.barcode;
            product.selling_price = details.selling_price;
            product.base_price = details.base_price;
            product.cost_price = details.cost_price;
            product.profit = details.profit;
            product.margin = details.margin;
            product.quantity = details.quantity;
            product.low_stock = details.low_stock;
            product.product_level_discount = details.item_level_discount;
            product.draft = details.draft;
        });
    }
}
