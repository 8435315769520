import {useState} from 'react';
import {useAuthContext, useLanguageContext, useThemeContext} from '../../../../../context';
import {CheckBox, Dropdown, MessageModal} from '../../../../common';
import TableBody from '../../../../common/TableContainer/TableBody';
import withObservables from '@nozbe/with-observables';
import {useNavigate} from 'react-router-dom';
import {formatNum} from '../../../../../constants';
import newImagePlaceholder from '../../../../../assets/images/newImagePlaceholder.svg';
import {bulkCreateProductBusiness} from '../../../../../pos-core/database/helpers/product_business';
import {VerificationModal} from '../../../../common/V2';

const List = ({product, category, template, handleChecked, selecteditem, setSelecteditem, hanldeChange, userPermission, prodBusiness , setShowMessage}) => {
    let navigate = useNavigate();

    const {theme} = useThemeContext();
    const {I18n} = useLanguageContext();
    const {account} = useAuthContext();

    const [archiveModal, setArchiveModal] = useState(false);
    const [loading, setLoading] = useState(false);

    const onChange = () => {
        // onChange
    };

    const toggleArchiveModal = () => setArchiveModal(x => !x);

    const handleArchive = async product => {
        setArchiveModal(false);
        let data = product?.id;
        if (selecteditem[data]) {
            setSelecteditem(items => {
                let obj = {...items};
                let item = obj[data];
                if (item) delete obj[data];
                return obj;
            });
        }
        await product?.archiveProduct(!product?.archive);
        setShowMessage({
            visible: true,
            message: product?.archive ? I18n.item_archive_successfully : I18n.item_unarchive_successfully,
        });
        hanldeChange(0);
    };

    const handleDuplicate = async () => {
        try {
            setLoading(true);
            const productTax = await product.sales_tax.fetch();
            const prodBus = await product.product_business.fetch();
            const business = await Promise.all(prodBus.map(async item => await item.business.fetch()));

            let paramsForProduct = {
                account,
                category,
                name: `${product.name} (Copy)`,
                description: product?.description,
                image: template?.image,
                unit: template?.unit,
                secondaryUnit: template?.secondary_unit,
                rate: Number(template?.conversion_rate),
                tax: productTax,
                base_price: product?.base_price,
                selling_price: product?.selling_price,
                cost_price: product?.cost_price,
                profit: product?.profit,
                margin: product?.margin,
                barcode: product?.barcode,
                locationsArray: business,
                item_level_discount: product?.product_level_discount,
                quantity: product?.quantity,
                low_stock: product?.low_stock,
                type: product?.type,
                draft: false,
            };

            const productTemplate = await account.createProductTemplate(paramsForProduct);
            const createdProduct = await productTemplate.createProduct(paramsForProduct);
            const prodBusPayload = {
                locations: business,
                product: createdProduct,
                quantity: product?.quantity,
            };
            await bulkCreateProductBusiness(prodBusPayload);
            setTimeout(() => {
                setLoading(false);
            }, 1000);
        } catch (error) {
            console.log('error', error);
            setLoading(false);
        }
    };

    const handleAction = type => {
        switch (type) {
            case 'archive':
                toggleArchiveModal();
                break;
            case 'duplicate':
                handleDuplicate();
                break;
            case 'edit':
                navigate(`${product.id}/edit`);
                break;
        }
    };

    const tableAction = [
        {
            title: I18n.edit,
            action: () => handleAction('edit'),
        },
        {
            title: I18n.duplicate,
            action: () => handleAction('duplicate'),
        },
        {
            title: I18n[product.archive ? 'unarchive' : 'archive'],
            action: () => handleAction('archive'),
        },
    ];

    const navigateToDetails = () => {
        if (!userPermission?.canCrud) return;
        handleAction('edit');
    };

    return (
        <>
            <TableBody className={'sevenColumn'} style={{borderColor: theme.posRightBg}} onClick={navigateToDetails}>
                <div
                    onClick={e => {
                        e.stopPropagation();
                    }}
                    className="BoxWidth justifyStart itemListBox">
                    <CheckBox
                        className={'itemlistCheckBox'}
                        checkboxLabel={
                            <p className="fontSize14 fontWeight600" style={{color: theme.white}}>
                                <span className="itemListImage">
                                    <img src={template?.image || newImagePlaceholder} style={{width: 40, height: 40, objectFit: 'cover'}} />
                                </span>
                                <span className="OneLineTruncate">{product.name}</span>
                            </p>
                        }
                        onChange={() => handleChecked(product)}
                        checked={selecteditem[product?.id]}
                    />
                </div>

                <div className="BoxWidth justifyCenter">
                    <p className="fontSize14 OneLineTruncate" style={{color: theme.white}}>
                        {category?.name || '-'}
                    </p>
                </div>
                <div className="BoxWidth justifyCenter">
                    <p className="fontSize14 OneLineTruncate" style={{color: theme.white}}>
                        {`${formatNum(prodBusiness[0]?.quantity)} ${template?.unit}`}
                    </p>
                </div>
                <div className="BoxWidth justifyCenter">
                    <p className="fontSize14 OneLineTruncate" style={{color: theme.white}}>
                        {formatNum(product.low_stock)}
                    </p>
                </div>
                <div className="BoxWidth justifyCenter">
                    <p className="fontSize14 OneLineTruncate" style={{color: theme.white}}>
                        Rs. {formatNum(product.selling_price)}
                    </p>
                </div>
                {userPermission?.canCrud && (
                    <div
                        className="BoxWidth justifyCenter"
                        onClick={e => {
                            e.stopPropagation();
                        }}>
                        <div className="listActionBox">
                            <Dropdown
                                dropDown={{
                                    name: 'threeDotIcon',
                                    fill: theme.barclaysBlue,
                                    width: 18,
                                    height: 4,
                                    viewBox: '0 0 18 4',
                                    option: tableAction,
                                }}
                            />
                        </div>
                    </div>
                )}
            </TableBody>

            <MessageModal
                className="messageModal"
                setToggle={setArchiveModal}
                toggle={archiveModal}
                title={I18n.archive_product}
                description={I18n[product.archive ? 'are_you_sure_you_want_to_unarchive' : 'are_you_sure_you_want_to_archive'] + product.name + '?'}
                secondaryCta={{
                    title: I18n.no,
                    action: toggleArchiveModal,
                }}
                primaryCta={{
                    title: I18n.yes,
                    action: () => handleArchive(product),
                    isDelete: true,
                }}
            />

            <VerificationModal toggle={loading} setToggle={setLoading} title={I18n.duplicating_item} />
        </>
    );
};

const enhance = withObservables(['product'], ({product}) => {
    const business_id = localStorage.getItem('business_id');
    return {
        product: product?.observe(),
        category: product?.category.observe(),
        template: product?.product_template.observe(),
        prodBusiness: product?.getProdBusiness(business_id),
    };
});

export default enhance(List);
