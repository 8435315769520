import {Tabs, Tab, Paper} from '@material-ui/core';
import {useThemeContext} from '../../../context';

const TabBox = ({tabValue, handleChange, type, data, tabs, className, tabsObj}) => {
    const {theme} = useThemeContext();

    return (
        <div className={className || 'posTabBox'}>
            <Paper style={{backgroundColor: theme.topBarBG}} square>
                <Tabs
                    value={tabValue}
                    // variant="scrollable"
                    indicatorColor="primary"
                    onChange={handleChange}>
                    {tabsObj
                        ? tabs.map((label, index) => {
                              return <Tab key={index} label={label.name} />;
                          })
                        : tabs.map((label, index) => {
                              return <Tab key={index} label={label} />;
                          })}
                </Tabs>
            </Paper>
        </div>
    );
};

export default TabBox;
