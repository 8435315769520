import {database} from '..';
import {uuid} from '../../constants';
import {DISCOUNT_BUSINESS_SCHEMA} from '../schema';

const discountBusiness = database.collections.get(DISCOUNT_BUSINESS_SCHEMA);

const prepareInsertion = (locations, discount) => {
    return locations.map(business => {
        return discountBusiness.prepareCreate(discountBus => {
            discountBus._raw.id = uuid();
            discountBus.discount.set(discount);
            discountBus.business.set(business);
        });
    });
};

export const bulkCreateDiscountBusiness = async ({locations, discount}) => {
    return await database.write(async () => {
        const allRecords = await prepareInsertion(locations, discount);
        await database.batch(...allRecords);
        return allRecords;
    });
};

const prepareDeletion = discountBusiness => {
    return discountBusiness.map(discBus => {
        return discBus.prepareMarkAsDeleted();
    });
};

export const bulkDeleteDiscountBusiness = async discountBusiness => {
    await database.write(async () => {
        const allRecords = await prepareDeletion(discountBusiness);
        await database.batch(...allRecords);
        return allRecords;
    });
};
