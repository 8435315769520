import {Container, Row, Col} from 'react-bootstrap';
import {useLanguageContext, useThemeContext} from '../../../context';
import Listitem from './ListItem';
import {DEVICE_HEIGHT} from '../../../constants';
import {useOrderFilter, useSearch} from '../../../hooks';
import TableHeader from '../TableContainer/TableHeader';
import withObservables from '@nozbe/with-observables';
import {observeHeldOrders} from '../../../pos-core/database/helpers';
import {PosAppModal} from '../V2';
import SearchBox from '../SearchBox';
import NonIdealScreen from '../NonIdeal';

const HoldOrderModal = ({toggle, setToggle, orders, handleSelect, handleSearch, handleHeldOrderCount, setShowMessage, isRestaurantPos}) => {
    const {I18n} = useLanguageContext();
    const {theme} = useThemeContext();

    const {text, setText, searchList} = useSearch(handleSearch);
    const {list, filter, setFilter} = useOrderFilter(handleFilter);

    const handleClose = () => {
        setToggle(false);
        setText('');
    };

    const onChange = (event, key) => {
        let value = key || event?.target?.value || '';

        setText(value);
    };

    function handleFilter(selected) {
        let filteredList = [...orders];
        setFilter(selected);
        if (selected && selected != 'all') {
            if (selected == 'most recent') filteredList = orders.sort((a, b) => new Date(b.completed_at) - new Date(a.completed_at));
            else if (selected == 'amount') filteredList = orders.sort((a, b) => a.total - b.total);
        } else {
            filteredList = orders;
        }
        return filteredList;
    }

    async function handleSearch(val) {
        let value = val.toLowerCase();
        const filteredList = await Promise.all(
            list?.map(async x => {
                const customerData = await x?.customer?.fetch();
                return x.number.toLowerCase().includes(value) || customerData?.name.toLowerCase().includes(value);
            }),
        );
        return list?.filter((_, index) => filteredList[index]);
    }

    const handleback = () => {
        setToggle(false);
        setText('');
    };

    const renderList = text ? searchList : list

    return (
        <PosAppModal
            className="selectCustomerModal"
            toggle={toggle}
            handleClose={handleClose}
            title={I18n.saved_orders}
            backCta={{
                name: 'backArrowIcon',
                fill: theme.barclaysBlue,
                width: '18',
                height: '18',
                viewBox: '0 0 18 18',
                action: handleback,
            }}>
            {!list.length == 0 && (
                <SearchBox
                    type="pos"
                    placeholder={I18n.search_order_by_name_number}
                    prefix={{
                        name: 'search2Icon',
                        fill: theme.white,
                    }}
                    onChange={onChange}
                />
            )}
            
            <Container fluid>
                <Row className="justify-content-md-center">
                    <Col md={12}>
                        {renderList.length ? (
                            <div className="posOrderList">
                                <TableHeader className={`fiveColumn`} style={{borderColor: theme.posRightBg}}>
                                    <div className="BoxWidth fontWeight600 justifyStart">
                                        <p className="fontSize16 OneLineTruncate" style={{color: theme.white}}>
                                            {I18n.customer}
                                        </p>
                                    </div>
                                    <div className="BoxWidth fontWeight600 justifyCenter">
                                        <p className="fontSize16 OneLineTruncate" style={{color: theme.white}}>
                                            {I18n.date}
                                        </p>
                                    </div>
                                    <div className="BoxWidth fontWeight600 justifyCenter">
                                        <p className="fontSize16 OneLineTruncate" style={{color: theme.white}}>
                                            {I18n.order_id}
                                        </p>
                                    </div>
                                    <div className="BoxWidth fontWeight600 justifyCenter">
                                        <p className="fontSize16 OneLineTruncate" style={{color: theme.white}}>
                                            {I18n.amount}
                                        </p>
                                    </div>
                                    <div className="BoxWidth fontWeight600 justifyCenter">
                                        <p className="fontSize16 OneLineTruncate" style={{color: theme.white}}>
                                            {I18n.actions}
                                        </p>
                                    </div>
                                </TableHeader>

                                <div
                                    className="selectCustomerListBox"
                                    style={{
                                        height: DEVICE_HEIGHT - 280,
                                    }}>
                                    {renderList?.map((val, index) => (
                                        <Listitem
                                            key={index}
                                            order={val}
                                            onPress={handleSelect}
                                            handleClose={handleClose}
                                            setShowMessage={setShowMessage}
                                            handleHeldOrderCount={handleHeldOrderCount}
                                            isRestaurantPos={isRestaurantPos}
                                        />
                                    ))}
                                </div>
                            </div>
                        ) : (
                            <NonIdealScreen
                                heading={ text ? I18n.search : I18n.saved_orders}
                                subHeading={ text ? I18n.we_couldnt_find_any_results_for_your_search : I18n.when_a_new_order_is_created_the_ongoing_order_will_be_saved_here}
                                name={text ? 'seacrhNotFoundIcon' :  "savedOrderIcon"}
                                fill={theme.white}
                                isMultiStep={false}
                            />
                        )}
                    </Col>
                </Row>
            </Container>
        </PosAppModal>
    );
};
const enhance = withObservables([], ({}) => {
    const business_id = localStorage.getItem('business_id');
    return {
        orders: observeHeldOrders(business_id),
    };
});

export default enhance(HoldOrderModal);
