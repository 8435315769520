import React from 'react';
import {Bar} from 'react-chartjs-2';
import {Chart as ChartJS, Title, Tooltip, Legend, BarElement, CategoryScale, LinearScale} from 'chart.js';

ChartJS.register(Title, Tooltip, Legend, BarElement, CategoryScale, LinearScale);

const BarChart = ({data}) => {
    const chartData = {
        labels: data.labels,
        datasets: [
            {
                label: data.legendText,
                data: data.values,
                backgroundColor: data.stylesData.barColor,
                borderColor: '#1E88E5',
                borderWidth: 1,
                borderRadius: data.stylesData.radius || 14,
                barPercentage: 0.7,
            },
        ],
    };

    const options = {
        responsive: true,
        plugins: {
            legend: {
                position: 'bottom',
                labels: {
                    color: '#ffff',
                    boxWidth: 0,
                    font: {
                        size: 14,
                    },
                    usePointStyle: false,
                },
                display: data.showLegend,
            },
            tooltip: {
                callbacks: {
                    // Remove label from tooltip
                    label: function () {
                        return '';
                    },
                },
            },
        },
        scales: {
            x: {
                title: {
                    display: true,
                    color: '#FFFFFF',
                    font: {
                        size: 14,
                    },
                },
                ticks: {
                    color: '#FFFFFF',
                    font: {
                        size: 12,
                        weight: 'bold',
                    },
                },
                barPercentage: 0.6,
                categoryPercentage: 0.8,
                stacked: true,
            },
            y: {
                title: {
                    display: true,
                    font: {
                        size: 12,
                        weight: '500',
                    },
                },
                ticks: {
                    color: '#FFFFFF',
                    font: {
                        size: 12,
                        weight: '500',
                    },
                    callback: function (value) {
                        return `Rs. ${value}`;
                    },
                    // stepSize: 20000,
                },
                // beginAtZero: false,
                grid: {
                    display: true,
                    color: '#2F3651',
                    lineWidth: 0.7,
                },
                stacked: true,
            },
        },
    };

    return (
        <div className="chart BarGraphBox">
            <Bar data={chartData} options={options} />;
        </div>
    );
};

export default BarChart;
