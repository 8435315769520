import React, {useEffect, useMemo, useState} from 'react';
import {useAuthContext, useLanguageContext, useThemeContext} from '../../../../../../context';
import {Header, NonIdealScreen} from '../../../../../common';
import {Col, Container, Row} from 'react-bootstrap';
import {usePayments} from '../../../../../../hooks';
import FilterItem from '../../../Payments/Transactions/TransactionList/FilterItem';
import HeaderItem from '../../../Payments/Transactions/TransactionList/HeaderItem';
import ListItem from './ListItem';
import {DEVICE_HEIGHT, downloadCsvVReport} from '../../../../../../constants';
import PaginationLoader from '../../../../../common/PaginationLoader';
import InfiniteScroll from 'react-infinite-scroll-component';
import debounce from 'lodash.debounce';
import {getWalletActivity, getWalletCtivityCSV} from '../../../../../../api';
import {useSearchParams} from 'react-router-dom';

const WalletActivity = ({selectedDateRange}) => {
    const {I18n} = useLanguageContext();
    const {theme} = useThemeContext();
    const [loading, setLoading] = useState(true);
    const {business} = useAuthContext();
    const [sortColumn, setSortColumn] = useState(null);
    const [sortOrder, setSortOrder] = useState(null);
    const [currentPage, setCurrentPage] = useState(1);
    const [totalpages, setTotalPages] = useState(0);
    const [filterOption, setFilterOption] = useState({});
    const [text, setText] = useState('');
    const [activityData, setActivityData] = useState([]);
    const [isFilterApplied, setIsFilterApplied] = useState(false);
    const [searchParams] = useSearchParams();
    const [isExportLoading, setIsExportLoading] = useState(false);

    useEffect(() => {
        setActivityData([]);
        setCurrentPage(1);
        setIsFilterApplied(!!text || Object.keys(filterOption).length > 0);
        getWalletActivityData(1);
    }, [selectedDateRange, text, filterOption]);

    async function getWalletActivityData(page) {
        if (!loading) setLoading(true);
        try {
            let params = {
                start_date: selectedDateRange.start_date,
                end_date: selectedDateRange?.end_date,
                business_id: business?.id,
                page: page || 1,
                limit: 20,
                filter: filterOption,
                search: text,
            };
            const response = await getWalletActivity(params);

            if (response?.success) {
                setActivityData(prevData => [...prevData, ...response?.data]);
                setCurrentPage(page);
                setTotalPages(response?.total_pages);
                setLoading(false);
            }
        } catch (error) {
            console.log('Error in getWalletActivityData API --->', error);
            setLoading(false);
        }
    }

    const handleExport = async () => {
        setIsExportLoading(true);
        try {
            let params = {
                business_id: business?.id,
                start_date: selectedDateRange.start_date,
                end_date: selectedDateRange?.end_date,
            };
            const response = await getWalletCtivityCSV(params);
            downloadCsvVReport(response, 'wallet_activity.csv');
            setIsExportLoading(false);
        } catch (error) {
            console.log('Error in Activity Export API --->', error);
            setIsExportLoading(false);
        }
    };

    const onChange = (label, value) => {
        setText(value);
    };

    const debouncedResults = useMemo(() => debounce(onChange, 500), []);

    const handleSort = column => {
        if (sortColumn === column) {
            if (sortOrder === 'asc') {
                setSortOrder('desc');
            } else if (sortOrder === 'desc') {
                setSortOrder(null);
                setSortColumn(null);
            } else {
                setSortOrder('asc');
            }
        } else {
            setSortColumn(column);
            setSortOrder('asc');
        }
    };

    const sortedTransactions = () => {
        if (!sortOrder || !sortColumn) return activityData;

        return [...activityData].sort((a, b) => {
            let valueA = a[sortColumn];
            let valueB = b[sortColumn];

            if (sortColumn === 'date_and_time') {
                valueA = new Date(a['timestamp']);
                valueB = new Date(b['timestamp']);
            }

            if (sortOrder === 'asc') {
                if (typeof valueA === 'string' && typeof valueB === 'string') {
                    return valueA.localeCompare(valueB);
                } else {
                    return valueA > valueB ? 1 : -1;
                }
            } else if (sortOrder === 'desc') {
                if (typeof valueA === 'string' && typeof valueB === 'string') {
                    return valueB.localeCompare(valueA);
                } else {
                    return valueA < valueB ? 1 : -1;
                }
            }

            return 0;
        });
    };

    const fetchMoreData = () => {
        getWalletActivityData(currentPage + 1);
    };

    const handleFilter = data => {
        const {amount = {}, type = [], status = []} = data;
        let filterData = {};

        if (amount.min != null && amount.max != null) {
            filterData.amount = {min: Number(amount.min), max: Number(amount.max)};
        }
        if (type.length > 0) filterData.type = type;
        if (status.length > 0) filterData.status = status;

        setFilterOption(filterData);
    };

    let HEADER_DATA = [
        {name: 'type', className: 'justifyStart', sorting: true},
        {name: 'amount', className: 'justifyCenter'},
        {name: 'date_and_time', className: 'justifyCenter', sorting: true},
        {name: 'transaction_id', className: 'justifyCenter'},
        {name: 'title', className: 'justifyCenter'},
    ];

    const urlFilter = searchParams.get('filter');
    return (
        <section className="layoutMainBox activityConatiner" style={{backgroundColor: theme.topBarBG, padding: '20px 0px'}}>
            {!isFilterApplied && !activityData.length && !loading ? (
                <NonIdealScreen
                    customHeight={220}
                    heading={I18n.activity}
                    subHeading={I18n.you_dont_have_any_activities_to_show_yet_please_make_some_transactions}
                    name="activityIcon"
                    fill={theme.white}
                    isMultiStep={false}
                />
            ) : (
                <div className="layoutMainInnerBox">
                    <Header
                        type="search"
                        title={I18n.activity}
                        search={{
                            placeholder: I18n.search_by_transaction_id,
                            onChange: debouncedResults,
                            prefix: {name: 'search2Icon', fill: theme.white},
                        }}
                        rightCta={{title: I18n.export_csv, action: handleExport, saveLoad: isExportLoading}}
                    />
                    <Container fluid>
                        <Row className="walletactivitylist">
                            <div className="walletFilterBox">
                                <FilterItem handleFilter={handleFilter} filterBasedOn={['amount', 'type', 'status']} urlFilter={urlFilter} />
                            </div>
                            {loading && !activityData?.length ? (
                                <PaginationLoader customeHeight={DEVICE_HEIGHT - 440} />
                            ) : activityData.length ? (
                                <Col md={12} xs={12}>
                                    <HeaderItem data={HEADER_DATA} handleSort={handleSort} sortColumn={sortColumn} sortOrder={sortOrder} />
                                    <div
                                        id="scrollableDiv"
                                        className="selectCustomerListBox tableListMainBox"
                                        style={{
                                            height: DEVICE_HEIGHT - 460,
                                        }}>
                                        <InfiniteScroll
                                            className="infiniteScrollBar"
                                            dataLength={activityData.length}
                                            next={fetchMoreData}
                                            hasMore={currentPage < totalpages}
                                            loader={<PaginationLoader />}
                                            scrollableTarget="scrollableDiv">
                                            {sortedTransactions().map((item, index) => (
                                                <ListItem key={index} index={index} item={item} />
                                            ))}
                                        </InfiniteScroll>
                                    </div>
                                </Col>
                            ) : (
                                <NonIdealScreen
                                    customHeight={0}
                                    heading={I18n.no_Payments_found}
                                    width={60}
                                    height={60}
                                    subHeading={I18n.there_arent_any_results_for_that_query_Try_using_different_filters}
                                    name="receiptSeacrchIcon"
                                    fill={theme.white}
                                    isMultiStep={false}
                                    backgroundColor={theme.blackBg}
                                />
                            )}
                        </Row>
                    </Container>
                </div>
            )}
        </section>
    );
};

export default WalletActivity;
