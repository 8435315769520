import {database} from '../index';
import {Q} from '@nozbe/watermelondb';
import {uuid} from '../../constants';
import {BUSINESS_SCHEMA, SETTING_CONFIG_SCHEMA} from '../schema';

const settings = database.collections.get(SETTING_CONFIG_SCHEMA);

export const getOptionByName = (business_id, name) =>
    settings.query(Q.on(BUSINESS_SCHEMA, [Q.where('id', business_id)]), Q.where('name', name)).fetch();

const prepareInsertOptions = (data, business) => {
    return data?.map(option => {
        try {
            return settings.prepareCreate(field => {
                field.business.set(business);
                field._raw.id = uuid();
                field.name = option.name;
                field.sub_title = option.sub_title;
                field.is_active = true;
                field.status = option.name === 'item_service_library' ? 'items' : 'off';
                field.created_at = new Date().getTime();

            });
        } catch (err) {
            console.log(err);
        }
    });
};

export const bulkCreateOptions = async (data, business) => {
    return await database.write(async () => {
        const allRecords = prepareInsertOptions(data, business);
        await database.batch(allRecords);
        return allRecords;
    });
};

export const bulkDeleteOptions = async options => {
    await database.write(async () => {
        const bulkDelete = options.map(item => item.prepareDestroyPermanently());
        await database.batch(...bulkDelete);
    });
};

