import {useLanguageContext, useThemeContext} from '../../../../../context';
import {CheckBox, Dropdown, MessageModal} from '../../../../common';
import TableBody from '../../../../common/TableContainer/TableBody';
import withObservables from '@nozbe/with-observables';
import {useNavigate} from 'react-router-dom';
import {useState} from 'react';
import {firstLetterCaptilize} from '../../../../../constants';

const List = ({tax, handleChecked, selectedtax, filter, setSelectedtax, userPermission , setShowMessage}) => {
    let navigate = useNavigate();

    const {I18n} = useLanguageContext();
    const {theme} = useThemeContext();

    const [archiveModal, setArchiveModal] = useState(false);

    const handleEdit = () => {
        if (!userPermission?.canCrud) return;
        navigate(`${tax.table == 'sales_tax' ? 'sales' : 'purchase'}/${tax.id}/edit`, {
            state: {
                screenName: filter,
            },
        });
    };

    const toggleArchiveModal = () => setArchiveModal(x => !x);

    const handleArchive = async val => {
        setArchiveModal(false);
        let data = val?.id;
        if (selectedtax[data]) {
            setSelectedtax(items => {
                let obj = {...items};
                let item = obj[data];
                if (item) delete obj[data];
                return obj;
            });
        }

        await tax.archiveTax(tax.archive ? false : true);
        setShowMessage({
            visible: true,
            message: tax.archive ? I18n.tax_archive_successfully : I18n.tax_unarchive_successfully,
        });
    };

    const tableAction = [
        {
            title: I18n.edit,
            action: handleEdit,
        },
        {
            title: I18n[tax.archive ? 'unarchive' : 'archive'],
            action: toggleArchiveModal,
        },
    ];

    return (
        <>
            <TableBody className={`fiveColumn`} style={{borderColor: theme.posRightBg}} onClick={handleEdit}>
                <div
                    className="checkboxCol"
                    onClick={e => {
                        e.stopPropagation();
                    }}>
                    <CheckBox
                        checkboxLabel={
                            <p className="marBot0 fontSize14 OneLineTruncate" style={{color: theme.text}}>
                                {firstLetterCaptilize(tax?.name || '--')}
                            </p>
                        }
                        onChange={() => {
                            handleChecked(tax);
                        }}
                        checked={selectedtax[tax?.id]}
                    />
                </div>
                <div className="BoxWidth justifyCenter">
                    <p className="fontSize14" style={{color: theme.white}}>
                        {`${tax.rate}%`}
                    </p>
                </div>
                <div className="BoxWidth justifyCenter">
                    <p className="fontSize14 OneLineTruncate" style={{color: theme.white}}>
                        {tax.table == 'sales_tax' ? I18n.sales : I18n.purchase}
                    </p>
                </div>
                <div className="BoxWidth justifyCenter">
                    <p className="fontSize14 OneLineTruncate" style={{color: theme.white}}>
                        {tax.table === 'sales_tax' ? (tax.is_inclusive ? I18n.tax_inclusive : I18n.tax_exclusive) : '--'}
                    </p>
                </div>

                {userPermission?.canCrud && (
                    <div
                        className="BoxWidth justifyCenter"
                        onClick={e => {
                            e.stopPropagation();
                        }}>
                        <div className="listActionBox">
                            <Dropdown
                                dropDown={{
                                    name: 'threeDotIcon',
                                    fill: theme.barclaysBlue,
                                    width: 18,
                                    height: 4,
                                    viewBox: '0 0 18 4',
                                    option: tableAction,
                                }}
                            />
                        </div>
                    </div>
                )}
            </TableBody>

            <MessageModal
                setToggle={setArchiveModal}
                toggle={archiveModal}
                className="messageModal"
                title={I18n.archive_tax}
                description={I18n[tax.archive ? 'are_you_sure_you_want_to_unarchive' : 'are_you_sure_you_want_to_archive'] + ' ' + tax.name + '?'}
                secondaryCta={{
                    title: I18n.no,
                    action: toggleArchiveModal,
                }}
                primaryCta={{
                    title: I18n.yes,
                    action: () => {
                        handleArchive(tax);
                    },
                    isDelete: true,
                }}
            />
        </>
    );
};

const enhance = withObservables(['tax'], ({tax}) => ({
    productCount: tax.product.observeCount(),
}));

export default enhance(List);
