import {Col, Container, Row} from 'react-bootstrap';
import {Header, Loading, NonIdealScreen, ToastBox} from '../../../../common';
import {useAuthContext, useLanguageContext, useThemeContext} from '../../../../../context';
import {useNavigate} from 'react-router-dom';
import TableHeader from '../../../../common/TableContainer/TableHeader';
import {DEVICE_HEIGHT} from '../../../../../constants';
import withObservables from '@nozbe/with-observables';
import List from './List';
import {useSearch} from '../../../../../hooks';
import {useEffect, useState} from 'react';
import {observeActiveCompany, observeDraftCompany} from '../../../../../pos-core/database/helpers';
import usePermission from '../../../../../hooks/usePermission';

const CompanyList = ({company, draftList}) => {
    const navigate = useNavigate();

    const {I18n} = useLanguageContext();
    const {theme} = useThemeContext();
    const {text, setText, searchList} = useSearch(handleSearch);

    const [filter, setFilter] = useState(I18n.all_company);
    const [loading, setloading] = useState(false);
    const [count, setCount] = useState(0);
    const [showMessage, setShowMessage] = useState({
        visible: false,
        message: '',
    });

    const {renderToast, setRenderToast} = useAuthContext();
    const userPermission = usePermission('company');

    useEffect(() => {
        setloading(true);
        setCount(company?.length);
        setloading(false);
    }, [company]);

    useEffect(() => {
        if (showMessage.visible) {
            const timeoutId = setTimeout(() => {
                setShowMessage({
                    visible: false,
                    message: '',
                });
                setRenderToast('');
            }, 3000);
            return () => clearTimeout(timeoutId);
        }
    }, [showMessage]);

    useEffect(() => {
        if (renderToast === 'company_created') {
            setShowMessage({
                visible: true,
                message: I18n.company_created_successfully,
            });
        } else if (renderToast === 'company_update') {
            setShowMessage({
                visible: true,
                message: I18n.company_updated_successfully,
            });
        } else if (renderToast === 'company_published_successfully') {
            setShowMessage({
                visible: true,
                message: I18n.company_published_successfully,
            });
        } else if (renderToast === 'company_saved_into_draft') {
            setShowMessage({
                visible: true,
                message: I18n.company_saved_into_draft,
            });
        }
    }, [renderToast]);

    const filterOption = [
        {
            title: I18n.all_company,
            action: () => handleFilter(I18n.all_company),
            checked: filter == I18n.all_company || filter === '' ? true : false,
        },
        {
            title: I18n.drafts_saved,
            action: () => handleFilter(I18n.drafts_saved),
            checked: filter == I18n.drafts_saved ? true : false,
        },
    ];

    const handleFilter = val => {
        setFilter(val);
    };

    const handleCreateCompany = () => {
        navigate('/company/create');
    };

    function handleSearch(val) {
        const filterList = company;
        const values = val?.toLowerCase();
        return filterList.filter(x => x?.name?.toLowerCase().includes(values));
    }

    const onChange = (label, value) => setText(value);

    const renderList = text ? searchList : filter === I18n.drafts_saved ? draftList : company;

    return loading ? (
        <Loading />
    ) : company?.length ? (
        <>
            <div className="pad20" style={{backgroundColor: theme.topBarBG}}>
                <Header
                    type="search"
                    title={`${I18n.company} (${count})`}
                    search={{
                        type: 'pos',
                        placeholder: I18n.search_by_name,
                        prefix: {
                            name: 'search2Icon',
                            fill: theme.white,
                        },
                        onChange: onChange,
                    }}
                    className={'borderRadiusTopleftTopRigt'}
                    leftCta={{
                        title: filter,
                        name: 'downIcon2',
                        fill: theme.white,
                        width: '20',
                        height: '20',
                        viewBox: '0 0 18 18',
                        option: filterOption,
                    }}
                    rightCta={
                        userPermission?.canCrud
                            ? {
                                  title: I18n.create_company,
                                  action: handleCreateCompany,
                              }
                            : null
                    }
                />

                <div className="tableListMainBox borderRadiusBottomleftBottomRigt" fluid style={{background: theme.blackBg, height: DEVICE_HEIGHT - 180}}>
                    <Container fluid>
                        <Row>
                            <Col>
                                <TableHeader className={`sixColumn`} style={{borderColor: theme.inputBorder}}>
                                    <div className="checkboxCol BoxWidth">
                                        <p className="fontSize16 OneLineTruncate" style={{color: theme.white}}>
                                            {I18n.name}
                                        </p>
                                    </div>

                                    <div className="BoxWidth justifyCenter">
                                        <p className="fontSize16 OneLineTruncate" style={{color: theme.white}}>
                                            {I18n.email}
                                        </p>
                                    </div>

                                    <div className="BoxWidth justifyCenter">
                                        <p className="fontSize16 OneLineTruncate" style={{color: theme.white}}>
                                            {I18n.phone}
                                        </p>
                                    </div>
                                    <div className="BoxWidth justifyCenter">
                                        <p className="fontSize16 OneLineTruncate" style={{color: theme.white}}>
                                            {I18n.province}
                                        </p>
                                    </div>
                                    <div className="BoxWidth justifyCenter">
                                        <p className="fontSize16 OneLineTruncate" style={{color: theme.white}}>
                                            {I18n.city}
                                        </p>
                                    </div>
                                    {userPermission?.canCrud && (
                                        <div className="BoxWidth justifyCenter">
                                            <p className="fontSize16 OneLineTruncate" style={{color: theme.white}}>
                                                {I18n.action}
                                            </p>
                                        </div>
                                    )}
                                </TableHeader>
                                <div style={{height: DEVICE_HEIGHT - 280}} className="tableListMainBox">
                                    {renderList?.length === 0 ? (
                                        <NonIdealScreen
                                            heading={filter === I18n.drafts_saved ? I18n.draft_company : I18n.search}
                                            subHeading={filter === I18n.drafts_saved ? I18n.no_draft_companies_found : I18n.we_couldnt_find_any_results_for_your_search}
                                            name={filter === I18n.drafts_saved ? 'draftIcon' : 'seacrhNotFoundIcon'}
                                            fill={theme.white}
                                            isMultiStep={false}
                                            tablenonIdeal={true}
                                        />
                                    ) : (
                                        renderList?.map((val, index) => <List key={index} company={val} userPermission={userPermission} />)
                                    )}
                                </div>
                                <ToastBox
                                    className="addSuccessfullyToast"
                                    ToastVisiable={showMessage.visible}
                                    bodyPara={showMessage.message}
                                    setShowMessage={setShowMessage}
                                    showIcon={true}
                                />
                            </Col>
                        </Row>
                    </Container>
                </div>
            </div>
        </>
    ) : (
        <>
            <NonIdealScreen
                heading={I18n.company}
                subHeading={I18n.create_new_company_and_add_to_sale}
                name="companyNewIcon"
                fill={theme.white}
                secondaryCta={
                    userPermission?.canCrud
                        ? {
                              title: I18n.create_company,
                              action: handleCreateCompany,
                          }
                        : null
                }
                isMultiStep={false}
            />
        </>
    );
};

const enhance = withObservables([], () => ({
    company: observeActiveCompany(),
    draftList: observeDraftCompany(),
}));

export default enhance(CompanyList);
