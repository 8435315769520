import React, {useEffect, useState} from 'react';
import {Col, Row} from 'react-bootstrap';
import {useLocation, useNavigate, useSearchParams} from 'react-router-dom';
import {useAuthContext, useLanguageContext, useThemeContext} from '../../../../../context';
import {BUSINESS_FLOORS, CITY, COUNTRY, Country_Name, LOCATION_TYPE, PROVINCE, toSnakeCase, uuid, validateLocationForm} from '../../../../../constants';
import {CustomContainer, Header, Loading, TextField} from '../../../../common';
import {bulkCreateOptions} from '../../../../../pos-core/database/helpers/setting_config';
import {getAllCountry} from '../../../../../api';
import {bulkCreateDineInTables} from '../../../../../pos-core/database/helpers/dine_in_table';
import { SETTING_CONFIG_OPTIONS } from '../../../../../pos-core/constants/utils';

const CreateLocation = ({location}) => {
    const {I18n} = useLanguageContext();
    const {theme} = useThemeContext();
    const {user, business, setRenderToast, account, role} = useAuthContext();
    const {state} = useLocation();

    let navigate = useNavigate();

    const [name, setName] = useState(location?.location_name || '');
    const [type, setType] = useState(location?.location_type || '');
    const [address, setAddress] = useState(location?.address || '');
    const [country, setCountry] = useState(location?.country || '');
    const [province, setProvince] = useState(location?.province || '');
    const [city, setCity] = useState(location?.city || '');
    const [zip, setZip] = useState(location?.zip_code || '');
    const [email, setEmail] = useState(location?.email || '');
    const [phone, setPhone] = useState(location?.phone || '');
    const [errors, setErrors] = useState({});
    const [loading, setLoading] = useState(false);
    const [data, setData] = useState([]);
    const [provincesList, setProvincesList] = useState([]);
    const [citiesList, setCitiesList] = useState([]);

    const handleEnterPress = event => {
        if (event?.key === 'Enter') {
            handleCreateLocation('save');
        }
    };

    const onChange = (label, val) => {
        if (label == I18n.location_name) setName(val);
        else if (label == I18n.location_type) setType(val.name);
        else if (label == I18n.address) setAddress(val);
        else if (label == I18n.country) {
            setCountry(val);
            setProvince('');
            setCity('');
            const selectedCountry = data.find(item => item.countryName === val);
            setProvincesList(selectedCountry ? selectedCountry.provinces : []);
        } else if (label == I18n.state_province) {
            setProvince(val);
            setCity('');
            const selectedProvince = provincesList.find(item => item?.provinceName === val);
            setCitiesList(selectedProvince ? selectedProvince.cities : []);
        } else if (label == I18n.city) setCity(val);
        else if (label == I18n.zip_postal) setZip(val);
        else if (label === I18n.email) setEmail(val);
        else if (label === I18n.phone) setPhone(val);

        setErrors(prevErrors => {
            const updatedErrors = {...prevErrors};
            if (updatedErrors[toSnakeCase(label)]) {
                delete updatedErrors[toSnakeCase(label)];
            }
            return updatedErrors;
        });
    };

    const reset = () => {
        setName('');
        setType('');
        setAddress('');
        setCountry('');
        setProvince('');
        setCity('');
        setEmail('');
        setZip('');
        setPhone('');
    };

    async function handleCreateLocation(val) {
        try {
            let createdBusiness = '';
            const ownerRole = role?.find(role => role.name == 'owner');
            const businessPayload = {
                id: uuid(),
                name: business?.name,
                kind: business?.kind,
                type: business?.type,
                city,
                address,
                province,
                location_name: name,
                location_type: type,
                is_active: true,
                isHq: location ? location?.is_hq : false,
                phone,
                email,
                country,
                zip,
            };

            console.log('business payload', businessPayload);

            const formErrors = validateLocationForm(businessPayload);
            setErrors(formErrors);
            if (Object.keys(formErrors).length > 0) return;

            if (location) {
                const updatedLocation = await location.updateDetails(businessPayload);
                console.log('updatedLocation', updatedLocation);
            } else {
                createdBusiness = await account.createBusiness(businessPayload);
                const userBusPayload = {
                    userRole: ownerRole,
                    user,
                };
                await createdBusiness.createUserBusinessRole(userBusPayload);
                await bulkCreateOptions(SETTING_CONFIG_OPTIONS, createdBusiness);

                BUSINESS_FLOORS.map(async item => {
                    const createdFloor = await createdBusiness.createFloorPlan(item);
                    const tableObj = [
                        {
                            id: uuid(),
                            name: 'Table 01',
                            seats: 4,
                            shape: 'Square',
                            height: 160,
                            width: 200,
                            xAxis: 20,
                            yAxis: 20,
                            xCoord: 20,
                            yCoord: 20,
                        },
                    ];
                    const createdTables = await bulkCreateDineInTables(tableObj, createdFloor);
                });
            }
            reset();
            if (state === 'item') {
                navigate(`/items/create?location_id=${createdBusiness.id}`);
            } else if (state === 'discount') {
                navigate(`/discounts/create?location_id=${createdBusiness.id}`);
            } else if (state === 'team') {
                navigate(`/team/create?location_id=${createdBusiness.id}`);
            } else {
                navigate(-1);
            }
        } catch (error) {
            console.log('error', error);
        }
    }

    const getBusiness = async () => {
        const userBusinessDetails = await user.user_business.fetch();
        for (const item of userBusinessDetails) {
            const business = await item.business.fetch();
            console.log('buisness', business);
        }
    };

    const fetchCountries = async () => {
        setLoading(true);
        try {
            const res = await getAllCountry();
            setData(res?.data);
        } catch (error) {
            console.log(error.message);
        } finally {
            setLoading(false);
        }
    };

    useEffect(() => {
        getBusiness();
        fetchCountries();
    }, []);

    return loading ? (
        <Loading />
    ) : (
        <div onKeyDown={handleEnterPress}>
            <Header
                type="draftHeader"
                title={I18n.back}
                saveCta={{
                    title: I18n.save,
                    action: () => handleCreateLocation('save'),
                }}
                primaryCta={
                    !location?.id
                        ? {
                              title: I18n.save_draft,
                              action: () => handleCreateLocation('saveDraft'),
                          }
                        : null
                }
            />

            <CustomContainer reduceHeight={110} className="addScreenMainBox createCustomerContainer">
                <Row className="justify-content-center width100">
                    <Col md={5}>
                        <Row>
                            <div>
                                <h3 className="fontSize24  textCenter fontWeight600 marBot20 marTop0" style={{color: theme.text}}>
                                    {I18n.create_location}
                                </h3>
                                <h3 className="fontSize20 fontWeight400 marBot15 marTop0" style={{color: theme.text}}>
                                    {I18n.location_details}
                                </h3>
                            </div>

                            <Col md={12}>
                                <TextField
                                    onChange={onChange}
                                    error={errors['location_name']}
                                    label={I18n.location_name}
                                    placeholder={I18n.location_name}
                                    value={name}
                                    required={true}
                                    autoFocus={true}
                                />
                                <div className="thinDivider width100 marTop10 marBot25" />
                            </Col>
                            <Col md={12}>
                                <p className="fontSize20 fontWeight400 marBot20" style={{color: theme.white}}>
                                    {I18n.business_address}
                                </p>

                                <TextField
                                    label={I18n.location_type}
                                    placeholder={I18n.location_type}
                                    data={LOCATION_TYPE}
                                    value={type}
                                    onChange={onChange}
                                    inputType="select"
                                    type="text"
                                    suffix="downIcon2"
                                    suffix_fill={theme.white}
                                    suffix_width={'24'}
                                    suffix_height={'24'}
                                    suffix_viewBox={'0 0 18 18'}
                                />
                            </Col>

                            <Col md={12}>
                                <TextField onChange={onChange} label={I18n.address} placeholder={I18n.address} value={address} />
                            </Col>
                            <Col md={6}>
                                <TextField
                                    label={I18n.country}
                                    placeholder={I18n.country}
                                    data={data?.map(item => item?.countryName)}
                                    value={country}
                                    onChange={onChange}
                                    inputType="select"
                                    type="text"
                                    suffix="downIcon2"
                                    suffix_fill={theme.white}
                                    suffix_width={'24'}
                                    suffix_height={'24'}
                                    suffix_viewBox={'0 0 18 18'}
                                />
                            </Col>
                            <Col md={6}>
                                <TextField
                                    label={I18n.state_province}
                                    placeholder={I18n.state_province}
                                    data={provincesList?.map(item => item.provinceName)}
                                    value={province}
                                    onChange={onChange}
                                    inputType="select"
                                    type="text"
                                    suffix="downIcon2"
                                    suffix_fill={theme.white}
                                    suffix_width={'24'}
                                    suffix_height={'24'}
                                    suffix_viewBox={'0 0 18 18'}
                                />
                            </Col>
                            <Col md={6}>
                                <TextField
                                    label={I18n.city}
                                    placeholder={I18n.city}
                                    data={citiesList}
                                    value={city}
                                    onChange={onChange}
                                    inputType="select"
                                    type="text"
                                    suffix="downIcon2"
                                    suffix_fill={theme.white}
                                    suffix_width={'24'}
                                    suffix_height={'24'}
                                    suffix_viewBox={'0 0 18 18'}
                                />
                            </Col>
                            <Col md={6}>
                                <TextField onChange={onChange} label={I18n.zip_postal} placeholder={I18n.zip_postal} value={zip} type={'numeric'} maxLength={11} />
                            </Col>
                            <Col md={12}>
                                <div className="thinDivider width100 marTop10 marBot25" />
                                <p className="fontSize20 fontWeight400 marBot20" style={{color: theme.white}}>
                                    Contact information
                                </p>
                            </Col>

                            <Col md={6}>
                                <TextField onChange={onChange} label={I18n.email} placeholder={I18n.email} value={email} />
                            </Col>
                            <Col md={6}>
                                <TextField onChange={onChange} label={I18n.phone} placeholder={I18n.phone} value={phone} type={'numeric'} maxLength={11} />
                            </Col>
                        </Row>
                    </Col>
                </Row>
            </CustomContainer>
        </div>
    );
};

export default CreateLocation;
