import React, {useState} from 'react';
import {Col, Container, Row} from 'react-bootstrap';
import List from './List';
import {useLanguageContext, useThemeContext} from '../../../context';
import ReportsBoxHeader from '../Header/ReportsBoxHeader';
import ReportCardBox from '../ReportCardBox';
import {CardData, DEVICE_HEIGHT, TopSell} from '../../../constants';
import NonIdealScreen from '../NonIdeal';
import TableHeader from '../TableContainer/TableHeader';
import TableFooter from '../TableFooter';
import Loading from '../Loading';
import InfiniteScroll from 'react-infinite-scroll-component';

const ItemSalesReport = ({hasMore, filter, setFilter, data, totalpages, setHasMore, setCurrentPage, setData, handleExportCSV, loading}) => {
    const {I18n} = useLanguageContext();
    const {theme} = useThemeContext();

    const handleExport = async () => {
        await handleExportCSV();
    };
    const handleFilter = value => {
        setFilter(value);
        setData({});
        setCurrentPage(1);
        setHasMore(true);
    };

    const filterOption = [
        {
            title: I18n.top_to_low,
            action: () => handleFilter(I18n.top_to_low),
            checked: filter == I18n.top_to_low || filter === '' ? true : false,
        },
        {
            title: I18n.low_to_top,
            action: () => handleFilter(I18n.low_to_top),
            checked: filter == I18n.low_to_top ? true : false,
        },
    ];

    /**
     * Fetches more data if there is more data to fetch.
     */ const fetchMoreData = () => {
        if (hasMore) {
            setCurrentPage(prevPage => {
                const nextPage = prevPage + 1;
                if (nextPage <= totalpages) {
                    setHasMore(true);
                    return nextPage;
                } else {
                    setHasMore(false);
                    return prevPage;
                }
            });
        }
    };

    return loading ? (
        <div className="reportsWrapper">
            <div className="reportsWrapperInner">
                <Loading />
            </div>
        </div>
    ) : data?.items?.length > 0 ? (
        <div className="reportsWrapper">
            <div className="reportsWrapperInner">
                <ReportsBoxHeader
                    title="item_sales"
                    primaryCta={{
                        title: 'export_csv',
                        action: handleExport,
                    }}
                    rightCta={{title: filter, name: 'downIcon2', fill: theme.white, width: '20', height: '20', viewBox: '0 0 18 18', option: filterOption}}
                />

                <TableHeader className={`eightColumn`} style={{borderColor: theme.inputBorder, marginRight: '20px'}}>
                    <div className="BoxWidth justifyStart">
                        <p className="fontSize16 OneLineTruncate" style={{color: theme.white}}>
                            {I18n.item}
                        </p>
                    </div>

                    <div className="BoxWidth justifyCenter">
                        <p className="fontSize16 OneLineTruncate" style={{color: theme.white}}>
                            {I18n.barcode}
                        </p>
                    </div>

                    <div className="BoxWidth justifyCenter">
                        <p className="fontSize16 OneLineTruncate" style={{color: theme.white}}>
                            {I18n.total_qty}
                        </p>
                    </div>
                    <div className="BoxWidth justifyCenter">
                        <p className="fontSize16 OneLineTruncate" style={{color: theme.white}}>
                            {I18n.Units_sold}
                        </p>
                    </div>
                    <div className="BoxWidth justifyCenter">
                        <p className="fontSize16 OneLineTruncate" style={{color: theme.white}}>
                            {I18n.sale_price}
                        </p>
                    </div>
                    <div className="BoxWidth justifyCenter">
                        <p className="fontSize16 OneLineTruncate" style={{color: theme.white}}>
                            {I18n.amount}
                        </p>
                    </div>
                    <div className="BoxWidth justifyCenter">
                        <p className="fontSize16 OneLineTruncate" style={{color: theme.white}}>
                            {I18n.margin}
                        </p>
                    </div>
                    <div className="BoxWidth justifyEnd">
                        <p className="fontSize16 OneLineTruncate" style={{color: theme.white}}>
                            {I18n.contribution}
                        </p>
                    </div>
                </TableHeader>

                <div id="scrollableDiv" className="" style={{height: DEVICE_HEIGHT - 490, overflow: 'auto'}}>
                    <InfiniteScroll
                        className="infiniteScrollBar"
                        dataLength={data?.items?.length}
                        next={fetchMoreData}
                        hasMore={hasMore}
                        loader={
                            <div className="reportsWrapper">
                                <div className="reportsWrapperInner">
                                    <Loading />
                                </div>
                            </div>
                        }
                        scrollableTarget="scrollableDiv">
                        {data?.items.length > 0 &&
                            data?.items?.map((item, index) => {
                                return <List data={item} key={index} />;
                            })}
                    </InfiniteScroll>
                </div>
                <TableFooter title="total" value="1392.81" />
            </div>
        </div>
    ) : (
        <div className="onlineNonIdeal reportDashboardBox">
            <NonIdealScreen
                heading={I18n.item_sales}
                subHeading={I18n.no_activity_zero_transactions_recorded}
                name="itemSummaryIcon"
                fill={theme.white}
                isMultiStep={false}
                viewBox="0 0 100 100"
            />
        </div>
    );
};

export default ItemSalesReport;
