import React from 'react'
import { useLanguageContext, useThemeContext } from '../../../context'

const TableFooter = ({title, value}) => {
    const { theme } = useThemeContext();
    const { I18n } = useLanguageContext();
    
  return (
    <div className='tableFooterWrapper'>
      <p className='marBot0 fontSize18 fontWeight400 marRight15' style={{color: theme.lightGray}}>
        {I18n[title]}:
      </p>
      <p className='marBot0 fontSize22 fontWeight500' style={{color: theme.white}}>
        Rs. {value}
      </p>
    </div>
  )
}

export default TableFooter
