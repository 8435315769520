import React from 'react';
import {useLanguageContext, useThemeContext} from '../../../context';
import DashboardDataBox from '../DashboardDataBox';
import DashboardListNonIdeal from '../NonIdeal/DashboardListNonIdeal';
import ItemList from './ItemList';
import {PandingPaymentData} from '../../../constants';

const PendingPayment = ({handleNavigate, data}) => {
    const {theme} = useThemeContext();
    const {I18n} = useLanguageContext();

    return (
        <DashboardDataBox className="pendingPaymentWrapper" disableViewButton={!data?.length > 0} title="pending_payments" ViewMoreBtn={true} handleView={handleNavigate}>
            {data?.length > 0 ? (
                <>
                    {data?.map((item, index) => {
                        return <ItemList data={item} key={index} />;
                    })}
                </>
            ) : (
                <DashboardListNonIdeal title="youve_not_done_any_transactions_yet" />
            )}
        </DashboardDataBox>
    );
};

export default PendingPayment;
