import {useLanguageContext, useThemeContext} from '../../../context';
import {formatNum} from '../../../constants';
import Icon from '../../../assets/icons';
import React, {useState} from 'react';
import TooltipComponent from '../V2/TooltipComponent';

const TotalTiles = ({withOutIcon, percent, amount, item, key}) => {
    const {iconBGcolor, iconName, viewBox, width, height, subTitle, heading, rs, showInfo, toolTipContent} = item || {};
    const {I18n} = useLanguageContext();
    const {theme} = useThemeContext();
    const [toggle, setToggle] = useState(false);
    return (
        <>
            <div className={`${withOutIcon ? 'titlecontainer' : 'totaltitlecontainer'}`} key={key}>
                <div className="tileContTop">
                    <div className="flex verticalCenter">
                        {withOutIcon ? null : (
                            <p className="iconBox marRight10 marBot0" style={{backgroundColor: iconBGcolor || theme.brightGreen}}>
                                <Icon name={iconName} viewBox={viewBox || '0 0 18 18'} height={height || '18'} width={width || '18'} fill={theme.white} />
                            </p>
                        )}
                        <p
                            className="marBot0 fontSize16 fontWeight400"
                            style={{
                                color: withOutIcon ? theme.newBorderColorTwo : theme.white,
                            }}>
                            {I18n[heading]}
                        </p>
                    </div>

                    <div>
                        {showInfo ? (
                            <div className="infoTooltip cursorPointer" onMouseEnter={() => setToggle(true)} onMouseLeave={() => setToggle(false)}>
                                <TooltipComponent
                                    titleName={I18n[heading]}
                                    desc={I18n[toolTipContent]}
                                    toggle={toggle}
                                    children={<Icon name="infocircleIcon" width={'18'} height={'18'} viewBox={'0 0 18 18'} />}
                                />
                            </div>
                        ) : null}
                    </div>
                </div>

                <div className={`tileContBottom ${subTitle ? 'Bottomsubtitle' : ''}`}>
                    <div className="tileContBottomLeft marTop10">
                        <p className="fontSize18 fontWeight500 marBot0" style={{color: theme.white}}>
                            {rs && <span className="marRight5">Rs.</span>}
                            <span>{formatNum(amount)}</span>
                        </p>
                        {subTitle ? (
                            <p className="fontSize12 fontWeight500 marBot0 marTop4" style={{color: theme.white}}>
                                <span>{subTitle}</span>
                            </p>
                        ) : null}
                    </div>

                    {percent ? (
                        <div className={`amountIdeal ${percent > 0 ? 'amountIncrease' : 'amountDecrease'}`}>
                            <Icon
                                name={percent > 0 ? 'upNewIcon' : 'downNewIcon'}
                                fill={percent > 0 ? theme.brightGreen : theme.red}
                                viewBox={'0 0 7 8'}
                                height={'8'}
                                width={'8'}
                            />

                            <p className="fontSize14 fontWeight400 marBot0 OneLineTruncate marLeft5" style={{color: percent > 0 ? theme.brightGreen : theme.red}}>
                                {`${formatNum(Math.abs(percent))} %`}
                            </p>
                        </div>
                    ) : // <div className="amountNonIdeal">
                    //     <p className="fontSize14  textCenter fontWeight400 marBot0" style={{color: theme.lightGrayTwo}}>
                    //         N/A
                    //     </p>
                    // </div>
                    null}
                </div>
            </div>
        </>
    );
};

export default TotalTiles;
