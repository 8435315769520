import React from 'react';
import {useLanguageContext, useThemeContext} from '../../../context';
import TableBody from '../TableContainer/TableBody';

const ItemsList = ({data, index}) => {
    const {theme} = useThemeContext();
    const {I18n} = useLanguageContext();

    const {average_sale, business_name, gross_sales, net_sales, orders, tax_collected} = data || {};
    return (
        <TableBody className={`sixColumn cursorPointer`} style={{borderColor: theme.inputBorder}}>
            <div key={index} className="BoxWidth justifyStart">
                <p className="fontSize14 OneLineTruncate" style={{color: theme.white}}>
                    {business_name}
                </p>
            </div>
            <div className="BoxWidth justifyCenter">
                <p className="fontSize14 OneLineTruncate" style={{color: theme.white}}>
                    {net_sales}
                </p>
            </div>
            <div className="BoxWidth justifyCenter">
                <p className="fontSize14 OneLineTruncate" style={{color: theme.white}}>
                    {orders}
                </p>
            </div>
            <div className="BoxWidth justifyCenter">
                <p className="fontSize14 OneLineTruncate" style={{color: theme.white}}>
                    {tax_collected}
                </p>
            </div>
            <div className="BoxWidth justifyCenter">
                <p className="fontSize14 OneLineTruncate" style={{color: theme.white}}>
                    {gross_sales}
                </p>
            </div>
            <div className="BoxWidth justifyEnd">
                <p className="fontSize14 OneLineTruncate" style={{color: theme.white}}>
                    {average_sale}
                </p>
            </div>
        </TableBody>
    );
};

export default ItemsList;
