import * as React from 'react';
import Box from '@mui/material/Box';
import Stepper from '@mui/material/Stepper';
import Step from '@mui/material/Step';
import StepLabel from '@mui/material/StepLabel';
import Icon from '../../../assets/icons';
import {useLanguageContext, useThemeContext} from '../../../context';

const StepperComponent = ({detais}) => {
    const {I18n} = useLanguageContext();
    const {theme} = useThemeContext();
    return (
        <Box sx={{width: '100%'}}>
            <Stepper className="stepperConatiner" orientation="vertical">
                {detais?.length > 0 &&
                    detais.map(item => (
                        <Step key={item}>
                            <StepLabel
                                icon={
                                    <span className="stepIcon">
                                        <Icon
                                            name={item?.iconName || 'tickCircleIcon'}
                                            fill={item?.fill || theme.brightGreen}
                                            width={item?.height || '18'}
                                            height={item?.width || '18'}
                                            viewBox={item?.viewBox || '0 0 18 18'}
                                        />
                                    </span>
                                }>
                                {
                                    <div className="label rowConatainer">
                                        <p className="fontSize12 fontWeight400 marBot0" style={{color: theme.lightGrayTwo}}>
                                            {item?.stepLebal || ''}
                                        </p>
                                        <p className="fontSize14 fontWeight400 marBot0" style={{color: theme.white}}>
                                            {item?.details || ''}
                                        </p>
                                    </div>
                                }
                            </StepLabel>
                        </Step>
                    ))}
            </Stepper>
        </Box>
    );
};

export default StepperComponent;
