import {useThemeContext} from '../../../context';
import CheckBox from '../CheckBox';

const List = ({beneficiary, selectedBeneficiaries, setSelectedBeneficiaries, from, setErrors}) => {
    const {theme} = useThemeContext();
    let beneficiaryId = beneficiary.beneficiary_id;

    const handleCheck = () => {
        if (from === 'transfer_money') {
            const isExist = selectedBeneficiaries?.[beneficiaryId];
            const status = isExist ? 'delete' : 'add';
            setSelectedBeneficiaries(beneficiary, status);
        } else {
            setSelectedBeneficiaries(prev => {
                let obj = {...prev};
                let item = obj[beneficiaryId];
                if (item) {
                    delete obj[beneficiaryId];
                    delete obj['all'];
                } else {
                    obj[beneficiaryId] = {...beneficiary};
                }

                return obj;
            });
        }
        setErrors({});
    };

    return (
        <div className="beneficiaryModal locationModalList">
            <CheckBox
                className="locationListCheck"
                onChange={handleCheck}
                checked={selectedBeneficiaries?.[beneficiaryId]}
                checkboxLabel={
                    <span>
                        <p className="fontSize16 fontWeight500 marBot0 gap02" style={{color: theme.white}}>
                            {beneficiary.name}
                            <span className="marLeft10 fontSize12  marBot0" style={{color: theme.darkGrayTwo}}>
                                <img
                                    src={beneficiary.logo_link}
                                    title={`${beneficiary.bank_name} logo`}
                                    alt={`${beneficiary.bank_name} logo`}
                                    style={{
                                        width: '20px',
                                        height: '20px',
                                        objectFit: 'contain',
                                        marginRight: '5px',
                                        marginBottom: '5px',
                                    }}
                                />
                                {beneficiary.bank_name}
                            </span>
                        </p>
                        <p className="fontSize12  marBot0" style={{color: theme.white}}>
                            {beneficiary.account_number}
                        </p>
                    </span>
                }
            />
        </div>
    );
};

export default List;
