import React, {useEffect, useState} from 'react';
import {Col, Row} from 'react-bootstrap';
import {useAuthContext, useLanguageContext, useThemeContext} from '../../../context';
import {TextField, Header, Loading, ToastBox} from '../../common';
import {OSCAR_BENEFICIARY_VERIFICATION, toSnakeCase, validateCreateBeneficiaryForm} from '../../../constants';
import {useNavigate} from 'react-router-dom';
import {getAccountTitle, getBanks} from '../../../api';

const CreateBeneficiaryComponent = ({current, setCurrent}) => {
    const {I18n} = useLanguageContext();
    const {theme} = useThemeContext();
    const {business, setRenderToast} = useAuthContext();
    let navigate = useNavigate();

    const [name, setName] = useState(current?.data?.name || '');
    const [phone, setPhone] = useState(current?.data?.phone || '');
    const [email, setEmail] = useState(current?.data?.email || '');
    const [bank, setBank] = useState(current?.data?.bank || '');
    const [accountNumber, setAccountNumber] = useState(current?.data?.accountNumber || '');
    const [errors, setErrors] = useState({});
    const [loading, setLoading] = useState(false);
    const [btnLoading, setBtnLoading] = useState(false);
    const [banks, setBanks] = useState([]);
    const [showMessage, setShowMessage] = useState({
        visible: false,
        message: '',
    });

    const onChange = (label, val) => {
        if (label == I18n.enter_name) setName(val);
        else if (label == I18n.enter_phone_number) setPhone(val);
        else if (label == I18n.enter_email_address) setEmail(val);
        else if (label === I18n.select_bank) {
            setBank(val);
        } else if (label == I18n.enter_account_number) setAccountNumber(val);

        setErrors(prevErrors => {
            const updatedErrors = {...prevErrors};
            if (updatedErrors[toSnakeCase(label)]) {
                delete updatedErrors[toSnakeCase(label)];
            }
            return updatedErrors;
        });
    };

    const handleCreateBeneficiary = async () => {
        setBtnLoading(true);
        try {
            const bankDetails = banks.find(x => x.display_name === bank);
            const beneficiaryPayload = {
                name,
                phone,
                email,
                bank,
                accountNumber,
                bankDetails,
            };

            const formErrors = validateCreateBeneficiaryForm(beneficiaryPayload);
            setErrors(formErrors);

            console.log('formErrors', formErrors);
            if (Object.keys(formErrors).length > 0) return;
            const accountPayload = {
                business_id: business.id,
                account: accountNumber,
                bank: bankDetails?.name,
            };
            const accountDetails = await getAccountTitle(accountPayload);

            if (accountDetails.success) {
                setCurrent({
                    step: 2,
                    data: {
                        accountTitle: accountDetails.name,
                        accountNumber,
                        bank,
                        phone,
                        name,
                        email,
                        bank_name: bankDetails.name,
                    },
                });
            }
        } catch (error) {
            console.log('error', error);
            setShowMessage({
                visible: true,
                message: error?.response?.data?.message,
            });
        } finally {
            setBtnLoading(false);
        }
    };

    const handleBack = () => {
        navigate(-1);
    };

    const handleCancel = () => {
        navigate(-1);
    };

    const getBanksList = async () => {
        setLoading(true);
        try {
            const payload = {
                business_id: business.id,
                action_type: OSCAR_BENEFICIARY_VERIFICATION,
            };
            const response = await getBanks(payload);
            setBanks(response?.banks);
        } catch (error) {
            console.log('error', error);
        } finally {
            setLoading(false);
        }
    };

    useEffect(() => {
        getBanksList();
    }, []);

    useEffect(() => {
        if (showMessage.visible) {
            const timeoutId = setTimeout(() => {
                setShowMessage({
                    visible: false,
                    message: '',
                });
                setRenderToast('');
            }, 3000);
            return () => clearTimeout(timeoutId);
        }
    }, [showMessage]);

    return loading ? (
        <Loading />
    ) : (
        <>
            <div>
                <Header
                    type="draftHeader"
                    title={I18n.back}
                    backAction={handleBack}
                    isDisabled={btnLoading}
                    saveCta={{
                        title: I18n.continue,
                        action: () => handleCreateBeneficiary(),
                        saveLoad: btnLoading,
                    }}
                    primaryCta={{
                        title: I18n.cancel,
                        action: () => handleCancel(),
                    }}
                />

                <Row className="justify-content-md-center">
                    <Col md={6}>
                        <div className="signupTextBox">
                            <h3 className="fontSize24  textCenter fontWeight600 marBot20 marTop0" style={{color: theme.text}}>
                                {I18n.create_beneficiary}
                            </h3>
                            <h3 className="fontSize20 fontWeight400 marBot15 marLeft15 marTop0" style={{color: theme.text}}>
                                {I18n.add_beneficiary_details}
                            </h3>
                        </div>

                        <div className="itemFields">
                            <div className="intventoryFormGridTwo">
                                <div className="priceSectionInputLeft">
                                    <TextField
                                        onChange={onChange}
                                        error={errors.enter_name}
                                        label={I18n.enter_name}
                                        placeholder={I18n.enter_name}
                                        value={name}
                                        required={true}
                                        autoFocus={true}
                                    />
                                </div>
                                <div className="priceSectionInputRight">
                                    <TextField
                                        onChange={onChange}
                                        error={errors.enter_phone_number}
                                        label={I18n.enter_phone_number}
                                        placeholder={I18n.enter_phone_number}
                                        value={phone}
                                        type={'numeric'}
                                        maxLength={11}
                                    />
                                </div>
                            </div>

                            <div className="intventoryFormGridTwo">
                                <div className="priceSectionInputLeft">
                                    <TextField onChange={onChange} label={I18n.enter_email_address} placeholder={I18n.enter_email_address} value={email} />
                                </div>
                                <div className="priceSectionInputRight">
                                    <TextField
                                        label={I18n.select_bank}
                                        placeholder={I18n.select_bank}
                                        error={errors.select_bank}
                                        data={banks?.map(x => x.display_name)}
                                        value={bank}
                                        onChange={onChange}
                                        inputType="select"
                                        type="text"
                                        suffix="downIcon2"
                                        suffix_fill={theme.white}
                                        suffix_width={'24'}
                                        suffix_height={'24'}
                                        suffix_viewBox={'0 0 18 18'}
                                    />
                                </div>
                            </div>

                            <div>
                                <TextField
                                    onChange={onChange}
                                    error={errors.enter_account_number}
                                    label={I18n.enter_account_number}
                                    placeholder={I18n.enter_account_number}
                                    maxLength={30}
                                    required={true}
                                    value={accountNumber}
                                    type={'numeric'}
                                />
                            </div>
                        </div>
                    </Col>
                </Row>
            </div>
            <ToastBox
                className={`addSuccessfullyToast ${showMessage?.visible ? 'errorToastMessage' : ''} `}
                ToastVisiable={showMessage.visible}
                bodyPara={showMessage.message}
                setShowMessage={setShowMessage}
                showIcon={true}
                errorToast={true}
            />
        </>
    );
};

export default CreateBeneficiaryComponent;
