import {Col, Container, Row} from 'react-bootstrap';
import AppModal from '../AppModal';
import {Button, SearchBox} from '../../common';
import Icon from '../../../assets/icons';
import {useAuthContext, useLanguageContext, useThemeContext} from '../../../context';
import {formatNum} from '../../../constants';
import {useNavigate} from 'react-router-dom';
import {useEffect, useRef, useState} from 'react';
import useShortcut from '../../../hooks/useShortcut';
import OrderCompletionReceipt from '../OrderCompletionReceipt/index';
import {useReactToPrint} from 'react-to-print';
import useFocus from '../../../hooks/useFocus';
import {Sync} from '../../../api';

const TransactionSuccessfulModal = ({toggle, setToggle, order, orderLines, givenPosDiscount, tax, isRestaurantPos, activeOrders, pendingPayment, completed_payments, customer}) => {
    let navigate = useNavigate();

    const {theme} = useThemeContext();
    const {handleCreateOrder, business, user, setPersistData, setRenderToast} = useAuthContext();
    const {I18n} = useLanguageContext();
    const {textBoxRef, handleFocus} = useFocus(true);
    const componentRef = useRef();

    const handlePrint = useReactToPrint({
        content: () => componentRef.current,
    });

    const [phone, setPhone] = useState('');
    const [orderReceiptData, setOrderReceiptData] = useState({});

    useEffect(() => {
        setTimeout(() => {
            handleFocus();
        }, 1000);
    }, [toggle]);

    const handleNewOrder = async () => {
        setPersistData(null);

        if (!isRestaurantPos) {
            if (activeOrders.length === 1) {
                await handleCreateOrder();
            }
            await order.updateOrderStatus('complete');
        }
        Sync(user?.email);

        if (isRestaurantPos) {
            if (toggle) {
                if (pendingPayment) {
                    handleClose();
                } else {
                    setRenderToast(I18n.order_completed_successfully);
                    await order.updateOrderStatus('complete');
                    navigate('/pos-cockpit');
                }
            }
        } else {
            if (toggle) {
                navigate('/pos');
            }
        }
    };

    useShortcut({
        keys: [{key: 'shift+n', action: handleNewOrder}],
    });

    const onChange = (label, val) => setPhone(val);

    const handleClose = event => {
        setToggle(false);
    };

    const prepareReceiptData = async () => {
        const account = await business.account.fetch();
        const customerData = await order?.customer.fetch();

        const orderReceipt = {
            account,
            business,
            order,
            orderLines,
            customerData,
            user,
            showAmountSection: true,
        };
        setOrderReceiptData(orderReceipt);
    };

    useEffect(() => {
        prepareReceiptData();
    }, [order, givenPosDiscount, tax, customer]);

    return (
        <AppModal className="transactionSuccessfulModal" toggle={toggle} handleClose={handleClose} title={I18n.pos_discounts}>
            <div style={{display: 'none'}}>
                <OrderCompletionReceipt ref={componentRef} orderReceiptData={orderReceiptData} />
            </div>
            <section className="transactionScreenMain" style={{background: theme.blackBg}}>
                <Container>
                    <Row className="justify-content-md-center">
                        <Col md={6}>
                            <div className="transactionScreenInner">
                                <div className="headerIcon">
                                    <Icon name="oscarlogo2" fill={'white'} width={'53'} height={'50'} viewBox={'0 0 53 50'} />
                                </div>

                                <div className="transactionMainConatiner">
                                    <div className="card">
                                        <p className="fontSize32 fontWeight500 textCenter marBot0 rowConatainer" style={{color: theme.blackFont}}>
                                            <span className="marRight10">{I18n.transaction_successful}</span>
                                            <Icon name="tickFilledNewIcon" fill={theme.white} width="30" height="30" viewBox={'0 0 20 20'} />
                                        </p>
                                        <div classsName="amountbox" style={{}}>
                                            <p className="fontSize24 marBot0 fontWeigh400 textCenter" style={{color: theme.darkGrayTwo}}>
                                                <span>{I18n.amount_paid_via} </span>
                                                <span>{toggle?.method}: </span>
                                                <span>Rs. </span>
                                                <span>{formatNum(toggle?.total || 0)}</span>
                                            </p>

                                            <p className="fontSize48 marBot0 textCenter fontWeight700" style={{color: theme.blackFont}}>
                                                {I18n.change} Rs. {formatNum(toggle?.change || 0)}
                                            </p>
                                        </div>

                                        <div className="receiptBox">
                                            <SearchBox
                                                type="pos"
                                                placeholder={I18n.for_receipt}
                                                inputRef={textBoxRef}
                                                onlyNumbers={true}
                                                value={phone}
                                                onChange={onChange}
                                                maxLength={11}
                                            />
                                            <Button
                                                title={I18n.send}
                                                className={`width40 fontWeight600 fontSize18`}
                                                backgroundColor={theme.barclaysBlue}
                                                txtColor={theme.white}
                                                handleClick={handleNewOrder}
                                            />
                                        </div>

                                        <p className="fontSize16  fontWeight500 marBot0 cursorPointer" style={{color: theme.blackFont}} onClick={handlePrint}>
                                            <span>
                                                <Icon name="printerNewIcon" fill={'black'} width={'24'} height={'24'} viewBox={'0 0 18 18'} />
                                            </span>
                                            <span> {I18n.print_receipt}</span>
                                        </p>
                                    </div>

                                    <div className="transactionBottomBox">
                                        <Button
                                            title={pendingPayment ? `Continue to payment ${completed_payments?.length + 1}` : I18n.new_order}
                                            className={`fontWeight500 fontSize24`}
                                            backgroundColor={theme.brightGreen}
                                            txtColor={theme.white}
                                            handleClick={handleNewOrder}
                                        />
                                    </div>
                                </div>
                            </div>
                        </Col>
                    </Row>
                </Container>
            </section>
        </AppModal>
    );
};

export default TransactionSuccessfulModal;
