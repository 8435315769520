import {Container, Row, Col} from 'react-bootstrap';
import {useLanguageContext, useThemeContext} from '../../../../context';
import withObservables from '@nozbe/with-observables';
import {DEVICE_HEIGHT} from '../../../../constants';
import {useSearch} from '../../../../hooks';
import PosAppModal from '../PosAppModal';
import SearchBox from '../../SearchBox';
import ListItem from './ListItem';
import NonIdealScreen from '../../NonIdeal';
import usePermission from '../../../../hooks/usePermission';
import {observeActiveRiders} from '../../../../pos-core/database/helpers/rider';

const SelectedRiderModal = ({toggle, setToggle, riders, handleSelect, handleCreateRider, order}) => {
    const {I18n} = useLanguageContext();
    const {theme} = useThemeContext();
    const userPermission = usePermission('riders');

    const {text, setText, searchList} = useSearch(handleSearch);

    function handleClose(event) {
        setToggle(false);
    }

    const handleCreate = () => {
        handleCreateRider();
    };

    const onChange = (event, key) => {
        let value = key || event?.target?.value || '';
        setText(value);
    };

    function handleSearch(val) {
        return riders.filter(x => x.name.toLowerCase().includes(val.toLowerCase()) || x.phone_number.toLowerCase().includes(val.toLowerCase()));
    }

    return (
        <PosAppModal
            className="selectCustomerModal"
            toggle={toggle}
            backCta={{
                name: 'backArrowIcon',
                fill: theme.barclaysBlue,
                width: '18',
                height: '18',
                viewBox: '0 0 18 18',
            }}
            handleClose={handleClose}
            rightCta={
                userPermission?.canCrud &&
                riders?.length && {
                    title: I18n.create_rider,
                    action: handleCreateRider,
                    iconName: 'add2Icon',
                    fill: theme.barclaysBlue,
                    width: '18',
                    height: '18',
                    viewBox: '0 0 18 18',
                }
            }
            title={'Add Rider'}>
            <>
                {!riders.length == 0 && (
                    <SearchBox
                        type="pos"
                        placeholder={I18n.search_customer_name_phone_or_email}
                        prefix={{
                            name: 'search2Icon',
                            fill: theme.white,
                        }}
                        onChange={onChange}
                    />
                )}

                <Container fluid>
                    <Row className="justify-content-md-center">
                        <Col md={12}>
                            {riders.length ? (
                                <div
                                    className="selectCustomerListBox tableListMainBox"
                                    style={{
                                        height: DEVICE_HEIGHT - 250,
                                    }}>
                                    {(text ? searchList : riders).map((val, index) => (
                                        <ListItem order={order} key={index} rider={val} onPress={handleSelect} />
                                    ))}
                                </div>
                            ) : (
                                <NonIdealScreen
                                    heading={I18n.waiter}
                                    subHeading={I18n.create_new_waiter_and_add_to_sale}
                                    name="customerSquareIcon"
                                    fill={theme.white}
                                    primaryCta={
                                        userPermission?.canCrud
                                            ? {
                                                  title: I18n.create_rider,
                                                  action: handleCreate,
                                              }
                                            : null
                                    }
                                    isMultiStep={false}
                                />
                            )}
                        </Col>
                    </Row>
                </Container>
            </>
        </PosAppModal>
    );
};

const enhance = withObservables([], () => ({
    riders: observeActiveRiders(),
}));

export default enhance(SelectedRiderModal);
