import React from 'react';
import {useLanguageContext, useThemeContext} from '../../../context';
import Button from '../Button';

const DashboardDataBox = ({title, children, handleView, ViewMoreBtn, className, disableViewButton}) => {
    const {I18n} = useLanguageContext();
    const {theme} = useThemeContext();
    return (
        <div className="salesByPaymentBox">
            <div className="salesByPaymentBoxHeadinBox">
                <h2 className="fontSize24 fontWeight600 white">{I18n[title]}</h2>
                {ViewMoreBtn && (
                    <Button
                        className={`headingViewBtn fontSize14 fontWeight500`}
                        type="secondary"
                        title={I18n.view}
                        backgroundColor={theme.blackPearl}
                        borderColor={theme.inputBorder}
                        txtColor={theme.white}
                        back
                        disabled={disableViewButton}
                        buttonIcon={true}
                        icon_name="viewBtnIcon"
                        icon_fill={disableViewButton ? theme.darkGrayTwo : theme.white}
                        icon_width={'13'}
                        icon_height={'13'}
                        icon_viewBox={'0 0 13 13'}
                        handleClick={handleView}
                    />
                )}
            </div>
            <div className={`${className} salesByPaymentBoxInner`}>{children}</div>
        </div>
    );
};

export default DashboardDataBox;
