import {Col, Container, Row} from 'react-bootstrap';
import {CheckBox, Header, Loading, MessageModal, NonIdealScreen, ToastBox} from '../../../../common';
import {useAuthContext, useLanguageContext, useThemeContext} from '../../../../../context';
import {useLocation, useNavigate} from 'react-router-dom';
import TableHeader from '../../../../common/TableContainer/TableHeader';
import {DEVICE_HEIGHT, exportDataToCSV, toSnakeCase} from '../../../../../constants';
import Icon from '../../../../../assets/icons';
import withObservables from '@nozbe/with-observables';
import List from './List';
import {useSearch} from '../../../../../hooks';
import {useEffect, useState} from 'react';
import CsvUploadModal from '../../../../common/CsvUploadModal';
import {observeActiveFloorPlan, observeArchiveFloorPlan} from '../../../../../pos-core/database/helpers/floor_plan';
import usePermission from '../../../../../hooks/usePermission';

const FloorPlans = ({floorPlans, archiveFloorPlans}) => {
    const {state} = useLocation();
    const navigate = useNavigate();

    const {I18n} = useLanguageContext();
    const {theme} = useThemeContext();
    const {text, setText, searchList} = useSearch(handleSearch);
    const [filter, setFilter] = useState(state?.isArchived ? I18n.archived : I18n.all_floors);
    const [filterList, setFilterList] = useState(floorPlans);
    const [showMessage, setShowMessage] = useState({
        visible: false,
        message: '',
    });
    const userPermission = usePermission('floor-plans');

    const handleFilter = val => {
        let temp = [];
        if (val === 'All floors') {
            temp = floorPlans;
        } else {
            temp = floorPlans.filter(floor => floor.type === val.toLowerCase().replace(/\s+/g, ''));
        }
        setFilter(val);
        setFilterList(temp);
    };

    const onChange = (event, key) => {
        let value = key || event?.target?.value || '';
        setText(value);
    };

    const filterOption = [
        {
            title: I18n.all_floors,
            action: () => handleFilter(I18n.all_floors),
            checked: filter == I18n.all_floors || filter === '' ? true : false,
        },
        {
            title: I18n.dine_in,
            action: () => handleFilter(I18n.dine_in),
            checked: filter == I18n.dine_in ? true : false,
        },
        {
            title: I18n.take_away,
            action: () => handleFilter(I18n.take_away),
            checked: filter == I18n.take_away ? true : false,
        },
        {
            title: I18n.delivery,
            action: () => handleFilter(I18n.delivery),
            checked: filter == I18n.delivery ? true : false,
        },
        {
            title: I18n.archived,
            action: () => handleFilter(I18n.archived),
            checked: filter == I18n.archived ? true : false,
        },
    ];

    function handleSearch(val) {
        return floorPlans.filter(x => x?.name?.toLowerCase().includes(val));
    }

    const handleCreateFloor = () => {
        navigate('/create-floor-plan');
    };

    const renderList = text ? searchList : filter === I18n.archived ? archiveFloorPlans : filterList;

    useEffect(() => {
        handleFilter(filter);
    }, [floorPlans]);

    return floorPlans?.length > 0 || archiveFloorPlans?.length > 0 ? (
        <>
            <div className="pad20" style={{backgroundColor: theme.topBarBG}}>
                <Header
                    type="search"
                    title={`${I18n.floor_plans} (${floorPlans.length})`}
                    search={{
                        type: 'pos',
                        placeholder: I18n.search_by_name,
                        prefix: {
                            name: 'search2Icon',
                            fill: theme.white,
                        },
                        onChange: onChange,
                    }}
                    className={'borderRadiusTopleftTopRigt'}
                    leftCta={{
                        title: filter,
                        name: 'downIcon2',
                        fill: theme.white,
                        width: '20',
                        height: '20',
                        viewBox: '0 0 18 18',
                        option: filterOption,
                    }}
                    rightCta={
                        userPermission?.canCrud
                            ? {
                                  title: I18n.create_floor_plan,
                                  action: handleCreateFloor,
                              }
                            : null
                    }
                />

                <div className="itemListContainer borderRadiusBottomleftBottomRigt" fluid style={{background: theme.blackBg, height: DEVICE_HEIGHT - 160}}>
                    <Container fluid>
                        <Row>
                            <Col>
                                <TableHeader className={`threeColumn`} style={{borderColor: theme.inputBorder}}>
                                    <div className="BoxWidth justifyStart">
                                        <p className="fontSize16 OneLineTruncate fontWeight500" style={{color: theme.white}}>
                                            {I18n.name}
                                        </p>
                                    </div>
                                    <div className="BoxWidth justifyCenter">
                                        <p className="fontSize16 OneLineTruncate fontWeight500" style={{color: theme.white}}>
                                            {I18n.type}
                                        </p>
                                    </div>

                                    <div className="BoxWidth justifyCenter">
                                        <p className="fontSize16 OneLineTruncate fontWeight500" style={{color: theme.white}}>
                                            {I18n.actions}
                                        </p>
                                    </div>
                                </TableHeader>
                                <div className="tableListMainBox" style={{height: DEVICE_HEIGHT - 280}}>
                                    {renderList?.length === 0 ? (
                                        <NonIdealScreen
                                            heading={text ? I18n.search : filter == I18n.archived ? I18n.archive_floor_plan : filter}
                                            subHeading={
                                                text
                                                    ? I18n.we_couldnt_find_any_results_for_your_search
                                                    : filter == I18n.archived
                                                    ? I18n.no_archive_floor_plans_found
                                                    : `No active ${filter} floor plans found`
                                            }
                                            name={text ? 'seacrhNotFoundIcon' : filter == I18n.archived ? 'archiveIcon' : 'draftIcon'}
                                            fill={theme.white}
                                            isMultiStep={false}
                                            tablenonIdeal={true}
                                        />
                                    ) : (
                                        renderList?.map((val, index) => <List floorPlan={val} userPermission={userPermission} />)
                                    )}
                                </div>
                            </Col>
                        </Row>
                    </Container>
                </div>
            </div>
        </>
    ) : (
        <>
            <NonIdealScreen
                heading={I18n.floor_plans}
                subHeading={I18n.no_active_floor_plans}
                name="customerSquareIcon"
                fill={theme.white}
                secondaryCta={
                    userPermission?.canCrud
                        ? {
                              title: I18n.create_floor_plan,
                              action: handleCreateFloor,
                          }
                        : null
                }
                isMultiStep={false}
            />
        </>
    );
};

const enhance = withObservables([], () => ({
    floorPlans: observeActiveFloorPlan(),
    archiveFloorPlans: observeArchiveFloorPlan(),
}));

export default enhance(FloorPlans);
