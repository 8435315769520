import React from 'react'

import { useLanguageContext, useThemeContext } from '../../../context';
import { onlinePresenceImage } from '../../../assets/images/image';
import DashboardDataBox from '../DashboardDataBox';
import Button from '../Button';


const ExpandYourOnline = () => {
    const {I18n} = useLanguageContext();
    const {theme} = useThemeContext();

    const handleRoute = () =>{
        // handleRoute
    }
  return (
    <DashboardDataBox title='expand_your_online_presence_with_oscar'>
        <div className='onlinePresenceInner'>
            <h1 className='fontSize20 fontWeight600 white'>
            {I18n.integrate_your_existing_online}
            </h1>
        <p className='fontSize14 fontWeight400 white'>
          {I18n.integrate_your_existing_online_descp}
        </p>
        <Button
            type="secondary"
            className={`fontSize18 cursorPointer width50 marTop20`}
            backgroundColor={theme.barclaysBlue}
            txtColor={theme.white}
            title={I18n.explore_payment_plans}
            handleClick={handleRoute}
        />
        <img src={onlinePresenceImage} alt="" />
        </div>
    </DashboardDataBox>
  )
}

export default ExpandYourOnline
