import React from 'react';
import {useLanguageContext, useThemeContext} from '../../../context';
import {firstLetterCaptilize, formatDateAndTime, formatNum} from '../../../constants';

const ItemList = ({data, key}) => {
    const {I18n} = useLanguageContext();
    const {theme} = useThemeContext();
    const {amount, date, status} = data;
    return (
        <div key={key} className="pendigPaymentListConatiner">
            <div className="listLeftBox">
                <p className="fontSize18 marBot0 fontWeight600 OneLineTruncate" style={{color: theme.white}}>
                    {`Rs. ${formatNum(amount || 0)}`}
                </p>
                <p className="fontSize16 marBot0  OneLineTruncate" style={{color: theme.white}}>
                    {formatDateAndTime(date)}
                </p>
            </div>
            <div className="listRightBox">
                <div className="listlebal">
                    <p className="fontSize12 marBot0  fontWeight500 OneLineTruncate" style={{color: theme.mustardYellow}}>
                        {firstLetterCaptilize(status)}
                    </p>
                </div>
            </div>
        </div>
    );
};

export default ItemList;
