import React, {useEffect, useState} from 'react';
import NewAppModal from '../NewAppModal';
import {useLanguageContext, useThemeContext} from '../../../context';
import TextField from '../TextField';

const ItemNotesModal = ({order, visible, setVisible, saveNotes}) => {
    const {theme} = useThemeContext();
    const {I18n} = useLanguageContext();

    const [notes, setNotes] = useState(null);

    const onChange = async (label, val) => {
        setNotes(val);
    };

    const clearNote = () => setNotes('');

    const handleSaveNotes = () => {
        saveNotes(notes);
        setVisible(null);
    };

    useEffect(() => {
        setNotes(order?.note);
    }, [visible]);
    return (
        <div>
            <NewAppModal
                className="deliveryFloorModal modal-backdrop-custom"
                toggle={visible}
                backCta={{
                    name: 'backArrowIcon',
                    fill: theme.barclaysBlue,
                    width: '18',
                    height: '18',
                    viewBox: '0 0 18 18',
                }}
                handleClose={() => setVisible(null)}
                primaryCta={{
                    title: 'Save',
                    action: () => handleSaveNotes(),
                }}
                cancelCta={{
                    title: I18n.cancel,
                    action: () => setVisible(null),
                }}
                title={I18n.back}>
                <div className="flex horizontalCenter width100">
                    <div className="flex width50" style={{flexDirection: 'column'}}>
                        <p className="fontSize20 marBot20" style={{color: theme.white}}>
                            {I18n.add_note}
                        </p>
                        <div className="noteModalFeild">
                            <TextField hideInputError={true} label={I18n.write_note} onChange={onChange} inputType="textarea" rows="5" value={notes} />
                        </div>
                        <div className="flex justifySpacebetween ">
                            <p className="fontSize12" style={{color: theme.darkGrayTwo}}>
                                {I18n.note_placeholder}
                            </p>
                            <p className="fontSize12 marBot10 cursorPointer textUnderline" style={{color: theme.barclaysBlue}} onClick={() => clearNote()}>
                                {I18n.clear_note}
                            </p>
                        </div>
                    </div>
                </div>
            </NewAppModal>
        </div>
    );
};

export default ItemNotesModal;
