import {Outlet, useLocation} from 'react-router-dom';
import {RequireKDSAuth} from '../../../services';
import Navbar from '../Navbar';
import SecondaryHamburgerMenu from '../SecondaryHamburgerMenu';

const SecondaryMenu = () => {
    const {pathname} = useLocation();

    const isMenuHidden = pathname == '/business-details' ? true : false;

    return (
        <RequireKDSAuth>
            {isMenuHidden ? null : (
                <>
                    <Navbar from="secondary" />
                    <SecondaryHamburgerMenu />
                </>
            )}

            <Outlet />
        </RequireKDSAuth>
    );
};

export default SecondaryMenu;
