import Icon from '../../../assets/icons';
import {useLanguageContext, useThemeContext} from '../../../context';
import {useVariationList} from '../../../hooks';
import CreateVarientComponent from './CreateVarientComponent';

const VariationsListComponent = ({variants, toggleVariantModal, errors, handleDeleteVariant}) => {
    const {I18n} = useLanguageContext();
    const {theme} = useThemeContext();
    const {variations, setVariations} = useVariationList(variants);

    const onChangeDetails = (title, label, value) => {
        setVariations(data => {
            let obj = {...data};
            obj[title][label] = value;
            return obj;
        });
    };

    return (
        <>
            {!Object.keys(variations)?.length && (
                <div onClick={toggleVariantModal} className="variationsListButton cursorPointer">
                    <p className="fontSize14 fontWeight400 marBot0" style={{color: theme.barclaysBlue}}>
                        {I18n.add_variations}
                    </p>
                    <Icon name={'add2Icon'} width="24" height="24" viewBox={'0 0 18 18'} fill={theme.barclaysBlue} />
                </div>
            )}
            {Object?.keys(variations)?.map((value, index) => (
                <CreateVarientComponent title={value} data={variations[value]} onChange={onChangeDetails} errors={errors} index={index} handleDeleteVariant={handleDeleteVariant} />
            ))}
        </>
    );
};

export default VariationsListComponent;
