import {Col, Row} from 'react-bootstrap';
import {ImageUploader, TextField, CreateOptionModal, VariationListModal, TaxesModal, Header, CustomContainer, CategoryModal, MessageModal} from '../../common';
import {useAuthContext, useLanguageContext, useThemeContext} from '../../../context';
import {useEffect, useRef, useState} from 'react';
import {
    toSnakeCase,
    validateCreateItemForm,
    validateDraftItem,
    validateCreateItemVariantForm,
    getAccountBusiness,
    getHashMap,
    getLocationNames,
    filterOptions,
    convertArrayToObj,
} from '../../../constants';
import {useLocation, useNavigate, useSearchParams} from 'react-router-dom';
import VariantsOptionModal from '../../common/AppModal/VariantOptionModal';
import withObservables from '@nozbe/with-observables';
import {Sync} from '../../../api';
import VariationsListComponent from './VariationsListComponent';
import SingleItemCreationFeild from './SingleItemCreationFeild';
import ImageUploadModal from '../ImageUploadModal';
import LocationModal from '../LocationModal';
import {bulkCreateProductBusiness, bulkDeleteProductBusiness} from '../../../pos-core/database/helpers/product_business';
import {bulkCreateProducts, observeActiveCategories, observeUnArchivedSalesTax} from '../../../pos-core/database/helpers';
import {ItemDiscountsModal} from '../V2';

const CreateItemComponent = ({
    sales_tax,
    categories,
    handleDone,
    product,
    product_template,
    selected_category,
    selected_tax,
    product_modifier,
    prodBusiness,
    product_businessess,
}) => {
    let navigate = useNavigate();
    const {state} = useLocation();

    const {user, business, setRenderToast, userBusiness, account, persistData, setPersistData} = useAuthContext();
    const {I18n} = useLanguageContext();
    const {theme} = useThemeContext();
    const [searchParams] = useSearchParams();

    const tax_id = searchParams?.get('tax_id');
    const category_id = searchParams?.get('category_id');
    const location_id = searchParams?.get('location_id');

    const {
        name_data,
        category_data,
        description_data,
        image_data,
        tax_data,
        unit_data,
        secondaryUnit_data,
        rate_data,
        sellingPrice_data,
        costPrice_data,
        profit_data,
        margin_data,
        quantity_data,
        lowStock_data,
        barcode_data,
        selectedLocations_data,
        itemLevelDiscount_data,
        variants_data,
        createdOption_data,
        disableVariants_data,
    } = persistData?.items || {};

    const isCreatedItem = !Boolean(product);

    const [name, setName] = useState(isCreatedItem ? name_data : product?.name || '');
    const [category, setCategory] = useState(isCreatedItem ? category_data : selected_category || '');
    const [description, setDescription] = useState(isCreatedItem ? description_data : product_template?.description || '');
    const [image, setImage] = useState(isCreatedItem ? image_data : product_template?.image || '');
    const [tax, setTax] = useState(isCreatedItem ? tax_data : selected_tax);
    const [unit, setUnit] = useState(unit_data ? unit_data : product_template?.unit || 'Pieces');
    const [secondaryUnit, setSecondaryUnit] = useState(isCreatedItem ? secondaryUnit_data : product_template?.secondary_unit);
    const [rate, setRate] = useState(isCreatedItem ? rate_data : product_template?.conversion_rate || 0);
    const [location, setLocation] = useState(null);
    const [sellingPrice, setSellingPrice] = useState(isCreatedItem ? sellingPrice_data : product?.selling_price);
    const [costPrice, setCostPrice] = useState(isCreatedItem ? costPrice_data : product?.cost_price);
    const [profit, setProfit] = useState(isCreatedItem ? profit_data : product?.profit);
    const [margin, setMargin] = useState(isCreatedItem ? margin_data : product?.margin);
    const [quantity, setQuantity] = useState(isCreatedItem ? quantity_data : prodBusiness?.[0]?.quantity || 0);
    const [lowStock, setLowStock] = useState(isCreatedItem ? lowStock_data : product?.low_stock || 0);
    const [barcode, setBarcode] = useState(isCreatedItem ? barcode_data : product?.barcode || '');
    const [selectedLocations, setSelectedLocations] = useState(isCreatedItem ? selectedLocations_data : product_businessess ? convertArrayToObj(product_businessess) : {});
    const [locationsObj, setLocationsObj] = useState();
    const [itemDiscountModal, setItemDiscountModal] = useState(false);
    const [itemLevelDiscount, setItemLevelDiscout] = useState(isCreatedItem ? itemLevelDiscount_data : product?.product_level_discount || null);
    const [variants, setVariants] = useState(variants_data || {});
    const [createdOption, setCreatedOption] = useState(isCreatedItem ? createdOption_data : '');
    const [disableVariants, setDisableVariants] = useState(product ? true : false);
    const [imageModal, setImageModal] = useState(false);
    const [variantModal, setVariantModal] = useState(false);
    const [optionModal, setOptionModal] = useState(false);
    const [variantsListModal, setVariantsListModal] = useState(false);
    const [taxModal, setTaxModal] = useState(false);
    const [categoryModalToggle, setCategoryModalToggle] = useState(false);
    const [discardModalToggle, setDiscardModalToggle] = useState(false);
    const [loading, setLoading] = useState(false);
    const [locationModal, setLocationModal] = useState(false);
    const [errors, setErrors] = useState({});

    const secondaryUnitRef = useRef(null);

    let item_data = {
        name_data: name,
        category_data: category,
        description_data: description,
        image_data: image,
        tax_data: tax,
        unit_data: unit || 'Pieces',
        secondaryUnit_data: secondaryUnit,
        rate_data: rate,
        sellingPrice_data: sellingPrice,
        costPrice_data: costPrice,
        profit_data: profit,
        margin_data: margin,
        quantity_data: quantity || 0,
        lowStock_data: lowStock || 0,
        barcode_data: barcode,
        selectedLocations_data: selectedLocations || {},
        itemLevelDiscount_data: itemLevelDiscount,
        variants_data: variants,
        createdOption_data: createdOption,
        disableVariants_data: disableVariants,
    };

    useEffect(() => {
        getBusinessOfAccount();
    }, []);

    useEffect(() => {
        if (tax_id && sales_tax) {
            findAndSetData(sales_tax, tax_id, setTax);
        } else if (category_id && categories) {
            findAndSetData(categories, category_id, setCategory);
        } else if (location_id && location) {
            setSelectedLocations(prev => {
                let obj = {...prev};
                obj[location_id] = location_id;
                return obj;
            });
        }
    }, [tax_id, category_id, location_id, location]);

    function findAndSetData(source, id, setter) {
        const data = source?.filter(x => x?._raw?.id?.includes(id));
        if (data.length > 0) {
            setter(data[0]);
        }
    }

    const handleEnterPress = event => {
        if (event.key === 'Enter') handleSave('save');
    };

    const resetDropdown = () => {
        setSecondaryUnit(null);
        secondaryUnitRef.current.value = '';
    };

    const onChange = (label, val) => {
        if (label == I18n.item_name) setName(val);
        else if (label == I18n.description) setDescription(val);
        else if (label == I18n.category) setCategoryModalToggle(true);
        else if (label == I18n.taxes) setTaxModal(true);
        else if (label == I18n.selling_price) {
            handleCalculateProfitMargin(val, costPrice);
            setSellingPrice(val);
        } else if (label == I18n.cost_price) {
            setCostPrice(val);
            handleCalculateProfitMargin(sellingPrice, val);
        } else if (label == I18n.add_quantity) setQuantity(val);
        else if (label == I18n.stock_alert) setLowStock(val);
        else if (label == I18n.barcode) setBarcode(val);
        else if (label == I18n.primary_unit) {
            setUnit(val.name);
            if (!val) {
                resetDropdown();
            }
        } else if (label == I18n.secondary_unit) setSecondaryUnit(val.name);
        else if (label == I18n.rate) setRate(val);
        else if (label == I18n.location) setLocationModal(true);
    };

    const handleCalculateProfitMargin = (sell, cost) => {
        let selling = sell || 0,
            costing = cost || 0;
        let totalProfit = selling - costing;
        let totalMargin = (totalProfit / costing) * 100;
        setProfit(totalProfit <= 0 ? 0 : totalProfit);
        setMargin(totalMargin <= 0 || totalMargin == Infinity ? 0 : totalMargin);
    };

    const toggleImageModal = () => {
        setImageModal(prev => !prev);
    };

    const toggleVariantModal = async () => {
        let options = await business.option.fetch();

        if (options.length) {
            setVariantModal(prev => !prev);
        } else {
            setOptionModal(prev => !prev);
        }
    };

    const toggleOptionModal = () => {
        setOptionModal(prev => !prev);
    };

    const handleSave = async val => {
        try {
            let formErrors;
            const {draft: item_draft_status, id: item_id} = product || {};

            if (selectedLocations && selectedLocations?.hasOwnProperty('all')) {
                delete selectedLocations['all'];
            }
            const locationsArray = Object.values(selectedLocations || {}).map(item => locationsObj[item]);

            let paramsForTemplate = {
                category,
                name,
                description,
                image,
                unit: unit,
                secondaryUnit,
                rate: Number(rate),
                sellingPrice,
                costPrice,
                barcode,
                locationsArray,
                item_level_discount: itemLevelDiscount,
            };

            if (Object.keys(variants)?.length) {
                let variantData = {
                    variants: Object.values(variants),
                    name,
                    category: category?.name,
                    item_level_discount: itemLevelDiscount,
                    locationsArray,
                    unit: unit,
                    secondaryUnit,
                    rate: Number(rate),
                };
                formErrors = await validateCreateItemVariantForm(variantData);
            } else {
                formErrors = await (val == 'save' ? validateCreateItemForm(paramsForTemplate, variants, product) : validateDraftItem(paramsForTemplate));
            }

            setErrors(formErrors);

            if (formErrors?.hasOwnProperty('main') && Object.keys(formErrors.main).length === 0) {
                delete formErrors.main;
            }

            if (Object.keys(formErrors).length > 0) return;

            setLoading(true);
            let template = product ? await product_template.updateDetails(paramsForTemplate) : await account.createProductTemplate(paramsForTemplate);

            if (Object.keys(variants).length) {
                let variantsToCreate = Object.values(variants);
                await bulkCreateProducts(variantsToCreate, template, account, category, tax, locationsArray, itemLevelDiscount);
            } else {
                let base_price = Number(sellingPrice || 0);
                if (tax?.is_inclusive) {
                    base_price = (sellingPrice * (100 / (100 + tax?.rate))).toFixed(2);
                }

                let paramsForProduct = {
                    account,
                    category,
                    tax,
                    name,
                    barcode,
                    base_price: Number(base_price || 0),
                    selling_price: Number(sellingPrice || 0),
                    cost_price: Number(costPrice || 0),
                    profit: Number(profit || 0),
                    margin: Number(margin || 0),
                    quantity: Number(quantity || 0),
                    low_stock: Number(lowStock || 0),
                    draft: val === 'saveDraft',
                    item_level_discount: itemLevelDiscount,
                    type: 'item',
                };
                if (product) {
                    await product.updateDetails(paramsForProduct);
                    await prodBusiness[0]?.updateQuantity(paramsForProduct);
                    const {additionArray, deletionArray} = filterOptions(product_businessess, locationsArray, 'business');

                    const prodBusPayload = {
                        locations: additionArray,
                        product: product,
                        quantity: Number(quantity || 0),
                    };

                    await bulkCreateProductBusiness(prodBusPayload);
                    await bulkDeleteProductBusiness(deletionArray);
                } else {
                    const createdProduct = await template.createProduct(paramsForProduct);
                    const prodBusPayload = {
                        locations: locationsArray,
                        product: createdProduct,
                        quantity: Number(quantity || 0),
                    };

                    console.log('prodBusPayload', prodBusPayload);
                    await bulkCreateProductBusiness(prodBusPayload);
                }
            }

            let toastMessage;
            if (val === 'saveDraft') {
                toastMessage = 'item_saved_into_draft';
            } else {
                toastMessage = item_draft_status
                    ? 'item_published_successfully'
                    : item_id
                    ? 'item_update'
                    : state?.screenName == 'is_onboarding'
                    ? 'onboarding_item_created'
                    : 'item_created';
            }
            setRenderToast(toastMessage);

            setLoading(false);
            await Sync(user?.email);
            setPersistData(null);
            state?.screenName == 'is_onboarding' ? navigate('/onboarding') : navigate('/items');
        } catch (err) {
            console.log('err in createItem', err);
            setLoading(false);
        }
    };

    const handleCreatedOption = option => {
        if (!variantModal) setVariantModal(true);
        setCreatedOption(option);
    };

    const handleCreateVariants = obj => {
        setVariants(obj);
        if (Object.keys(obj).length > 0) setVariantModal(false);
    };

    const handleToggleCategroyModal = () => {
        if (!product) {
            setCategoryModalToggle(x => !x);
            setPersistData({items: item_data});
        }
    };

    const updateVariantList = obj => {
        setVariants(obj.variants);
        setTax(obj.tax);
        setUnit(obj.unit);
        setSecondaryUnit(obj.secondaryUnit);
        setRate(obj.rate);
        setVariantsListModal(false);
    };

    const handleSalesTax = async val => {
        if (val == 'remove_saleTax') {
            setTax('');
        } else {
            setTax(val);
        }
        setTaxModal(false);
    };

    const handleCategory = async val => {
        if (val == 'remove_category') {
            setCategory('');
        } else {
            setCategory(val);
        }
        setCategoryModalToggle(false);
    };
    const handleItemDiscount = () => {
        setItemDiscountModal(true);
    };

    const handleOrderDiscount = async (type, value) => {
        setItemLevelDiscout({discountType: type, discountAmount: value});
        setItemDiscountModal(false);
    };

    const handleBack = () => {
        toggleDiscardModal();
    };

    const toggleDiscardModal = () => {
        setDiscardModalToggle(x => !x);
    };

    const handleDiscardAction = () => {
        setPersistData(null);
        navigate(-1);
    };

    async function getBusinessOfAccount() {
        const singleSelection = false;
        const businessLocations = await getAccountBusiness(account, business, userBusiness, singleSelection);
        const businessObj = await getHashMap(account);

        setLocation(businessLocations);
        setLocationsObj(businessObj);
    }

    useEffect(() => {
        // for handling location field, onchange isn't working there
        if (Object.values(selectedLocations || {}).length > 0) {
            setErrors(prevErrors => {
                const updatedErrors = {...prevErrors};
                let label = 'Location';
                if (updatedErrors[toSnakeCase(label)]) {
                    delete updatedErrors[toSnakeCase(label)];
                }

                return updatedErrors;
            });
        }
    }, [selectedLocations]);

    const handleToggleTaxModal = () => {
        setTaxModal(true);
        setPersistData({items: item_data});
    };

    const handleToggleLocation = () => {
        setLocationModal(true);
        setPersistData({items: item_data});
    };

    const handleDeleteVariant = key => {
        setVariants(prevState => {
            const updatedVariants = {...prevState};
            delete updatedVariants[key];
            return updatedVariants;
        });
    };

    return (
        <div onKeyDown={handleEnterPress}>
            <Header
                type="draftHeader"
                title={I18n.back}
                backAction={handleBack}
                saveCta={{
                    title: I18n.save,
                    saveLoad: loading,
                    action: () => handleSave('save'),
                }}
                primaryCta={
                    !Object.keys(variants).length && !disableVariants
                        ? {
                              title: I18n.save_draft,
                              action: () => handleSave('saveDraft'),
                          }
                        : null
                }
            />
            <CustomContainer reduceHeight={110} className="addScreenMainBox  createCustomerContainer">
                <Row className="justify-content-md-center">
                    <Col md={6}>
                        <h3 className="fontSize24  textCenter fontWeight600 marBot20 marTop0" style={{color: theme.text}}>
                            {product?.id ? I18n.edit_item : I18n.create_an_item}
                        </h3>
                        <h3 className="fontSize20 fontWeight400 marBot20 marTop0 marLeft20" style={{color: theme.text}}>
                            {I18n.item_details}
                        </h3>
                        <div className="itemFields">
                            <div className="intventoryFormGridTwo">
                                <div className="intventoryFormGridLeft">
                                    <TextField
                                        onChange={onChange}
                                        error={errors?.main?.name}
                                        label={I18n.item_name}
                                        placeholder={I18n.item_name}
                                        value={name}
                                        required={true}
                                        autoFocus={true}
                                    />
                                    <div onClick={handleToggleCategroyModal}>
                                        <TextField
                                            label={I18n.category}
                                            placeholder={I18n.category}
                                            onChange={onChange}
                                            required={true}
                                            error={errors?.main?.category}
                                            hlighter={true}
                                            value={category?.name || ''}
                                            inputType="select"
                                            type="text"
                                            suffix="downIcon2"
                                            suffix_fill={theme.white}
                                            suffix_width={'24'}
                                            suffix_height={'24'}
                                            suffix_viewBox={'0 0 18 18'}
                                            disabled={product ? true : false}
                                        />
                                    </div>
                                </div>
                                <div className="intventoryFormGridRight marBot15">
                                    <ImageUploader image={image} toggleImageModal={toggleImageModal} />
                                </div>
                            </div>

                            <div className="intventoryFormGridTwo">
                                <div className="priceSectionInputLeft">
                                    <TextField onChange={onChange} label={I18n.description} placeholder={I18n.description} value={description} />
                                </div>

                                <div className="priceSectionInputRight" onClick={handleToggleLocation}>
                                    <TextField
                                        label={I18n.location}
                                        placeholder={I18n.location}
                                        error={errors?.main?.location}
                                        required={true}
                                        onChange={onChange}
                                        value={getLocationNames(locationsObj, selectedLocations)}
                                        inputType="text"
                                        type="text"
                                        suffix="downIcon2"
                                        suffix_fill={theme.white}
                                        suffix_width={'24'}
                                        suffix_height={'24'}
                                        suffix_viewBox={'0 0 18 18'}
                                        // disabled={product ? true : false}
                                    />
                                </div>
                            </div>

                            <div className="intventoryFormGridTwo">
                                <div className="priceSectionInputLeft" onClick={handleToggleTaxModal}>
                                    <TextField
                                        label={I18n.taxes}
                                        placeholder={I18n.taxes}
                                        value={tax ? `${tax?.name} ${tax?.rate}%` : ''}
                                        onChange={onChange}
                                        inputType="text"
                                        type="text"
                                        suffix="downIcon2"
                                        suffix_fill={theme.white}
                                        suffix_width={'24'}
                                        suffix_height={'24'}
                                        suffix_viewBox={'0 0 18 18'}
                                    />
                                </div>
                                <div className="priceSectionInputRight" onClick={() => handleItemDiscount()}>
                                    <TextField
                                        label={I18n.item_level_discount}
                                        placeholder={I18n.item_level_discount}
                                        value={itemLevelDiscount?.discountAmount || ''}
                                        error={errors?.main?.item_level_discount}
                                        onChange={onChange}
                                        inputType="text"
                                        type="text"
                                        suffix="downIcon2"
                                        suffix_fill={theme.white}
                                        suffix_width={'24'}
                                        suffix_height={'24'}
                                        suffix_viewBox={'0 0 18 18'}
                                    />
                                </div>
                            </div>

                            <SingleItemCreationFeild
                                sellingPrice={sellingPrice}
                                costPrice={costPrice}
                                profit={profit}
                                margin={margin}
                                quantity={quantity}
                                lowStock={lowStock}
                                barcode={barcode}
                                errors={errors}
                                rate={rate}
                                unit={unit}
                                secondaryUnit={secondaryUnit}
                                onChange={onChange}
                                disableVarientOption={!Object.keys(variants).length}
                                secondaryUnitRef={secondaryUnitRef}
                            />
                            {!disableVariants && (
                                <>
                                    <p style={{background: theme.topBarBG}} className="itemSeperator"></p>
                                    <h3 className="fontSize20 fontWeight400 marBot15" style={{color: theme.text}}>
                                        {I18n.variations}
                                    </h3>
                                    <VariationsListComponent
                                        toggleVariantModal={toggleVariantModal}
                                        variants={variants}
                                        errors={errors}
                                        handleDeleteVariant={handleDeleteVariant}
                                    />
                                </>
                            )}
                        </div>
                    </Col>
                </Row>
            </CustomContainer>

            <ImageUploadModal setToggle={setImageModal} toggle={imageModal} setImage={setImage} />
            <VariantsOptionModal
                setToggle={setVariantModal}
                toggle={variantModal}
                business={business}
                handleCreateOption={toggleOptionModal}
                createdOption={createdOption}
                handleCreateVariants={handleCreateVariants}
            />
            <CreateOptionModal setToggle={setOptionModal} toggle={optionModal} business={business} handleCreatedOption={handleCreatedOption} />
            <TaxesModal
                setToggle={setTaxModal}
                toggle={taxModal}
                productId={product?.id}
                screen_name={isCreatedItem ? 'item_creation' : 'item_edit'}
                handleSelect={handleSalesTax}
                data={tax}
            />
            <CategoryModal setToggle={setCategoryModalToggle} toggle={categoryModalToggle} handleSelect={handleCategory} data={category} />
            <VariationListModal setToggle={setVariantsListModal} toggle={variantsListModal} variants={variants} business={business} updateVariantList={updateVariantList} />
            <LocationModal
                toggle={locationModal}
                setToggle={setLocationModal}
                selectedLocations={selectedLocations}
                setSelectedLocations={setSelectedLocations}
                locations={location}
                singleSelection={false}
                screenName="item"
            />

            <ItemDiscountsModal
                setToggle={setItemDiscountModal}
                selectedItem={product}
                toggle={itemDiscountModal}
                handleSave={handleOrderDiscount}
                showDiscountType={true}
                title={I18n.item_level_discount}
                from="item"
            />
            <MessageModal
                className="messageModal"
                setToggle={setDiscardModalToggle}
                toggle={discardModalToggle}
                description={I18n.do_you_really_want_to_discard}
                subDescription={I18n.this_action_is_irreversible_once_you_discard_it_its_gone}
                secondaryCta={{
                    title: I18n.cancel,
                    action: toggleDiscardModal,
                }}
                primaryCta={{
                    title: I18n.discard,
                    backgroundColor: theme.white,
                    borderColor: theme.red,
                    txtColor: theme.red,
                    action: handleDiscardAction,
                    isDelete: true,
                }}
            />
        </div>
    );
};

const enhance = withObservables(['product'], ({product}) => {
    const business_id = localStorage.getItem('business_id');

    return product
        ? {
              product_template: product.product_template.observe(),
              selected_category: product.category.observe(),
              selected_tax: product.sales_tax.observe(),
              sales_tax: observeUnArchivedSalesTax(),
              categories: observeActiveCategories(),
              prodBusiness: product.getProdBusiness(business_id),
              product_businessess: product.product_business.observe(),
          }
        : {
              sales_tax: observeUnArchivedSalesTax(),
              categories: observeActiveCategories(),
          };
});

export default enhance(CreateItemComponent);
