import {useLanguageContext, useThemeContext} from '../../../context';
import {Col, Container, Row} from 'react-bootstrap';
import {Button, Dropdown, SearchBox, TextField} from '../../common';
import {firstLetterCaptilize} from '../../../constants';

const SearchHeader = ({leftCta, rightCta, actionCta, rightAction, search, title, className, hideSearch}) => {
    const {I18n} = useLanguageContext();
    const {theme} = useThemeContext();
    return (
        <section style={{background: theme.blackBg}} className={`topBarMainCont ${className}`}>
            <Container fluid>
                <Row>
                    <Col md={4}>
                        <div className="topBarLeftSide">
                            {title && (
                                <p className="fontSize18 fontWeight500 marLeft10 marBot0" style={{color: theme.white}}>
                                    {firstLetterCaptilize(title || '')}
                                </p>
                            )}
                        </div>
                    </Col>
                    <Col md={8}>
                        <div className="topBarRightSide">
                            {hideSearch ? null : <SearchBox type="pos" {...search} />}
                            {leftCta && (
                                <Dropdown
                                    type="radioList"
                                    dropDown={{
                                        title: leftCta.title,
                                        name: leftCta.name,
                                        fill: leftCta.fill,
                                        width: leftCta.width,
                                        height: leftCta.height,
                                        viewBox: leftCta.viewBox,
                                        option: leftCta.option,
                                    }}
                                />
                            )}
                            {actionCta && (
                                <Dropdown
                                    type={actionCta?.radio ? 'radioList' : ''}
                                    dropDown={{
                                        title: actionCta.title,
                                        name: actionCta.name,
                                        fill: actionCta.fill,
                                        width: actionCta.width,
                                        height: actionCta.height,
                                        viewBox: actionCta.viewBox,
                                        option: actionCta.option,
                                    }}
                                />
                            )}
                            {rightAction && (
                                <div className="marLeft10 taxDropdown">
                                    <Dropdown
                                        dropDown={{
                                            title: rightAction.title,
                                            name: rightAction.name,
                                            fill: rightAction.fill,
                                            width: rightAction.width,
                                            height: rightAction.height,
                                            viewBox: rightAction.viewBox,
                                            option: rightAction.option,
                                        }}
                                    />
                                </div>
                            )}
                            {rightCta && (
                                <Button
                                    className={`headerRightCta marLeft10 fontWeight500 fontSize16 cursorPointer`}
                                    backgroundColor={rightCta.backgroundColor || theme.brightGreen}
                                    txtColor={rightCta.txtColor || theme.white}
                                    title={rightCta.title}
                                    handleClick={rightCta.action}
                                    borderColor={rightCta.borderColor || theme.brightGreen}
                                    saveLoad={rightCta.saveLoad}
                                />
                            )}
                        </div>
                    </Col>
                </Row>
            </Container>
        </section>
    );
};
export default SearchHeader;
