import React from 'react'
import { useLanguageContext, useThemeContext } from '../../../../../../context';
import TableBody from '../../../../../common/TableContainer/TableBody';
import { formatNum } from '../../../../../../constants';

const DepositBreakDownList = ({ item, index, handleNavigate }) => {
    const { theme } = useThemeContext();
    const { transaction_id, gross_amount, fee, net_amount, bussiess_id } = item || {};


    return (
        <TableBody key={index} className={`fourColumn cursorPointer`} style={{ borderColor: theme.inputBorder }}>
            <div className="BoxWidth justifyStart">
                <p onClick={() => handleNavigate(transaction_id, bussiess_id)} className="fontSize14 fontWeight600 OneLineTruncate" style={{ color: theme.barclaysBlue }}>
                    {transaction_id || "--"}
                </p>
            </div>
            <div className="BoxWidth justifyCenter">
                <p className="fontSize14 fontWeight600 OneLineTruncate" style={{ color: theme.white }}>
                    {`Rs. ${formatNum(gross_amount || 0)}`}
                </p>
            </div>

            <div className="BoxWidth justifyCenter">
                <p className="fontSize14 fontWeight600 OneLineTruncate" style={{ color: theme.white }}>
                    {`Rs. ${formatNum(fee || 0)}`}
                </p>
            </div>
            <div className="BoxWidth justifyCenter">
                <p className="fontSize14 fontWeight600 OneLineTruncate" style={{ color: theme.white }}>
                    {`Rs. ${formatNum(net_amount || 0)}`}
                </p>
            </div>
        </TableBody>
    );
};



export default DepositBreakDownList
