import {useEffect, useState} from 'react';
import {useParams} from 'react-router-dom';

const useRider = business => {
    const {id} = useParams();
    const [isLoading, setIsLoading] = useState(id ? true : false);
    const [rider, setRider] = useState();

    useEffect(() => {
        handleRider(id);
    }, [id]);

    const handleRider = async () => {
        let riderList = await business.getRider(id).fetch();
        if (riderList.length) {
            let selectedRider = riderList[0];
            setRider(selectedRider);
        }
        setIsLoading(false);
    };

    return {isLoading, rider};
};

export default useRider;
