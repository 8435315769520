import React from 'react';
import {useThemeContext} from '../../../context';
import TableBody from '../TableContainer/TableBody';

const ListItem = ({item}) => {
    let {category_name, total_sales, color_code, percentage} = item;

    const {theme} = useThemeContext();
    return (
        <TableBody className={`fourColumn`} style={{borderColor: theme.inputBorder}}>
            <div className="BoxWidth justifyCenter">
                <p className="circleBox" style={{backgroundColor: color_code}}></p>
            </div>

            <div className="BoxWidth justifyStart">
                <p className="fontSize12 fontWeight400 OneLineTruncate" style={{color: theme.white}}>
                    {category_name}
                </p>
            </div>
            <div className="BoxWidth justifyCenter">
                <p className="fontSize12 fontWeight400 OneLineTruncate" style={{color: theme.white}}>
                    Rs. {total_sales}
                </p>
            </div>
            <div className="BoxWidth justifyEnd">
                <p className="fontSize12 fontWeight400 OneLineTruncate" style={{color: theme.white}}>
                    {Number(percentage).toFixed(2)}%
                </p>
            </div>
        </TableBody>
    );
};

export default ListItem;
