import CreateFloorPlan from '../CreateFloorPlan';
import {useAuthContext} from '../../../../../context';
import {CustomContainer, Loading} from '../../../../common';
import useFloorPlan from '../../../../../hooks/useFloorPlan';

const EditFloorPlan = () => {
    const {business} = useAuthContext();
    const {isLoading, floorData} = useFloorPlan(business);

    return isLoading ? (
        <Loading />
    ) : (
        <>
            <CustomContainer className="addScreenMainBox createCustomerContainer">
                <CreateFloorPlan floorData={floorData} />
            </CustomContainer>
        </>
    );
};

export default EditFloorPlan;
