import {useEffect, useState} from 'react';

const useBarcode = (products, handleAddBarcodeSearchedProduct) => {
    const [barcode, setBarcode] = useState(null);

    useEffect(() => {
        handleSelectItemFromBarcode();
    }, [barcode]);

    const handleScan = data => {
        setBarcode(data);
    };

    const handleError = err => {
        console.error(err);
    };

    const handleSelectItemFromBarcode = () => {
        let item = products?.find(x => x?.barcode !== null && x?.barcode !== '' && x?.barcode == barcode?.toString());

        setBarcode(null);
        if (item) {
            handleAddBarcodeSearchedProduct(item);
        }
    };

    return {handleScan, handleError, barcode};
};

export default useBarcode;
