import {CustomContainer} from '../../../../common';
import CreateRiderComponent from '../../../../common/CreateRiderComponent';

const CreateRider = () => {
    return (
        <>
            <CustomContainer className="addScreenMainBox  createCustomerContainer">
                <CreateRiderComponent />
            </CustomContainer>
        </>
    );
};

export default CreateRider;
