import {useLanguageContext, useThemeContext} from '../../../../../../context';
import TableHeader from '../../../../../common/TableContainer/TableHeader';
import {Col} from 'react-bootstrap';
import React from 'react';
import Icon from '../../../../../../assets/icons';

const HeaderItem = ({data, handleSort, sortColumn, sortOrder, isMuiltlocation}) => {
    const {I18n} = useLanguageContext();
    const {theme} = useThemeContext();

    return (
        <Col md={12} xs={12}>
            <TableHeader className={`${isMuiltlocation ? 'sevenColumn' : 'sixColumn'} marRight20`} style={{borderColor: theme.inputBorder}}>
                {data?.map((item, index) => (
                    <div key={index} className={`BoxWidth ${item?.className}`} onClick={() => handleSort(item.name)}>
                        <p className="fontSize16 fontWeight600 cursorPointer" style={{color: theme.white}}>
                            {I18n[item.name]}
                            {item?.sorting && (
                                <span className="marLeft5">
                                    <Icon name={sortColumn === item.name ? (sortOrder === 'asc' ? 'ascendingIcon' : 'descendingIcon') : 'sortIcon'} viewBox="0 0 8 16" />
                                </span>
                            )}
                        </p>
                    </div>
                ))}
            </TableHeader>
        </Col>
    );
};

export default HeaderItem;
