import {Col, Row} from 'react-bootstrap';
import {CustomContainer, Header, TextField} from '../../../../common';
import {useAuthContext, useLanguageContext, useSessionContext, useThemeContext} from '../../../../../context';
import {useEffect, useState} from 'react';
import {formatDate} from '../../../../../constants';
import {useLocation, useNavigate} from 'react-router-dom';
import Icon from '../../../../../assets/icons';
import useFocus from '../../../../../hooks/useFocus';

const CreateSession = () => {
    let navigate = useNavigate();
    let location = useLocation();

    let from = location.state?.from?.pathname || '/';
    let isLogout = location.state == 'logout' ? true : false;

    const {I18n} = useLanguageContext();
    const {theme} = useThemeContext();
    const {user, business, handleIncrementCounter, handleLogoutInDB} = useAuthContext();
    const {handleCreateSession, handleCloseSession} = useSessionContext();

    const [date, setDate] = useState(new Date());
    const [loading, setLoading] = useState(false);
    const [balance, setBalance] = useState('');
    const [errors, setErrors] = useState('');
    const {textBoxRef, handleFocus} = useFocus();   

    useEffect(() => {
        setTimeout(() => {
            handleFocus();
        }, 1000);
    }, []);
    const onChange = (label, val) => setBalance(val);

    useEffect(() => {
        setErrors('');
    }, [balance]);

    const handleSubmit = async balance => {
        try {
            if (!balance) {
                setErrors(I18n[isLogout ? 'set_your_closing_balance' : 'set_your_opening_balance']);
                return;
            }

            setLoading(true);
            if (isLogout) {
                await handleCloseSession(balance);
                await handleLogoutInDB();
                navigate('/login', {replace: true});
            } else {
                let paramsForSession = {
                    business,
                    user,
                    is_active: true,
                    opening_balance: Number(balance) || 0,
                    started_at: date.getTime(),
                };

                await handleCreateSession(paramsForSession);
                await  handleIncrementCounter('session');
                navigate(from, {replace: true});
            }
            setLoading(false);
        } catch (err) {
            console.log('err in handleCreateSession', err);
        }
    };

    const handleEnterPress = event => {
        if (event.key == 'Enter') {
            handleSubmit(balance);
        }
    };

    return (
        <div onKeyDown={handleEnterPress}>
            <Header
                type="draftHeader"
                saveCta={{
                    title: I18n.submit,
                    action: () => handleSubmit(balance),
                    saveLoad : loading,
                }}
                removeBackButton={true}
            />
            <CustomContainer className={'createCustomerContainer'} reduceHeight={105}>
                <Row className="justify-content-md-center">
                    <Col md={6}>
                        <div className="loginFormBox">
                            <div className="signupTextBox">
                                <h1 className="fontSize24 fontWeight600 marBot40 textCenter" style={{color: theme.text}}>
                                    {I18n[isLogout ? 'set_your_closing_balance' : 'set_your_opening_balance']}
                                </h1>
                            </div>

                            <div className="itemFields">
                                <div className="calanderfeild">
                                    <Icon name="calenderNewIcon" fill={theme.white} width={24} height={24} viewBox={'0 0 24 24'} />
                                    <p className="marBot0 marLeft10 fontSize16 fontWeight600" style={{color: theme.white}}>
                                        {formatDate(date)}
                                    </p>
                                </div>

                                <p className="fontSize20" style={{color: theme.white}}>
                                    {I18n[isLogout ? 'closing_balance' : 'opening_balance']}
                                </p>
                                <TextField
                                    onChange={onChange}
                                    label={I18n[isLogout ? 'closing_balance' : 'opening_balance']}
                                    inputRef={textBoxRef}
                                    error={errors}
                                    value={balance}
                                    required={true}
                                    autoFocus={true}
                                    type="numeric"
                                    maxLength={12}
                                />
                            </div>
                        </div>
                    </Col>
                </Row>
            </CustomContainer>
        </div>
    );
};

export default CreateSession;
