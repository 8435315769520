import React from 'react';
import {useLanguageContext, useThemeContext} from '../../../context';
import TableBody from '../TableContainer/TableBody';

const ItemsList = ({data, index}) => {
    const {theme} = useThemeContext();
    const {I18n} = useLanguageContext();

    const {item_name, low_stock_level, stock_needed, current_stock} = data || {};

    return (
        <TableBody className={`threeColumn cursorPointer`} style={{borderColor: theme.inputBorder}}>
            <div key={index} className="BoxWidth justifyStart">
                <p className="fontSize14 OneLineTruncate" style={{color: theme.white}}>
                    {item_name}
                </p>
            </div>
            <div className="BoxWidth justifyCenter">
                <p className="fontSize14 OneLineTruncate" style={{color: theme.white}}>
                    {current_stock}
                </p>
            </div>
            <div className="BoxWidth justifyCenter">
                <p className="fontSize14 OneLineTruncate" style={{color: theme.white}}>
                    {low_stock_level}
                </p>
            </div>
            <div className="BoxWidth justifyEnd">
                <p className="fontSize14 OneLineTruncate" style={{color: theme.white}}>
                    {stock_needed}
                </p>
            </div>
        </TableBody>
    );
};

export default ItemsList;
