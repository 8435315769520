import React from 'react'
import { useLanguageContext, useThemeContext } from '../../../context';
import DashboardDataBox from '../DashboardDataBox';
import Button from '../Button';

const UpgradedOscarPlan = () => {

    const { theme } = useThemeContext();
    const { I18n } = useLanguageContext();

    const handleRoute = () => {
        // handleRoute
    }

    const listData = [
        {
            title: 'everything_from_standard',
        },
        {
            title: 'crm',
        },
        {
            title: 'loyalty_program',
        },
        {
            title: 'customer_feedback',
        },
        {
            title: 'sms_marketing',
        },
        {
            title: 'multi_location',
        },
        {
            title: 'franchise_management',
        },
        {
            title: 'warehouse_operations',
        },
        {
            title: 'automated_reporting',
        },
        {
            title: 'unified_reporting_for_hq',
        },
    ]

  return (
    <DashboardDataBox title='upgrade_your_oscar_plan'> 
        <h1 className='fontSize20 fontWeight600 grayNew'>
          {I18n.upgrade_your_oscar_heading}
        </h1>
        <p className='fontSize14 fontWeight400 lightGrayTwo'>
          {I18n.upgrade_your_oscar_descp}<br />
          {I18n.upgrade_your_oscar_desc}
        </p>
        <div className='dashboardBannerListData'>
          <ul>
            {listData.map((item, index) => {
                const { title } = item || {};
                return(
                    <li key={index}>
                        <span className='dashboardListIconGreen' />
                        <span className='grayNew'>{I18n[title]}</span>
                    </li>
                )
            })}
          </ul>
        </div>
        <Button
            type="secondary"
            className={`fontSize18 cursorPointer`}
            backgroundColor={theme.brightGreen}
            txtColor={theme.white}
            title={I18n.upgrade_to_enterprise}
            handleClick={handleRoute}
        />
    </DashboardDataBox>
  )
}

export default UpgradedOscarPlan
