import React from 'react';
import {useLanguageContext, useThemeContext} from '../../../../../../context';
import {formatNum} from '../../../../../../constants';
import StatusBox from '../../../../../common/StatusBox';
import Icon from '../../../../../../assets/icons';
import {useNavigate} from 'react-router-dom';

const DepositTransaction = ({data}) => {
    const {I18n} = useLanguageContext();
    const {theme} = useThemeContext();
    let navigate = useNavigate();

    const {transaction_id, amount, fee_amount, gross_amount, fee_rate, type, net_amount, total_num_transactions, created_at, status = 'succeeded'} = data || {};

    const handleNavigate = () => {
        if (transaction_id) {
            navigate(`/payment-breakdown/${transaction_id}`);
        }
    };
    return (
        <div className="transactionDetailWrapper">
            <div className="transactionDetailAmountSection">
                <div className="transactionDetailAmountTop marBot20">
                    <p className="marBot5 fontSize16" style={{color: theme.darkGrayTwo}}>
                        {I18n.amount}
                    </p>
                    <p className="rowConatainer" style={{color: theme.white}}>
                        <span className="marRight10 fontSize24 fontWeight600 marBot0"> {`Rs. ${formatNum(amount || 0)}`} </span>
                        <span>
                            <StatusBox status={type} />
                        </span>
                    </p>
                    <StatusBox status={status} labelChip={true} />
                </div>
                <hr className="detailSeperator" />

                <div className="transactionDetailItemPurchased">
                    <h3 className="fontSize16 ">
                        <span className="fontWeight700" style={{color: theme.white}}>
                            {I18n.payment_breakdown}
                        </span>
                        <span className="fontWeight400 marLeft5" style={{color: theme.darkGrayTwo}}>
                            {`( ${I18n.total_transactions} ${total_num_transactions} )`}
                        </span>
                    </h3>

                    <div className="detailItemPurchasedInner">
                        <p className="fontSize12 fontWeight400" style={{color: theme.lightGrayTwo}}>
                            {I18n.gross_amount}
                        </p>
                        <p className="fontSize14 fontWeight600" style={{color: theme.white}}>
                            Rs. {formatNum(gross_amount)}
                        </p>
                    </div>

                    <div className="detailItemPurchasedInner">
                        <p className="fontSize12 fontWeight400" style={{color: theme.lightGrayTwo}}>
                            {I18n.fees}
                            {` (${fee_rate}%)`}
                        </p>
                        <p className="fontSize14 fontWeight400" style={{color: theme.white}}>
                            Rs. {formatNum(fee_amount) || 0}
                        </p>
                    </div>

                    <div className="detailItemPurchasedInner">
                        <p className="fontSize12 fontWeight400" style={{color: theme.lightGrayTwo}}>
                            {I18n.net_amount}
                        </p>
                        <p className="fontSize14 fontWeight400" style={{color: theme.white}}>
                            Rs. {formatNum(net_amount) || 0}
                        </p>
                    </div>
                    <div className="detailItemPurchasedInner cursorPointer" onClick={handleNavigate}>
                        <p className="fontSize12 fontWeight400" style={{color: theme.barclaysBlue}}>
                            <span className="marRight5"> {I18n.view_transactions_breakdown_detailed_report}</span>
                            <Icon name="payoutNewIcon" viewBox={'0 0 18 18'} width={'14'} height={'14'} fill={theme.barclaysBlue} />
                        </p>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default DepositTransaction;
