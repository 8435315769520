import React from 'react';
import {useLanguageContext, useThemeContext} from '../../../context';
import TableBody from '../TableContainer/TableBody';

const List = ({data, index}) => {
    const {theme} = useThemeContext();
    const {I18n} = useLanguageContext();

    const {category_name, items_sold, total_sales} = data || {};

    return (
        <TableBody key={index} className={`threeColumn cursorPointer`} style={{borderColor: theme.inputBorder}}>
            <div className="BoxWidth justifyStart">
                <p className="fontSize14 OneLineTruncate" style={{color: theme.white}}>
                    {category_name}
                </p>
            </div>
            <div className="BoxWidth justifyCenter">
                <p className="fontSize14 OneLineTruncate" style={{color: theme.white}}>
                    {items_sold}
                </p>
            </div>
            <div className="BoxWidth justifyEnd">
                <p className="fontSize14 OneLineTruncate" style={{color: theme.white}}>
                    Rs. {total_sales}
                </p>
            </div>
        </TableBody>
    );
};

export default List;
