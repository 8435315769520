import {useEffect, useState} from 'react';
import {useParams} from 'react-router-dom';

const useFloorPlan = business => {
    const {id} = useParams();
    const [isLoading, setIsLoading] = useState(id ? true : false);
    const [floorData, setFloorData] = useState();

    useEffect(() => {
        handleFloorPlan(id);
    }, [id]);

    const handleFloorPlan = async () => {
        let floorPlan = await business.getFloorPlan(id).fetch();

        if (floorPlan.length) {
            let selectedFloor = floorPlan[0];
            let floorTables = await selectedFloor.getTables.fetch();

            const temp = {
                selectedFloor,
                floorTables,
            };

            setFloorData(temp);
        }
        setIsLoading(false);
    };

    return {isLoading, floorData};
};

export default useFloorPlan;
