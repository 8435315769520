import {Col, Row} from 'react-bootstrap';
import {useAuthContext, useLanguageContext, useThemeContext} from '../../../context';
import {TextField, Header, CustomContainer, MessageModal, Loading} from '../../common';
import {DEVICE_HEIGHT, validateCategroy} from '../../../constants';
import {useState, useEffect, useRef} from 'react';
import ColorPicker from '../ColorPicker';
import {Sync} from '../../../api';
import {useLocation, useNavigate} from 'react-router-dom';

const CreateCategoryComponent = ({category}) => {
    const {I18n} = useLanguageContext();
    const {theme} = useThemeContext();
    const {user, setRenderToast, account} = useAuthContext();
    const {state} = useLocation();
    let navigate = useNavigate();

    const [name, setName] = useState(category?.name || '');
    const [errors, setErrors] = useState({});
    const [selectedColor, setSelectedColor] = useState(category?.color_code || '#8F9397');
    const [toggleColorPicker, setToggleColorPicker] = useState(false);
    const [discardModalToggle, setDiscardModalToggle] = useState(false);
    const [loading, setLoading] = useState(false);
    const colorPickerRef = useRef(null);

    useEffect(() => {
        document.addEventListener('mousedown', handleClickOutside);
        return () => {
            document.removeEventListener('mousedown', handleClickOutside);
        };
    }, [colorPickerRef]);

    function handleClickOutside(event) {
        if (colorPickerRef.current && !colorPickerRef.current.contains(event.target)) {
            setToggleColorPicker(false);
        }
    }
    const onChange = (label, val) => {
        if (label === I18n.category_name) setName(val);
        setErrors({});
    };

    const handleToggle = () => {
        setToggleColorPicker(true);
    };

    const handleSave = async () => {
        let paramsForCategory = {
            name,
            color_code: selectedColor,
        };

        const formErrors = await validateCategroy(paramsForCategory, category?.id);

        setErrors(formErrors);
        if (Object.keys(formErrors).length > 0) return;

        setLoading(true);
        let createdCategory;
        if (category) {
            createdCategory = await category.updateDetails(paramsForCategory);
        } else {
            createdCategory = await account.createCategory(paramsForCategory);
        }

        let toastMessage = category?.id ? 'update_category' : 'create_category';

        setRenderToast(toastMessage);

        await Sync(user.email);
        setLoading(false);
        if (state?.screenName === 'item_creation') {
            navigate(`/items/create?category_id=${createdCategory?.id}`);
        } else {
            navigate(-1);
        }
    };

    const handleEnterPress = event => {
        if (event.key === 'Enter') {
            handleSave();
        }
    };

    const handleBack = () => {
        toggleDiscardModal();
    };
    const toggleDiscardModal = () => {
        setDiscardModalToggle(x => !x);
    };

    const handleDiscardAction = () => {
        navigate(-1);
    };
    return loading ? (
        <Loading />
    ) : (
        <div>
            <Header
                type="draftHeader"
                title={I18n.back}
                backAction={handleBack}
                saveCta={{
                    title: I18n.save,
                    disabled: loading,
                    action: () => handleSave(),
                }}
            />
            <CustomContainer reduceHeight={110} className="addScreenMainBox createCustomerContainer">
                <Row className="justify-content-md-center" onKeyDown={handleEnterPress}>
                    <Col md={8}>
                        <h3 className="fontSize24 textCenter fontWeight600 marBot20 marTop0" style={{color: theme.text}}>
                            {category?.id ? I18n.edit_category : I18n.create_category}
                        </h3>
                        <h3 className="fontSize20 fontWeight400 marBot20 marTop0 marLeft20" style={{color: theme.text}}>
                            {I18n.category_details}
                        </h3>

                        <div className="itemFields" style={{height: DEVICE_HEIGHT * 0.75}}>
                            <div className="categoryTileContainer marBot10">
                                <div className="categoryBox">
                                    <div className="categoryTile" style={{backgroundColor: selectedColor || theme.white}}>
                                        <p style={{color: theme.white}} className="fontSize16 fontWeight500 twoLineTruncate marBot0 pad10">
                                            {name ? name : I18n.category}
                                        </p>
                                    </div>
                                    <p onClick={handleToggle} style={{color: theme.barclaysBlue}} className="fontSize12 fontWeight500 marBot0 cursorPointer">
                                        {I18n.edit_tile_color}
                                    </p>
                                    {toggleColorPicker && (
                                        <div className="colorPickerWrapper" ref={colorPickerRef}>
                                            <ColorPicker selectedColor={selectedColor} setSelectedColor={setSelectedColor} />
                                        </div>
                                    )}
                                </div>
                            </div>
                            <TextField
                                onChange={onChange}
                                error={errors['name']}
                                label={I18n.category_name}
                                placeholder={I18n.category_name}
                                value={name}
                                autoFocus={true}
                                required={true}
                            />
                        </div>
                    </Col>
                </Row>
                <MessageModal
                    className="messageModal"
                    setToggle={setDiscardModalToggle}
                    toggle={discardModalToggle}
                    description={I18n.do_you_really_want_to_discard}
                    subDescription={I18n.this_action_is_irreversible_once_you_discard_it_its_gone}
                    secondaryCta={{
                        title: I18n.cancel,
                        action: toggleDiscardModal,
                    }}
                    primaryCta={{
                        title: I18n.discard,
                        backgroundColor: theme.white,
                        borderColor: theme.red,
                        txtColor: theme.red,
                        action: handleDiscardAction,
                        isDelete: true,
                    }}
                />
            </CustomContainer>
        </div>
    );
};

export default CreateCategoryComponent;
