import InfiniteScroll from 'react-infinite-scroll-component';
import {useLanguageContext, useThemeContext} from '../../../../../context';
import ProductItem from './ProductItem';
import {useEffect} from 'react';

const ProductList = ({data, handleProduct, fetchMoreData, hasMoreItem, isRestaurantPos}) => {
    const {I18n} = useLanguageContext();
    const {theme} = useThemeContext();

    return (
        <>
            <InfiniteScroll
                style={{
                    display: 'grid',
                    gridTemplateColumns: 'repeat(auto-fill, minmax(137px, 1fr))',
                    gap: '10px',
                }}
                // className="infiniteScrollBar"
                dataLength={data.length}
                next={fetchMoreData}
                hasMore={hasMoreItem}
                loader={<h4>Loading...</h4>}
                scrollableTarget="scrollableItemsWrapper">
                {data.map((products, index) => (
                    <ProductItem item={products} key={index} onPress={handleProduct} isRestaurantPos={isRestaurantPos} />
                ))}
            </InfiniteScroll>
        </>
    );
};

export default ProductList;
