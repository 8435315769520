import {database} from '..';
import {uuid} from '../../constants';
import {Q} from '@nozbe/watermelondb';
import {BUSINESS_SCHEMA, WAITER_SCHEMA} from '../schema';

const waiter = database.collections.get(WAITER_SCHEMA);

export const observeActiveWaiters = () =>
    waiter.query(Q.on(BUSINESS_SCHEMA, 'id', localStorage.getItem('business_id')), Q.where('archive', Q.notEq(true)), Q.where('draft', Q.notEq(true))).observe();

export const observeArchivedWaiters = () => waiter.query(Q.on(BUSINESS_SCHEMA, 'id', localStorage.getItem('business_id')), Q.where('archive', Q.eq(true))).observe();

export const observeDraftWaiters = () => waiter.query(Q.on(BUSINESS_SCHEMA, 'id', localStorage.getItem('business_id')), Q.where('draft', Q.eq(true))).observe();

export const fetchWaitersByDate = async (start, end) =>
    await waiter.query(Q.on(BUSINESS_SCHEMA, Q.where('is_active', true)), Q.where('archive', Q.notEq(true)), Q.where('created_at', Q.between(Number(start), Number(end)))).fetch();

const prepareInsertion = (data, business) => {
    return data.map(item => {
        try {
            return waiter.prepareCreate(field => {
                field.business.set(business);
                field._raw.id = uuid();
                field.name = item.name;
                field.phone_number = item.phone_number;
                field.email = item.email;
                field.province = item.province;
                field.city = item.city;
                field.dob = item.dob;
                field.address = item.address;
                field.created_at = new Date()?.getTime();
                field.archive = false;
            });
        } catch (err) {
            console.log(err);
        }
    });
};

export const bulkCreateWaiter = async (data, business) => {
    await database.write(async () => {
        const allRecords = prepareInsertion(data, business);
        await database.batch(allRecords);
    });
};
