import React from 'react';
import {useLanguageContext, useThemeContext} from '../../../context';

const SalesSummaryList = ({data, key}) => {
    const {I18n} = useLanguageContext();
    const {theme} = useThemeContext();
    const {title, value} = data;
    return (
        <div key={key} className="saleSummaryListConatiner">
            <p className="fontSize14 OneLineTruncate marBot0" style={{color: theme.white}}>
                {I18n[title]}
            </p>
            <p className="fontSize14 OneLineTruncate marBot0" style={{color: theme.white}}>
                {value || 0}
            </p>
        </div>
    );
};

export default SalesSummaryList;
