import {useEffect, useState} from 'react';
import {useParams} from 'react-router-dom';

const useLocation = user => {
    const {id} = useParams();
    const [isLoading, setIsLoading] = useState(id ? true : false);
    const [location, setLocation] = useState();

    useEffect(() => {
        handleLocation(id);
    }, [id]);

    const handleLocation = async () => {
        const userBusinessDetails = await user.user_business.fetch();

        const location = userBusinessDetails.find(userBus => userBus?.business.id === id);

        if (location) {
            const foundLoc = await location.business.fetch();
            setLocation(foundLoc);
        }

        setIsLoading(false);
    };

    return {isLoading, location};
};

export default useLocation;
