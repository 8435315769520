import React, {useState} from 'react';
import {useLanguageContext} from '../../../context';
import {EQUAL_PAYMENTS} from '../../../constants';
import TextField from '../TextField';

const SplitBillIntoEqualPayments = ({totalBill, setNumberOfPayment, errors, setErrors}) => {
    const {I18n} = useLanguageContext();
    const [splitPayments, setSplitPayments] = useState(0);

    const onChange = (label, val) => {
        if (label === I18n.number_of_split) setSplitPayments(val);
        setNumberOfPayment(val);
        setErrors({});
    };

    const hanldeSplitPayment = val => {
        setSplitPayments(val);
        setNumberOfPayment(val);
        setErrors({});
    };

    return (
        <div className="width100">
            <div className="flex spaceBetweenCenter totalBillAmountWrapper">
                <p className="fontSize20 marBot0 white fontWeight500">{I18n.total_bill_amount}</p>

                <p className="fontSize20 marBot0 fontWeight500 white">{`Rs. ${totalBill}`}</p>
            </div>
            <p className="fontSize20 marBot5 marTop30 white fontWeight500">{I18n.split_into_equal_payments}</p>

            <p className="fontSize12 marBot10 darkGray fontWeight500">{I18n.dividing_the_cost_or_bill_evenly}</p>

            <div className="flex gap10">
                <div className="width50" style={{display: 'flex', justifyContent: 'space-between'}}>
                    {EQUAL_PAYMENTS.map(item => {
                        return (
                            <div className="equalPayBox marTop5" onClick={() => hanldeSplitPayment(item.value)}>
                                <p className="fontSize16 marBot0 barclaysBlueColor fontWeight600">{item.value}</p>
                            </div>
                        );
                    })}
                </div>
                <div className="width50">
                    <TextField
                        onChange={onChange}
                        error={errors['number_of_split']}
                        label={I18n.number_of_split}
                        placeholder={I18n.number_of_split}
                        value={splitPayments}
                        onlyNumbers={true}
                    />
                </div>
            </div>
        </div>
    );
};

export default SplitBillIntoEqualPayments;
