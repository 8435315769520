import React, {useEffect, useState} from 'react';
import {getCommaSeparatedNames} from '../../../../../../constants';
import Filter from '../../../../../common/Filter';

const displayNameMap = {
    cash: 'Cash',
    card: 'Card',
    all: 'All',
    Succeeded: 'Succeeded',
    in_processing: 'In processing',
    deposits: 'Deposits',
    payout: 'Payout',
    refund: 'Refund',
    failed: 'Failed',
    processing: 'Processing',
    settled: 'Settled',
};

const getDisplayNames = keys => {
    return keys.map(key => displayNameMap[key] || key).join(', ');
};

const FilterItem = ({handleFilter, filterBasedOn, urlFilter, loading}) => {
    const [filterData, setFilterData] = useState({
        amount: {min: null, max: null},
        payment_method: [],
        status: [],
        type: [],
        transaction_status: [],
    });

    const [isFilterApplied, setIsFilterApplied] = useState({
        amount: false,
        payment_method: false,
        status: false,
        type: false,
        transaction_status: false,
    });

    useEffect(() => {
        if (urlFilter === 'deposited') {
            setFilterData(prev => ({...prev, type: ['deposits']}));
            handleFilter({...filterData, type: ['deposits']});
        }
    }, [urlFilter]);

    const handleFilterChange = (filterType, value, label) => {
        setFilterData(prev => {
            if (filterType === 'status' || filterType === 'payment_method' || filterType === 'type' || filterType === 'transaction_status') {
                const isSelected = prev[filterType].includes(value);
                let updatedValues;

                if (value === 'all') {
                    updatedValues = isSelected
                        ? []
                        : [
                              'all',
                              ...(filterType === 'payment_method'
                                  ? ['cash', 'card']
                                  : filterType === 'status'
                                  ? ['succeeded', 'in_processing', 'failed']
                                  : filterType === 'type'
                                  ? ['deposits', 'payout', 'refund']
                                  : ['in_processing', 'settled']),
                          ];
                } else {
                    updatedValues = isSelected ? prev[filterType].filter(item => item !== value) : [...prev[filterType], value];
                    const allOptions =
                        filterType === 'payment_method'
                            ? ['cash', 'card']
                            : filterType === 'status'
                            ? ['succeeded', 'in_processing', 'failed']
                            : filterType === 'type'
                            ? ['deposits', 'payout', 'refund']
                            : ['in_processing', 'settled'];
                    if (allOptions.every(option => updatedValues.includes(option))) {
                        updatedValues = ['all', ...allOptions];
                    }
                }

                return {...prev, [filterType]: updatedValues};
            } else if (filterType === 'amount') {
                return {...prev, amount: {...prev.amount, [label]: value}};
            } else {
                return {...prev, [filterType]: value};
            }
        });
    };

    const onApply = filterType => {
        if (!loading) {
            handleFilter(filterData);
            setIsFilterApplied(prevState => ({
                ...prevState,
                [filterType]: true,
            }));
        }
    };

    const onCancel = filterType => {
        setFilterData(prev => {
            const resetFilterData = {
                ...prev,
                [filterType]: filterType === 'amount' ? {min: null, max: null} : [],
            };

            handleFilter(resetFilterData);
            return resetFilterData;
        });

        setIsFilterApplied(prevState => ({
            ...prevState,
            [filterType]: false,
        }));
    };

    let FILTER_DATA = [
        filterBasedOn.includes('amount') && {
            subTitle: filterData['amount'].min > 0 && filterData['amount'].max > 0 ? `Rs. ${filterData['amount'].min} - Rs. ${filterData['amount'].max}` : null,
            type: 'radioInput',
            ctaDisabled: loading,
            title: 'amount',
            heading: 'more_filter_by_amount',
            onApply: isFilterApplied.amount ? () => onCancel('amount') : () => onApply('amount'),
            buttonTitle: isFilterApplied.amount ? 'Cancel' : 'Apply',
            textInputs: [
                {
                    label: 'Min',
                    type: 'numeric',
                    value: filterData.amount.min ?? '',
                    onChange: (label, value) => handleFilterChange('amount', value, 'min'),
                },
                {
                    label: 'Max',
                    type: 'numeric',
                    value: filterData.amount.max ?? '',
                    onChange: (label, value) => handleFilterChange('amount', value, 'max'),
                },
            ],
        },
        filterBasedOn.includes('payment_method') && {
            subTitle: filterData['payment_method'].includes('all') ? 'All' : getDisplayNames(filterData['payment_method'].filter(item => item !== 'all')),
            title: 'payment_method',
            heading: 'filter_by_method',
            type: 'radioList',
            ctaDisabled: loading,
            onApply: isFilterApplied.payment_method ? () => onCancel('payment_method') : () => onApply('payment_method'),
            buttonTitle: isFilterApplied.payment_method ? 'Cancel' : 'Apply',
            option: [
                {checked: Boolean(filterData['payment_method'].includes('all')), action: () => handleFilterChange('payment_method', 'all'), title: 'all'},
                {checked: Boolean(filterData['payment_method'].includes('cash')), action: () => handleFilterChange('payment_method', 'cash'), title: 'Cash'},
                {checked: Boolean(filterData['payment_method'].includes('card')), action: () => handleFilterChange('payment_method', 'card'), title: 'Card'},
            ],
        },
        filterBasedOn.includes('type') && {
            subTitle: filterData['type'].includes('all') ? 'All' : getDisplayNames(filterData['type'].filter(item => item !== 'all')),
            type: 'radioList',
            title: 'type',
            ctaDisabled: loading,
            heading: 'filter_by_type',
            onApply: isFilterApplied.type ? () => onCancel('type') : () => onApply('type'),
            buttonTitle: isFilterApplied.type ? 'Cancel' : 'Apply',
            option: [
                {checked: Boolean(filterData['type'].includes('all')), action: () => handleFilterChange('type', 'all'), title: 'all'},
                {checked: Boolean(filterData['type'].includes('deposits')), action: () => handleFilterChange('type', 'deposits'), title: 'Deposits'},
                {checked: Boolean(filterData['type'].includes('payout')), action: () => handleFilterChange('type', 'payout'), title: 'Payout'},
                {checked: Boolean(filterData['type'].includes('refund')), action: () => handleFilterChange('type', 'refund'), title: 'Refund'},
            ],
        },
        filterBasedOn.includes('status') && {
            subTitle: filterData['status'].includes('all') ? 'All' : getDisplayNames(filterData['status'].filter(item => item !== 'all')),
            type: 'radioList',
            ctaDisabled: loading,
            title: 'status',
            heading: 'filter_by_status',
            onApply: isFilterApplied.status ? () => onCancel('status') : () => onApply('status'),
            buttonTitle: isFilterApplied.status ? 'Cancel' : 'Apply',
            option: [
                {checked: Boolean(filterData['status'].includes('all')), action: () => handleFilterChange('status', 'all'), title: 'all'},
                {checked: Boolean(filterData['status'].includes('succeeded')), action: () => handleFilterChange('status', 'succeeded'), title: 'Succeeded'},
                {checked: Boolean(filterData['status'].includes('in_processing')), action: () => handleFilterChange('status', 'in_processing'), title: 'In processing'},
                {checked: Boolean(filterData['status'].includes('failed')), action: () => handleFilterChange('status', 'failed'), title: 'Failed'},
            ],
        },
        filterBasedOn.includes('transaction_status') && {
            subTitle: filterData['transaction_status'].includes('all') ? 'All' : getDisplayNames(filterData['transaction_status'].filter(item => item !== 'all')),
            type: 'radioList',
            title: 'status',
            ctaDisabled: loading,
            heading: 'filter_by_transaction_status',
            onApply: isFilterApplied.transaction_status ? () => onCancel('transaction_status') : () => onApply('transaction_status'),
            buttonTitle: isFilterApplied.transaction_status ? 'Cancel' : 'Apply',
            option: [
                {checked: Boolean(filterData['transaction_status'].includes('all')), action: () => handleFilterChange('transaction_status', 'all'), title: 'All'},
                {
                    checked: Boolean(filterData['transaction_status'].includes('in_processing')),
                    action: () => handleFilterChange('transaction_status', 'in_processing'),
                    title: 'In processing',
                },
                {checked: Boolean(filterData['transaction_status'].includes('settled')), action: () => handleFilterChange('transaction_status', 'settled'), title: 'Settled'},
            ],
        },
    ].filter(Boolean);

    return <Filter filters={FILTER_DATA} />;
};

export default FilterItem;
