import {useEffect, useState} from 'react';
import {useParams} from 'react-router-dom';
import {getProductByID} from '../../pos-core/database/helpers';
import {getDealProducts} from '../../pos-core/database/helpers/deal_product';

const useDeal = account => {
    const {id} = useParams();

    const [isLoading, setIsLoading] = useState(id ? true : false);
    const [deal, setDeal] = useState();

    useEffect(() => {
        handleDeal();
    }, [id]);

    const handleDeal = async () => {
        let product = await getProductByID(id);

        if (product) {
            const deal = await product.deal.fetch();
            const dealProducts = await getDealProducts(deal.id);

            const temp = {
                product,
                dealProducts,
            };
            setDeal(temp);
        }

        setIsLoading(false);
    };

    return {isLoading, deal};
};

export default useDeal;
