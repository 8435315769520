import React, {useEffect, useState} from 'react';
import {PosAppModal} from '../V2';
import {useAuthContext, useLanguageContext, useThemeContext} from '../../../context';
import {Col, Row} from 'react-bootstrap';
import {DEVICE_HEIGHT, formatTimeInSec, OSCAR_BENEFICIARY_VERIFICATION} from '../../../constants';
import Otp from '../Otp';
import Icon from '../../../assets/icons';
import {createBeneficiary, sendWalletOTP} from '../../../api';
import {useNavigate} from 'react-router-dom';

const VerifyOtpModal = ({heading, toggle, handleClose, btnTitle, business, current, from, setCurrent}) => {
    const {I18n} = useLanguageContext();
    const {theme} = useThemeContext();
    const {setRenderToast, account} = useAuthContext();

    const [otp, setOtp] = useState('');
    const [seconds, setSeconds] = useState(300);
    const [errors, setErrors] = useState({});
    const [loading, setLoading] = useState(false);
    let navigate = useNavigate();

    const handleDone = async () => {
        setLoading(true);
        try {
            if (otp.length < 4) {
                setErrors({message: 'Please enter verification code to continue'});
                return;
            }

            const beneficiaryPayload = {
                business_id: business.id,
                name: current.data.name,
                email: current.data?.email,
                bank_name: current.data.bank_name,
                account_number: current.data.accountNumber,
                contact: current.data?.phone,
                document_id: current.document_id,
                otp: Number(otp),
            };

            const createdBeneficiary = await createBeneficiary(beneficiaryPayload);

            if (createdBeneficiary.success) {
                setSeconds(300);
                setRenderToast('beneficiary_created');

                if (from === 'beneficiaryPage') {
                    navigate('/beneficiary');
                } else {
                    navigate('/dashboard', {state: {screenName: 'wallet', tab: 'balance'}});
                    localStorage.setItem('showBeneficiaryModal', true);
                }
            }
        } catch (error) {
            console.log('error', error);
            setErrors({
                message: error?.response?.data?.message,
            });
        } finally {
            setLoading(false);
        }
    };

    const resendCode = async () => {
        try {
            if (seconds === 0) {
                const sendOtpPayload = {
                    business_id: business.id,
                    action_type: OSCAR_BENEFICIARY_VERIFICATION,
                };

                const response = await sendWalletOTP(sendOtpPayload);

                setCurrent(prevCurrent => ({
                    ...prevCurrent,
                    document_id: response.document_id,
                }));
                setOtp('');
                setSeconds(300);
            }
        } catch (error) {
            console.log('error', error);
        }
    };

    const handleCloseModal = () => {
        setOtp('');
        setSeconds(300);
        handleClose();
    };

    useEffect(() => {
        setErrors({});
    }, [otp]);

    useEffect(() => {
        const intervalId = setInterval(() => {
            setSeconds(seconds => {
                if (seconds <= 0) {
                    clearInterval(intervalId);
                    return 0;
                }
                return seconds - 1;
            });
        }, 1000);

        return () => clearInterval(intervalId);
    }, [seconds]);

    return (
        <div>
            <PosAppModal
                className="selectCustomerModal"
                toggle={toggle}
                backCta={{
                    name: 'backArrowIcon',
                    fill: theme.barclaysBlue,
                    width: '18',
                    height: '18',
                    viewBox: '0 0 18 18',
                }}
                handleClose={handleCloseModal}
                bottomSaveCta={{
                    title: btnTitle,
                    action: handleDone,
                    saveLoad: loading,
                    disabled: loading,
                }}
                bottomCancelCta={{
                    title: I18n.cancel,
                    action: handleCloseModal,
                }}
                title={I18n.back}>
                <Row className="justify-content-md-center">
                    <Col md={6}>
                        <div
                            style={{
                                height: DEVICE_HEIGHT - 200,
                            }}>
                            <div className="marTop60 otpverifictionConatiner">
                                <p className="fontSize20 fontWeight400" style={{color: theme.white}}>
                                    {heading}
                                </p>
                                <p style={{color: theme.darkGrayTwo}} className="fontSize16 fontWeight400">
                                    {`${I18n.oTP_code_has_been_sent_to} ${account.phone_number}`}
                                </p>
                                <div className="otpverification">
                                    <Otp otp={otp} setOtp={setOtp} numInputs={4} focus={true} />

                                    <p className="inputError" style={{paddingLeft: '10px'}}>
                                        {Object.keys(errors || {}).length > 0 ? errors.message : ''}
                                    </p>
                                </div>

                                <div className="spaceBetweenCenter marTop5" style={{padding: '0 10px'}}>
                                    <p className="textCenter fontSize14 fontWeight400 cursorPointer" onClick={() => resendCode()} style={{color: theme.seperatorTwo}}>
                                        <span>
                                            <Icon name="messageIcon" fill={seconds === 0 ? theme.barclaysBlue : theme.darkGrayTwo} viewBox={'0 0 19 19'} />
                                        </span>
                                        <span
                                            className="marLeft5"
                                            style={{
                                                color: seconds === 0 ? theme.barclaysBlue : theme.darkGrayTwo,
                                            }}>
                                            {I18n.resend_code}
                                        </span>
                                    </p>

                                    <p className="fontSize16 fontWeight400 marTop20" style={{color: theme.white}}>
                                        {formatTimeInSec(seconds)}
                                    </p>
                                </div>
                            </div>
                        </div>
                    </Col>
                </Row>
            </PosAppModal>
        </div>
    );
};

export default VerifyOtpModal;
