import React, {useState} from 'react';
import TextField from '../TextField';
import {useLanguageContext, useThemeContext} from '../../../context';
import Button from '../Button';
import Icon from '../../../assets/icons';

const SplitBillByAmount = ({payments, setPayments, totalBill, errors, setErrors}) => {
    const {I18n} = useLanguageContext();
    const {theme} = useThemeContext();

    const [splitAmount, setSplitAmount] = useState('');
    const [remainingAmount, setRemainingAmount] = useState(totalBill);

    const onChange = (label, val) => {
        if (label === I18n.split_bill_by_amount) setSplitAmount(val);

        setErrors({});
    };

    const handleAddOption = () => {
        const paymentNum = `Payment ${payments?.length + 1}`;
        if (splitAmount <= remainingAmount) {
            let remaining = remainingAmount - splitAmount;
            setPayments(prev => [...prev, {paymentNum, amount: splitAmount}]);
            setSplitAmount('');
            setRemainingAmount(remaining);
        } else {
            setErrors({
                amount: 'Your amount is greater than bill amount or remaining amount',
            });
        }
    };

    const handleDelete = payNum => {
        let totalSplittedAmount = 0;
        const modifiedPayments = payments
            ?.filter(item => item.paymentNum !== payNum)
            .map((item, index) => {
                totalSplittedAmount += Number(item.amount);
                return {...item, paymentNum: `Payment ${index + 1}`};
            });

        const remainingAmount = totalBill - totalSplittedAmount;

        setPayments(modifiedPayments);
        setRemainingAmount(remainingAmount);
    };

    return (
        <div className="width100">
            <div className="flex spaceBetweenCenter totalBillAmountWrapper">
                <p className="fontSize20 marBot0 white fontWeight500">{I18n.total_bill_amount}</p>

                <p className="fontSize20 marBot0 fontWeight500 white">{`Rs. ${totalBill}`}</p>
            </div>
            <p className="fontSize20 marBot5 marTop30 white fontWeight500">{I18n.split_bill_by_amount}</p>

            <p className="fontSize12 marBot10 darkGray fontWeight500">{I18n.dividing_the_cost}</p>

            <div className="flex">
                <div className="width90">
                    <TextField
                        onChange={onChange}
                        error={errors.amount}
                        label={I18n.split_bill_by_amount}
                        placeholder={I18n.split_bill_by_amount}
                        value={splitAmount}
                        type="numeric"
                    />
                </div>
                <div className="width10">
                    <Button
                        className={`addAmountButton borderRadius10`}
                        type="iconButton"
                        icon_name="newIcon"
                        icon_fill={theme.barclaysBlue}
                        icon_width={'20'}
                        icon_height={'20'}
                        icon_viewBox={'0 0 13 13'}
                        handleClick={handleAddOption}
                    />
                </div>
            </div>
            {payments?.length > 0 && (
                <div className="marTop10 width90  splitAmountWrapper">
                    {payments?.map(payment => {
                        return (
                            <div className="flex spaceBetweenCenter splitAmountList">
                                <p className="fontSize14 marBot0  white">{payment.paymentNum}</p>
                                <p className="fontSize14 marBot0  white ">{`Rs. ${payment.amount}`}</p>

                                <span className="cursorPointer">
                                    <Icon name="delete2Icon" fill={theme.red} width="18" height="18" viewBox={'0 0 18 18'} onClick={() => handleDelete(payment.paymentNum)} />
                                </span>
                            </div>
                        );
                    })}
                </div>
            )}
        </div>
    );
};

export default SplitBillByAmount;
