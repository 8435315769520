import {Col, Container, Row} from 'react-bootstrap';
import {Header, Loading, NonIdealScreen, ToastBox} from '../../../../common';
import {useAuthContext, useLanguageContext, useThemeContext} from '../../../../../context';
import {useNavigate} from 'react-router-dom';
import TableHeader from '../../../../common/TableContainer/TableHeader';
import {DEVICE_HEIGHT} from '../../../../../constants';
import List from './List';
import {useSearch} from '../../../../../hooks';
import {useEffect, useState} from 'react';
import usePermission from '../../../../../hooks/usePermission';
import {getBeneficiariesList} from '../../../../../api';

const BeneficiaryList = () => {
    const navigate = useNavigate();
    const {I18n} = useLanguageContext();
    const {theme} = useThemeContext();
    const {business, renderToast, setRenderToast} = useAuthContext();
    const userPermission = usePermission('beneficiary');
    const {text, setText, searchList} = useSearch(handleSearch);

    const [loading, setloading] = useState(false);
    const [beneficiariesList, setBeneficiariesList] = useState([]);
    const [showMessage, setShowMessage] = useState({
        visible: false,
        message: '',
    });

    const handleCreateBeneficiary = () => {
        navigate('/beneficiary/create');
    };

    const onChange = (label, value) => setText(value);

    const getBeneficiaries = async () => {
        setloading(true);
        try {
            const payload = {
                business_id: business.id,
            };
            const resposne = await getBeneficiariesList(payload);
            if (resposne.success) {
                setBeneficiariesList(resposne.data);
            }
        } catch (error) {
            console.log('error', error);
        } finally {
            setloading(false);
        }
    };

    useEffect(() => {
        if (showMessage.visible) {
            const timeoutId = setTimeout(() => {
                setShowMessage({
                    visible: false,
                    message: '',
                });
                setRenderToast('');
            }, 3000);
            return () => clearTimeout(timeoutId);
        }
    }, [showMessage]);

    useEffect(() => {
        if (renderToast === 'beneficiary_created') {
            setShowMessage({
                visible: true,
                message: I18n.beneficiary_created_successfully,
            });
        } else if (renderToast === 'beneficiary_deleted') {
            setShowMessage({
                visible: true,
                message: I18n.beneficiary_deleted_successfully,
            });
        }
    }, [renderToast]);

    useEffect(() => {
        getBeneficiaries();
    }, []);

    function handleSearch(val) {
        const values = val?.toLowerCase();
        return beneficiariesList.filter(
            x => x?.account_title?.toLowerCase().includes(values) || x?.name?.toLowerCase().includes(values) || x?.account_number?.toLowerCase().includes(values),
        );
    }

    const renderList = text ? searchList : beneficiariesList;

    return (
        <>
            {loading ? (
                <Loading />
            ) : beneficiariesList.length ? (
                <div className="pad20" style={{backgroundColor: theme.topBarBG}}>
                    <Header
                        type="search"
                        title={`${I18n.beneficiaries} (${beneficiariesList.length})`}
                        search={{
                            type: 'pos',
                            placeholder: I18n.search_beneficiary,
                            prefix: {
                                name: 'search2Icon',
                                fill: theme.white,
                            },
                            onChange: onChange,
                        }}
                        className={'borderRadiusTopleftTopRigt'}
                        rightCta={
                            userPermission?.canCrud
                                ? {
                                      title: I18n.create_beneficiary,
                                      action: handleCreateBeneficiary,
                                  }
                                : null
                        }
                    />

                    <div className="tableListMainBox borderRadiusBottomleftBottomRigt" fluid style={{background: theme.blackBg, height: DEVICE_HEIGHT - 180}}>
                        <Container fluid>
                            <Row>
                                <Col>
                                    <TableHeader className={`sevenColumn`} style={{borderColor: theme.inputBorder}}>
                                        <div className="BoxWidth justifyStart">
                                            <p className="marBot0 fontWeight500 fontSize16 OneLineTruncate" style={{color: theme.text}}>
                                                {I18n.account_title}
                                            </p>
                                        </div>
                                        <div className="BoxWidth justifyCenter">
                                            <p className="fontSize16 fontWeight500 OneLineTruncate" style={{color: theme.white}}>
                                                {I18n.bank}
                                            </p>
                                        </div>
                                        <div className="BoxWidth justifyCenter">
                                            <p className="fontSize16 fontWeight500 OneLineTruncate" style={{color: theme.white}}>
                                                {I18n.account_number}
                                            </p>
                                        </div>
                                        <div className="BoxWidth justifyCenter">
                                            <p className="fontSize16 fontWeight500 OneLineTruncate" style={{color: theme.white}}>
                                                {I18n.nick_name}
                                            </p>
                                        </div>
                                        <div className="BoxWidth justifyCenter">
                                            <p className="fontSize16 fontWeight500 OneLineTruncate" style={{color: theme.white}}>
                                                {I18n.phone}
                                            </p>
                                        </div>
                                        <div className="BoxWidth justifyCenter">
                                            <p className="fontSize16 fontWeight500 OneLineTruncate" style={{color: theme.white}}>
                                                {I18n.email}
                                            </p>
                                        </div>

                                        {userPermission?.canCrud && (
                                            <div className="BoxWidth justifyCenter">
                                                <p className="fontSize16 fontWeight500 OneLineTruncate" style={{color: theme.white}}>
                                                    {I18n.action}
                                                </p>
                                            </div>
                                        )}
                                    </TableHeader>
                                    <div style={{height: DEVICE_HEIGHT - 280}} className="tableListMainBox">
                                        {renderList?.length === 0 ? (
                                            <NonIdealScreen
                                                heading={text ? I18n.search : I18n.beneficiaries}
                                                subHeading={text ? I18n.we_couldnt_find_any_results_for_your_search : I18n.nothing_found_create_new_beneficiary}
                                                name={text ? 'seacrhNotFoundIcon' : 'bankIcon'}
                                                fill={theme.white}
                                                isMultiStep={false}
                                                tablenonIdeal={true}
                                            />
                                        ) : (
                                            renderList?.map((val, index) => (
                                                <List key={index} beneficiary={val} userPermission={userPermission} business={business} fetchBeneficiaries={getBeneficiaries} />
                                            ))
                                        )}
                                    </div>
                                    <ToastBox
                                        className="addSuccessfullyToast"
                                        ToastVisiable={showMessage.visible}
                                        bodyPara={showMessage.message}
                                        setShowMessage={setShowMessage}
                                        showIcon={true}
                                    />
                                </Col>
                            </Row>
                        </Container>
                    </div>
                </div>
            ) : (
                <NonIdealScreen
                    heading={I18n.beneficiaries}
                    subHeading={I18n.create_new_beneficiary_and_make_payouts}
                    name="bankIcon"
                    height="100"
                    width="100"
                    viewBox="0 0 100 100"
                    fill={theme.white}
                    secondaryCta={
                        userPermission?.canCrud
                            ? {
                                  title: I18n.create_beneficiary,
                                  action: handleCreateBeneficiary,
                              }
                            : null
                    }
                    isMultiStep={false}
                />
            )}
        </>
    );
};

export default BeneficiaryList;
