import React from 'react';
import {Spinner} from 'react-bootstrap';

const PaginationLoader = ({customeHeight}) => {
    return (
        <div className="paginationLoader" style={{height: customeHeight ? customeHeight : ''}}>
            <Spinner animation="border" variant="brightGreen" className="loaderCircle marBot25" />
        </div>
    );
};

export default PaginationLoader;
