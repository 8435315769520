import {database} from '..';
import {BUSINESS_SCHEMA, CUSTOMER_SCHEMA, ORDER_SCHEMA, PAYMENT_SCHEMA, USER_SCEHMA} from '../schema';
import {Q} from '@nozbe/watermelondb';

const order = database.collections.get(ORDER_SCHEMA);

export const observeActiveOrder = () =>
    order
        .query(
            Q.experimentalJoinTables([USER_SCEHMA]),
            Q.on(BUSINESS_SCHEMA, 'id', localStorage.getItem('business_id')),
            Q.and(Q.where('status', 'active'), Q.on(USER_SCEHMA, 'id', localStorage.getItem('session'))),
        )
        .observeWithColumns(['redeemed_points', 'status']);

export const observeGetOrderById = id =>
    order.query(Q.on(BUSINESS_SCHEMA, 'id', localStorage.getItem('business_id')), Q.where('number', id)).observeWithColumns(['redeemed_points', 'status']);

export const fetchOrderByNum = num => order.query(Q.on(BUSINESS_SCHEMA, 'id', localStorage.getItem('business_id')), Q.where('number', num)).fetch();

export const observeCockpitActiveOrders = business_id =>
    order
        .query(
            Q.on(BUSINESS_SCHEMA, 'id', business_id),
            Q.and(
                Q.where('archive', Q.notEq(true)),
                Q.where('status', Q.notEq('active')),
                Q.where('status', Q.notEq('complete')),
                Q.where('status', Q.notEq('void')),
                Q.where('status', Q.notEq('hold')),
            ),
            Q.sortBy('started_at', Q.desc),
        )
        .observeWithColumns(['status']);

export const fetchCompletedOrders = async (start, end, locationId) =>
    await order
        .query(
            Q.on(BUSINESS_SCHEMA, 'id', locationId || localStorage.getItem('business_id')),
            Q.where('status', 'complete'),
            Q.where('completed_at', Q.between(Number(start), Number(end))),
        )
        .fetch();
export const fetchOrdersByDate = async (start, end, locationId) =>
    await order.query(Q.on(BUSINESS_SCHEMA, 'id', locationId || localStorage.getItem('business_id')), Q.where('completed_at', Q.between(Number(start), Number(end)))).fetch();
export const fetchOrdersByCustomer = async id => await order.query(Q.on(CUSTOMER_SCHEMA, [Q.where('id', id)]), Q.where('status', 'complete')).fetch();

export const observeCompleteOrders = () =>
    order
        .query(Q.on(BUSINESS_SCHEMA, 'id', localStorage.getItem('business_id')), Q.sortBy('started_at', Q.desc))
        .observeWithColumns(['type', 'floor_plan', 'dine_in_table', 'no_of_guests']);

export const observeHeldOrderCount = business_id => handleHeldOrders(business_id).fetchCount();

export const observeHeldOrders = business_id => handleHeldOrders(business_id).observe();

const handleHeldOrders = business_id => order.query(Q.on(BUSINESS_SCHEMA, 'id', business_id), Q.where('status', 'hold'), Q.where('archive', Q.notEq(true)));

export const searchOrders = async (searchTerm, business) =>
    order
        .query(
            Q.experimentalJoinTables([CUSTOMER_SCHEMA]),
            Q.on(BUSINESS_SCHEMA, 'id', business.id),
            Q.or(
                Q.on(CUSTOMER_SCHEMA, Q.where('name', Q.like(`%${Q.sanitizeLikeString(searchTerm)}%`))),
                Q.where('number', Q.like(`%${Q.sanitizeLikeString(searchTerm)}%`)),
                Q.where('total', Q.like(`%${Q.sanitizeLikeString(searchTerm)}%`)),
            ),
        )
        .fetch();

export const filterOrders = async (selectedFilter, searchTerm, business) => {
    let filterCondition;

    switch (selectedFilter) {
        case 'all_orders':
            filterCondition = Q.where('archive', Q.notEq(true));
            break;
        case 'void_orders':
            filterCondition = Q.where('void', Q.eq(true));
            break;
        case 'paid_orders':
            filterCondition = Q.where('status', 'complete');
            break;
        case 'complete_order':
            filterCondition = Q.where('status', 'complete');
            break;
        case 'cash_payment':
            filterCondition = Q.on(PAYMENT_SCHEMA, Q.where('payment_method', 'cash'));
            break;
        case 'card_payment':
            filterCondition = Q.on(PAYMENT_SCHEMA, Q.where('payment_method', 'card'));
            break;
        case 'credit_payment':
            filterCondition = Q.on(PAYMENT_SCHEMA, Q.where('payment_method', 'credit'));
            break;
        case 'saved_order':
            filterCondition = Q.where('status', 'hold');
            break;
        case 'dine_in':
            filterCondition = Q.where('type', 'dinein');
            break;
        case 'take_away':
            filterCondition = Q.where('type', 'takeaway');
            break;
        case 'delivery':
            filterCondition = Q.where('type', 'delivery');
            break;
        default:
            filterCondition = Q.where('archive', Q.notEq(true));
    }

    const filteredData = order.query(
        Q.experimentalJoinTables([PAYMENT_SCHEMA, CUSTOMER_SCHEMA]),
        Q.on(BUSINESS_SCHEMA, 'id', business.id),
        filterCondition,
        Q.or(Q.where('number', Q.like(`%${searchTerm}%`)), Q.on(CUSTOMER_SCHEMA, Q.where('name', Q.like(`%${Q.sanitizeLikeString(searchTerm)}%`)))),
    );

    const data = await filteredData.fetch();
    return {data};
};

export const getFilteredOrders = async (business_id, payment_method, status, date, name_sort, price_sort) => {
    let filterQuery = [];

    if (date?.start || date?.end) filterQuery.push(Q.where('started_at', Q.between(Number(date?.start), Number(date?.end))));
    if (payment_method != 'all') filterQuery.push(Q.where('payment_method', payment_method));

    if (name_sort) filterQuery.push(Q.sortBy('number', name_sort == 'asc' ? Q.asc : Q.desc));
    if (price_sort) filterQuery.push(Q.sortBy('total', price_sort == 'asc' ? Q.asc : Q.desc));

    if (status != 'all') filterQuery.push(Q.where('status', status));

    if (!name_sort && !price_sort) filterQuery.push(Q.sortBy('started_at', Q.desc));

    return await order.query(Q.on(BUSINESS_SCHEMA, 'id', business_id), ...filterQuery).fetch();
};
