import React from 'react';
import {useLanguageContext} from '../../../context';

const DashboardListNonIdeal = ({title, chipColor = '#555968', textColor = 'nonIdealText'}) => {
    const {I18n} = useLanguageContext();
    const leftSideStyle = {
        backgroundColor: chipColor,
    };

    return (
        <div className="listBoxNonIdeal">
            <div className="listBoxInneNonIdeal">
                <div className={'listLeftSide'}>
                    <p style={leftSideStyle} className="marBot5"></p>
                    <p style={leftSideStyle} className="marBot0"></p>
                </div>
                <div className="listRightSide">
                    <p style={leftSideStyle} className="marBot0"></p>
                </div>
            </div>
            <div className="listBoxInneNonIdeal">
                <div className="listLeftSide">
                    <p style={leftSideStyle} className="marBot5"></p>
                    <p style={leftSideStyle} className="marBot0"></p>
                </div>
                <div className="listRightSide">
                    <p style={leftSideStyle} className="marBot0"></p>
                </div>
            </div>

            <div className="listBoxInneNonIdeal">
                <div className="listLeftSide">
                    <p style={leftSideStyle} className="marBot5"></p>
                    <p style={leftSideStyle} className="marBot0"></p>
                </div>
                <div className="listRightSide">
                    <p style={leftSideStyle} className="marBot0"></p>
                </div>
            </div>

            <div className="listBoxInneNonIdeal">
                <div className="listLeftSide">
                    <p style={leftSideStyle} className="marBot5"></p>
                    <p style={leftSideStyle} className="marBot0"></p>
                </div>
                <div className="listRightSide">
                    <p style={leftSideStyle} className="marBot0"></p>
                </div>
            </div>
            <p className={`fontWeight400 fontSize14 marBot40 width45 textLeft marBot0 marTop10 ${textColor}`}>{I18n[title]}</p>
        </div>
    );
};

export default DashboardListNonIdeal;
