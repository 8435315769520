import React from 'react';
import {useLanguageContext, useThemeContext} from '../../../context';
import TableBody from '../TableContainer/TableBody';

const List = ({data, index}) => {
    const {theme} = useThemeContext();
    const {I18n} = useLanguageContext();
    const {tax_name, tax_rate, applied_items, taxable_items_sales_amount, tax_collected_amount} = data || {};

    return (
        <TableBody className={`fiveColumn cursorPointer`} style={{borderColor: theme.inputBorder}}>
            <div className="BoxWidth justifyStart">
                <p className="fontSize14 OneLineTruncate" style={{color: theme.white}}>
                    {tax_name}
                </p>
            </div>
            <div className="BoxWidth justifyCenter">
                <p className="fontSize14 OneLineTruncate" style={{color: theme.white}}>
                    {tax_rate}%
                </p>
            </div>
            <div className="BoxWidth justifyCenter">
                <p className="fontSize14 OneLineTruncate" style={{color: theme.white}}>
                    {applied_items}
                </p>
            </div>
            <div className="BoxWidth justifyCenter">
                <p className="fontSize14 OneLineTruncate" style={{color: theme.white}}>
                    Rs. {taxable_items_sales_amount}
                </p>
            </div>
            <div className="BoxWidth justifyEnd">
                <p className="fontSize14 OneLineTruncate" style={{color: theme.white}}>
                    Rs. {tax_collected_amount}
                </p>
            </div>
        </TableBody>
    );
};

export default List;
