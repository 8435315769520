import {Model, Q} from '@nozbe/watermelondb';
import {field, text, relation, children, writer, date, lazy} from '@nozbe/watermelondb/decorators';
import {RIDER_SCHEMA} from '../schema';

export default class Rider extends Model {
    static table = RIDER_SCHEMA;

    static associations = {
        business: {type: 'belongs_to', key: 'business_id'},
        order: {type: 'has_many', foreignKey: 'rider_id'},
    };

    @text('name') name;
    @text('phone_number') phone_number;
    @text('email') email;
    @text('province') province;
    @text('country') country;
    @text('city') city;
    @text('address') address;
    @field('archive') archive;
    @field('draft') draft;
    @date('dob') dob;
    @date('created_at') created_at;

    @children('order') order;

    @relation('business', 'business_id') business;

    @lazy getCompletedOrders = this.order.extend(Q.where('status', 'complete'));

    @writer async updateDetails(details) {
        return await this.update(rider => {
            rider.name = details.name;
            rider.phone_number = details.phone;
            rider.email = details.email;
            rider.dob = details.dob;
            rider.province = details.province;
            rider.city = details.city;
            rider.country = details.country;
            rider.address = details.address;
            rider.draft = details.draft;
        });
    }

    @writer async archiveRider(status = true) {
        return await this.update(rider => {
            rider.archive = status;
        });
    }

    @writer async addRidertoDraft(status = true) {
        return await this.update(rider => {
            rider.draft = status;
        });
    }
}
