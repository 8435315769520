import {Container, Row, Col} from 'react-bootstrap';
import {useAuthContext, useLanguageContext, useThemeContext} from '../../../context';
import List from './List';
import NonIdealScreen from '../NonIdeal';
import {useNavigate} from 'react-router-dom';
import {PosAppModal} from '../V2';
import SearchBox from '../SearchBox';
import {useSearch} from '../../../hooks';
import usePermission from '../../../hooks/usePermission';
import {DEVICE_HEIGHT, validateBeneficiaryModal} from '../../../constants';
import {useEffect, useState} from 'react';
import {getBeneficiariesList} from '../../../api';
import Loading from '../Loading';

const BeneficiaryModal = ({toggle, setToggle, selectedBeneficiaries, setSelectedBeneficiaries, from}) => {
    const {handleBeneficiaries, business} = useAuthContext();
    const {I18n} = useLanguageContext();
    const {theme} = useThemeContext();

    const {text, setText, searchList} = useSearch(handleSearch);
    const userPermission = usePermission('beneficiary');
    const navigate = useNavigate();

    const [errors, setErrors] = useState({});
    const [beneficiaries, setBeneficiaries] = useState([]);
    const [loading, setLoading] = useState(false);

    const handleClose = event => {
        setToggle(false);
        setText();
    };

    const handleCreate = () => {
        navigate('/beneficiary/create', {state: {screenName: 'wallet'}});
    };

    const onChange = (event, key) => {
        let value = key || event?.target?.value || '';
        setText(value);
    };

    function handleSearch(val) {
        return beneficiaries?.filter(x => x?.name?.toLowerCase().includes(val?.toLowerCase()) || x?.account_number?.toLowerCase().includes(val));
    }

    const handleSave = () => {
        const formErrors = validateBeneficiaryModal(Object.values(selectedBeneficiaries || {}));
        console.log('formErrors', formErrors);
        setErrors(formErrors);

        if (Object.keys(formErrors).length > 0) return;

        handleBeneficiaries(selectedBeneficiaries);

        if (from === 'wallet') {
            navigate('/payout', {state: {screenName: 'wallet'}});
        }
        setToggle(false);
    };

    const handleback = () => {
        setToggle(false);
        setText();
    };

    const getBeneficiaries = async () => {
        try {
            setLoading(true);
            const payload = {
                business_id: business.id,
            };
            const resposne = await getBeneficiariesList(payload);
            if (resposne.success) {
                setBeneficiaries(resposne.data);
            }
        } catch (error) {
            console.log('error', error);
        } finally {
            setLoading(false);
        }
    };

    useEffect(() => {
        if (toggle && beneficiaries.length === 0) {
            getBeneficiaries();
        }
    }, [toggle, beneficiaries]);

    useEffect(() => {
        if (Object.keys(errors).length > 0) {
            const timeoutId = setTimeout(() => {
                setErrors({});
            }, 3000);
            return () => clearTimeout(timeoutId);
        }
    }, [errors]);

    const renderList = text ? searchList : beneficiaries;
    return (
        <PosAppModal
            className="selectCustomerModal"
            toggle={toggle}
            backCta={{
                name: 'backArrowIcon',
                fill: theme.barclaysBlue,
                width: '18',
                height: '18',
                viewBox: '0 0 18 18',
                action: handleback,
            }}
            handleClose={handleClose}
            bottomSaveCta={
                beneficiaries?.length > 0
                    ? {
                          title: I18n.confirm,
                          action: () => handleSave(),
                      }
                    : null
            }
            bottomCancelCta={
                beneficiaries?.length > 0
                    ? {
                          title: I18n.cancel,
                          action: () => handleClose(),
                      }
                    : null
            }
            rightCta={
                userPermission?.canCrud &&
                beneficiaries?.length && {
                    title: I18n.create_beneficiary,
                    action: handleCreate,
                    iconName: 'add2Icon',
                    fill: theme.barclaysBlue,
                    width: '18',
                    height: '18',
                    viewBox: '0 0 18 18',
                }
            }
            toastBox={
                Object?.values(errors).length > 0
                    ? {
                          errorToast: true,
                          message: errors.message,
                      }
                    : null
            }
            title={I18n.select_beneficiaries_for_transfer}>
            <>
                {!beneficiaries?.length == 0 && (
                    <SearchBox
                        type="pos"
                        placeholder={I18n.search_beneficiary}
                        prefix={{
                            name: 'search2Icon',
                            fill: theme.white,
                        }}
                        onChange={onChange}
                    />
                )}

                <Container fluid>
                    <Row className="justify-content-md-center">
                        <Col md={12}>
                            {loading ? (
                                <Loading />
                            ) : renderList?.length > 0 ? (
                                <section className="addScreenMainBox">
                                    <Row className="justify-content-md-center">
                                        <Col md={12}>
                                            <div
                                                className="selectCustomerListBox"
                                                style={{
                                                    height: DEVICE_HEIGHT - 270,
                                                }}>
                                                {renderList?.map((val, index) => (
                                                    <List
                                                        key={index}
                                                        beneficiary={val}
                                                        selectedBeneficiaries={selectedBeneficiaries}
                                                        setSelectedBeneficiaries={setSelectedBeneficiaries}
                                                        from={from}
                                                        setErrors={setErrors}
                                                    />
                                                ))}
                                            </div>
                                        </Col>
                                    </Row>
                                </section>
                            ) : (
                                <NonIdealScreen
                                    subHeading={text ? I18n.we_couldnt_find_any_results_for_your_search : I18n.create_new_beneficiary_and_make_payouts}
                                    name={text ? 'seacrhNotFoundIcon' : 'bankIcon'}
                                    fill={theme.white}
                                    viewBox={text ? '0 0 18 18' : '0 0 100 100'}
                                    secondaryCta={
                                        text
                                            ? null
                                            : userPermission?.canCrud
                                            ? {
                                                  title: I18n.create_beneficiary,
                                                  action: handleCreate,
                                              }
                                            : null
                                    }
                                    isMultiStep={false}
                                />
                            )}
                        </Col>
                    </Row>
                </Container>
            </>
        </PosAppModal>
    );
};

export default BeneficiaryModal;
