import React, {useState} from 'react';
import {useLanguageContext, useThemeContext} from '../../../context';
import ReportsBoxHeader from '../Header/ReportsBoxHeader';
import {DEVICE_HEIGHT} from '../../../constants';
import NonIdealScreen from '../NonIdeal';
import SalesSummaryList from '../SalesSummaryList';
import Loading from '../Loading';

const SalesSummary = ({data, handleExportCSV, loading}) => {
    const {I18n} = useLanguageContext();
    const {theme} = useThemeContext();

    const handleExport = async () => {
        await handleExportCSV();
    };

    const isNonIdeal = Object?.values(data || {})?.some(value => value > 0);

    return loading ? (
        <div className="reportsWrapper">
            <div className="reportsWrapperInner">
                <Loading />
            </div>
        </div>
    ) : isNonIdeal ? (
        <div className="reportsWrapper">
            <div className="reportsWrapperInner">
                <ReportsBoxHeader
                    title="sales_summary"
                    primaryCta={{
                        title: 'export_csv',
                        action: handleExport,
                    }}
                />

                <p className="fontSize14 fontWeight600 marBot10 marLeft10" style={{color: theme.white}}>
                    {I18n.sales}
                </p>
                <div className="tableListMainBox" style={{height: DEVICE_HEIGHT - 465, overflow: 'auto'}}>
                    {['sales_summary', 'taxes_summary', 'discounts_summary', 'net_sales_summary']?.map(summaryKey => (
                        <SalesSummaryList key={summaryKey} data={{title: summaryKey, value: data[summaryKey]}} />
                    ))}
                </div>
            </div>
        </div>
    ) : (
        <div className="onlineNonIdeal reportDashboardBox">
            <NonIdealScreen
                heading={I18n.sales_summary}
                subHeading={I18n.no_activity_zero_transactions_recorded}
                name="salesSummaryIcon"
                fill={theme.white}
                isMultiStep={false}
                viewBox="0 0 100 100"
            />
        </div>
    );
};

export default SalesSummary;
