import React from 'react';
import {useLanguageContext, useThemeContext} from '../../../context';
import ItemsList from './ItemsList';
import DashboardDataBox from '../DashboardDataBox';
import TableHeader from '../TableContainer/TableHeader';
import DashboardListNonIdeal from '../NonIdeal/DashboardListNonIdeal';

const LowStockItems = ({handleNavigate, data}) => {
    const {theme} = useThemeContext();
    const {I18n} = useLanguageContext();
    return (
        <DashboardDataBox disableViewButton={!data?.length > 0} title="low_stock_items" ViewMoreBtn={true} handleView={handleNavigate}>
            {data?.length > 0 ? (
                <>
                    <TableHeader className={`fourColumn`} style={{borderColor: theme.inputBorder}}>
                        <div className="BoxWidth justifyStart">
                            <p className="fontSize16 OneLineTruncate" style={{color: theme.white}}>
                                {I18n.item_name}
                            </p>
                        </div>

                        <div className="BoxWidth justifyCenter">
                            <p className="fontSize16 OneLineTruncate" style={{color: theme.white}}>
                                {I18n.current_stock}
                            </p>
                        </div>

                        <div className="BoxWidth justifyCenter">
                            <p className="fontSize16 OneLineTruncate" style={{color: theme.white}}>
                                {I18n.low_stock_level}
                            </p>
                        </div>
                        <div className="BoxWidth justifyEnd">
                            <p className="fontSize16 OneLineTruncate" style={{color: theme.white}}>
                                {I18n.stock_needed}
                            </p>
                        </div>
                    </TableHeader>

                    {data?.map((item, index) => {
                        return <ItemsList data={item} index={index} />;
                    })}
                </>
            ) : (
                <DashboardListNonIdeal title="youve_not_done_any_transactions_yet" />
            )}
        </DashboardDataBox>
    );
};

export default LowStockItems;
