import {Container, Row, Col} from 'react-bootstrap';
import {useLanguageContext, useThemeContext} from '../../../context';
import List from './List';
import withObservables from '@nozbe/with-observables';
import NonIdealScreen from '../NonIdeal';
import {useNavigate} from 'react-router-dom';
import {PosAppModal} from '../V2';
import {useSearch} from '../../../hooks';
import SearchBox from '../SearchBox';
import {DEVICE_HEIGHT} from '../../../constants';
import useFocus from '../../../hooks/useFocus';
import {useEffect} from 'react';
import {observeActiveCategories} from '../../../pos-core/database/helpers';
import usePermission from '../../../hooks/usePermission';

const CategoryModal = ({toggle, setToggle, categories, handleSelect, data}) => {
    const navigate = useNavigate();
    const {I18n} = useLanguageContext();
    const {theme} = useThemeContext();

    const {text, setText, searchList} = useSearch(handleSearch);
    const {textBoxRef, handleFocus} = useFocus(true);

    const userPermission = usePermission('category');

    useEffect(() => {
        handleFocus();
    }, [toggle]);

    const handleClose = event => {
        setToggle(false);
    };

    function handleSearch(val) {
        return categories?.filter(x => x.name.toLowerCase().includes(val.toLowerCase()));
    }
    const handleCreateCategory = () => {
        navigate('/category/create', {state: {screenName: 'item_creation'}});
    };

    const onChange = (event, key) => {
        let value = key || event?.target?.value || '';

        setText(value);
    };

    const renderList = text ? searchList : categories;

    return (
        <PosAppModal
            className="selectCustomerModal"
            toggle={toggle}
            backCta={{
                name: 'backArrowIcon',
                fill: theme.barclaysBlue,
                width: '18',
                height: '18',
                viewBox: '0 0 18 18',
            }}
            handleClose={handleClose}
            rightCta={
                userPermission?.canCrud &&
                categories?.length && {
                    title: I18n.create_category,
                    action: handleCreateCategory,
                    iconName: 'add2Icon',
                    fill: theme.barclaysBlue,
                    width: '18',
                    height: '18',
                    viewBox: '0 0 18 18',
                }
            }
            hideFooter={true}
            title={I18n.back}>
            {!categories.length == 0 && (
                <SearchBox
                    type="pos"
                    placeholder={I18n.search_category_by_name}
                    prefix={{
                        name: 'search2Icon',
                        fill: theme.white,
                    }}
                    onChange={onChange}
                    inputRef={textBoxRef}
                />
            )}
            <Container fluid>
                <Row className="justify-content-md-center">
                    <Col>
                        {renderList.length ? (
                            <div
                                className="selectCustomerListBox"
                                style={{
                                    height: DEVICE_HEIGHT - 230,
                                }}>
                                {renderList.map((val, index) => (
                                    <List key={index} val={val} data={data} handleClick={handleSelect} />
                                ))}
                            </div>
                        ) : (
                            <NonIdealScreen
                                heading={text ? I18n.search : I18n.categories}
                                subHeading={text ? I18n.we_couldnt_find_any_results_for_your_search : I18n.create_categories_and_add_them_into_items}
                                name={text ? 'seacrhNotFoundIcon' : 'categoryIcon'}
                                fill={theme.white}
                                primaryCta={
                                    text
                                        ? null
                                        : userPermission?.canCrud
                                        ? {
                                              title: I18n.create_categories,
                                              action: handleCreateCategory,
                                          }
                                        : null
                                }
                                isMultiStep={false}
                            />
                        )}
                    </Col>
                </Row>
            </Container>
        </PosAppModal>
    );
};

const enhance = withObservables([], () => ({
    categories: observeActiveCategories(),
}));

export default enhance(CategoryModal);
