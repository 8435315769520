import {useEffect, useState} from 'react';
import Icon from '../../../assets/icons';
import {firstLetterCaptilize, handleOrderStatus} from '../../../constants';
import {useLanguageContext, useThemeContext} from '../../../context';

const StatusBox = ({status, labelChip = false}) => {
    const {theme} = useThemeContext();
    const {I18n} = useLanguageContext();
    const [orderStatus, setOrderStatus] = useState();
    useEffect(() => {
        let data = handleOrderStatus(status, theme);
        setOrderStatus(data);
    }, []);

    return (
        <div
            className="textWithTagInner"
            style={{
                background: orderStatus?.backgroundColor,
                borderColor: orderStatus?.borderColor,
                color: orderStatus?.titleColor || theme.white,
                ...(orderStatus?.icon && {height: '25px'}),
                ...(labelChip && {padding: '2px 10px'}),
            }}>
            {orderStatus?.icon ? (
                <Icon
                    name={orderStatus?.icon}
                    viewBox={orderStatus.viewBox || '0 0 13 10'}
                    width={orderStatus.width || '10'}
                    height={orderStatus.width || '7'}
                    fill={orderStatus?.iconColor || theme.brightGreen}
                />
            ) : null}
            <p className="fontSize12 fontWeight500  OneLineTruncate">{firstLetterCaptilize(orderStatus?.title) || '--'}</p>
            {orderStatus?.prefix ? (
                <Icon
                    name={orderStatus?.prefix?.iconName}
                    viewBox={orderStatus?.prefix.viewBox || '0 0 18 18'}
                    width={orderStatus?.prefix.width || '18'}
                    height={orderStatus?.prefix.width || '18'}
                    fill={orderStatus?.prefix?.iconColor || theme.brightGreen}
                />
            ) : null}
        </div>
    );
};

export default StatusBox;
