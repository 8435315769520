import React from 'react';
import {Doughnut} from 'react-chartjs-2';
import {Chart as ChartJS, ArcElement, Tooltip} from 'chart.js';
import TableHeader from '../TableContainer/TableHeader';
import {useLanguageContext, useThemeContext} from '../../../context';
import ListItem from './ListItem';

ChartJS.register(ArcElement, Tooltip);

const DonutChart = ({data}) => {
    const {I18n} = useLanguageContext();
    const {theme} = useThemeContext();
    const chartData = {
        labels: data.map(item => item._id),
        datasets: [
            {
                data: data.map(item => item.total_sales),
                backgroundColor: ['#FF6384', '#36A2EB', '#FFCE56', '#4BC0C0', '#9966FF', '#FF9F40'],
                borderColor: '#fff',
                borderWidth: 0,
            },
        ],
    };

    const options = {
        responsive: true,
        plugins: {
            tooltip: {
                callbacks: {
                    label: context => {
                        const label = context.label || '';
                        const value = context.formattedValue;
                        // const percentage = `${context.parsed}%`;
                        return `${label}: ${value}`;
                    },
                },
            },
            legend: {
                display: false,
            },
        },
        cutout: '70%',
    };

    const maxPercentageObj = data.reduce((maxObj, currentObj) => {
        return currentObj.percentage > maxObj.percentage ? currentObj : maxObj;
    }, data[0]);

    return data?.length > 0 ? (
        <div className="chart">
            <div className="chartDonutBox">
                <Doughnut data={chartData} options={options} />
                <p className="chartText">
                    <span className="fontSize14 fontWeight500 white">{maxPercentageObj?._id}</span>
                    <span className="fontSize32 fontWeight600 white">{Number(maxPercentageObj?.percentage)?.toFixed(2)}%</span>
                </p>
            </div>
            <div className="donutTableBody">
                <TableHeader className={`fourColumn`} style={{borderColor: theme.inputBorder}}>
                    <div className="BoxWidth justifyCenter">
                        <p className="fontSize12 fontWeight600 OneLineTruncate" style={{color: theme.white}}></p>
                    </div>
                    <div className="BoxWidth justifyStart">
                        <p className="fontSize12 fontWeight600 OneLineTruncate" style={{color: theme.white}}>
                            {I18n.category}
                        </p>
                    </div>
                    <div className="BoxWidth justifyCenter">
                        <p className="fontSize12 fontWeight600 OneLineTruncate" style={{color: theme.white}}>
                            {I18n.amount}
                        </p>
                    </div>
                    <div className="BoxWidth justifyEnd">
                        <p className="fontSize12 fontWeight600 OneLineTruncate" style={{color: theme.white}}>
                            {I18n.percentage}
                        </p>
                    </div>
                </TableHeader>
                <div className='donutTableBodyInner'>

                {data?.map((item, index) => (
                    <ListItem item={item} key={index} />
                ))}
                </div>
            </div>
        </div>
    ) : null;
};

export default DonutChart;
