import {database} from '..';
import {uuid} from '../../constants';
import {Q} from '@nozbe/watermelondb';
import {PRODUCT_BUSINESS_SCHEMA} from '../schema';

const productBusiness = database.collections.get(PRODUCT_BUSINESS_SCHEMA);

export const validateProdBusiness = (prodId, bussId) =>
    productBusiness.query(Q.where('product_id', prodId), Q.where('business_id', bussId), Q.where('archive', Q.notEq(true))).fetch();

const prepareInsertion = (locations, product, quantity) => {
    return locations.map(business => {
        return productBusiness.prepareCreate(prodBus => {
            prodBus._raw.id = uuid();
            prodBus.product.set(product);
            prodBus.business.set(business);
            prodBus.quantity = quantity;
        });
    });
};

export const bulkCreateProductBusiness = async ({locations, product, quantity}) => {
    return await database.write(async () => {
        const allRecords = await prepareInsertion(locations, product, quantity);
        await database.batch(...allRecords);
        return allRecords;
    });
};

const prepareDeletion = productBusiness => {
    return productBusiness.map(prodBus => {
        return prodBus.prepareMarkAsDeleted();
    });
};

export const bulkDeleteProductBusiness = async productBusiness => {
    await database.write(async () => {
        const allRecords = await prepareDeletion(productBusiness);
        await database.batch(...allRecords);
        return allRecords;
    });
};
