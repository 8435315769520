import {DEVICE_HEIGHT} from '../../../constants';

const NonIdealContainer = ({listNonideal, children, disabled, tablenonIdeal, customHeight}) =>
    disabled ? (
        children
    ) : (
        <div
            className={`${listNonideal ? 'listNonIdealBox' : tablenonIdeal ? 'tableNonIdealBox' : 'allScreenNonIdealBox'}`}
            style={{height: customHeight ? DEVICE_HEIGHT - customHeight : null}}>
            {children}
        </div>
    );

export default NonIdealContainer;
