import {useAuthContext, useLanguageContext, useThemeContext} from '../../../../../context';
import {Header, NonIdealScreen} from '../../../../common';
import withObservables from '@nozbe/with-observables';
import {DEVICE_HEIGHT, RESTAURANT} from '../../../../../constants';
import {Col, Container, Row} from 'react-bootstrap';
import TableHeader from '../../../../common/TableContainer/TableHeader';
import List from './List';
import {useSearch} from '../../../../../hooks';
import {filterOrders, observeCompleteOrders} from '../../../../../pos-core/database/helpers';
import useFilter from '../../../../../hooks/useFilter';
import {useNavigate} from 'react-router-dom';
import usePermission from '../../../../../hooks/usePermission';
import {useState} from 'react';

const OrderHistoryList = ({orders}) => {
    const {theme} = useThemeContext();
    const {I18n} = useLanguageContext();
    const {business} = useAuthContext();

    const {text, setText, searchList} = useSearch(handleSearch);
    const [filteredList, setFilteredList] = useState([]);
    const [primaryOption, setPrimaryOption] = useState('');
    const [secondaryOption, setSecondaryOption] = useState('');

    let navigate = useNavigate();
    const userPermission = usePermission(business?.type === RESTAURANT ? 'restaurant-pos' : 'pos');
    const isRestaurant = business?.type === RESTAURANT ? true : false;

    const actionList = [
        {
            title: I18n.all_types,
            action: () => handleFilter('all_types', 'secondary'),
            checked: secondaryOption === '' || secondaryOption === 'all_types' ? true : false,
        },
        {
            title: I18n.dine_in,
            action: () => handleFilter('dine_in', 'secondary'),
            checked: secondaryOption === 'dine_in' ? true : false,
        },
        {
            title: I18n.delivery,
            action: () => handleFilter('delivery', 'secondary'),
            checked: secondaryOption === 'delivery' ? true : false,
        },
        {
            title: I18n.take_away,
            action: () => handleFilter('take_away', 'secondary'),
            checked: secondaryOption === 'take_away' ? true : false,
        },
    ];

    const restaurantFilterOption = [
        {
            title: I18n.all_orders,
            action: () => handleFilter('all_orders', 'primary'),
            checked: primaryOption === '' || primaryOption === 'all_orders' ? true : false,
        },
        {
            title: I18n.void_orders,
            action: () => handleFilter('void_orders', 'primary'),
            checked: primaryOption === 'void_orders' ? true : false,
        },
        {
            title: I18n.paid_orders,
            action: () => handleFilter('paid_orders', 'primary'),
            checked: primaryOption === 'paid_orders' ? true : false,
        },
        {
            title: I18n.cash_payment,
            action: () => handleFilter('cash_payment', 'primary'),
            checked: primaryOption === 'cash_payment' ? true : false,
        },
        {
            title: I18n.card_payment,
            action: () => handleFilter('card_payment', 'primary'),
            checked: primaryOption === 'card_payment' ? true : false,
        },
        {
            title: I18n.credit_payment,
            action: () => handleFilter('credit_payment', 'primary'),
            checked: primaryOption === 'credit_payment' ? true : false,
        },
    ];

    const posFilterOption = [
        {
            title: I18n.all_orders,
            action: () => handleFilter('all_orders', 'primary'),
            checked: primaryOption === '' || primaryOption === 'all_orders' ? true : false,
        },
        {
            title: I18n.complete_order,
            action: () => handleFilter('complete_order', 'primary'),
            checked: primaryOption === 'complete_order' ? true : false,
        },
        {
            title: I18n.saved_order,
            action: () => handleFilter('saved_order', 'primary'),
            checked: primaryOption === 'saved_order' ? true : false,
        },
    ];

    async function handleFilter(selected, type) {
        if (type === 'primary') {
            setPrimaryOption(selected);
        } else {
            setSecondaryOption(selected);
        }
        const {data} = await filterOrders(selected, '', business);
        setFilteredList(data);
    }

    async function handleSearch(val) {
        const {data} = await filterOrders('', val, business);
        return data;
    }

    const onChange = (event, key) => {
        let value = key || event?.target?.value || '';
        setText(value);
    };

    const handleSelling = () => {
        if (business.type === RESTAURANT) {
            navigate('/pos-cockpit');
        } else {
            navigate('/pos');
        }
    };

    const renderList = text ? searchList : primaryOption || secondaryOption ? filteredList : orders;
    return orders?.length ? (
        <>
            <div className="pad20" style={{backgroundColor: theme.topBarBG}}>
                <Header
                    title={`${I18n.order_history} (${renderList?.length})`}
                    type="search"
                    className={'borderRadiusTopleftTopRigt'}
                    leftCta={{
                        title: primaryOption || I18n.all_orders,
                        name: 'downIcon2',
                        fill: theme.white,
                        width: '20',
                        height: '20',
                        viewBox: '0 0 18 18',
                        option: isRestaurant ? restaurantFilterOption : posFilterOption,
                    }}
                    search={{
                        type: 'pos',
                        placeholder: I18n.search_by_order_number,
                        prefix: {
                            name: 'search2Icon',
                            fill: theme.white,
                        },
                        onChange: onChange,
                    }}
                    actionCta={
                        isRestaurant
                            ? {
                                  title: secondaryOption || I18n.all_types,
                                  name: 'downIcon2',
                                  fill: theme.white,
                                  width: '20',
                                  height: '20',
                                  viewBox: '0 0 18 18',
                                  option: actionList,
                                  radio: true,
                              }
                            : null
                    }
                />
                <div className="tableListMainBox borderRadiusBottomleftBottomRigt" style={{background: theme.blackBg, height: DEVICE_HEIGHT - 180}}>
                    <Container fluid>
                        <Row>
                            <Col>
                                <TableHeader className={`${isRestaurant ? 'eightColumn' : 'sevenColumn'}`} style={{borderColor: theme.posRightBg}}>
                                    <div className="BoxWidth justifyStart">
                                        <p className="fontSize16 OneLineTruncate" style={{color: theme.white}}>
                                            {I18n.order_no}
                                        </p>
                                    </div>
                                    <div className="BoxWidth justifyCenter">
                                        <p className="fontSize16 OneLineTruncate" style={{color: theme.white}}>
                                            {I18n.date}
                                        </p>
                                    </div>
                                    {isRestaurant && (
                                        <div className="BoxWidth justifyCenter">
                                            <p className="fontSize16 OneLineTruncate" style={{color: theme.white}}>
                                                {I18n.type}
                                            </p>
                                        </div>
                                    )}

                                    <div className="BoxWidth justifyCenter">
                                        <p className="fontSize16 OneLineTruncate" style={{color: theme.white}}>
                                            {I18n.customer}
                                        </p>
                                    </div>
                                    <div className="BoxWidth justifyCenter">
                                        <p className="fontSize16 OneLineTruncate" style={{color: theme.white}}>
                                            {I18n.status}
                                        </p>
                                    </div>

                                    <div className="BoxWidth justifyCenter">
                                        <p className="fontSize16 OneLineTruncate" style={{color: theme.white}}>
                                            {I18n.payment}
                                        </p>
                                    </div>

                                    <div className="BoxWidth justifyCenter">
                                        <p className="fontSize16 OneLineTruncate" style={{color: theme.white}}>
                                            {I18n.amount}
                                        </p>
                                    </div>

                                    <div className="BoxWidth justifyCenter">
                                        <p className="fontSize16 OneLineTruncate" style={{color: theme.white}}>
                                            {I18n.action}
                                        </p>
                                    </div>
                                </TableHeader>

                                <div style={{height: DEVICE_HEIGHT - 280}} className="tableListMainBox">
                                    {renderList?.length === 0 ? (
                                        <NonIdealScreen
                                            heading={text ? I18n.search : primaryOption == 'complete_order' ? I18n.complete_order : I18n.saved_order}
                                            subHeading={
                                                text
                                                    ? I18n.we_couldnt_find_any_results_for_your_search
                                                    : primaryOption == 'complete_order'
                                                    ? I18n.no_complete_order_found
                                                    : I18n.no_saved_order_found
                                            }
                                            name={text ? 'seacrhNotFoundIcon' : primaryOption == 'complete_order' ? 'soldOutIcon' : 'soldOutIcon'}
                                            fill={theme.white}
                                            isMultiStep={false}
                                            tablenonIdeal={true}
                                        />
                                    ) : (
                                        renderList?.map((val, index) => <List key={val.id} order={val} isRestaurant={isRestaurant} />)
                                    )}
                                </div>
                            </Col>
                        </Row>
                    </Container>
                </div>
            </div>
        </>
    ) : (
        <NonIdealScreen
            heading={I18n.order_history}
            subHeading={I18n.order_history_not_found_start_selling}
            name="clockhistoryIcon"
            fill={theme.white}
            secondaryCta={
                userPermission?.canCrud
                    ? {
                          title: I18n.start_selling,
                          action: handleSelling,
                      }
                    : null
            }
            isMultiStep={false}
        />
    );
};

const enhance = withObservables([], () => ({
    orders: observeCompleteOrders(),
}));

export default enhance(OrderHistoryList);
