import {Model, Q} from '@nozbe/watermelondb';
import {
    ACCOUNT_SCHEMA,
    BUSINESS_SCHEMA,
    USER_SCEHMA,
    ROLE_SCHEMA,
    CATEGORY_SCHEMA,
    PRODUCT_TEMPLATE_SCHEMA,
    CUSTOMER_SCHEMA,
    COMPANY_SCHEMA,
    DISCOUNT_SCHEMA,
    SALES_TAX_SCHEMA,
    MODIFIER_SCHEMA,
    PAYMENT_METHOD_SCHEMA,
    DEAL_SCHEMA,
} from '../schema';
import {text, children, writer, field, lazy} from '@nozbe/watermelondb/decorators';
import {uuid} from '../../constants';

export default class Account extends Model {
    static table = ACCOUNT_SCHEMA;

    static associations = {
        business: {type: 'has_many', foreignKey: 'account_id'},
        user: {type: 'has_many', foreignKey: 'account_id'},
        role: {type: 'has_many', foreignKey: 'account_id'},
        category: {type: 'has_many', foreignKey: 'account_id'},
        product_template: {type: 'has_many', foreignKey: 'account_id'},
        product: {type: 'has_many', foreignKey: 'account_id'},
        customer: {type: 'has_many', foreignKey: 'account_id'},
        company: {type: 'has_many', foreignKey: 'account_id'},
        discount: {type: 'has_many', foreignKey: 'account_id'},
        sales_tax: {type: 'has_many', foreignKey: 'account_id'},
        modifier: {type: 'has_many', foreignKey: 'account_id'},
        payment_method: {type: 'has_many', foreignKey: 'account_id'},
        deal: {type: 'has_many', foreignKey: 'account_id'},
    };

    @text('name') name;
    @text('email') email;
    @text('phone_number') phone_number;
    @field('is_active') is_active;

    @children('business') business;
    @children('user') user;
    @children('role') role;
    @children('category') category;
    @children('product_template') product_template;
    @children('product') product;
    @children('customer') customer;
    @children('company') company;
    @children('discount') discount;
    @children('sales_tax') sales_tax;
    @children('modifier') modifier;
    @children('payment_method') payment_method;
    @children('deal') deal;

    @lazy getCustomer = id => this.customer.extend(Q.where('id', id));
    @lazy getProduct = id => this.product.extend(Q.where('id', id));
    @lazy getModifier = id => this.modifier.extend(Q.where('id', id));
    @lazy getDiscount = id => this.discount.extend(Q.where('id', id));
    @lazy getSalesTax = id => this.sales_tax.extend(Q.where('id', id));
    @lazy getCategory = id => this.category.extend(Q.where('id', id));
    @lazy getCompany = id => this.company.extend(Q.where('id', id));
    @lazy getProducts = this.product.extend(Q.sortBy('name', Q.asc));
    @lazy getCategories = this.category.extend(Q.sortBy('name', Q.asc));
    @lazy getCustomerByNumber = number => this.customer.extend(Q.where('phone_number', number));

    @writer async createBusiness(details) {
        return await this.collections.get(BUSINESS_SCHEMA).create(business => {
            business.account.set(this);
            business._raw.id = details.id;
            business.name = details.name || 'My Business';
            business.is_active = true;
            business.last_active_at = new Date().getTime();
            business.is_hq = details.is_hq;
            business.kind = details.kind;
            business.type = details.type;
            business.province = details.province;
            business.city = details.city;
            business.address = details.address;
            business.location_type = details.location_type;
            business.location_name = details.location_name;
            business.email = details.email;
            business.phone = details.phone;
            business.country = details.country;
            business.zip_code = details.zip;
        });
    }

    @writer async createUser(details) {
        return await this.collections.get(USER_SCEHMA).create(user => {
            user.account.set(this);
            user._raw.id = details.user;
            user.username = details.username;
            user.name = details.name;
            user.email = details.email;
            user.phone_number = details.phone_number;
            user.is_active = false;
            user.pin = details.pin;
            user.image = details.image;
            user.archive = false;
            user.created_at = details.created_at;
            user.last_visited = details.last_visited;
            user.cnic = details.cnic;
            user.cnic_front = details.cnic_front;
            user.cnic_back = details.cnic_back;
        });
    }

    @writer async createCustomer(details) {
        return await this.collections.get(CUSTOMER_SCHEMA).create(customer => {
            customer.account.set(this);
            customer._raw.id = uuid();
            customer.name = details.name;
            customer.phone_number = details.phone;
            customer.email = details.email;
            customer.province = details.province;
            customer.city = details.city;
            customer.dob = details.dob;
            customer.address = details.address;
            customer.created_at = new Date().getTime();
            customer.archive = false;
            customer.draft = details.draft;
        });
    }

    @writer async createRole(details) {
        return await this.collections.get(ROLE_SCHEMA).create(role => {
            role.account.set(this);
            role._raw.id = details.id;
            role.name = details.name;
        });
    }

    @writer async createCategory(details) {
        return await this.collections.get(CATEGORY_SCHEMA).create(category => {
            category.account.set(this);
            category._raw.id = uuid();
            category.name = details.name;
            category.color_code = details.color_code;
        });
    }

    @writer async createProductTemplate(details) {
        return await this.collections.get(PRODUCT_TEMPLATE_SCHEMA).create(template => {
            template.account.set(this);
            details.category && template.category.set(details.category);
            template._raw.id = uuid();
            template.name = details.name;
            template.description = details.description;
            template.image = details.image;
            template.unit = details.unit;
            template.secondary_unit = details.secondaryUnit;
            template.conversion_rate = details.rate;
            template.tax = details.tax;
            template.is_taxable = details.is_taxable;
            template.is_trackable = details.is_trackable;
        });
    }

    @writer async createCompany(details) {
        return await this.collections.get(COMPANY_SCHEMA).create(company => {
            company.account.set(this);
            company._raw.id = uuid();
            company.name = details.name;
            details.email && (company.email = details.email);
            details.phone && (company.phone_number = details.phone);
            details.country && (company.country = details.country);
            details.province && (company.province = details.province);
            details.city && (company.city = details.city);
            details.address && (company.address = details.address);
            details.postalCode && (company.postal_code = details.postalCode);
            company.archive = false;
            company.draft = details.draft;
        });
    }

    @writer async createDiscount(details) {
        return await this.collections.get(DISCOUNT_SCHEMA).create(discount => {
            discount.account.set(this);
            discount._raw.id = uuid();
            discount.name = details.name;
            details.type && (discount.type = details.type);
            details.location && (discount.location = details.location);
            discount.is_inclusive = details.is_inclusive;
            discount.limit = details.limit;
            discount.max_discount_cap = details.max_discount_cap;
            discount.archive = false;
            discount.draft = details.draft;
            discount.start_date = details.start_date;
            discount.end_date = details.end_date;
            discount.total_given = 0;
        });
    }

    @writer async createSalesTax(details) {
        return await this.collections.get(SALES_TAX_SCHEMA).create(tax => {
            tax.account.set(this);
            tax._raw.id = uuid();
            tax.name = details.name;
            tax.rate = details.rate;
            tax.is_inclusive = details.is_inclusive;
            tax.is_pre_discounted = details.is_pre_discounted;
            tax.is_selected_items = details.is_selected_items;
            tax.archive = false;
            tax.created_at = new Date().getTime();
            tax.draft = details.draft;
        });
    }

    @writer async createModifier(details) {
        return await this.collections.get(MODIFIER_SCHEMA).create(modifier => {
            modifier.account.set(this);
            modifier._raw.id = uuid();
            modifier.name = details.name;
            modifier.print_in_receipt = details.print_in_receipt;
            modifier.archive = false;
            modifier.created_at = new Date().getTime();
        });
    }
    @writer async createDeal(details) {
        return await this.collections.get(DEAL_SCHEMA).create(deal => {
            deal.account.set(this);
            deal._raw.id = uuid();
            deal.name = details.name;
            deal.amount = details.amount;
            deal.created_at = new Date().getTime();
            deal.archive = false;
        });
    }

    @writer async createPaymentMethod(details) {
        return await this.collections.get(PAYMENT_METHOD_SCHEMA).create(paymentMethod => {
            paymentMethod.account.set(this);
            paymentMethod._raw.id = uuid();
            paymentMethod.name = details.name;
            paymentMethod.placement = details.placement;
            paymentMethod.created_at = new Date().getTime();
        });
    }

    @writer async deleteDatabase(database) {
        await database.unsafeResetDatabase();
    }
}
