import {database} from '..';
import {uuid} from '../../constants';
import {Q} from '@nozbe/watermelondb';
import {DEAL_PRODUCT_SCHEMA, DEAL_SCHEMA} from '../schema';

const dealProduct = database.collections.get(DEAL_PRODUCT_SCHEMA);

export const observeDealProducts = id => dealProduct.query(Q.on(DEAL_SCHEMA, 'id', id), Q.where('archive', Q.notEq(true))).observe();
export const getDealProducts = id => dealProduct.query(Q.on(DEAL_SCHEMA, 'id', id), Q.where('archive', Q.notEq(true))).fetch();

const prepareCreation = (deal, products) => {
    return products.map(prod => {
        return dealProduct.prepareCreate(obj => {
            obj._raw.id = uuid();
            obj.deal.set(deal);
            obj.product.set(prod.item);
            obj.quantity = prod.quantity;
        });
    });
};

export const bulkCreateProdDeal = async (deal, products) => {
    await database.write(async () => {
        const allRecords = await prepareCreation(deal, products);
        await database.batch(...allRecords);
        return allRecords;
    });
};

const prepareDeletion = async (deal, selectedOptions) => {
    return await Promise.all(
        selectedOptions.map(async elem => {
            try {
                const dealProducts = await getDealProducts(deal.id);
                const itemToDelete = dealProducts.find(item => item.product.id === elem.id);
                return itemToDelete.prepareMarkAsDeleted();
            } catch (err) {
                console.log('Error in prepareDeletion2 ', err);
            }
        }),
    );
};

export const bulkDeleteDealProductRelation = async (deal, selectedOptions) => {
    await database.write(async () => {
        const allRecords = await prepareDeletion(deal, selectedOptions);
        await database.batch(...allRecords);
        return allRecords;
    });
};

const prepareUpdationDealItems = async (deal, items) => {
    return await Promise.all(
        items.map(async opt => {
            const dealProducts = await getDealProducts(deal.id);
            const itemToUpdate = dealProducts.find(item => item.product.id === opt.updatedObj.id);
            return itemToUpdate.prepareUpdate(obj => {
                obj.quantity = Number(opt.updatedObj.quantity);
            });
        }),
    );
};

export const bulkUpdateDealItems = async (deal, items) => {
    return await database.write(async () => {
        const allRecords = await prepareUpdationDealItems(deal, items);
        await database.batch(...allRecords);
        return allRecords;
    });
};
