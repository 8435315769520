import {useEffect, useState} from 'react';
import {Col, Row} from 'react-bootstrap';
import {useNavigate} from 'react-router-dom';
import {postUserLogin, Sync} from '../../../../api';
import Icon from '../../../../assets/icons';
import {useAuthContext, useLanguageContext, useThemeContext} from '../../../../context';
import {Button, CustomContainer, MessageModal, TextField, ToastBox} from '../../../common';
import Cookies from 'js-cookie';
import {RESTAURANT} from '../../../../constants';

const Login = () => {
    const navigate = useNavigate();

    const {I18n} = useLanguageContext();
    const {theme} = useThemeContext();
    const {handleLoginInDB, renderToast, setRenderToast} = useAuthContext();

    const [isLoading, setIsLoading] = useState(false);
    const [email, setEmail] = useState('');
    const [password, setPassword] = useState('');
    const [viewPassword, setViewPassword] = useState(false);
    const [forceLoginModal, setForceLoginModal] = useState(false);
    const [showMessage, setShowMessage] = useState({
        visible: false,
        message: '',
    });

    const [errors, setErrors] = useState({});

    const handleRegister = () => {
        navigate('/register');
    };

    const onChange = (label, value) => {
        if (label === I18n.enter_email) {
            setEmail(value);
        } else if (label === I18n.enter_password) {
            setPassword(value);
        }

        setErrors({});
    };

    const handleClose = () => {
        setForceLoginModal(false);
    };

    const handleForceLogin = () => {
        setForceLoginModal(false);
        const force_logout = true;
        handleLogin(force_logout);
    };

    const handleLogin = forceLogout => {
        let paramsForApi = {
            email,
            password,
            force_logout: forceLogout,
        };

        setIsLoading(true);

        postUserLogin(paramsForApi)
            .then(async res => {
                if (res?.status === 200 || res?.response?.status === 200) {
                    const {user_id, access_token, login_session} = res.data;
                    try {
                        Cookies.set('access_token', access_token, {expires: 365 * 20});
                        await Sync(email);
                        const response = await handleLoginInDB(user_id, login_session);
                        setIsLoading(false);
                        response.DBbusiness.type === RESTAURANT ? navigate('/pos-cockpit', {replace: true}) : navigate('/pos', {replace: true});
                    } catch (err) {
                        console.log('Something went wrong', err);
                        setIsLoading(false);
                    }
                } else {
                    if (res?.response?.status === 406) {
                        setForceLoginModal(true);
                    } else {
                        setErrors({authError: res?.response?.data?.msg});
                    }
                    setIsLoading(false);
                }
            })
            .catch(err => {
                console.log('err is', err);
                setIsLoading(false);
            });
    };

    const handleEnterPress = event => {
        if (event.key === 'Enter' && email && password && !Object.keys(errors).length) {
            if (forceLoginModal) {
                handleForceLogin();
            } else {
                const force_logout = false;
                handleLogin(force_logout);
            }
        }
    };

    useEffect(() => {
        if (showMessage.visible) {
            const timeoutId = setTimeout(() => {
                setShowMessage({
                    visible: false,
                    message: '',
                });
                setRenderToast('');
            }, 3000);
            return () => clearTimeout(timeoutId);
        }
    }, [showMessage]);

    useEffect(() => {
        if (renderToast === 'forced_logout') {
            setShowMessage({
                visible: true,
                message: I18n.you_have_been_logged_out,
            });
        }
    }, [renderToast]);

    return (
        <CustomContainer handlePress={handleEnterPress} className="loginPageWrapper">
            <div className="logoBox">
                <Icon name={'oscarLogo'} fill={'white'} viewBox={'0 0 115 110'} width={'55'} height={'50'} />
            </div>

            <Row className="justify-content-md-center">
                <Col md={5}>
                    <div className="loginFormBox">
                        <h1 className="fontSize24 fontWeight700 marBot10 " style={{color: theme.text}}>
                            {I18n.login}
                        </h1>

                        <TextField
                            onChange={onChange}
                            label={I18n.enter_email}
                            placeholder={I18n.enter_email}
                            value={email}
                            error={errors.email}
                            required={true}
                            autoFocus={true}
                        />

                        <TextField
                            onChange={onChange}
                            error={errors['authError']}
                            label={I18n.enter_password}
                            placeholder={I18n.enter_password}
                            value={password}
                            required={true}
                            type={viewPassword ? 'text' : 'password'}
                            suffix={viewPassword ? 'showEyeNewIcon' : 'hideEyeNewIcon'}
                            suffix_fill={theme.white}
                            suffix_width={'18'}
                            suffix_height={'18'}
                            suffix_viewBox={'0 0 18 18'}
                            suffixClick={() => setViewPassword(prev => !prev)}
                        />

                        <p className="fontSize14 cursorPointer marTop15 marBot15 barclaysBlueColor" onClick={() => navigate('/forgot-password')}>
                            {I18n.forgot_password}
                        </p>

                        <div className="signinLoginButton">
                            <Button
                                className={`width100 fontWeight600 fontSize16 cursorPointer`}
                                title={I18n.login}
                                saveLoad={isLoading ? true : false}
                                handleClick={() => handleLogin(false)}
                            />
                        </div>

                        <div>
                            <p className="fontSize14 marTop20">
                                <span className="white">{I18n.new_to_oscar}</span>
                                <span className="cursorPointer  marLeft5 barclaysBlueColor" onClick={handleRegister}>
                                    {I18n.get_started}
                                </span>
                                <span className="marLeft5">
                                    <Icon name="arrowRight" viewBox="0 0 18 18 " />
                                </span>
                            </p>
                        </div>
                    </div>
                </Col>
            </Row>
            <MessageModal
                className="messageModal"
                setToggle={setForceLoginModal}
                toggle={forceLoginModal}
                description={I18n.you_are_already_logged_in}
                subDescription={I18n.logging_into_this_device}
                secondaryCta={{
                    title: I18n.cancel,
                    action: handleClose,
                }}
                primaryCta={{
                    title: I18n.login,
                    backgroundColor: theme.brightGreen,
                    borderColor: theme.brightGreen,
                    txtColor: theme.white,
                    action: handleForceLogin,
                }}
            />

            <ToastBox className="addSuccessfullyToast" ToastVisiable={showMessage.visible} bodyPara={showMessage.message} setShowMessage={setShowMessage} showIcon={true} />
        </CustomContainer>
    );
};

export default Login;
