import React from 'react';
import {Col, Row} from 'react-bootstrap';
import {Graphcard, PaymentKeyMetrics} from '../../../../../common';
import PendingPayment from '../../../../../common/PendingPayment';

const WalletSummary = ({isPosPermission, dashboardData}) => {
    const successfulPaymentData = {
        showLegend: true,
        legendText: 'Succeeded Rs. 9200',
        labels: Object?.keys(dashboardData?.successful_payments),
        values: Object?.values(dashboardData?.successful_payments),
        additionalText: ['Mon', 'Tue', 'Wed', 'Thu', 'Fri', 'Sat', 'Sun'],
        stylesData: {
            barColor: '#FFE6C1',
            textColor: 'white',
            secondaryTextColor: '#CECECE',
            radius: 35,
        },
    };

    const avgTransactionData = {
        showLegend: true,
        legendText: 'Average Transaction value Rs. 5500',
        labels: Object?.keys(dashboardData?.average_transaction),
        values: Object?.values(dashboardData?.average_transaction),
        additionalText: ['Mon', 'Tue', 'Wed', 'Thu', 'Fri', 'Sat', 'Sun'],
        stylesData: {
            barColor: '#A5B0EE',
            textColor: 'white',
            secondaryTextColor: '#CECECE',
            radius: 35,
        },
    };

    const payoutData = {
        showLegend: true,
        legendText: 'Total payouts Rs. 7200',
        labels: Object?.keys(dashboardData?.payouts),
        values: Object?.values(dashboardData?.payouts),
        additionalText: ['Mon', 'Tue', 'Wed', 'Thu', 'Fri', 'Sat', 'Sun'],
        stylesData: {
            barColor: '#FFE6C1',
            textColor: 'white',
            secondaryTextColor: '#CECECE',
            radius: 35,
        },
    };

    const handleNavigatton = () => {};

    return (
        <Row>
            <Col className="marTop30" md={12}>
                <div className="paymentKeyMetrics">
                    <PaymentKeyMetrics type={'payment_dashborard'} isPosPermission={isPosPermission} metricsData={dashboardData} title="payment_summary" />
                </div>
            </Col>
            <Col md={6}>
                <Graphcard type="bar" data={avgTransactionData} title={'average_transaction_value'} />
            </Col>
            <Col md={6}>
                <Graphcard type="bar" data={successfulPaymentData} title={'successful_payments'} handleNavigate={handleNavigatton} ViewMoreBtn={true} />
            </Col>
            <Col md={6}>
                <Graphcard type="bar" data={payoutData} title={'payouts'} handleNavigate={handleNavigatton} ViewMoreBtn={true} />
            </Col>
            <Col md={6}>
                <PendingPayment data={dashboardData?.pending_payment} handleNavigate={handleNavigatton} />
            </Col>
        </Row>
    );
};

export default WalletSummary;
