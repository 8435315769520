import React from 'react';
import { firstLetterCaptilize, formatDayTime } from '../../../../../../constants';
import { useLanguageContext, useThemeContext } from '../../../../../../context';

const CardPaymentDetail = data => {
    const { I18n } = useLanguageContext();
    const { theme } = useThemeContext();
    const {
        bank_name,
        card_holder_name,
        card_number,
        card_type_logo,
        expiry_date,
        payment_method,
        type,
    } = data?.payment || {};

    return (
        <>
            {card_holder_name && (
                <div className="detailItemPurchasedInner">
                    <p className="fontSize12 fontWeight400" style={{ color: theme.lightGrayTwo }}>
                        {I18n.card_holder_name}
                    </p>
                    <p className="fontSize14 fontWeight400" style={{ color: theme.white }}>
                        {firstLetterCaptilize(card_holder_name) || '--'}
                    </p>
                </div>
            )}

            {
                card_number && <div className="detailItemPurchasedInner">
                    <p className="fontSize12 fontWeight400" style={{ color: theme.lightGrayTwo }}>
                        {I18n.card_number}
                    </p>
                    <p className="fontSize14 fontWeight400" style={{ color: theme.white }}>
                        {card_number?.substring(8) || '--'}
                    </p>
                </div>
            }

            {expiry_date && <div className="detailItemPurchasedInner">
                <p className="fontSize12 fontWeight400" style={{ color: theme.lightGrayTwo }}>
                    {I18n.expires}
                </p>
                <p className="fontSize14 fontWeight400" style={{ color: theme.white }}>
                    {expiry_date ? expiry_date : '--'}
                </p>
            </div>}

            {type && (
                <div className="detailItemPurchasedInner">
                    <p className="fontSize12 fontWeight400" style={{ color: theme.lightGrayTwo }}>
                        {I18n.type}
                    </p>

                    <p className="fontSize14 fontWeight400 flex" style={{ color: theme.white }}>
                        {payment_method == 'card' ? <img src={card_type_logo} alt="logo" height={17} width={30} /> : '--'}
                        <span className="marLeft5"> {type || '--'}</span>
                    </p>
                </div>
            )}

            {bank_name && (
                <div className="detailItemPurchasedInner">
                    <p className="fontSize12 fontWeight400" style={{ color: theme.lightGrayTwo }}>
                        {I18n.bank_name}
                    </p>
                    <p className="fontSize14 fontWeight400" style={{ color: theme.white }}>
                        {firstLetterCaptilize(bank_name) || '--'}
                    </p>
                </div>
            )}
        </>
    );
};

export default CardPaymentDetail;
