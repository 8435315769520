import {Container, Col, Row} from 'react-bootstrap';
import {useLanguageContext, useThemeContext} from '../../../context';
import {TextField, Button} from '../../common';
import {useState} from 'react';
import ListItem from './ListItem';
import {DEVICE_HEIGHT, toSnakeCase, validateCreateOptionForm} from '../../../constants';
import {PosAppModal} from '../V2';
import withObservables from '@nozbe/with-observables';

const CreateOptionModal = ({toggle, setToggle, business, handleCreatedOption, options}) => {
    const {I18n} = useLanguageContext();
    const {theme} = useThemeContext();

    const [name, setName] = useState();
    const [values, setValues] = useState([]);
    const [text, setText] = useState('');
    const [errors, setErrors] = useState({});

    const handleClose = event => {
        setToggle(false);
    };

    const clearState = () => {
        setName();
        setText();
        setValues([]);
    };

    const handleAddOption = () => {
        if (!text) {
            setErrors({values: I18n.please_enter_a_value});
            return;
        }

        if (values.filter(x => x.toLowerCase() == text.toLowerCase()).length) {
            setErrors({values: I18n.value_already_selected});
            return;
        }

        setValues(x => {
            let arr = [...x];
            arr.push(text);
            return arr;
        });

        setText('');
    };

    const handleNext = async () => {
        try {
            let paramsForOption = {
                name,
                values,
            };

            const formErrors = await validateCreateOptionForm(paramsForOption, options);
            setErrors(formErrors);

            console.log('paramsForOption', paramsForOption);
            console.log('form error', formErrors);

            if (Object.keys(formErrors).length > 0) {
                return;
            }

            let option = await business.createOption(paramsForOption);
            setToggle(false);
            handleCreatedOption(option);
            clearState();
        } catch (err) {
            console.log('err in handleSave', err);
        }
    };

    const onChange = (label, val) => {
        if (label == I18n.variation_display_name) setName(val);
        else if (label == I18n.values) setText(val);

        setErrors(prevErrors => {
            const updatedErrors = {...prevErrors};
            if (updatedErrors[toSnakeCase(label)]) {
                delete updatedErrors[toSnakeCase(label)];
            }
            return updatedErrors;
        });
    };

    const handleDelete = item => {
        setValues(x => x.filter(y => y != item));
    };

    const handleEnterPress = event => {
        if (event.key === 'Enter') {
            if (text) handleAddOption();
            else handleNext();
        }
    };

    return (
        <PosAppModal
            className="selectCustomerModal"
            toggle={toggle}
            handleClose={handleClose}
            title={I18n.create_variation}
            backCta={{
                name: 'backArrowIcon',
                fill: theme.barclaysBlue,
                width: '18',
                height: '18',
                viewBox: '0 0 18 18',
            }}
            bottomSaveCta={{
                title: I18n.next,
                action: () => handleNext(),
            }}
            bottomCancelCta={{
                title: I18n.cancel,
                action: () => handleClose(),
            }}>
            <div
                className="selectCustomerListBox"
                style={{
                    height: DEVICE_HEIGHT - 210,
                }}>
                <Container>
                    <Row className="justify-content-md-center" onKeyDown={handleEnterPress}>
                        <Col md={6}>
                            <div className="itemFields">
                                <h3 className="fontSize20 fontWeight400 marBot20 marTop40" style={{color: theme.text}}>
                                    {I18n.variation_details}
                                </h3>
                                <TextField
                                    onChange={onChange}
                                    error={errors?.['variation_display_name']}
                                    value={name}
                                    label={I18n.variation_display_name}
                                    placeholder={I18n.size_color_etc}
                                    required={true}
                                    autoFocus={true}
                                />

                                <h3 className="fontSize20 fontWeight400 marBot20 marTop20" style={{color: theme.text}}>
                                    {I18n.option}
                                </h3>

                                <div className="optionFieldBox">
                                    <TextField onChange={onChange} error={errors?.['values']} value={text} label={I18n.values} placeholder={I18n.values} />

                                    <Button
                                        className={`addOptionButton borderRadius10`}
                                        type="iconButton"
                                        icon_name="newIcon"
                                        icon_fill={theme.barclaysBlue}
                                        icon_width={'17'}
                                        icon_height={'17'}
                                        icon_viewBox={'0 0 13 13'}
                                        handleClick={handleAddOption}
                                    />
                                </div>

                                <div className="optionList">
                                    {values.map(val => (
                                        <ListItem value={val} handleDelete={handleDelete} />
                                    ))}
                                </div>
                            </div>
                        </Col>
                    </Row>
                </Container>
            </div>
        </PosAppModal>
    );
};

const enhance = withObservables(['business'], ({business}) => ({
    options: business.option.observe(),
}));

export default enhance(CreateOptionModal);
