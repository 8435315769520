import React, {useEffect, useState} from 'react';
import {CustomContainer, Header, ToastBox} from '../../../../common';
import {useAuthContext, useLanguageContext, useThemeContext} from '../../../../../context';
import {useNavigate} from 'react-router-dom';
import {Col, Row} from 'react-bootstrap';
import Icon from '../../../../../assets/icons';
import BeneficiaryCard from '../../../../common/BeneficiaryCard';
import BeneficiaryModal from '../../../../common/BeneficiaryModal';
import {formatNum, validatePayoutForm} from '../../../../../constants';
import {getWalletCurrentBalance} from '../../../../../api';
import usePermission from '../../../../../hooks/usePermission';

const TransferMoney = () => {
    const {I18n} = useLanguageContext();
    const {theme} = useThemeContext();
    const walletPermission = usePermission('wallet-overview');
    const {payoutBeneficiaries, handleUpdateBeneficiaries, business} = useAuthContext();
    const [beneficiaryModal, setBeneficiaryModal] = useState(false);
    const [errors, setErrors] = useState({});
    const [loading, setLoading] = useState(false);
    const payoutList = Object.values(payoutBeneficiaries || {});
    const totalAmount = payoutList?.reduce((acc, curr) => acc + (curr.amount || 0), 0);
    const [showMessage, setShowMessage] = useState({
        visible: false,
        message: '',
    });

    let navigate = useNavigate();

    const handleNext = async () => {
        setLoading(true);
        try {
            const payoutList = Object.values(payoutBeneficiaries || {});
            const response = await getWalletCurrentBalance({business_id: business.id});
            const formErrors = validatePayoutForm(payoutList, response.current_balance, totalAmount);

            console.log('formErrors', formErrors);
            setErrors(formErrors);

            if (formErrors.limit_exceed) {
                setShowMessage({
                    visible: true,
                    message: formErrors.limit_exceed,
                });
            }

            if (Object.keys(formErrors).length > 0) return;
            navigate('/review-payout');
        } catch (error) {
            console.log('error', error);

            setShowMessage({
                visible: true,
                message: error?.response?.data?.message,
            });
        } finally {
            setLoading(false);
        }
    };

    const handleBack = () => {
        navigate('/dashboard', {state: {screenName: 'wallet', tab: 'balance'}});
    };

    useEffect(() => {
        if (showMessage.visible) {
            const timeoutId = setTimeout(() => {
                setShowMessage({
                    visible: false,
                    message: '',
                });
            }, 3000);
            return () => clearTimeout(timeoutId);
        }
    }, [showMessage]);

    return (
        <>
            <CustomContainer className="addScreenMainBox createCustomerContainer" reduceHeight={45}>
                <Header
                    type="draftHeader"
                    title={I18n.back}
                    backAction={handleBack}
                    isDisabled={loading}
                    saveCta={
                        walletPermission?.canCrud
                            ? {
                                  title: I18n.next,
                                  action: () => handleNext(),
                                  saveLoad: loading,
                              }
                            : null
                    }
                    primaryCta={
                        walletPermission?.canCrud
                            ? {
                                  title: I18n.cancel,
                                  action: () => handleBack(),
                              }
                            : null
                    }
                />

                <Row className="justify-content-md-center">
                    <Col md={6}>
                        <div>
                            <p className="fontSize24  textCenter fontWeight600 marBot0 marTop20" style={{color: theme.text}}>
                                {I18n.transfer_money}
                            </p>
                            <div className="spaceBetweenCenter marTop20">
                                <p className="fontSize20 " style={{color: theme.text}}>
                                    {I18n.beneficiary_details}
                                </p>

                                <p className="fontSize16" style={{color: theme.text}}>
                                    {`${I18n.total_amount}: Rs.${formatNum(totalAmount)}`}
                                </p>
                            </div>

                            <div className="addMoreBeneficiaries" onClick={() => setBeneficiaryModal(true)}>
                                <Icon name={'add2Icon'} width="24" height="24" viewBox={'0 0 18 18'} fill={theme.barclaysBlue} />

                                <p className="fontSize16 marBot0" style={{color: theme.barclaysBlue}}>
                                    {I18n.add_more_beneficiaries}
                                </p>
                            </div>
                            {errors['error'] && <p className="inputError">{errors.error}</p>}
                            <div className="marTop30">
                                {payoutList?.map((val, ind) => (
                                    <BeneficiaryCard
                                        length={payoutList?.length}
                                        beneficiary={val}
                                        handleUpdateBeneficiaries={handleUpdateBeneficiaries}
                                        errors={errors}
                                        setErrors={setErrors}
                                        key={ind}
                                    />
                                ))}
                            </div>
                        </div>
                    </Col>
                </Row>
            </CustomContainer>

            <BeneficiaryModal
                toggle={beneficiaryModal}
                setToggle={setBeneficiaryModal}
                selectedBeneficiaries={payoutBeneficiaries}
                setSelectedBeneficiaries={handleUpdateBeneficiaries}
                from="transfer_money"
            />

            <ToastBox
                className={`addSuccessfullyToast ${showMessage?.visible ? 'errorToastMessage' : ''} `}
                ToastVisiable={showMessage.visible}
                bodyPara={showMessage.message}
                setShowMessage={setShowMessage}
                showIcon={true}
                errorToast={true}
            />
        </>
    );
};

export default TransferMoney;
