import DefaultHeader from "./DefaultHeader";
import PosHeader from "./PosHeader";
import SearchHeader from "./SearchHeader";
import SecondaryHeader from "./SecondaryHeader";
import SelectHeader from "./SelectHeader";
import POHeader from "./POHeader";
import NewPosHeader from "./NewPosHeader";
import PosCockpitHeader from "./PosCockpitHeader";
import KDSHeader from "./KDSHeader";
import DraftHeader from "./DraftHeader";
import ReportsBoxHeader from "./ReportsBoxHeader";

const Header = ({ type, ...rest }) => {
  return type == "select" ? (
    <SelectHeader {...rest} />
  ) : type == "search" ? (
    <SearchHeader {...rest} />
  ) : type == "newPosHeader" ? (
    <NewPosHeader {...rest} />
  ) : type == "pos" ? (
    <PosHeader {...rest} />
  ) : type == "secondary" ? (
    <SecondaryHeader {...rest} />
  ) : type == "poHeader" ? (
    <POHeader {...rest} />
  ) : type == "draftHeader" ? (
    <DraftHeader {...rest} />
  ): type == "posCockpit" ? (
    <PosCockpitHeader {...rest} />
  ) : type === "kdsHeader" ? (
    <KDSHeader {...rest} />
  ) : type === "kdsHeader" ? (
    <ReportsBoxHeader {...rest} />
  ) : (
    <DefaultHeader {...rest} />
  );
};

export default Header;
