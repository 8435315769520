import {Modal, ModalBody, ModalFooter, ModalHeader} from 'react-bootstrap';
import {useLanguageContext, useThemeContext} from '../../../context';
import Icon from '../../../assets/icons';
import Button from '../Button';
import UploadButton from '../Button/UploadButton';

const AppModal = ({
    children,
    toggle,
    handleClose,
    className,
    primaryCta,
    secondaryCta,
    discardCta,
    saveCta,
    uploadCta,
    footer,
    editCta,
    handleEdit,
    disableCloseIcon,
    hideIconText,
    showHeader = false,
    title,
    titleName,
}) => {
    const {I18n} = useLanguageContext();
    const {theme} = useThemeContext();
    return (
        <Modal
            dialogClassName={'animate-bottom'}
            className={`${className}`}
            onHide={() => handleClose()}
            keyboard={false}
            show={toggle}
            centered
            scrollable={true}
            backdrop="static">
            {showHeader ? (
                <ModalHeader>
                    <div className="modalHeaderLeftSide">
                        <p className="marBot0 fontSize18 fontWeight500" style={{color: theme.white}}>
                            <span>{title}</span>
                            {titleName && <span className="modalTitleName fontWeight400">{titleName}</span>}
                        </p>
                    </div>

                    <div className="modalHeaderRightSide">
                        {discardCta && (
                            <Button title={discardCta.title} handleClick={discardCta.action} className={`secondaryCta fontWeight600 fontSize16 cursorPointer marBot0 marRight10`} />
                        )}
                        {saveCta && (
                            <Button
                                saveLoad={saveCta.saveLoad}
                                title={saveCta.title}
                                handleClick={saveCta.action}
                                disabled={saveCta?.disabled}
                                className={`fontWeight600 fontSize16 cursorPointer marBot0 marRight20`}
                            />
                        )}

                        {editCta && (
                            <p className="marBot0 white marRight20 textCenter">
                                <span className="backBoxIcon cursorPointer" style={{borderColor: theme.seperatorTwo}} onClick={handleEdit}>
                                    <Icon name={editCta.name} fill={editCta.fill} height={editCta.height} width={editCta.width} viewBox={editCta.viewBox} />
                                </span>
                                <span className="fontSize12 fontWeight400">{editCta.title}</span>
                            </p>
                        )}

                        {disableCloseIcon ? null : (
                            <p className="marBot0 white textCenter cursorPointer" onClick={handleClose}>
                                <span className="backBoxIcon cursorPointer" style={{borderColor: theme.seperatorTwo}} onClick={handleClose}>
                                    <Icon name="closeIcon" fill={theme.white} width={'12'} height={'12'} viewBox={'0 0 15 15'} />
                                </span>
                                {!hideIconText && <span className="fontSize12 fontWeight400">{I18n.close}</span>}
                            </p>
                        )}
                    </div>
                </ModalHeader>
            ) : null}

            <ModalBody>{children}</ModalBody>
            {(footer || primaryCta) && (
                <ModalFooter>
                    {uploadCta && <UploadButton className={`fontWeight600 fontSize16 cursorPointer marBot15 marRight10`} title={uploadCta.title} onChange={uploadCta.action} />}
                    {secondaryCta && (
                        <Button
                            title={secondaryCta.title}
                            handleClick={secondaryCta.action}
                            disabled={secondaryCta?.disabled}
                            backgroundColor={secondaryCta?.isDelete ? theme.red : theme.topBarBG}
                            className={`fontWeight600 fontSize16 cursorPointer marBot10`}
                        />
                    )}
                    {primaryCta && (
                        <Button
                            title={primaryCta.title}
                            handleClick={primaryCta.action}
                            backgroundColor={primaryCta.backgroundColor || theme.brightGreen}
                            borderColor={primaryCta.borderColor || theme.darkSlateBlue}
                            txtColor={primaryCta.txtColor || theme.white}
                            className={` fontWeight600 fontSize16 cursorPointer marBot10 marRight10`}
                            saveLoad={primaryCta.loading}
                        />
                    )}
                </ModalFooter>
            )}
        </Modal>
    );
};

export default AppModal;
