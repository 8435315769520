import React, {useEffect, useState} from 'react';
import DashboardDataBox from '../DashboardDataBox';
import TabBox from '../TabBox';
import DonutChart from '../DonutChart/DonutChart';
import BarChart from '../BarChart/BarChart';
import DashboardGraphNonIdeal from '../NonIdeal/DashboardGraphNonIdeal';
import {graph_non_ideal} from '../../../assets/images/image';
import {hasPositiveValue} from '../../../constants';

const Graphcard = ({type, data, title, tabValue, setTabVal, handleNavigate, ViewMoreBtn}) => {
    const handleChange = (event, newValue) => {
        setTabVal(newValue);
    };

    return (
        <DashboardDataBox ViewMoreBtn={ViewMoreBtn} title={title} handleView={handleNavigate}>
            {data.tabs ? <TabBox tabValue={tabValue} tabs={['AM', 'PM']} handleChange={handleChange} /> : null}
            {hasPositiveValue(data, title) ? (
                type === 'donut' ? (
                    <DonutChart data={data} />
                ) : (
                    <BarChart data={data} />
                )
            ) : (
                <DashboardGraphNonIdeal
                    data={{
                        title: 'youve_not_done_any_transactions_yet',
                        image: graph_non_ideal,
                    }}
                />
            )}
        </DashboardDataBox>
    );
};

export default Graphcard;
