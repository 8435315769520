import {Container, Row} from 'react-bootstrap';
import {useThemeContext} from '../../../context';
import NavbarRight from './NavbarRight';
import NavbarLeft from './NavbarLeft';

const Navbar = ({from}) => {
    const {theme} = useThemeContext();

    return (
        <section className="headerMainCont" style={{background: theme.background}}>
            <Container fluid>
                <Row>
                    <NavbarLeft from={from} />
                    <NavbarRight />
                </Row>
            </Container>
        </section>
    );
};

export default Navbar;
