import 'react-native-get-random-values';
import {v4 as uuidv4} from 'uuid';
import {
    ALPHABET_REGIX,
    CNIC_REGEX,
    EMAIL_REGEX,
    MONTHS,
    NAME_REGEX,
    USERNAME_REGEX,
    PHONE_REGEX,
    CSV_HEADER_VALUES,
    NumberRegex,
    UNITS,
    CSV_TEMPLATE_DUMMY_DATA,
    PRICE_REGEX,
    DAYS,
    PREPARING,
    DATE_REGEX,
    RESTAURANT,
} from './utils';
import dayjs from 'dayjs';
import {getCheckPhoneNumber, getCheckUseremail, postUploadImage} from '../api';
import {utils, writeFile, read} from 'xlsx';
import {getAllProducts, isBarCodeExist, isCategoryExist, isCustomerExist} from '../pos-core/database/helpers';
import {validateProdBusiness} from '../pos-core/database/helpers/product_business';

export const uuid = () => uuidv4();

export const sanitizeOptionValues = values => {
    return Array.isArray(values) ? values.map(String) : [];
};

export const sanitizeOrderDiscount = data => data;

export const formatDate = date => {
    if (date) {
        date = new Date(date);
        return `${date.getDate()} ${MONTHS[date.getMonth()]}, ${date.getFullYear()}`;
    }
};

export const formatDateWithTime = date => {
    const year = date.getFullYear();
    const month = String(date.getMonth() + 1).padStart(2, '0');
    const day = String(date.getDate()).padStart(2, '0');
    const hours = String(date.getHours()).padStart(2, '0');
    const minutes = String(date.getMinutes()).padStart(2, '0');
    const seconds = String(date.getSeconds()).padStart(2, '0');

    return `${year}-${month}-${day} ${hours}:${minutes}:${seconds}`;
};

export const checkIfValueExists = (itemArray, value) => {
    return itemArray.includes(value);
};
export const formatDayTime = date => {
    date = new Date(date);
    var hours = date.getHours();
    var minutes = date.getMinutes();
    var ampm = hours >= 12 ? 'PM' : 'AM';
    hours = hours % 12;
    hours = hours ? hours : 12;
    minutes = minutes < 10 ? '0' + minutes : minutes;
    return `${MONTHS[date.getMonth()]} ${date.getDate()}, ${hours}:${minutes} ${ampm}`;
};

export const formatDateAndDays = date => {
    date = new Date(date);

    return `${DAYS[date.getDay()]} ${date.getDate()} ${MONTHS[date.getMonth()]} ${date.getFullYear()}`;
};
export const capitalizeFirstAlphabet = value => value?.charAt(0)?.toUpperCase() + value?.substring(1);
export const bulkUploadImages = imagesToUpload => {
    return new Promise(async (resolve, reject) => {
        try {
            if (imagesToUpload.length) {
                let promises = imagesToUpload.map(val => {
                    return new Promise(async (resolve, reject) => {
                        try {
                            let paramsToUpload = {
                                data: val.image,
                                data_id: val.id,
                                data_type: val.table,
                            };

                            let {url} = await postUploadImage(paramsToUpload);
                            resolve({name: val.state, url});
                        } catch (err) {
                            console.log('err in updateImage', err);
                            reject(err);
                        }
                    });
                });

                Promise.all(promises)
                    .then(imageUrls => {
                        console.log('All images backed up');
                        const imageUrlObj = imageUrls.reduce((acc, item) => {
                            acc[item.name] = item.url;
                            return acc;
                        }, {});

                        resolve(imageUrlObj);
                    })
                    .catch(err => {
                        console.log('err in uploading images', err);
                        reject(err);
                    });
            } else {
                console.log('No images to backup');
                resolve([]);
            }
        } catch (err) {
            console.log('Err in handleImageBackup', err);
            reject(err);
        }
    });
};

export const formatTime = (date, getSec = false) => {
    date = new Date(date);

    var hours = date.getHours();
    var minutes = date.getMinutes();
    var seconds = date.getSeconds();

    var ampm = hours >= 12 ? 'PM' : 'AM';
    hours = hours % 12;
    hours = hours ? hours : 12;
    minutes = minutes < 10 ? '0' + minutes : minutes;

    return `${hours}:${minutes}${getSec ? `:${seconds}` : ''} ${ampm}`;
};

export const formatHours = date => {
    date = new Date(date);

    var hours = date.getHours();
    var ampm = hours >= 12 ? 'PM' : 'AM';
    hours = hours % 12;
    hours = hours ? hours : 12;

    return `${hours} ${ampm}`;
};
export const firstLetterCaptilize = val => {
    let value = val?.toString();
    return value?.charAt(0).toUpperCase() + value?.slice(1);
};

export const validateOwner = (roles, userRole) => {
    const userRoleId = userRole.id;
    const userRoleInLocation = roles.find(role => role.id === userRoleId);
    return userRoleInLocation.name === 'owner' ? true : false;
};

export const validateCategroy = async (formData, id) => {
    const formErrors = {};
    const categoryAllReadyExit = await isCategoryExist(formData.name);
    if (!formData.name) {
        formErrors.name = 'Please enter category name.';
    }
    if (formData.name && categoryAllReadyExit?.length > 0 && !id) {
        formErrors.name = 'Category available in the system.';
    }
    return formErrors;
};

export const validateDealForm = async formData => {
    const formErrors = {};
    if (!formData.name) {
        formErrors.deal_name = 'Please enter deal name.';
    }
    if (!formData.amount) {
        formErrors.amount = 'Please enter deal amount.';
    }
    if (formData?.locationsArray?.length === 0) {
        formErrors.location = 'Please select location.';
    }
    if (formData?.locationsArray?.length > 0 && formData?.selectedItems?.length > 0) {
        await formData.locationsArray.forEach(loc => {
            formData?.selectedItems.forEach(async x => {
                const prodId = x.item.id;
                const bussId = loc.id;

                const isExist = await validateProdBusiness(prodId, bussId);
                if (isExist.length === 0) {
                    formErrors.location = 'One or more locations have missing items';
                    formErrors.missing_loc_id = bussId;

                    return;
                }
            });
        });
    }
    if (!formData?.discountRules || formData?.selectedItems?.length === 0) {
        formErrors.item_list = 'Please select items to create deal.';
    }

    return formErrors;
};

export const validateDealDraftForm = async formData => {
    const formErrors = {};
    if (!formData.name) {
        formErrors.deal_name = 'Please enter deal name.';
    }
    if (formData?.locationsArray?.length === 0) {
        formErrors.location = 'Please select location.';
    }
    if (formData?.locationsArray?.length > 0 && formData?.selectedItems?.length > 0) {
        await formData.locationsArray.forEach(loc => {
            formData?.selectedItems.forEach(async x => {
                const prodId = x.item.id;
                const bussId = loc.id;

                const isExist = await validateProdBusiness(prodId, bussId);
                if (isExist.length === 0) {
                    formErrors.location = 'One or more locations have missing items';
                    formErrors.missing_loc_id = bussId;

                    return;
                }
            });
        });
    }

    return formErrors;
};

export const validateDraftCreateCompany = async formData => {
    const formErrors = {};
    if (!formData.name) {
        formErrors.name = 'Name is required to save into draft.';
    }
    return formErrors;
};

export const getHashMap = async account => {
    const businesses = await account.business.fetch();
    const hashedObj = businesses.reduce((acc, item) => {
        acc[item.id] = item;
        return acc;
    }, {});

    return hashedObj;
};
export const formatStringAndCapitalize = inputString => {
    const words = inputString?.split('_');
    const capitalizedWords = words?.map(word => word.charAt(0).toUpperCase() + word.slice(1));
    const formattedString = capitalizedWords?.join(' ');

    return formattedString;
};

export const getColorBasedOnOrderType = val => {
    let color = '';

    switch (val) {
        case 'delivery':
            color = 'lightPurpleColor';
            break;

        case 'dine_in':
            color = 'orange';
            break;

        case 'takeaway':
            color = 'green';
            break;

        default:
            color = 'green';
    }

    return {color};
};

export const formatTimeInSec = secondsDifference => {
    const absoluteSeconds = Math.abs(secondsDifference);
    const hours = Math.floor(absoluteSeconds / 3600);
    const formattedHours = `${Math.abs(hours)}`.padStart(2, '0');
    const minutes = Math.floor((absoluteSeconds % 3600) / 60);
    const seconds = absoluteSeconds % 60;

    return `${secondsDifference < 0 ? '-' : ''}${formattedHours}:${minutes < 10 ? '0' : ''}${minutes}:${seconds < 10 ? '0' : ''}${seconds}`;
};

export const getColors = (val, deviceConfiguration, order) => {
    let primaryColor = 'lightGreenBg';
    let secondaryColor = 'greenBg';
    let stopTimer = false;
    let isRecall = order.status === 'recall';
    let isCancelled = order.status === 'cancel';

    const {green_timer, yellow_timer, red_timer} = deviceConfiguration?.configuration;
    const greenTimerInSec = green_timer * 60;
    const yellowTimerInSec = (green_timer + yellow_timer) * 60;
    const redTimerInSec = (green_timer + yellow_timer + red_timer) * 60;

    if (isRecall) {
        primaryColor = 'lightPurpleBg';
        secondaryColor = 'purpleBg';
        stopTimer = true;
    } else if (isCancelled) {
        primaryColor = 'lightRedBg';
        secondaryColor = 'redBg';
        stopTimer = true;
    } else if (val <= greenTimerInSec) {
        primaryColor = 'lightGreenBg';
        secondaryColor = 'greenBg';
    } else if (val <= yellowTimerInSec) {
        primaryColor = 'lightOrangeBg';
        secondaryColor = 'orangeBg';
    } else {
        primaryColor = 'lightRedBg';
        secondaryColor = 'redBg';
        stopTimer = true;
    }

    return {primaryColor, secondaryColor, stopTimer};
};

export const getOrderStatusColor = val => {
    let color = '';

    switch (val) {
        case 'on_time':
            color = 'green';
            break;

        case 'cancelled':
            color = 'red';
            break;

        case 'late':
            color = 'red';
            break;

        case 'delayed':
            color = 'orange';
            break;

        default:
            color = 'green';
    }

    return {color};
};

export const isDisableRecall = order => {
    const currentTime = new Date().getTime();
    const twentyFourHoursInMilliseconds = 24 * 60 * 60 * 1000;

    return currentTime - order?.completed_at > twentyFourHoursInMilliseconds || order?.status === 'completed';
};

export const aggregatePermission = async (userRole, moduleName) => {
    let obj = null;
    const modules = await userRole?.module.fetch();
    const permission = modules?.find(mod => mod.name === moduleName);

    if (permission) {
        obj = {
            canView: permission.read,
            canCrud: permission.create,
        };
    }

    return obj;
};

export const isRolesEqual = (modules, selectedModule) => {
    const lengthsEqual = Object.keys(selectedModule).length === modules.length;

    const areEqual =
        lengthsEqual &&
        modules.every(route => {
            const key = Object.keys(selectedModule).find(k => selectedModule[k].name === route.name);
            return key !== undefined;
        });

    return areEqual;
};

export const getAccountBusiness = async (account, business, userBusiness, singleSelection) => {
    let businesses;

    if (business.is_hq) {
        businesses = await account.business.fetch();
    } else {
        businesses = userBusiness;
    }

    if (singleSelection) {
        return businesses.map(item => ({
            ...item,
            name: item.location_name,
        }));
    }

    return [
        {
            name: 'All locations',
            value: 'all',
            _raw: {
                id: 'all',
            },
        },
        ...businesses.map(item => ({
            ...item,
            name: item.location_name,
        })),
    ];
};

export const formatNum = (number, currencySign = '') =>
    currencySign +
    Number(number)
        .toFixed(2)
        .replace(/[.,]00$/, '')
        .toString()
        .replace(/\B(?=(\d{3})+(?!\d))/g, ',');

export const getFocus = activeOpenItem => {
    const fieldName = activeOpenItem.field?.includes('amount') ? 'amount' : 'quantity';
    const fieldToFocus = document.getElementById(`${fieldName}-${activeOpenItem.id}`);

    return {fieldName, fieldToFocus};
};

export const hasPositiveValue = (data, title) => {
    data = title === 'sales_by_category' ? data : data.values;

    if (data?.length > 0) {
        if (title === 'sales_by_category') {
            return data.length > 0;
        } else {
            return data?.some(val => val > 0);
        }
    } else {
        return false;
    }
};

export const formatSingleDigitNum = num => {
    if (num < 10) {
        return '0' + num;
    } else {
        return num;
    }
};

export const getFirstErrorMessage = errorObject => {
    for (const key in errorObject) {
        if (errorObject.hasOwnProperty(key)) {
            const errors = errorObject[key];
            if (errors.length > 0 || Object.values(errors || {}).length > 0) {
                return errors.length > 0 ? errors[0] : Object.values(errors)?.[0];
            }
        }
    }

    return null;
};

export const formatDateAndTime = date => `${formatDate(date)}. ${formatTime(date)}`;

export const formatDateAndTimewithoutYear = date => {
    let dates = new Date(date);
    let finalDate = `${dates.getDate()} ${MONTHS[dates.getMonth()]}`;
    return `${finalDate}, ${formatTime(date)}`;
};
export const formatDateAndTimeWithYear = date => {
    let dates = new Date(date);
    let finalDate = `${dates.getDate()} ${MONTHS[dates.getMonth()]}, ${dates.getFullYear()}`;
    return `${finalDate}. ${formatTime(dates)}`;
};

export const validateAccountForm = async formData => {
    const formErrors = {};

    if (!formData.firstName) {
        formErrors.first_name = 'First name is required.';
    }
    if (!formData.lastName) {
        formErrors.last_name = 'Last name is required.';
    }

    if (!formData.email) {
        formErrors.email = 'Email is required';
    }

    if (formData.email && !EMAIL_REGEX.test(formData.email)) {
        formErrors.email = 'Please enter a valid email';
    }

    if (formData.email) {
        try {
            await getCheckUseremail({email: formData.email});
        } catch (err) {
            formErrors.email = err.response.data.msg;
            console.log('err', err);
        }
    }

    if (!formData.phoneNumber) {
        formErrors.phone_number = 'Phone number is required';
    }

    if (formData.phoneNumber && !PHONE_REGEX.test(formData.phoneNumber)) {
        formErrors.phone_number = 'Phone number should be in the format 03xxxxxxxxx';
    }

    if (formData.phoneNumber) {
        try {
            await getCheckPhoneNumber({phoneNumber: formData.phoneNumber});
        } catch (err) {
            formErrors.phone_number = err.response.data.msg;
            console.log('err', err);
        }
    }

    return formErrors;
};

const isValidPassword = password => {
    return password.length >= 8 && /[A-Z]/.test(password) && /[a-z]/.test(password) && /[0-9]/.test(password) && /[!@#$%^&*(),.?":{}|<>]/.test(password);
};

export const validateConfirmPassPin = formData => {
    const formErrors = {};

    // Helper function to validate the password
    if (!formData.password) {
        formErrors.password = 'Password is required.';
    } else if (!isValidPassword(formData.password)) {
        formErrors.password = 'Password must be at least 8 characters long, include 1 uppercase letter, 1 lowercase letter, 1 number, and 1 special character.';
    }
    if (!formData.confirmPassword) {
        formErrors.confirm_password = 'Please enter the confirm new password';
    } else if (formData.password !== formData.confirmPassword) {
        formErrors.confirm_password = 'Password and confirm password do not match.';
    }

    if (!formData.pin) {
        formErrors.pin = 'Pin is required.';
    }
    if (formData?.pin?.length !== 4) {
        formErrors.pin = 'Pin must be 4 digits.';
    }
    if (!formData.isChecked) {
        formErrors.terms_accepted = 'You must accept the terms and conditions.';
    }

    return formErrors;
};

export const validateConfirmPassword = formData => {
    const formErrors = {};

    if (!formData.password) {
        formErrors.create_new_password = 'Password is required.';
    }
    if (!isValidPassword(formData.password)) {
        formErrors.create_new_password = 'Password must be at least 8 characters long, include 1 uppercase letter, 1 lowercase letter, 1 number, and 1 special character.';
    }

    if (!formData.confirmPassword || formData.password !== formData.confirmPassword) {
        formErrors.confirm_new_password = 'Password and confirm password do not match.';
    }

    return formErrors;
};

export const validateBusinessDetails = (formData, activeIndex) => {
    const formErrors = {};

    if (activeIndex === 0 && !formData.kind) {
        formErrors.kind = 'Business kind is required.';
    }

    if (activeIndex === 1 && !formData.type) {
        formErrors.type = 'Business type is required.';
    }

    if (activeIndex === 1 && !formData.name) {
        formErrors.name = 'Business name is required.';
    }

    if (activeIndex === 2 && !formData.location_name) {
        formErrors.location_name = 'Location name is required.';
    }

    if (activeIndex === 2 && !formData.location_type) {
        formErrors.location_type = 'Location type is required.';
    }
    if (activeIndex === 3 && !formData.province) {
        formErrors.province = 'Province is a required field.';
    }
    if (activeIndex === 3 && !formData.city) {
        formErrors.city = 'City is required field.';
    }

    return formErrors;
};

export const validatePOForm = formData => {
    const formErrors = {};
    const poLineErrors = {};

    if (!formData.company) {
        formErrors.company = 'Company is required';
    }
    if (!formData.purchase_order_date) {
        formErrors.purchase_order_date = 'PO Date is required';
    }
    if (!formData.expected_on) {
        formErrors.expected_on = 'PO Expected Date is required';
    }
    if (!formData.reference) {
        formErrors.reference = 'Reference number is required';
    }
    if (!formData.shipping_method) {
        formErrors.shipping_method = 'Shipping method is required';
    }
    if (formData?.po_line?.length === 0) {
        formErrors.po_line = 'Atleast one item required to create PO';
    }
    if (formData?.po_line) {
        formData?.po_line.forEach(poLine => {
            const errors = {};

            if (!poLine.qty) {
                errors.qty = 'required';
            }
            if (!poLine.unit_cost) {
                errors.unit_cost = 'required';
            }
            if (!poLine.unit) {
                errors.unit = 'required';
            }

            if (Object.keys(errors).length > 0) {
                poLineErrors[poLine.id] = errors;
            }
        });

        if (Object.keys(poLineErrors).length > 0) {
            formErrors.poLines = poLineErrors;
        }
    }

    return formErrors;
};

export const validateDraftPOForm = formData => {
    const formErrors = {};

    if (!formData.company) {
        formErrors.company = 'Company is required';
    }

    return formErrors;
};

export const validateCreateRoleForm = (formData, roles) => {
    const formErrors = {};

    if (!formData.name) {
        formErrors.role_name = 'role name is required';
    }
    if (formData.name) {
        const nameExist = roles.some(role => role.name === formData.name);
        if (nameExist) {
            formErrors.role_name = 'role with this name already exist, please enter a unique role name';
        }
    }

    if (formData.modules?.length === 0) {
        formErrors.modules = 'please select atleast one module';
    }

    return formErrors;
};

export const validateFloorPlanForm = formData => {
    const formErrors = {};

    if (!formData.name) {
        formErrors.floor_plan_name = 'Floor name is required';
    }
    if (!formData.type) {
        formErrors.floor_plan_type = 'Floor type is required';
    }

    return formErrors;
};

export const validateRedeemPointsForm = (formData, availablePoints) => {
    const formErrors = {};

    if (!formData.redeem_points) {
        formErrors.redeem_points = 'Please enter redeem points';
    }
    if (formData.redeem_points > availablePoints) {
        formErrors.redeem_points = "You don't have enough points to redeem";
    }

    return formErrors;
};

export const validateModifierForm = formData => {
    const formErrors = {};

    if (!formData.modifierName) {
        formErrors.modifier_name = 'Modifier name is required';
    }
    if (formData.selectedItems.length === 0) {
        formErrors.items = 'Please apply atleast one item on modifier';
    }
    if (formData.modifier.length === 0) {
        formErrors.modifier = 'Please enter atleast one modifier option';
    }
    if (formData.modifier.length > 0) {
        formData.modifier.forEach((mod, index) => {
            const rowId = mod.id;
            if (!mod.modifier_name) {
                formErrors[rowId] = `Modifier name is required`;
            }
        });
    }
    return formErrors;
};

export const validateCreateDeviceForm = formData => {
    const formErrors = {};

    if (!formData.name) {
        formErrors.device_name = 'Device name is required';
    }
    if (!formData.location) {
        formErrors.location = 'Device location is required';
    }

    return formErrors;
};

export const validateOdooForm = formData => {
    const formErrors = {};

    if (!formData.odoo_username) {
        formErrors.username = 'Username is required';
    }
    if (!formData.odoo_password) {
        formErrors.password = 'Password is required';
    }

    if (!formData.odoo_url) {
        formErrors.url = 'Url is required';
    }

    return formErrors;
};

export const validateCreateCustomerForm = async (formData, id) => {
    const formErrors = {};
    let customerAlreadyExist = [];
    if (!formData.name) {
        formErrors.name = 'name is required';
    }

    if (formData?.phone) {
        customerAlreadyExist = await isCustomerExist(formData.phone);
    }

    if (!PHONE_REGEX.test(formData.phone) || (customerAlreadyExist.length > 0 && !id) || !formData.phone) {
        formErrors.phone_number = !formData.phone
            ? 'phone number is required'
            : customerAlreadyExist.length > 0
            ? 'Contact available in the system'
            : 'phone number should be in format 03xxxxxxxxx';
    }

    if (formData.email && !EMAIL_REGEX.test(formData.email)) {
        formErrors.email = 'Enter a valid email';
    }
    return formErrors;
};

export const validateDraftCreateCustomer = async formData => {
    const formErrors = {};

    if (!formData.name) {
        formErrors.name = 'name is required to save into draft.';
    }

    return formErrors;
};

export const validateCreatetWaiterForm = async formData => {
    const formErrors = {};

    if (!formData.name) {
        formErrors.name = 'Name is required';
    }

    if (!formData.phone) {
        formErrors.phone_number = 'Phone number is required';
    }

    if (formData.phone && !PHONE_REGEX.test(formData.phone)) {
        formErrors.phone_number = 'Phone number should be in the format 03xxxxxxxxx';
    }

    if (formData.email && !EMAIL_REGEX.test(formData.email)) {
        formErrors.email = 'Enter a valid email';
    }

    return formErrors;
};

export const validateDraftWaiterForm = async formData => {
    const formErrors = {};

    if (!formData.name) {
        formErrors.name = 'Name is required to save into draft.';
    }

    if (formData.phone && !PHONE_REGEX.test(formData.phone)) {
        formErrors.phone_number = 'Phone number should be in the format 03xxxxxxxxx';
    }

    if (formData.email && !EMAIL_REGEX.test(formData.email)) {
        formErrors.email = 'Enter a valid email';
    }

    return formErrors;
};

export const validateLocationForm = formData => {
    const formErrors = {};

    if (!formData.location_name) {
        formErrors.location_name = 'Location name is required';
    }

    return formErrors;
};

export const validateCompanyCreation = formData => {
    const formErrors = {};

    if (!formData.name) {
        formErrors.name = 'Name is required.';
    }

    if (formData.email && !EMAIL_REGEX.test(formData.email)) {
        formErrors.email = 'Enter a valid email';
    }

    if (formData.phone && !PHONE_REGEX.test(formData.phone)) {
        formErrors.phone = 'Phone number should be in the format 03xxxxxxxxx';
    }

    return formErrors;
};

export const validateCreateOptionForm = async (formData, optionsList) => {
    const formErrors = {};

    console.log('optionsList', optionsList);

    if (!formData.name) {
        formErrors.variation_display_name = 'name is required';
    }

    if (formData.name) {
        const isExist = optionsList.some(opt => opt.name.toLowerCase() === formData.name.toLowerCase());
        console.log('isExist', isExist);
        if (isExist) {
            formErrors.variation_display_name = 'Variation name already present.';
        }
    }

    if (!formData.values.length) {
        formErrors.values = 'Atleast one value is required';
    }

    return formErrors;
};

export const validateDiscountForm = async formData => {
    const formErrors = {};

    if (!formData.name) {
        formErrors.discount_name = 'Please enter discount name';
    }

    if (!formData?.type) {
        formErrors.amount_type = 'Select amount type';
    }
    if ((formData?.type == 'percentage' && formData.limit > 100) || !formData.limit) {
        formErrors.amount = !formData.limit ? 'Please enter amount' : 'Discount should not exceed 100%';
    }
    if (formData?.locationsArray?.length === 0) {
        formErrors.location = 'Please select location';
    }
    if (formData?.enableDateRange && !formData?.start_date) {
        formErrors.start_date = 'Start date is required';
    }
    if (formData?.enableDateRange && !formData?.end_date) {
        formErrors.end_date = 'End date is required';
    }

    return formErrors;
};

export const validateDraftDiscount = async formData => {
    const formErrors = {};

    if (!formData.name) {
        formErrors.discount_name = 'Discount name is required to save into draft.';
    }

    if (formData?.locationsArray?.length === 0) {
        formErrors.location = 'Please select location';
    }

    return formErrors;
};

export const validateCreateItemForm = async (formData, variants, itemDetails) => {
    const formErrors = {
        main: {},
    };
    if (!formData.name) {
        formErrors.main.name = 'Please enter item name';
    }
    if (!formData.category) {
        formErrors.main.category = 'Please select category';
    }
    if (formData?.locationsArray?.length === 0) {
        formErrors.main.location = 'Please select location';
    }
    if (formData?.item_level_discount) {
        if (formData?.item_level_discount?.discountType === 'amount' && Number(formData?.item_level_discount?.discountAmount) > Number(formData?.sellingPrice)) {
            formErrors.main.item_level_discount = 'Discount amount should not exceed selling price';
        }
    }
    if (!formData.unit) {
        formErrors.unit = 'Unit is required';
    }
    if (formData.unit && formData.secondaryUnit && !formData.rate) {
        formErrors.rate = 'Rate is required';
    }
    if (Object.keys(variants).length === 0 && !formData.barcode) {
        formErrors.barcode = 'Barcode is required';
    }
    if (formData.barcode) {
        try {
            if (itemDetails && itemDetails?.barcode == formData.barcode) {
            } else {
                const item = await isBarCodeExist(formData.barcode);
                if (item.length > 0) {
                    formErrors.barcode = 'This barcode is already present';
                }
            }
        } catch (err) {
            formErrors.barcode = 'Please enter barcode';
        }
    }
    if (Object.keys(variants).length === 0 && !formData.sellingPrice) {
        formErrors.price = 'Please enter selling price';
    }
    if (Object.keys(variants).length === 0 && !formData.costPrice) {
        formErrors.cost_per_item = 'Please enter cost price';
    }
    return formErrors;
};

export const validateCreateItemVariantForm = async formData => {
    const formErrors = {
        main: {},
    };
    const barcodeMap = {};

    if (!formData.name) {
        formErrors.main.name = 'Please enter item name';
    }
    if (!formData.category) {
        formErrors.main.category = 'Please select category';
    }
    if (formData?.locationsArray?.length === 0) {
        formErrors.main.location = 'Please select location';
    }
    if (!formData.unit) {
        formErrors.unit = 'Unit is required';
    }
    if (formData.unit && formData.secondaryUnit && !formData.rate) {
        formErrors.rate = 'Rate is required';
    }

    if (formData.item_level_discount) {
        const isPriceExceed = formData.variants.some(variant => Number(formData?.item_level_discount?.discountAmount) > Number(variant.sellingPrice));

        if (isPriceExceed) {
            formErrors.main.item_level_discount = 'Discount amount should not exceed selling price';
        }
    }

    if (Object.values(formData.variants || {}).length > 0) {
        Object.values(formData.variants || {}).forEach(async (variant, index) => {
            const rowName = variant.name;

            if (!variant.barcode) {
                formErrors[rowName] = {
                    ...formErrors[rowName],
                    barcode: 'Barcode is required',
                };
            }

            if (variant.barcode) {
                const isCodeExist = await isBarCodeExist(variant.barcode);
                if (barcodeMap[variant?.barcode] || isCodeExist.length > 0) {
                    formErrors[rowName] = {
                        ...formErrors[rowName],
                        barcode: 'Barcode should be unique',
                    };
                } else {
                    barcodeMap[variant?.barcode] = [variant];
                }
            }

            if (!variant.costPrice) {
                formErrors[rowName] = {
                    ...formErrors[rowName],
                    costPrice: 'Cost price is required',
                };
            }

            if (!variant.sellingPrice) {
                formErrors[rowName] = {
                    ...formErrors[rowName],
                    sellingPrice: 'Selling price is required',
                };
            }
        });
    }

    return formErrors;
};
export const validateSalesTaxForm = formData => {
    const formErrors = {};

    if (!formData.name) {
        formErrors.tax_name = 'Tax name is required';
    }

    if (!formData.rate) {
        formErrors.tax_rate = 'Please enter tax rate %';
    }

    if (formData.rate && formData.rate > 100) {
        formErrors.tax_rate = 'Rate should not exceed 100%';
    }

    return formErrors;
};

export const validateDraftSalesTaxForm = formData => {
    const formErrors = {};

    if (!formData.name) {
        formErrors.tax_name = 'Tax name is required';
    }

    return formErrors;
};

export const validateBeneficiaryModal = beneficiaries => {
    const formErrors = {};

    if (beneficiaries.length === 0) {
        formErrors.message = 'Please select atleast one beneficiary';
    }

    return formErrors;
};

export function convertToArray(value) {
    if (!Array.isArray(value)) {
        return [value];
    }
    return value;
}
export const validateCreateTeamForm = async (formData, memberDetails) => {
    const formErrors = {};

    const roleModules = await formData.userRole.module.fetch();
    const isPosActive = roleModules.some(mod => mod.name === 'pos');

    if (formData.cnicNumber && !CNIC_REGEX.test(formData.cnicNumber)) {
        formErrors.cnic_number = 'Cnic number should be in format xxxxxxxxxxxxx';
    }
    if (!formData?.userRole) {
        formErrors.select_role = 'Please select role';
    }
    if (formData?.locationsArray?.length === 0) {
        formErrors.location = 'Please select location';
    }
    if (!formData?.email) {
        formErrors.email = 'Please enter email';
    }
    if (formData?.email && !EMAIL_REGEX.test(formData?.email)) {
        formErrors.email = 'Please enter valid email';
    }
    if (formData.email) {
        try {
            if (memberDetails);
            else {
                try {
                    await getCheckUseremail({email: formData.email});
                } catch (err) {
                    formErrors.email = err.response.data.msg;
                    console.log('err', err);
                }
            }
        } catch (err) {
            formErrors.email = 'Email already exists';
        }
    }

    if (!memberDetails && isPosActive && formData?.posPin?.length !== 4) {
        formErrors.pos_pin = '4 digit pin code is required';
    }

    if (!memberDetails && !formData.password) {
        formErrors.password = 'Password is required.';
    }
    if (!memberDetails && formData.password && !isValidPassword(formData.password)) {
        formErrors.password = 'Password must be at least 8 characters long, include 1 uppercase letter, 1 lowercase letter, 1 number, and 1 special character.';
    }

    if (!NAME_REGEX.test(formData.firstname)) {
        formErrors.first_name = 'Please enter valid first name';
    }
    if (!NAME_REGEX.test(formData.lastname)) {
        formErrors.last_name = 'Please enter valid last name';
    }
    if (!PHONE_REGEX.test(formData.phone)) {
        formErrors.phone = 'Phone number should be in format 03xxxxxxxxx';
    }
    if (formData.phone) {
        try {
            if (memberDetails && memberDetails?.phone_number == formData.phone);
            else {
                try {
                    await getCheckPhoneNumber({phoneNumber: formData.phone});
                } catch (err) {
                    formErrors.phone = err.response.data.msg;
                    console.log('err', err);
                }
            }
        } catch (err) {
            formErrors.phone = 'Phone number already exists';
        }
    }

    return {formErrors, isPosActive};
};

export const validatePayoutForm = (formData, currenetBalance, totalAmount) => {
    const formErrors = {};

    if (formData.length === 0) {
        formErrors.error = 'Please add at least one beneficiary to continue';
    }

    if (totalAmount > currenetBalance) {
        formErrors.limit_exceed = `Low balance: Your current balance is Rs. ${formatNum(currenetBalance)}. Please adjust the payment amount`;
    }

    formData.forEach((beneficiary, index) => {
        const rowName = beneficiary.beneficiary_id;

        if (!beneficiary.amount) {
            formErrors[rowName] = {
                ...formErrors[rowName],
                amount: 'Please enter amount',
            };
        }
    });
    return formErrors;
};

export const filteredObjects = (clonePO, updatePO) => {
    const updatePOS = [];
    const addPOS = [];
    let deletePOS = [...clonePO];

    updatePO.forEach(item => {
        let isUpdatedElem = clonePO.find(po => po.id === item.id);

        if (isUpdatedElem) {
            updatePOS.push({ref: isUpdatedElem, updatedObj: item});
            deletePOS = deletePOS.filter(deletePo => deletePo.id !== item.id);
        } else {
            addPOS.push(item);
        }
    });
    return {addPOS, deletePOS, updatePOS};
};

export const filterOptions = (cloneObj, updateObj, from) => {
    const updationArray = [];
    const additionArray = [];
    let deletionArray = [...cloneObj];

    const getId = item => (from === 'business' ? item.business.id : item.id);

    updateObj.forEach(item => {
        let isUpdatedElem = cloneObj.find(x => getId(x) === item.id);

        if (isUpdatedElem) {
            updationArray.push({ref: isUpdatedElem, updatedObj: item});
            deletionArray = deletionArray.filter(del => getId(del) !== item.id);
        } else {
            additionArray.push(item);
        }
    });

    return {additionArray, deletionArray, updationArray};
};

export const filterProductRelation = (cloneObj, updateObj) => {
    const additionProdArray = [];
    const updationArray = [];
    let deletionProdArray = [...cloneObj];

    updateObj.forEach(obj => {
        let isExist = cloneObj.find(x => x.id === obj.item.id);
        if (isExist) {
            updationArray.push({ref: isExist, updatedObj: obj});
            deletionProdArray = deletionProdArray.filter(del => del.id !== obj.item.id);
        } else {
            additionProdArray.push(obj);
        }
    });

    return {additionProdArray, updationArray, deletionProdArray};
};

export const compareOrderLines = (oldOrderLines, newOrderLines, currentStatus) => {
    let status = currentStatus;
    const oldOrderLinesMap = new Map(oldOrderLines.map(line => [line.id, line]));

    for (let newLine of newOrderLines) {
        const oldLine = oldOrderLinesMap.get(newLine.id);
        if (!oldLine) {
            status = PREPARING;
            break;
        } else if (newLine.quantity > oldLine.quantity) {
            status = PREPARING;
            break;
        }
    }

    if (status === 'active' || status === 'hold') {
        status = PREPARING;
    }
    return status;
};

export const filteredRoles = (cloneRole, updatedRole) => {
    const addModule = [];
    let deleteModule = [...cloneRole];

    updatedRole.forEach(item => {
        let isRoleExist = cloneRole.find(role => role.name === item.name);

        if (isRoleExist) {
            deleteModule = deleteModule.filter(role => role.name !== item.name);
        } else {
            addModule.push(item);
        }
    });
    return {addModule, deleteModule};
};

export const calculateTotal = (itemData, orderLevelDiscount) => {
    let subTotal = 0;
    let totalDiscount = 0;
    let totalTax = 0;

    itemData.forEach(item => {
        subTotal += item.unit_cost * item.qty || 0;
        totalDiscount += item.discount || 0;
        totalTax += (item?.unit_cost * item?.qty * item?.taxes?.rate) / 100 || 0;
    });

    let total = subTotal - totalDiscount + totalTax;
    if (orderLevelDiscount?.type == 'percentage') {
        const percent = (total / 100) * orderLevelDiscount.value;
        total = total - percent;
    }
    if (orderLevelDiscount?.type == 'amount') {
        const amount = orderLevelDiscount?.value;
        total = total - amount;
    }

    return {subTotal, totalDiscount, totalTax, total};
};

export const isDateDisabled = (date, poDate) => {
    if (poDate) {
        return dayjs(date).isBefore(dayjs(poDate), 'day');
    }
    return true;
};

export const getDayComparison = inputDate => {
    const inputDateTime = new Date(inputDate);
    const today = new Date();
    if (inputDateTime.toISOString().split('T')[0] === today.toISOString().split('T')[0]) {
        return 'Today';
    }

    today.setDate(today.getDate() - 1);
    if (inputDateTime.toISOString().split('T')[0] === today.toISOString().split('T')[0]) {
        return 'Yesterday';
    }

    return inputDate;
};

export const convertArrayToObj = array => {
    const result = array.reduce((acc, curr) => {
        acc[curr.business.id] = curr.business.id;
        return acc;
    }, {});

    return result;
};

export const getLocationNames = (locationsObj, selectedLocations) => {
    const locationNames = Object.values(selectedLocations || {})
        .map(locationKey => {
            return locationsObj?.[locationKey]?.location_name;
        })
        .filter(Boolean);

    if (locationNames.length === 0) {
        return '';
    }

    return locationNames.join(', ');
};

export const getCommaSeparatedNames = (array, from) => {
    const names = from === 'modifier' ? array.map(item => item.name) : array;
    if (names.length <= 2) {
        return names.join(', ');
    } else {
        const firstTwoNames = names.slice(0, 2).join(', ');
        const remainingCount = names.length - 2;
        return `${firstTwoNames}, +${remainingCount} more`;
    }
};

export const formatDateComparison = userDate => {
    if (userDate) {
        const userDateTime = new Date(userDate);
        const currentDate = new Date();

        userDateTime.setHours(0, 0, 0, 0);
        currentDate.setHours(0, 0, 0, 0);

        const timeDifference = currentDate.getTime() - userDateTime.getTime();
        const dayDifference = timeDifference / (1000 * 3600 * 24);

        if (dayDifference === 0) {
            return 'today';
        } else if (dayDifference === 1) {
            return 'yesterday';
        } else if (dayDifference === 2) {
            return '2 days Ago';
        } else {
            return formatDate(userDateTime);
        }
    }
};

export const generateRandomNumbers = () => {
    let randomNumber = '';

    for (let i = 0; i < 3; i++) {
        const digit = Math.floor(Math.random() * 10);
        randomNumber += digit;
    }

    return `# ${parseInt(randomNumber, 10)}`;
};
export const exportDataToCSV = async (type, Csvdata = []) => {
    const headings =
        type === 'customer'
            ? Csvdata.length
                ? [CSV_HEADER_VALUES?.customer.display_heading]
                : [CSV_HEADER_VALUES?.customer.create_heading]
            : type === 'discount'
            ? Csvdata.length
                ? [CSV_HEADER_VALUES?.discount.display_heading]
                : [CSV_HEADER_VALUES?.discount.create_heading]
            : type === 'tax'
            ? Csvdata.length
                ? [CSV_HEADER_VALUES?.tax.display_heading]
                : [CSV_HEADER_VALUES?.tax.create_heading]
            : type === 'item'
            ? Csvdata.length
                ? [CSV_HEADER_VALUES?.item.display_heading]
                : [CSV_HEADER_VALUES?.item.create_heading]
            : null;

    try {
        let mergedArray = [];
        if (Csvdata?.length) {
            await Promise.all(
                Csvdata?.map(async item => {
                    if (type === 'customer') {
                        const {name, phone_number, created_at} = item;
                        let orders = await item.order.fetch();
                        let total = orders.reduce((total, val) => total + val.total, 0);

                        const customerObj = {
                            Name: name,
                            'Phone #': phone_number,
                            'Last visited': orders.length ? formatDate(orders[orders.length - 1].completed_at) : '-',
                            Visits: `${orders.length} visit${orders.length === 1 ? '' : 's'}`,
                            Created: created_at ? formatDate(created_at) : '-',
                            'Average spend': formatNum(total / orders.length || 0),
                            'Credit amount': '-',
                            'Total spent': formatNum(total || 0),
                        };

                        mergedArray.push(customerObj);
                    } else if (type === 'discount') {
                        const {name, limit, type, total_given} = item;

                        const discountObj = {
                            Name: name,
                            Location: '-',
                            Rate: limit,
                            Type: type == 'amount' ? 'Rs.' : '%',
                            Given: total_given,
                        };
                        mergedArray.push(discountObj);
                    } else if (type === 'tax') {
                        const {name, is_selected_items, rate} = item;
                        const AppliedOnItemCount = await item.product.fetchCount();

                        const Taxobj = {
                            Name: name,
                            Type: item?.table == 'sales_tax' ? 'Sales' : 'Purchase',
                            Rate: rate,
                            'Tax application': is_selected_items ? 'Selected items' : 'Multiple items',
                            'Applied on # of items': AppliedOnItemCount,
                        };
                        mergedArray.push(Taxobj);
                    } else if (type === 'item') {
                        let category = await item?.category?.fetch();
                        let template = await item?.product_template?.fetch();
                        let stock = `${formatNum(item?.quantity)} ${template?.unit}`;
                        const itemobj = {
                            Items: item?.name,
                            Category: category?.name,
                            'Stock available': stock,
                            'Low stock': formatNum(item.low_stock) || 0,
                            Price: `Rs.${formatNum(item.selling_price)}`,
                            Image: template?.image,
                        };
                        mergedArray.push(itemobj);
                    }
                }),
            );
        }

        let demoData =
            type === 'customer'
                ? CSV_TEMPLATE_DUMMY_DATA.customer
                : type === 'discount'
                ? CSV_TEMPLATE_DUMMY_DATA.discount
                : type === 'tax'
                ? CSV_TEMPLATE_DUMMY_DATA.tax
                : type === 'item'
                ? CSV_TEMPLATE_DUMMY_DATA.item
                : [];

        const array = Csvdata.length ? mergedArray : demoData;
        const wb = utils.book_new();
        const ws = utils.json_to_sheet([]);

        utils.sheet_add_aoa(ws, headings);
        utils.sheet_add_json(ws, array, {origin: 'A2', skipHeader: true});

        utils.book_append_sheet(wb, ws, 'Report');

        // Use a constant for the filename
        const fileName = Csvdata.length ? 'report.xlsx' : 'template.xlsx';
        writeFile(wb, fileName);
    } catch (error) {
        console.log('Error exporting data to CSV', error);
    }
};

export const compareStrings = (str1, str2) => {
    const cleanStr1 = str1.replace(/\s/g, '').toLowerCase();
    const cleanStr2 = str2.replace(/\s/g, '').toLowerCase();

    if (cleanStr1 === cleanStr2) {
        return true;
    } else {
        return false;
    }
};

export const fetchFileData = async excelData => {
    const response = await fetch(excelData);
    const arrayBuffer = await response.arrayBuffer();

    const wb = read(arrayBuffer, {type: 'array'});
    const sheets = wb.SheetNames;

    if (sheets.length) {
        const rows = utils.sheet_to_json(wb.Sheets[sheets[0]]);
        const rowsWithId = rows.map(row => ({
            ...row,
            id: uuid(),
        }));

        return rowsWithId;
    }
};

export const taxCsvDatavalidate = data => {
    const taxErrors = {};
    data.forEach(item => {
        const formErrors = {};

        const {
            'Tax Name': name,
            'Tax Type (Sales / Purchase)': type,
            'Tax rate %': rate,
            'Tax behavior': tax_behavior,
            'Tax application': tax_application,
            'Tax Calculation': tax_calculation,
            id,
        } = item;

        if (!name) {
            formErrors.name = {
                message: 'Name is required',
            };
        }

        if (!rate || !NumberRegex.test(rate)) {
            formErrors.rate = {
                message: !rate ? 'Rate is required' : 'Enter valid rate',
            };
        }

        if (rate > 100) {
            formErrors.rate = {
                message: 'Rate should not exceed 100%',
            };
        }
        let is_tax_type_valid = type && (compareStrings(type, 'sales') || compareStrings(type, 'purchase')) ? true : false;

        if (!type || !is_tax_type_valid) {
            formErrors.type = {
                message: !type ? 'Type is required' : 'Enter valid type',
            };
        }

        let is_tax_behavior_valid = tax_behavior && (compareStrings(tax_behavior, 'inclusive') || compareStrings(tax_behavior, 'exclusive')) ? true : false;

        if (tax_behavior && !is_tax_behavior_valid) {
            formErrors.tax_behavior = {
                message: 'Enter valid tax behavior',
            };
        }

        let is_tax_calculation_valid = tax_calculation && (tax_calculation?.toLowerCase()?.includes('pre') || tax_calculation?.toLowerCase()?.includes('post')) ? true : false;

        if (tax_calculation && !is_tax_calculation_valid) {
            formErrors.tax_calculation = {
                message: 'Enter valid tax calculation',
            };
        }

        let is_tax_application_valid = tax_application && (tax_application?.toLowerCase()?.includes('all') || tax_application?.toLowerCase()?.includes('select')) ? true : false;

        if (tax_application && !is_tax_application_valid) {
            formErrors.tax_application = {
                message: 'Enter valid tax application',
            };
        }

        if (Object.keys(formErrors).length > 0) {
            taxErrors[id] = formErrors;
        }
    });
    return taxErrors;
};

export const handleOrderStatus = (status, theme) => {
    let data;
    status = status?.toLowerCase();
    if (status == 'succeeded' || status == 'settled' || status == 'deposited' || status == 'complete') {
        data = {
            backgroundColor: theme.greenshadeNew,
            titleColor: theme.brightGreen,
            borderColor: theme.brightGreen,
            title: status == 'deposited' || status == 'complete' ? 'Settled' : status == 'succeeded' ? 'Succeeded' : 'Settled',
            prefix: {
                iconName: 'singleTicketIcon',
                iconColor: theme.brightGreen,
                height: 14,
                width: 14,
                viewBox: '0 0 18 18',
            },
        };
    } else if (status == 'in_processing') {
        data = {
            backgroundColor: theme.white,
            titleColor: theme.mediumGray,
            borderColor: theme.mediumGray,
            title: 'In processing',
            prefix: {
                iconName: 'pendingPaymentIcon',
                iconColor: theme.mediumGray,
                height: 14,
                width: 14,
                viewBox: '0 0 18 18',
            },
        };
    } else if (status == 'on_the_way') {
        data = {
            backgroundColor: theme.lightOrange,
            titleColor: theme.brightOrange,
            borderColor: theme.brightOrange,
            title: 'On the way',
        };
    } else if (status == 'deposit' || status == 'deposits') {
        data = {
            backgroundColor: theme.blackBg,
            titleColor: theme.brightGreen,
            borderColor: theme.brightGreen,
            title: 'deposited',
            icon: 'depositIcon',
            iconColor: theme.brightGreen,
            height: 14,
            width: 14,
            viewBox: '0 0 18 18',
        };
    } else if (status == 'refund') {
        data = {
            backgroundColor: theme.lightOrange,
            titleColor: theme.brightOrange,
            borderColor: theme.brightOrange,
            title: 'refund',
            icon: 'depositIcon',
            iconColor: theme.brightOrange,
            height: 14,
            width: 14,
            viewBox: '0 0 18 18',
        };
    } else if (status == 'payout') {
        data = {
            backgroundColor: theme.blackBg,
            titleColor: theme.mustardYellow,
            borderColor: theme.mustardYellow,
            title: 'payout',
            icon: 'payoutNewIcon',
            iconColor: theme.mustardYellow,
            height: 14,
            width: 14,
            viewBox: '0 0 18 18',
        };
    } else {
        data = {
            backgroundColor: theme.white,
            titleColor: theme.red,
            borderColor: theme.red,
            title: 'Failed',
            prefix: {
                iconName: 'crossIcon',
                iconColor: theme.red,
                height: 14,
                width: 14,
                viewBox: '0 0 18 18',
            },
        };
    }
    return data;
};

export const DiscountCsvDatavalidate = data => {
    const discountErrors = {};
    data?.forEach(item => {
        const formErrors = {};

        const {name, type, limit, max_discount_cap, id} = item;

        if (!name) {
            formErrors.name = {
                message: 'Name is required',
            };
        }

        if (!limit || !NumberRegex.test(limit)) {
            formErrors.limit = {
                message: !limit ? 'amount is required' : 'Enter valid amount',
            };
        }

        let is_tax_type_valid = type && (type?.includes('%') || type?.includes('rs') || type?.includes('amount') || type?.includes('percentage')) ? true : false;

        if (!type || !is_tax_type_valid) {
            formErrors.type = {
                message: !type ? 'Type is required' : 'Enter valid type',
            };
        }

        if (max_discount_cap && !NumberRegex.test(max_discount_cap)) {
            formErrors.max_discount_cap = {
                message: 'Discount max_discount_cap accept only number',
            };
        }

        if (Object.keys(formErrors).length > 0) {
            discountErrors[id] = formErrors;
        }
    });
    return discountErrors;
};
export const getDuplicateBarcodes = data => {
    const barcodeMap = {};

    data.forEach(item => {
        const barcode = item['Barcode (is Optional)'];
        if (barcodeMap[barcode]) {
            barcodeMap[barcode].push(item);
        } else {
            barcodeMap[barcode] = [item];
        }
    });

    const itemsWithDuplicateBarcodes = Object.values(barcodeMap).filter(itemsArray => itemsArray.length > 1);

    return itemsWithDuplicateBarcodes;
};
export const itemCsvDatavalidate = (data, products) => {
    const itemError = {};
    const barcodeMap = {};

    data?.forEach(item => {
        const formErrors = {};

        const {barcode, category, cost_per_item, description, id, low_stock_alert, name, price, qty, units} = item;

        let unit = units?.toLowerCase();

        if (!name) {
            formErrors.name = {
                message: 'Name is required',
            };
        }

        if (!price || !PRICE_REGEX.test(price)) {
            formErrors.price = {
                message: !price ? 'Price is required' : 'Accept only numbers',
            };
        }

        if (!cost_per_item || !PRICE_REGEX.test(cost_per_item)) {
            formErrors.cost_per_item = {
                message: !cost_per_item ? 'Price is required' : 'Accept only numbers',
            };
        }

        if (!category) {
            formErrors.category = {
                message: 'Category is required',
            };
        }

        if (qty && !NumberRegex.test(qty)) {
            formErrors.qty = {
                message: 'Accept only numbers',
            };
        }

        if (low_stock_alert && !NumberRegex.test(low_stock_alert)) {
            formErrors.low_stock_alert = {
                message: 'Accept only numbers',
            };
        }
        if (low_stock_alert && !NumberRegex.test(low_stock_alert)) {
            formErrors.low_stock_alert = {
                message: 'Accept only numbers',
            };
        }
        if (!barcode) {
            formErrors.barcode = {
                message: 'Barcode is required',
            };
        }
        if (barcode) {
            const isBarCodeExist = products?.some(prod => prod?.barcode?.toString() === barcode?.toString());
            if (barcodeMap[barcode] || isBarCodeExist) {
                formErrors.barcode = {
                    message: 'Barcode should be unique',
                };
            } else {
                barcodeMap[barcode] = [item];
            }
        }
        let is_unit_valid = unit && UNITS.some(item => item.name.toLowerCase() === unit);

        if (!unit || !is_unit_valid) {
            formErrors.unit = {
                message: !unit ? 'Unit is required' : 'Please enter this unit(Box,Bags,Bottles,Cartons,Pieces)',
            };
        }

        if (Object.keys(formErrors).length > 0) {
            itemError[id] = formErrors;
        }
    });

    return itemError;
};

export const isValidDateFormat = date => {
    if (date) {
        return DATE_REGEX.test(date?.toString());
    }
};

export const CustomerCsvDatavalidate = async data => {
    const CustomerError = {};
    let obj = {};

    for (const item of data) {
        const formErrors = {};
        let customerAlreadyExist = [];
        const {name, phone_number, email, dob, province, city, country, id} = item;

        if (obj[phone_number]) {
            obj[phone_number] += 1;
        } else {
            obj[phone_number] = 1;
        }

        if (!name) {
            formErrors.name = {
                message: 'One or more names are not available',
            };
        }

        if (phone_number) {
            customerAlreadyExist = await isCustomerExist(phone_number);
        }

        if (!phone_number) {
            formErrors.phone_number = {
                message: 'One or more phones are not available',
            };
        } else if (!PHONE_REGEX.test(phone_number)) {
            formErrors.phone_number = {
                message: 'Enter a valid phone number',
            };
        } else if (customerAlreadyExist?.length > 0) {
            formErrors.phone_number = {
                message: 'This phone number is already present',
            };
        }

        if (obj[phone_number] > 1) {
            formErrors.phone_number = {
                message: 'One contact is repeated multiple times',
            };
        }

        if (email && !EMAIL_REGEX.test(email)) {
            formErrors.email = {
                message: 'Enter a valid email',
            };
        }

        if (dob && !isValidDateFormat(dob)) {
            formErrors.dob = {
                message: 'Enter a valid date format (date mon year)',
            };
        }

        if (province && !ALPHABET_REGIX.test(province)) {
            formErrors.province = {
                message: 'Enter a valid province',
            };
        }

        if (country && !ALPHABET_REGIX.test(country)) {
            formErrors.country = {
                message: 'Enter a valid country',
            };
        }

        if (city && !ALPHABET_REGIX.test(city)) {
            formErrors.city = {
                message: 'Enter a valid city',
            };
        }

        if (Object.keys(formErrors).length > 0) {
            CustomerError[id] = formErrors;
        }
    }

    return CustomerError;
};

export const handleUpdateProductQty = (val, qty, type, orderLines, order, businessType) => {
    let existLine = null;

    if (businessType === RESTAURANT) {
        existLine = orderLines?.find(x => x?.id == val?.id);
    } else {
        existLine = orderLines?.find(x => x?.product?.id == val?.product?.id);
    }

    if (existLine) {
        const quantity = type === 'increment' ? Number(existLine?.quantity + 1) : type == 'decrement' ? Number(existLine?.quantity - 1) : qty;
        existLine?.updateQuantity(quantity);
    } else {
        order?.createOrderLine(val);
    }
};

export const validateDraftItem = async formData => {
    const formErrors = {
        main: {},
    };
    if (!formData.name) {
        formErrors.main.name = 'Please enter item name';
    }
    if (!formData.category) {
        formErrors.main.category = 'Please select category';
    }
    if (formData?.locationsArray?.length === 0) {
        formErrors.main.location = 'Please select location';
    }
    return formErrors;
};

export const FILTER_MONTHS = [
    {filter: 'Today', value: 'day'},
    {filter: 'Yesterday', value: 'day'},
    {filter: 'This week', value: 'week'},
    {filter: 'Last week', value: 'week'},
    {filter: 'This month', value: 'month'},
    {filter: 'Last month', value: 'month'},
    {filter: 'This year', value: 'year'},
    {filter: 'Last year', value: 'year'},
    {filter: 'Custom', value: 'custom'},
];

export const shortcutsItems = [
    {
        label: FILTER_MONTHS[0].filter, // Today
        getValue: () => {
            const date = dayjs();
            return [date.startOf('day'), date.endOf('day')];
        },
    },
    {
        label: FILTER_MONTHS[1].filter, // Yesterday
        getValue: () => {
            const date = dayjs().subtract(1, 'day');
            return [date.startOf('day'), date.endOf('day')];
        },
    },
    {
        label: FILTER_MONTHS[2].filter,
        getValue: () => {
            const date = dayjs();
            return [date.startOf('week'), date.endOf('week')];
        },
    },
    {
        label: FILTER_MONTHS[3].filter,
        getValue: () => {
            const date = dayjs().subtract(1, 'week');
            return [date.startOf('week'), date.endOf('week')];
        },
    },
    {
        label: FILTER_MONTHS[4].filter,
        getValue: () => {
            const date = dayjs();
            return [date.startOf('month'), date.endOf('month')];
        },
    },
    {
        label: FILTER_MONTHS[5].filter,
        getValue: () => {
            const date = dayjs().subtract(1, 'month');
            return [date.startOf('month'), date.endOf('month')];
        },
    },
    {
        label: FILTER_MONTHS[6].filter,
        getValue: () => {
            const date = dayjs();
            return [date.startOf('year'), date.endOf('year')];
        },
    },
    {
        label: FILTER_MONTHS[7].filter,
        getValue: () => {
            const date = dayjs().subtract(1, 'year');
            return [date.startOf('year'), date.endOf('year')];
        },
    },
];

export const validateSendEmailForm = formData => {
    const formErrors = {};

    if (!formData?.email) {
        formErrors.enter_email_address = 'Please enter email';
    }
    if (formData?.email && !EMAIL_REGEX.test(formData.email)) {
        formErrors.enter_email_address = 'Please enter valid email';
    }

    return formErrors;
};

export const handleDashboardHeader = (val, I18n) => {
    const DashboardListHeaders = {
        top_selling_items: [I18n.item_name, I18n.qty, I18n.unit_cost, `${I18n.unit_cost} %`, I18n.sale_price, I18n.amount, I18n.margin, I18n.contribution],
        low_selling_items: [I18n.item_name, I18n.qty, I18n.unit_cost, `${I18n.unit_cost} %`, I18n.sale_price, I18n.amount, I18n.margin, I18n.contribution],
        low_stock_items: [I18n.item_name, I18n.current_stock, I18n.low_stock_level, I18n.stock_needed],
        location_wise_sales: [I18n.location, I18n.net_sale, I18n.orders, I18n.tax_Collected, I18n.gross_sales, I18n.average_net_sales],
    };

    const defaultHeaders = [I18n.item_name, I18n.category, I18n.current_stock, I18n.low_stock_level, I18n.stock_needed];

    return DashboardListHeaders[val] || defaultHeaders;
};

export const validateTableData = formData => {
    const formErrors = {};

    if (!formData?.name) {
        formErrors.table_name = 'Please enter table name';
    }
    if (!formData?.seats) {
        formErrors.no_of_seats = 'Please enter no of seats';
    }

    if (!formData?.shape) {
        formErrors.table_shape = 'Please select table shape';
    }
    if (!formData?.height) {
        formErrors.table_height = 'Please enter table height';
    }
    if (!formData?.width) {
        formErrors.table_width = 'Please enter table width';
    }
    return formErrors;
};

export const validateCreateBeneficiaryForm = formData => {
    const formErrors = {};

    if (!formData?.name) {
        formErrors.enter_name = 'Please enter beneficiary name';
    }
    if (!formData?.bank) {
        formErrors.select_bank = 'Please select bank';
    }
    if (!formData?.accountNumber) {
        formErrors.enter_account_number = 'Please enter account number';
    }
    if (formData?.accountNumber && formData.bankDetails.valid_sizes.length > 0) {
        console.log('formData.bankDetails.valid_sizes', formData.bankDetails.valid_sizes);
        if (!formData.bankDetails.valid_sizes.includes(formData.accountNumber.length)) {
            formErrors.enter_account_number = 'Please enter valid account number';
        }
    }

    if (formData.phone && !PHONE_REGEX.test(formData.phone)) {
        formErrors.enter_phone_number = 'Phone number should be in the format 03xxxxxxxxx';
    }

    return formErrors;
};

export const formateIdForApi = (data, Id) => {
    const formattedIds = Object.keys(data)
        .filter(id => id !== 'all')
        .map(id => `${id}`)
        .join(',');
    return formattedIds || Id;
};

export const formatPhoneNumber = (country, phoneNumber) => {
    const countryName = country.toLowerCase();

    switch (countryName) {
        case 'singapore':
            // Format Singaporean phone numbers
            phoneNumber = phoneNumber.toString().replace(/(\d{4})(\d{4})/, '$1 $2');
            return phoneNumber;

        case 'uae':
            // Format UAE phone numbers
            phoneNumber = phoneNumber.toString().replace(/(\d{3})(\d{3})(\d{4})/, '$1 $2 $3');
            return phoneNumber;

        case 'pakistan':
            // Format Pakistani phone numbers
            phoneNumber = phoneNumber.toString();
            if (phoneNumber.startsWith('0')) {
                phoneNumber = phoneNumber.slice(1); // Remove leading zero
            }
            phoneNumber = '+92' + phoneNumber; // Add country code
            phoneNumber = phoneNumber.replace(/(\d{4})(\d{7})/, '$1 $2');
            return phoneNumber;

        default:
            return phoneNumber; // Return unformatted number for unsupported countries
    }
};

export const getConcisedId = inputString => {
    if (inputString.length <= 16) {
        return inputString;
    }
    return inputString.slice(-16);
};

export const downloadCsvVReport = (csvData, filename = 'report.csv') => {
    try {
        const blob = new Blob([csvData], {type: 'text/csv'});

        const url = window.URL.createObjectURL(blob);
        const link = document.createElement('a');
        link.href = url;
        link.setAttribute('download', filename);

        document.body.appendChild(link);
        link.click();

        link.remove();
        window.URL.revokeObjectURL(url);
    } catch (error) {
        console.error('Error downloading CSV:', error);
    }
};
