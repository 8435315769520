import {database} from '..';
import {PRODUCT_MODIFIER_SCHEMA} from '../schema';

const productModifier = database.collections.get(PRODUCT_MODIFIER_SCHEMA);

const prepareInsertion = (modifier, selectedItems) => {
    return selectedItems.map(elem => {
        return productModifier.prepareCreate(obj => {
            obj.modifier.set(modifier);
            obj.product.set(elem.item);
            obj.archive = false;
        });
    });
};

export const bulkCreateProductModifierRelation = async (modifier, selectedItems) => {
    return await database.write(async () => {
        const allRecords = await prepareInsertion(modifier, selectedItems);
        await database.batch(...allRecords);
        return allRecords;
    });
};

const prepareUpdation = async (modifier, selectedOptions) => {
    return await Promise.all(
        selectedOptions.map(async elem => {
            try {
                const prodMod = await modifier.product_modifier.fetch();
                const modToDelete = prodMod.find(item => item.modifier.id === modifier.id);
                return modToDelete.prepareMarkAsDeleted();
            } catch (err) {
                console.log('Error in prepareDeletion', err);
            }
        }),
    );
};

export const bulkUpdateProductModifierRelation = async (modifier, selectedOptions) => {
    await database.write(async () => {
        const allRecords = await prepareUpdation(modifier, selectedOptions);
        await database.batch(...allRecords);
        return allRecords;
    });
};
