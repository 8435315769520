import {CustomContainer} from '../../../../common';
import CreateDealComponent from '../../../../common/CreateDealComponent';

const CreateDeal = () => {
    return (
        <>
            <CustomContainer className="addScreenMainBox  createCustomerContainer">
                <CreateDealComponent />
            </CustomContainer>
        </>
    );
};

export default CreateDeal;
