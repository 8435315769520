import React from 'react';
import usePermission from '../hooks/usePermission';
import {useAuthContext, useLanguageContext, useThemeContext} from '../context';
import {NonIdealScreen} from '../components/common';
import {RESTAURANT} from '../constants';
import {useNavigate} from 'react-router-dom';

const ProtectedRoute = ({children, permissionName, allowedBusinessTypes}) => {
    const {theme} = useThemeContext();
    const {I18n} = useLanguageContext();
    const userPermission = usePermission(permissionName);
    const {business} = useAuthContext();
    let navigate = useNavigate();

    const navigateToHome = () => {
        if (business.type === RESTAURANT) {
            navigate('/pos-cockpit');
        } else {
            navigate('/pos');
        }
    };

    if (userPermission && allowedBusinessTypes.includes(business.type)) {
        return children;
    }
    return (
        <NonIdealScreen
            heading={I18n.page_not_found}
            subHeading={I18n.this_page_doesnot_exist}
            name="notFoundIcon"
            fill={theme.white}
            viewBox="0 0 100 100"
            height="100"
            width="100"
            secondaryCta={{
                title: I18n.go_back_to_home_page,
                action: () => navigateToHome(),
            }}
            isMultiStep={false}
        />
    );
};

export default ProtectedRoute;
