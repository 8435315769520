import {database} from '..';
import {uuid} from '../../constants';
import {ORDER_LINE_SCHEMA} from '../schema';
import {getDealProducts} from './deal_product';

const orderLine = database.collections.get(ORDER_LINE_SCHEMA);

export const bulkDeleteOrderLines = async lines => {
    await database.write(async () => {
        const bulkDelete = lines.map(item => item.prepareDestroyPermanently());
        await database.batch(...bulkDelete);
    });
};

export const bulkUpdateOrderLinesCal = async lines => {
    await database.write(async () => {
        const allRecords = await prepareUpdateOrderLines(lines);
        await database.batch(...allRecords);
    });
};

const prepareUpdateOrderLines = async lines => {
    return await Promise.all(
        lines.map(async line => {
            try {
                return line.prepareUpdate(field => {
                    let discountValue;
                    if (line.discount_type === 'amount') {
                        discountValue = line.discount;
                    } else if (line.discount_type === 'percentage') {
                        discountValue = line.base_price * (line.discount / 100);
                    } else {
                        discountValue = 0;
                    }

                    field.aggregate_discount = discountValue * line.quantity;

                    if (line.tax_rate) {
                        if (line.is_tax_inclusive) {
                            field.aggregate_tax = (line.selling_price - line.base_price) * line.quantity;
                        } else {
                            field.aggregate_tax = (line.selling_price / line.tax_rate) * line.quantity;
                        }
                    } else {
                        field.aggregate_tax = 0;
                    }

                    field.sub_total = line.base_price * line.quantity;
                    field.total = field.sub_total + field.aggregate_tax - field.aggregate_discount;
                });
            } catch (err) {
                console.log('err in prepareUpdate', err);
            }
        }),
    );
};

const getProducts = async lines => {
    let products = []; // Initialize the products array outside the loop

    const findProductIndex = (products, productId, selectedUnit) => {
        console.log('products', products);
        console.log('productId', productId);
        console.log('selectedUnit', selectedUnit);

        return products.findIndex(p => p.product.id === productId && p.selectedUnit === selectedUnit);
    };

    try {
        await Promise.all(
            lines.map(async line => {
                const selectedUnit = line.unit;
                const lineQuantity = line.quantity;
                const product = await line.product.fetch();

                // Check if product is a deal or a single product
                if (product.type === 'deal') {
                    const deal = await product.deal.fetch();
                    const dealProducts = await getDealProducts(deal.id);

                    // Fetch deal products and handle quantity aggregation
                    await Promise.all(
                        dealProducts.map(async dealProduct => {
                            const prod = await dealProduct.product.fetch();
                            const index = findProductIndex(products, prod.id, selectedUnit);

                            if (index !== -1) {
                                // Product exists, update the quantity
                                products[index].quantity += dealProduct.quantity;
                            } else {
                                // New product, add to array
                                products.push({
                                    product: prod,
                                    quantity: dealProduct.quantity,
                                    lineQuantity,
                                    selectedUnit,
                                });
                            }
                        }),
                    );
                } else {
                    // Handle single products and aggregate if necessary
                    const index = findProductIndex(products, product.id, selectedUnit);

                    if (index !== -1) {
                        // Product exists, update the quantity
                        products[index].quantity += line.quantity;
                    } else {
                        // New product, add to array
                        products.push({
                            product,
                            quantity: line.quantity,
                            lineQuantity,
                            selectedUnit,
                        });
                    }
                }
            }),
        );
    } catch (error) {
        console.log(error);
    }

    return products; // Return the populated products array
};

const prepareUpdate = async (lineProducts, type, business_id) => {
    return await Promise.all(
        lineProducts.map(async ({product, quantity, selectedUnit, lineQuantity}) => {
            const prodBusiness = await product.getProdBusiness(business_id);
            const product_template = await product.product_template.fetch();
            const {unit, secondary_unit, conversion_rate} = product_template;

            console.log('prodBusiness', prodBusiness);

            let quantityToSubtract = 0;

            if (selectedUnit === unit) {
                if (product.type === 'deal') {
                    quantityToSubtract = lineQuantity * quantity;
                } else {
                    quantityToSubtract = quantity;
                }
            } else {
                const convertToPrimaryUnitQuantity = (1 / conversion_rate) * lineQuantity;
                quantityToSubtract = convertToPrimaryUnitQuantity;
            }

            console.log('----------', prodBusiness[0], quantityToSubtract);
            return prodBusiness[0].prepareUpdate(item => {
                item.quantity = type === 'sale' ? item.quantity - quantityToSubtract : item.quantity + quantityToSubtract;
            });
        }),
    );
};

export const bulkUpdateOrderProductQuantity = async (lines, type, business_id) => {
    await database.write(async () => {
        const lineProducts = await getProducts(lines);
        console.log('lineProducts', lineProducts);
        const allRecords = await prepareUpdate(lineProducts, type, business_id);
        console.log('all recordes', allRecords);
        await database.batch(...allRecords);
    });
};

export const bulkCreateOrderLines = async (order, items) => {
    await database.write(async () => {
        const allRecords = prepareInsertion(order, items);
        await database.batch(...allRecords);
    });
};

const prepareInsertion = (order, items) => {
    return items.map(item => {
        try {
            return orderLine.prepareCreate(field => {
                field._raw.id = uuid();
                field.order.set(order);
                !item?.is_open && field.product.set(item.product);
                field.name = item?.name;
                field.quantity = item?.quantity;
                field.unit = item?.unit;
                field.cost_price = item?.cost_price;
                field.selling_price = item?.selling_price;
                field.base_price = item?.selling_price;
                field.discount = item?.discount || 0;
                field.discount_type = item?.discount_type;
                field.is_open = item?.is_open || false;
                field.rate = item.rate;
                field.tax_name = item.tax_name;
                field.tax_rate = item.tax_rate;
                field.is_tax_inclusive = item.is_tax_inclusive;
                field.transacted_at = new Date().getTime();
                field.order_line_modifiers = item?.order_line_modifiers || {};
                field.note = item?.note || '';
            });
        } catch (e) {
            console.log('failed to multi insert items ', e);
        }
    });
};

export const bulkUpdateOrderLines = async items => {
    console.log('Updating orderlines...');
    await database.write(async () => {
        const allRecords = prepareOrderUpdation(items);
        await database.batch(...allRecords);
        console.log('order lines updated ', allRecords.length);
    });
};

const prepareOrderUpdation = items => {
    return items.map(item => {
        try {
            const {original, updated} = item;

            return original.prepareUpdate(x => {
                x.name = updated?.name;
                x.quantity = updated?.quantity;
                x.unit = updated?.unit;
                x.cost_price = updated?.cost_price;
                x.selling_price = updated?.selling_price;
                x.base_price = updated?.selling_price;
                x.discount = updated?.discount || 0;
                x.discount_type = updated?.discount_type;
                x.is_open = updated?.is_open || false;
                x.rate = updated?.rate;
                x.tax_name = updated?.tax_name;
                x.tax_rate = updated?.tax_rate;
                x.is_tax_inclusive = updated?.is_tax_inclusive;
                x.order_line_modifiers = updated?.order_line_modifiers || {};
                x.note = updated?.note || '';
            });
        } catch (err) {
            console.log('Error in prepareOrderUpdation ', err);
        }
    });
};

export const bulkDeleteOrderLinesV2 = async items => {
    await database.write(async () => {
        const allRecords = prepareDeletion(items);
        await database.batch(...allRecords);
        console.log('order lines deleted', allRecords.length);
    });
};

const prepareDeletion = items => {
    return items.map(order => {
        try {
            return order.prepareMarkAsDeleted();
        } catch (err) {
            console.log('error in prepareDeletion', err);
        }
    });
};
