import React from 'react';
import {useAuthContext, useLanguageContext, useThemeContext} from '../../../context';
import StatusBox from '../StatusBox';
import DashboardListNonIdeal from '../NonIdeal/DashboardListNonIdeal';
import {formatDate, formatNum} from '../../../constants';
import {useNavigate} from 'react-router-dom';

const RecentDeposits = ({title, list, footer}) => {
    const {theme} = useThemeContext();
    const {I18n} = useLanguageContext();
    const {business} = useAuthContext();
    let navigate = useNavigate();

    const handleNavigate = id => {
        if (id && business?.id) navigate(`/activity/${id}`, {state: {businessId: business?.id}});
    };

    return (
        <div>
            <p className="marTop20 marBot20 fontSize20 fontWeight500" style={{color: theme.white}}>
                {title}
            </p>

            {list?.length > 0 ? (
                <>
                    <div className="pad10">
                        <div className="spaceBetweenCenter width100">
                            <div>
                                <p className=" marBot0 fontSize16 fontWeight500" style={{color: theme.white}}>
                                    {I18n.transactions}
                                </p>
                            </div>

                            <div className="textRight">
                                <p className=" marBot0 fontSize16 fontWeight500" style={{color: theme.white}}>
                                    {I18n.amount}
                                </p>
                            </div>
                        </div>
                        <div className="thinDivider width100 marTop10 marBot10" />
                        {list.map((fund, ind) => {
                            return (
                                <div key={ind}>
                                    <div className="spaceBetweenCenter cursorPointer">
                                        <div className="flex verticalCenter gap10">
                                            <p
                                                onClick={() => handleNavigate(fund?.transaction_id)}
                                                className="marBot0 fontSize14 fontWeight400"
                                                style={{color: theme.barclaysBlue}}>
                                                {`Deposited on ${formatDate(fund.date)}`}
                                            </p>
                                            <StatusBox status="succeeded" />
                                        </div>

                                        <div>
                                            <p className="marBot0 fontSize14 fontWeight400" style={{color: theme.white}}>
                                                <span className="marRight5">Rs.</span>
                                                {formatNum(fund.net_amount)}
                                            </p>
                                        </div>
                                    </div>
                                    <div className="thinDivider width100 marTop10 marBot10" />
                                </div>
                            );
                        })}

                        <div className="flex width100">
                            <p className="marBot0 fontSize14 fontWeight500 cursorPointer" style={{color: theme.barclaysBlue}} onClick={() => footer.action()}>
                                {footer.title}
                            </p>
                        </div>
                    </div>
                </>
            ) : (
                <DashboardListNonIdeal title="youve_not_done_any_transactions_yet" chipColor={'#2f3651'} textColor="white" />
            )}
        </div>
    );
};

export default RecentDeposits;
