import {useLanguageContext, useThemeContext} from '../../../../../context';
import {Dropdown, MessageModal} from '../../../../common';
import TableBody from '../../../../common/TableContainer/TableBody';
import {useNavigate} from 'react-router-dom';
import {useState} from 'react';

const List = ({floorPlan, userPermission}) => {
    let navigate = useNavigate();
    const {I18n} = useLanguageContext();
    const {theme} = useThemeContext();
    const [archiveModal, setArchiveModal] = useState(false);
    const toggleArchiveModal = () => setArchiveModal(x => !x);

    const tableAction = [
        {
            title: I18n.edit,
            action: () => navigate(`/create-floor-plan/${floorPlan.id}/edit`),
        },
        {
            title: I18n[floorPlan.archive ? 'unarchive' : 'archive'],
            action: toggleArchiveModal,
        },
    ];

    const handleArchive = async () => {
        let status = floorPlan.archive ? false : true;
        await floorPlan.archiveFloorPlan(status);
        setArchiveModal(false);
    };

    return (
        <>
            <TableBody className={`threeColumn cursorPointer`} style={{borderColor: theme.inputBorder}}>
                <div className="BoxWidth justifyStart">
                    <p className="fontSize14 OneLineTruncate fontWeight500" style={{color: theme.white}}>
                        {floorPlan.name}
                    </p>
                </div>

                <div className="BoxWidth justifyCenter">
                    <p className="fontSize14 OneLineTruncate" style={{color: theme.white}}>
                        {floorPlan.type}
                    </p>
                </div>

                {userPermission?.canCrud && (
                    <div className="BoxWidth justifyCenter ">
                        <div className="listActionBox">
                            <Dropdown
                                dropDown={{
                                    name: 'threeDotIcon',
                                    fill: theme.barclaysBlue,
                                    width: 18,
                                    height: 4,
                                    viewBox: '0 0 18 4',
                                    option: tableAction,
                                }}
                            />
                        </div>
                    </div>
                )}
            </TableBody>

            <MessageModal
                setToggle={setArchiveModal}
                toggle={archiveModal}
                className="messageModal"
                title={I18n.archive_customer}
                description={`${I18n[floorPlan.archive ? 'are_you_sure_you_want_to_unarchive' : 'are_you_sure_you_want_to_archive']}  ${floorPlan.name} ?`}
                secondaryCta={{
                    title: I18n.no,
                    action: toggleArchiveModal,
                }}
                primaryCta={{
                    title: I18n.yes,
                    action: () => {
                        handleArchive(floorPlan);
                    },
                    isDelete: true,
                }}
            />
        </>
    );
};

export default List;
