import React from 'react';
import {useLanguageContext, useThemeContext} from '../../../context';
import TableBody from '../TableContainer/TableBody';
import Icon from '../../../assets/icons';

const List = ({data, index, label}) => {
    const {theme} = useThemeContext();
    const {I18n} = useLanguageContext();
    const {sales, payments, percentage} = data || {};

    return (
        <TableBody key={index} className={`fourColumn cursorPointer`} style={{borderColor: theme.inputBorder}}>
            <div className="BoxWidth justifyStart">
                <p className="fontSize14 OneLineTruncate" style={{color: theme.white}}>
                    {
                        label === 'card' ? (
                            <span className='cardIcon'>
                            <Icon 
                                name='cardIconNew'
                                width='24' 
                                height='24' 
                                viewBox='0 0 24 24'                             
                            />
                            </span>
                        )
                        : label === 'credit' ? (
                            <span className='creditIcon'>
                            <Icon 
                                name='creditIconNew'
                                fill='theme.brightGreen'
                                width='24' 
                                height='24' 
                                viewBox='0 0 24 24'                             
                            />
                            </span>
                        )
                        : 
                        label === 'wallet' ? (
                            <span className='walletIcon'>
                            <Icon 
                                name='walletIconNew'
                                width='24' 
                                height='24' 
                                viewBox='0 0 24 24'                             
                            />
                            </span>
                        )
                        : 
                        (
                            <span className='rsIcon'>
                            <Icon 
                                name='rsIconNew'
                                fill='theme.brightGreen'
                                width='24' 
                                height='24' 
                                viewBox='0 0 24 24'                             
                            />
                            </span>
                        )
                    }                    
                    <span className='marLeft10'>
                        {label}
                    </span>
                </p>
            </div>
            <div className="BoxWidth justifyCenter">
                <p className="fontSize14 OneLineTruncate" style={{color: theme.white}}>
                    {payments}
                </p>
            </div>
            <div className="BoxWidth justifyCenter">
                <p className="fontSize14 OneLineTruncate" style={{color: theme.white}}>
                    {percentage}
                </p>
            </div>
            <div className="BoxWidth justifyEnd">
                <p className="fontSize14 OneLineTruncate" style={{color: theme.white}}>
                    Rs. {sales}
                </p>
            </div>
        </TableBody>
    );
};

export default List;
