import {useState} from 'react';
import {Col, Row} from 'react-bootstrap';
import {useNavigate} from 'react-router-dom';
import Icon from '../../../../assets/icons';
import {useLanguageContext, useThemeContext} from '../../../../context';
import {Button, CustomContainer, TextField} from '../../../common';
import {toSnakeCase, validateSendEmailForm} from '../../../../constants';
import {sendOtp} from '../../../../api';

const EmailScreen = ({setStep}) => {
    const navigate = useNavigate();

    const {I18n} = useLanguageContext();
    const {theme} = useThemeContext();

    const [isLoading, setIsLoading] = useState(false);
    const [email, setEmail] = useState('');
    const [errors, setErrors] = useState({});

    const onChange = (label, value) => {
        if (label === I18n.enter_email) {
            setEmail(value);
        }

        setErrors(prevErrors => {
            const updatedErrors = {...prevErrors};

            if (label === I18n.enter_email) {
                label = 'enter_email_address';
            }

            if (updatedErrors[toSnakeCase(label)]) {
                delete updatedErrors[toSnakeCase(label)];
            }

            return updatedErrors;
        });
    };

    const handleRecover = async () => {
        try {
            setIsLoading(true);
            const payload = {
                email,
            };
            const formErrors = validateSendEmailForm(payload);
            console.log('form Errors', formErrors);
            setErrors(formErrors);

            if (Object.keys(formErrors).length > 0) return;

            const response = await sendOtp(payload);
            if (response.success !== true) {
                setErrors({enter_email_address: response.msg});
            } else {
                localStorage.setItem('email', response.email);
                localStorage.setItem('created_at', response.created_at);
                setStep(2);
            }
            setIsLoading(false);
        } catch (error) {
            console.log('err', error);
        } finally {
            setIsLoading(false);
        }
    };

    const handleEnterPress = event => {
        if (event.key === 'Enter') {
            handleRecover();
        }
    };

    return (
        <CustomContainer handlePress={handleEnterPress} className="loginPageWrapper">
            <div className="logoBox">
                <Icon name={'oscarLogo'} fill={'white'} viewBox={'0 0 115 110'} width={'55'} height={'50'} />
            </div>

            <Row className="justify-content-md-center">
                <Col md={5}>
                    <div className="loginFormBox">
                        <h1 className="fontSize24 fontWeight700 marBot10 " style={{color: theme.text}}>
                            {I18n.reset_your_password}
                        </h1>
                        <p className="fontSize14 fontWeight400 marBot20 darkGrayTwo">{I18n.enter_the_email_address_you_used_to_register_with}</p>

                        <TextField
                            onChange={onChange}
                            label={I18n.enter_email}
                            placeholder={I18n.enter_email}
                            value={email}
                            error={errors.enter_email_address}
                            required={true}
                            autoFocus={true}
                        />

                        <div className="signinLoginButton marTop10">
                            <Button
                                className={`width100 fontWeight600 fontSize16 cursorPointer`}
                                title={I18n.continue}
                                saveLoad={isLoading ? true : false}
                                handleClick={handleRecover}
                            />
                        </div>

                        <p className="fontSize14 marTop20">
                            <span className="cursorPointer  marLeft5 barclaysBlueColor" onClick={() => navigate('/login')}>
                                {I18n.login}
                            </span>
                            <span className="marLeft5">
                                <Icon name="arrowRight" viewBox="0 0 18 18 " />
                            </span>
                        </p>
                    </div>
                </Col>
            </Row>
        </CustomContainer>
    );
};

export default EmailScreen;
