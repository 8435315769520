import {useAuthContext} from '../../../../../../context';
import useModifier from '../../../../../../hooks/useModifier';
import {Loading} from '../../../../../common';
import CreateModifier from '../CreateModifier';

const EditModifier = () => {
    const {account} = useAuthContext();
    const {isLoading, modifier} = useModifier(account);
    return isLoading ? <Loading /> : <CreateModifier selectedModifier={modifier} />;
};

export default EditModifier;
