import React from 'react';
import {useLanguageContext, useThemeContext} from '../../../context';
import TableBody from '../TableContainer/TableBody';

const ItemsList = ({data, index}) => {
    const {theme} = useThemeContext();
    const {I18n} = useLanguageContext();
    const {item_name, total_quantity, cost_price, unit_cost_percent, total_sales, sale_price, margin_percent, contribution} = data || {};

    return (
        <TableBody className={`threeColumn cursorPointer`} style={{borderColor: theme.inputBorder}}>
            <div key={index} className="BoxWidth justifyStart">
                <p className="fontSize14 OneLineTruncate" style={{color: theme.white}}>
                    {item_name}
                </p>
            </div>
            <div className="BoxWidth justifyCenter">
                <p className="fontSize14 OneLineTruncate" style={{color: theme.white}}>
                    {total_quantity}
                </p>
            </div>
            <div className="BoxWidth justifyCenter">
                <p className="fontSize14 OneLineTruncate" style={{color: theme.white}}>
                    {cost_price ? `Rs. ${cost_price}` : 'N/A'}
                </p>
            </div>
            <div className="BoxWidth justifyCenter">
                <p className="fontSize14 OneLineTruncate" style={{color: theme.white}}>
                    {unit_cost_percent} %
                </p>
            </div>
            <div className="BoxWidth justifyCenter">
                <p className="fontSize14 OneLineTruncate" style={{color: theme.white}}>
                    {sale_price ? `Rs. ${sale_price}` : 'N/A'}
                </p>
            </div>
            <div className="BoxWidth justifyCenter">
                <p className="fontSize14 OneLineTruncate" style={{color: theme.white}}>
                    {total_sales ? `Rs. ${total_sales}` : 'N/A'}
                </p>
            </div>
            <div className="BoxWidth justifyCenter">
                <p className="fontSize14 OneLineTruncate" style={{color: theme.white}}>
                    {margin_percent} %
                </p>
            </div>
            <div className="BoxWidth justifyEnd">
                <p className="fontSize14 OneLineTruncate" style={{color: theme.white}}>
                    {contribution} %
                </p>
            </div>
        </TableBody>
    );
};

export default ItemsList;
