import {Container, Col, Row} from 'react-bootstrap';
import {useAuthContext, useLanguageContext, useSessionContext, useThemeContext} from '../../../context';
import AppModal from '../AppModal';
import {Calculator, TextField} from '../../common';
import {DEVICE_HEIGHT, RESTAURANT} from '../../../constants';
import {useSessionUser} from '../../../hooks';
import Loading from '../Loading';
import {useEffect, useState} from 'react';
import bcryptjs, {compareSync} from 'bcryptjs';
import {useNavigate} from 'react-router-dom';
import {validateDevicePin} from '../../../api';
import Cookies from 'js-cookie';
import useFocus from '../../../hooks/useFocus';

const PosPinModal = ({toggle, setToggle, isKdsPin}) => {
    const {I18n} = useLanguageContext();
    const {theme} = useThemeContext();
    const navigate = useNavigate();

    const {isLoading, localUser} = useSessionUser();
    const {setSessionUser} = useSessionContext();
    const {handleKdsLogin, business} = useAuthContext();

    const [pin, setPin] = useState('');
    const [errors, setErrors] = useState({});
    const {textBoxRef, handleFocus} = useFocus();

    useEffect(() => {
        setTimeout(() => {
            handleFocus();
        }, 1000);
    }, []);
    const handleClose = event => {
        //
    };

    useEffect(() => {
        if (pin.length === 4 && isKdsPin) {
            validatePin(pin);
        }
        if (pin.length === 4 && !isKdsPin) {
            decrypt();
        }
    }, [pin]);

    const decrypt = () => {
        bcryptjs.compare(pin, localUser?.pin).then(res => {
            if (res) {
                setToggle(false);
                setPin('');
                setErrors({
                    pin: '',
                });

                if (business.type === RESTAURANT) {
                    navigate('/restaurant-pos');
                } else {
                    navigate('/pos');
                }
            } else {
                setErrors({
                    pin: I18n.invalid_pin,
                });
            }
        });
    };

    const validatePin = async value => {
        try {
            let device_id = Cookies.get('device_id');
            const pinObject = {
                pin: value,
                device_id: device_id,
            };

            const response = await validateDevicePin(pinObject);

            if (response.success) {
                await handleKdsLogin(response.data);

                setToggle(false);
                setPin('');
                setErrors({
                    pin: '',
                });
                navigate('/kitchendisplay');
            } else {
                console.log('wrong pin');
            }
        } catch (error) {
            console.log('error', error);
            setErrors({
                pin: I18n.invalid_pin,
            });
        }
    };

    const onChange = (label, val) => {
        setPin(val);
        if (val.length == 4 && !isKdsPin) {
            if (compareSync(val, localUser?.pin)) {
                setSessionUser(localUser);
            } else {
                setErrors({
                    pin: I18n.invalid_pin,
                });
            }
        }
        setErrors({});
    };

    return isLoading ? (
        <Loading />
    ) : (
        <AppModal className="createOptionModal" toggle={toggle} handleClose={handleClose} title={localUser?.name} disableCloseIcon={true}>
            <Container>
                <Row className="justify-content-md-center">
                    <Col md={4}>
                        <div className="pinModalContainer">
                            <div className="itemFields posPinField">
                                <div className="signupTextBox">
                                    <h2 className="optionPara fontSize20 fontWeight500 marBot25 marTop0 " style={{color: theme.text}}>
                                        {I18n.enter_pin_code}
                                    </h2>
                                </div>
                                <TextField
                                    inputRef={textBoxRef}
                                    autoFocus={true}
                                    onChange={onChange}
                                    error={pin.length === 4 && errors?.pin ? errors?.pin : null}
                                    value={pin}
                                    type="password"
                                    onlyNumbers={true}
                                    maxLength={4}
                                />
                                <div className="pinCodeCalculatorBox" style={{height: DEVICE_HEIGHT * 0.34}}>
                                    <Calculator columnFour={false} setState={setPin} maxLength={4} />
                                </div>
                            </div>
                        </div>
                    </Col>
                </Row>
            </Container>
        </AppModal>
    );
};

export default PosPinModal;
