import React from 'react';
import {useLanguageContext, useThemeContext} from '../../../context';
import TableBody from '../TableContainer/TableBody';
import {formatNum} from '../../../pos-core/constants';

const List = ({data, key}) => {
    const {theme} = useThemeContext();
    const {I18n} = useLanguageContext();
    const {item_name, barcode, qty_on_hand, sale_price, margin_percent, total_sales, total_quantity, contribution} = data || {};

    return (
        <TableBody key={key} className={`eightColumn cursorPointer`} style={{borderColor: theme.inputBorder}}>
            <div className="BoxWidth justifyStart">
                <p className="fontSize14 OneLineTruncate" style={{color: theme.white}}>
                    {item_name}
                </p>
            </div>
            <div className="BoxWidth justifyCenter">
                <p className="fontSize14 OneLineTruncate" style={{color: theme.white}}>
                    {barcode}
                </p>
            </div>
            <div className="BoxWidth justifyCenter">
                <p className="fontSize14 OneLineTruncate" style={{color: theme.white}}>
                    {qty_on_hand}
                </p>
            </div>
            <div className="BoxWidth justifyCenter">
                <p className="fontSize14 OneLineTruncate" style={{color: theme.white}}>
                    {total_quantity}
                </p>
            </div>
            <div className="BoxWidth justifyCenter">
                <p className="fontSize14 OneLineTruncate" style={{color: theme.white}}>
                    {`Rs. ${formatNum(sale_price || 0)}`}
                </p>
            </div>
            <div className="BoxWidth justifyCenter">
                <p className="fontSize14 OneLineTruncate" style={{color: theme.white}}>
                    {`Rs. ${formatNum(total_sales)}`}
                </p>
            </div>
            <div className="BoxWidth justifyCenter">
                <p className="fontSize14 OneLineTruncate" style={{color: theme.white}}>
                    {margin_percent}%
                </p>
            </div>
            <div className="BoxWidth justifyEnd">
                <p className="fontSize14 OneLineTruncate" style={{color: theme.white}}>
                    {contribution}%
                </p>
            </div>
        </TableBody>
    );
};

export default List;
